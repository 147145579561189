import React, {useEffect, useState} from "react";
import {Alert, AlertTitle} from "@material-ui/lab";
import {Paper, Box, Typography, Button} from "@material-ui/core";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import {useAppDispatch} from "../Contexts/AppContext";
import {useQuery} from "react-query";
import {getExportFiles} from "../api";
import {toLocalFormat} from "../Utils/dates";

export default function ExportFiles() {
    const {setBreadcrumbs, setSection} = useAppDispatch();

    const handleDownloadFile = (url) => {
        window.open(url)
    }

    const getStatusLabel = (status) => {
        return STATUS_LABELS[status];
    }

    const listRequest = useQuery(["get-export-files"], () => getExportFiles(), {
        keepPreviousData: false,
    });

    useEffect(() => {
        if (listRequest.isSuccess) {
            setExportFiles(listRequest.data.data);
        }
    }, [listRequest.isSuccess, listRequest.data]);

    useEffect(() => {
        setBreadcrumbs([
            {label: "Settings", link: "./settings"},
            {label: "Export files"},
        ]);
        setSection("settings");
    }, [setBreadcrumbs, setSection]);

    const [exportFiles, setExportFiles] = useState([]);

    const STATUS_NEW = 1;
    const STATUS_IN_PROGRESS = 2;
    const STATUS_COMPLETED = 3;

    const STATUS_LABELS = {
        1: "New",
        2: "In progress",
        3: "Completed",
    }

    if (!exportFiles.length) {
        return (
            <Alert severity="info">
                <AlertTitle>Files no found.</AlertTitle>
            </Alert>
        );
    }

    return (
        <Box m={3}>
            {exportFiles.map((exportFile) => (
                <Paper key={exportFile.id} style={{margin: "10px"}} elevation={1}>
                    <Box p={3}>
                        <Box display="flex" justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                                <FolderOpenIcon
                                    style={{marginRight: 10}}
                                    fontSize="large"
                                    color="primary"
                                />
                                <Typography component="span">
                                    <strong>{exportFile.filename}</strong>
                                </Typography>
                            </Box>
                            <Box>
                                <Button
                                    disabled={exportFile.status !== STATUS_COMPLETED}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={() => handleDownloadFile(exportFile.url)}

                                >Download
                                </Button>
                            </Box>
                        </Box>
                        <Typography>
                            Rows: <strong>{exportFile.total_rows}</strong>
                        </Typography>
                        <Typography>
                            Completed rows: <strong>{exportFile.completed_rows}</strong>
                        </Typography>
                        <Typography>
                            Segment: <strong>{exportFile.segment?.name}</strong>
                        </Typography>
                        <Typography>
                            Status: {<strong>{getStatusLabel(exportFile.status)}</strong>}
                        </Typography>
                        <Typography>
                            Created at: {<strong>{toLocalFormat(exportFile.created_at)}</strong>}
                        </Typography>
                    </Box>
                </Paper>
            ))}
        </Box>
    );
}
