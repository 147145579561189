import React, { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import axios from 'axios';
import AlertToastr from '../Utils/alert';

const jsonDefault = JSON.stringify({
    leads: [
        {
            attributes: [
                {
                    'type': 'email',
                    'attribute': 'Email',
                    'value': 'mail@gmail.com',
                },
                {
                    'type': 'phone',
                    'attribute': 'Phone',
                    'value': '+380502227777',
                },
                {
                    'type': 'string',
                    'attribute': 'Name',
                    'value': 'John',
                },
                {
                    'type': 'string',
                    'attribute': 'Domain',
                    'value': 'john.com',
                }
            ]
        },
        {
            attributes: [
                {
                    'type': 'email',
                    'attribute': 'Email',
                    'value': 'john@gmail.com',
                },
                {
                    'type': 'phone',
                    'attribute': 'Phone',
                    'value': '+38050333888',
                },
                {
                    'type': 'string',
                    'attribute': 'Name',
                    'value': 'Bob',
                }
            ]
        },
    ]
}, null, 2);

const TestImport = () => {

    const [apiKey, setApiKey] = useState('');
    const [data, setData] = useState(jsonDefault);
    const [hasError, setHasError] = useState(false);
    const apiUrl = useMemo(() => {
        const configUrl = process.env.REACT_APP_DATA_API_URL;
        const url = configUrl.slice(0, configUrl.length - 4);
        const apiKeyOutput = apiKey || '[API_KEY]';
        return `${url}import/lead?api_key=${apiKeyOutput}`;
    }, [apiKey]);

    const postMutation = useMutation(
        data => axios.post(apiUrl, data)
    )

    const reset = () => setData(jsonDefault);

    const save = async () => {
        try {
            const response = await postMutation.mutateAsync(JSON.parse(data));
            AlertToastr.showAlert('Lead has been saved.')
            console.log(response.data);
        } catch (e) {
            const errorResponse = e.response;
            if (errorResponse) {
                if (errorResponse.status === 401) {
                    AlertToastr.showErrorAlert(errorResponse?.data?.error?.message)
                }

                if (errorResponse.status === 422) {
                    AlertToastr.showErrorResponseAlert(e)
                }
            }

        }
    };

    const onChangeData = (e) => {
        const { value } = e.target;
        try {
            JSON.parse(value);
            setHasError(false);
        } catch (e) {
            setHasError(true);
        }
        setData(value);
    };

    const addAttr = () => {
        if (hasError) return;

        try {
            const arr = JSON.parse(data);
            if (Array.isArray(arr)) {
                arr.push({
                    'type': 'string',
                    'attribute': '',
                    'value': ''
                })
            }
            setData(JSON.stringify(arr, null, 2))
        } catch (e) {
            console.log(e)
        }

    }

    return (
        <div>
            <h1>Import Lead Test</h1>
            <label>
                API KEY <br />
                <input style={{minWidth: '350px'}} type="text" value={apiKey} onChange={e => setApiKey(e.target.value)} />
            </label>
            <br />
            <label>
                Attrs <button onClick={addAttr} disabled={hasError}>+</button> <br />
                <textarea
                    value={data}
                    onChange={onChangeData}
                    id=""
                    cols="60"
                    rows="25"
                />
            </label>
            <p>POST {apiUrl}</p>
            <button onClick={reset}>reset</button>
            <button onClick={save} disabled={hasError || !apiKey}>save</button>
        </div>
    );
};

export default TestImport;
