import {useEffect} from 'react'
import Echo from 'laravel-echo'
import {useAuth} from '../Contexts/AuthProvider'
import {useAppDispatch} from '../Contexts/AppContext'
import AlertToastr from "../Utils/alert";
import {useQueryClient} from "react-query";

window.Pusher = require('pusher-js')

const ImportSubscription = () => {

    const {token, userId} = useAuth()
    const {addExportFile} = useAppDispatch()
    const queryClient = useQueryClient();

    useEffect(() => {
        if (!token || !userId)
            return

        if (window.Echo)
            return

        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: 'local',
            cluster: 'mt1',
            forceTLS: false,
            wsHost: process.env.REACT_APP_PUSHER_HOST,
            wsPort: process.env.REACT_APP_PUSHER_PORT,
            wssPort: process.env.REACT_APP_PUSHER_PORT,
            disableStats: true,
            encrypted: true,
            authEndpoint: process.env.REACT_APP_AUTH_ENDPOINT,
            auth: {headers: {Authorization: `Bearer ${token}`}}
        })
        window.Echo.private(`App.Models.User.${userId}`)
            .listen('ImportStatus', e => {
                const {type, client_original_name, processed, current, total} = e
                let message = `${type} (${client_original_name})@ ${processed}`

                if (current && total) message = `step ${current} from ${total}: ${message}`
            })
            .listen('ExportStatus', data => addExportFile(data))
            .listen('UserMessage', data => {
                AlertToastr.showAlert(data.message);
            })
            .listen('RefreshSegmentCompleted', data => {
                if (data.is_sub_segment) {
                    queryClient.invalidateQueries('subsegmentsList');
                } else {
                    queryClient.invalidateQueries('segmentsList')
                }
            })

        return () => {

        }
    }, [
        token,
        userId,
        addExportFile
    ])

    return null
}

export default ImportSubscription
