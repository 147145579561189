import React, {useEffect, useRef, useMemo, useState} from "react";
import {useMutation} from "react-query";
import {withStyles} from "@material-ui/core/styles";
import IconAdd from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Popover from '@material-ui/core/Popover';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import SegmentFilterItem from "./SegmentFilterItem";
import {isFilterEmpty, getEmptySegment} from "./helpers";
import {useSegmentFilterState, useSegmentFilterDispatch} from "./SegmentFilterContext";
import {storeSegmentFilters} from "../../api";

const FiltersDropdown = withStyles({
    paper: {
        borderRadius: 8,
        width: 720,
        padding: "20px 10px",
    },
})((props) => (
    <Popover
        transformOrigin={{
            vertical: "top",
            horizontal: "left",
        }}
        {...props}
    />
));

const SegmentFilter = ({ segment, onSegmentUpdated }) => {
    const { normalizedFilters } = useSegmentFilterState();
    const {
        prepareFiltersBeforeStore,
        formatSegmentFilterError,
        getExpressions,
        getFields,
        setNormalizedFilters,
    } = useSegmentFilterDispatch();
    const initialState = useRef();
    const [filtersMenuEl, setFiltersMenuEl] = useState(null);
    const [errors, setErrors] = useState({});
    const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(true);

    useEffect(() => {
        if (filtersMenuEl) {
            initialState.current = normalizedFilters;
        } else {
            initialState.current = null;
            setIsApplyButtonDisabled(true);
        }
    }, [filtersMenuEl])

    const storeFiltersMutation = useMutation(storeSegmentFilters, {
        onError: (error) => {
            if (error?.response?.data?.errors) {
                setErrors(formatSegmentFilterError(normalizedFilters, error.response.data.errors));
            }
        },
        onSuccess: () => {
            setErrors({});
            onSegmentUpdated();
        }
    });

    const canAddFilter = useMemo(() => {
        return normalizedFilters.every((filter) => {
            return !isFilterEmpty(filter);
        });
    }, [normalizedFilters]);

    const storeFilters = async (normalizedFilters, segment) => {
        if (!segment) {
            return;
        }

        const notEmptyFilters = normalizedFilters.filter(f => !isFilterEmpty(f));
        const filtersToStore = prepareFiltersBeforeStore(notEmptyFilters);

        await storeFiltersMutation.mutateAsync({id: segment.id, filters: filtersToStore});
    };

    const handleClick = (event) => {
        if (normalizedFilters.length === 0) {
            onFilterAdd();
        }

        setFiltersMenuEl(event.currentTarget);
    };

    const handleClose = (skipSetInitialState = false) => {
        setFiltersMenuEl(null);
        if (initialState.current && !skipSetInitialState) {
            setNormalizedFilters(initialState.current);
        }
    };

    const onFilterAdd = () => {
        setNormalizedFilters((prev) => [...prev, getEmptySegment()]);
    }

    const onFilterRemove = (index, filter) => {
        setNormalizedFilters((prev) => {
            const nextValue = prev.filter((_, i) => i !== index);

            if (nextValue?.length === 0) {
                handleClose(true);
                storeFilters(nextValue, segment);
            }

            return nextValue;
        });
    }

    const onFilterChange = (index, filter) => {
        setNormalizedFilters((prev) => {
            const nextValue = prev.map((prevFilter, i) => {
                if (i === index) {
                    return filter;
                }

                return prevFilter;
            });

            const filterToChange = nextValue[index];
            if (!isFilterEmpty(filterToChange)) {
                setIsApplyButtonDisabled(false);
            } else {
                setIsApplyButtonDisabled(true);
            }

            return nextValue;
        });
    }

    const onFilterApply = () => {
        if (isApplyButtonDisabled) {
            return;
        }

        initialState.current = null;
        storeFilters(normalizedFilters, segment);
        handleClose();
    }

    return (
        <>
            {/*<pre>{ JSON.stringify(normalizedFilters, null, 2) }</pre>*/}
            <Button
                style={{ marginBottom: 15 }}
                startIcon={<IconAdd fontSize="small" />}
                disableRipple
                onClick={handleClick}
            >
                {
                    normalizedFilters.length === 0
                        ? "Add an attribute"
                        : `${normalizedFilters.length} attribute active`
                }
            </Button>
            <FiltersDropdown
                anchorEl={filtersMenuEl}
                open={Boolean(filtersMenuEl)}
                onClose={handleClose}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}  sm={1} style={{ paddingLeft: 14, paddingTop: 14 }}>
                        Where
                    </Grid>
                    <Grid item xs={11} sm={11} style={{ position: "relative" }}>
                        {
                            normalizedFilters.map((filter, index) => (
                                <SegmentFilterItem
                                    key={index}
                                    filter={filter}
                                    error={errors[index]}
                                    fieldOptions={getFields()}
                                    expressionOptions={getExpressions(filter)}
                                    isLast={index === normalizedFilters.length - 1}
                                    onFilterRemove={(filter) => onFilterRemove(index, filter)}
                                    onFilterChange={(filter) => onFilterChange(index, filter)}
                                />
                            ))
                        }
                    </Grid>
                </Grid>

                <Box style={{ paddingTop: 16, paddingRight: 10, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Button
                        size="small"
                        startIcon={<IconAdd fontSize="small" />}
                        disabled={!canAddFilter}
                        onClick={() => onFilterAdd()}
                    >
                        Add attribute
                    </Button>

                    <Button
                        color="primary"
                        variant="contained"
                        disabled={isApplyButtonDisabled}
                        onClick={() => onFilterApply()}
                    >
                        Apply
                    </Button>
                </Box>
            </FiltersDropdown>
        </>
    )
}

export default SegmentFilter;
