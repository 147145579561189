import { systemSegmentFilterField } from "../enums";

export const getDataFieldOptions = ({ disableSource, disableList }) => ([
    { id: systemSegmentFilterField.SOURCE, name: "Source", disabled: disableSource },
    { id: systemSegmentFilterField.LIST, name: "List", disabled: disableList },
])

export const getSystemFieldOptions = () => ([
    {id: systemSegmentFilterField.DUPLICATES, name: "Duplicates"},
    {id: systemSegmentFilterField.LEAD_STATUS, name: "Lead Status"},
    {id: systemSegmentFilterField.LEAD_PHONE_CARRIER_STATUS, name: "Lead Phone Carrier Status"},
    {id: systemSegmentFilterField.LEAD_PHONE_CAMPAIGN_STATUS, name: "Lead Phone Campaign Status"},
    {id: systemSegmentFilterField.LEAD_EMAIL_STATUS, name: "Lead Email Status"},
    {id: systemSegmentFilterField.CARRIER, name: "Carrier"},
    {id: systemSegmentFilterField.CARRIER_TYPE, name: "Carrier Type"},
    {id: systemSegmentFilterField.LAST_DAY_SMS_CLICKED, name: "DOW SMS Clicked"},
    {id: systemSegmentFilterField.LAST_DAY_EMAIL_CLICKED, name: "DOW Email Clicked"},
    {id: systemSegmentFilterField.WEEK_OF_YEAR_SMS_CLICKED, name: "WOY - Clicked"},
    {id: systemSegmentFilterField.WEEK_OF_YEAR_IMPORTED, name: "WOY - Imported"},
    {id: systemSegmentFilterField.CREATED_AT, name: "Imported Date"},
    {id: systemSegmentFilterField.DAY_IMPORT, name: "Day Import"},
    {id: systemSegmentFilterField.STATE, name: "State"},
    {id: systemSegmentFilterField.NON_DELIVERED_SMS_LEADS, name: "Non Delivered SMS Leads"},
]);

export const getCampaignFieldOptions = () => ([
    {id: systemSegmentFilterField.CAMPAIGN_SENT_SMS_STATUS, name: "Campaign Sent Sms Status"},
    {id: systemSegmentFilterField.CAMPAIGN_SENT_EMAIL_STATUS, name: "Campaign Sent Email Status"},
    {id: systemSegmentFilterField.CAMPAIGNS, name: "Campaign"},
    {id: systemSegmentFilterField.SMS_CLICKED, name: "Sms Clicked"},
    {id: systemSegmentFilterField.EMAIL_CLICKED, name: "Email Clicked"},
    {id: systemSegmentFilterField.SMS_UNSUBSCRIBED, name: "Sms Unsubscribed"},
    {id: systemSegmentFilterField.EMAIL_UNSUBSCRIBED, name: "Email Unsubscribed"},
    {id: systemSegmentFilterField.EMAIL_OPENED, name: "Email Opened"},
]);
