import React, {Fragment, useState} from 'react'
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import DataService from '../../Services/DataService'
import {useParams} from 'react-router-dom'
import {useAppDispatch} from '../../Contexts/AppContext'
import AlertToastr from "../../Utils/alert";

const LeadsExportModal = props => {

    const {open, handleClose, visibleColumns, search, sorting} = props
    const [name, setName] = useState('')
    const {segmentId, subsegmentId} = useParams()
    const {addExportFile} = useAppDispatch()
    const [exportInProgress, setExportInProgress] = useState(false);

    const handleExport = async () => {

        setExportInProgress(true)
        const segId = subsegmentId || segmentId

        const requestData = {
            segmentId: segId,
            attributes: visibleColumns,
            name,
            search: search || ''
        }

        if (sorting && sorting[0]) {
            const sortingFirst = sorting[0]
            if (sortingFirst.key && sortingFirst.direction) {
                requestData.order = `${sortingFirst.key},${sortingFirst.direction}`
            }
        }

        await DataService.exportToCSV(requestData)
            .then((response => {
                setName('')
                setExportInProgress(false)
                handleClose()
                AlertToastr.showAlert('Export added to queue. See result in Settings->Export Files', 'Success')
            }))
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.errors) {
                    AlertToastr.showErrorAlert(error.response.data.errors)
                }
                setExportInProgress(false)
            })

    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className='modal'
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
            >
                {exportInProgress ? <LinearProgress></LinearProgress> : null}
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>Export as CSV</h2>
                    </div>
                    <TextField
                        value={name}
                        onChange={e => setName(e.target.value)}
                        id="name"
                        label="Name"
                        variant="outlined"
                        margin='normal'
                    />
                    <div className="btnHolder hasPadTop twoBtn">
                        <div className="part">
                            <Button
                                disabled={!name || exportInProgress}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleExport}
                            >
                                Export
                            </Button>
                        </div>
                        <div className="part">
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default LeadsExportModal
