import React, {useState, useEffect} from 'react'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import DataService from '../../../Services/DataService'
import AlertToastr from '../../../Utils/alert'
import {useQueryClient} from "react-query";
import {AddCircleRounded, Close} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";

const CreateSenderEmailGroupModal = ({open, handleClose, senderEmailGroup}) => {

    const queryClient = useQueryClient()
    const [name, setName] = useState('')
    const [usernames, setUsernames] = useState([{id: null}])
    const [disableButtons, setDisabledButtons] = useState(false)

    useEffect(() => {
        if (senderEmailGroup) {
            setName(senderEmailGroup.name)
            let tempUsernames = [];
            senderEmailGroup.usernames.map(item => {
                tempUsernames.push({id: item.id, from_name: item.from_name, user_name: item.user_name})
            })
            setUsernames(tempUsernames)
        } else {
            setName('');
        }
    }, [senderEmailGroup])


    const handleCreate = () => {
        setDisabledButtons(true)
        DataService.createSenderEmailGroup(name, usernames)
            .then(response => {
                setName('')
                setUsernames([{id: null}])
                setDisabledButtons(false)
                handleClose(response.data.data)
                queryClient.invalidateQueries('senderEmailGroupList')
            })
            .catch((error) => {
                setDisabledButtons(false)
                if (error && error.response && error.response.data && error.response.data.errors) {
                    AlertToastr.showErrorAlert(error.response.data.errors)
                }
            })
    }

    const handleSave = () => {
        setDisabledButtons(true)
        DataService.updateSenderEmailGroup(senderEmailGroup.id, name, usernames)
            .then(response => {
                setDisabledButtons(false)
                setName('')
                setUsernames([{id: null}])
                handleClose()
                queryClient.invalidateQueries('senderEmailGroupList')
            })
            .catch((error) => {
                setDisabledButtons(false)
                if (error && error.response && error.response.data && error.response.data.errors) AlertToastr.showErrorAlert(error.response.data.errors)
            })
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className='modal'
        >
            <div className="modalBody">
                <div className="modalTitleHolder mb30">
                    <h2 className='mb15'>{senderEmailGroup ? 'Edit' : 'Create'} Sender Email Group</h2>
                </div>
                <TextField
                    value={name}
                    onChange={e => {
                        setName(e.target.value)
                    }}
                    id="source"
                    label="Name"
                    variant="outlined"
                    margin='normal'
                />
                {usernames.map((item, key) => (item.id !== undefined &&
                    <div className="wrapper content-around align-center" key={key}>
                        <TextField
                            value={item.from_name ?? ''}
                            id={'usernames_from_name_' + key}
                            onChange={e => {
                                usernames[key].from_name = e.target.value
                                setUsernames([...usernames]);
                            }}
                            label="From Name"
                            variant="outlined"
                            margin='normal'
                            name={"usernames_from_name[" + key + "]"}
                        />
                        <TextField
                            value={item.user_name ?? ''}
                            id={'usernames_user_name_' + key}
                            onChange={e => {
                                usernames[key].user_name = e.target.value
                                setUsernames([...usernames]);
                            }}
                            label="User Name"
                            variant="outlined"
                            margin='normal'
                            name={"usernames_user_name[" + key + "]"}
                        />
                        <IconButton style={{marginLeft: 5}} size="small" onClick={() => {
                            usernames.splice(key, 1);
                            setUsernames([...usernames]);
                        }}>
                            <Close fontSize="inherit"/>
                        </IconButton>
                    </div>
                ))}

                <div className="align-center wrapper content-justified">
                    <Button
                        variant="outlined"
                        name="add_usernames"
                        startIcon={<AddCircleRounded/>}
                        onClick={(e) => {
                            setUsernames([...usernames, {id: null}]);
                        }}
                    >Add Row</Button>
                </div>
                <div className="btnHolder hasPadTop twoBtn">
                    <div className="part">
                        <Button
                            disabled={!!disableButtons}
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={(e) => {
                                senderEmailGroup ? handleSave() : handleCreate()
                            }}
                        >
                            {senderEmailGroup ? 'Save' : 'Create'}
                        </Button>
                    </div>
                    <div className="part">
                        <Button
                            disabled={disableButtons}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default CreateSenderEmailGroupModal
