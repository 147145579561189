import React, {useEffect, useMemo, useState} from 'react';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import {useQuery, useQueryClient} from 'react-query';
import {getCampaignClicks} from '../../api';
import {useParams} from 'react-router-dom';
import {useQueryState} from 'use-location-state';
import ListTable from '../../components/ListTable/ListTable';
import {toLocalFormat} from "../../Utils/dates";
import CampaignClickSearch from "./clicks/CampaignClickSearch";
import AlertToastr from "../../Utils/alert";

const CampaignClicks = () => {
    const [clicks, setClicks] = useState([]);
    const [page, setPage] = useQueryState('page', 1);
    const [limit, setLimit] = useQueryState('limit', 20);
    const [pagination, setPagination] = useState(null);
    const [sorting, setSorting] = useState([{key: 'id', direction: 'desc'}]);
    const [search, setSearch] = useState({column: null, 'value': null})
    const {campaignId, loggedUser} = useParams();
    const queryClient = useQueryClient();

    const tableColumns = useMemo(() => {
        return [
            {
                Header: "ID",
                accessor: 'id',
            },
            {
                Header: 'Lead ID',
                accessor: 'lead_id',
                Cell: ({value, row}) => <a target="_blank" href={`/u/${loggedUser}/leads/${value}`}>{value}</a>,
            },
            {
                Header: "Click date",
                accessor: 'click_date',
                Cell: ({value, row}) => toLocalFormat(value),
            },
            {
                Header: 'IP',
                accessor: 'ip',
                Cell: ({value, row}) => {
                    return row.original.ip_address.ip
                }
            },
            {
                Header: 'Country',
                accessor: 'country',
                Cell: ({value, row}) => {
                    return row.original.ip_address.country
                }
            },
            {
                Header: 'Region',
                accessor: 'region_name',
                Cell: ({value, row}) => {
                    return row.original.ip_address.region_name
                }
            },
            {
                Header: 'City',
                accessor: 'city',
                Cell: ({value, row}) => {
                    return row.original.ip_address.city
                }
            },
            {
                Header: 'ISP',
                accessor: 'internet_provider',
                Cell: ({value, row}) => {
                    return row.original.ip_address.internet_provider
                }
            },
            {
                Header: 'ASNAME',
                accessor: 'asname',
                Cell: ({value, row}) => {
                    return row.original.ip_address.asname
                }
            },
            {
                Header: 'Mobile',
                accessor: 'mobile',
                Cell: ({value, row}) => {
                    return row.original.ip_address.mobile ? 'Yes' : 'No'
                }
            },
            {
                Header: 'Proxy',
                accessor: 'proxy',
                Cell: ({value, row}) => {
                    return row.original.ip_address.proxy ? 'Yes' : 'No'
                }
            },
            {
                Header: 'User agent',
                accessor: 'user_agent',
            },
            {
                Header: 'Referer',
                accessor: 'referer',
            }
        ];

    }, []);

    const tableValues = useMemo(() => {
        return clicks;
    }, [clicks]);

    const tableSorting = useMemo(() => {
        if (!sorting) return null;

        return sorting.map(item => ({
            id: item.key, desc: item.direction === 'desc'
        }))
    }, [sorting])

    const campaignClickRequest = useQuery(
        ['campaignClicksView', campaignId, page, limit, sorting, search],
        () => {
            return getCampaignClicks(campaignId, page, limit, sorting, search)
                .catch(error => {
                        if (error && error.response && error.response.data && error.response.data.errors) {
                            AlertToastr.showErrorAlert(error.response.data.errors)
                        }

                        return null;
                    }
                )
        },
        {keepPreviousData: true},
    );
    useEffect(() => {
        if (campaignClickRequest.isSuccess) {
            if (campaignClickRequest.data === null) {
                return;
            }

            setClicks(campaignClickRequest.data.data);
            const paginationData = campaignClickRequest.data.meta;
            if (paginationData.last_page < page) {
                setPage(1);
            }
            setPagination(paginationData);
        }
    }, [campaignClickRequest.isSuccess, campaignClickRequest.data, page, setPage]);

    const handleItemClick = item => {
        window.open(`/u/${loggedUser}/leads/${item?.lead_id}`)
    }

    return (
        <div className="campaignsPageHolder">
            <div className="timeLine">
                <div className="timeLineHeading mb15">
                    <h2>Clicks</h2>
                    <Button
                        disabled={campaignClickRequest.isFetching}
                        variant="contained"
                        color="primary"
                        startIcon={<RefreshIcon/>}
                        size="small"
                        onClick={() => queryClient.invalidateQueries('campaignClicksView')}
                    >
                        Refresh
                    </Button>
                </div>
                <CampaignClickSearch setSearch={setSearch}></CampaignClickSearch>
                <ListTable
                    columns={tableColumns}
                    data={tableValues}
                    loading={campaignClickRequest.isLoading}
                    fetching={campaignClickRequest.isFetching}
                    isSelectionColumn={false}
                    onRowClick={handleItemClick}
                    onSort={setSorting}
                    emptyDataText="Clicks Not Found"
                    pagination={pagination}
                    pageLimit={limit}
                    onPageChange={setPage}
                    onLimitChange={setLimit}
                    initSort={tableSorting}
                    disableSearchBlock
                />
            </div>
        </div>
    );
};

export default CampaignClicks;
