import React, {Fragment, useState, useEffect} from "react"
import {useNavigate, useParams} from "react-router-dom"
import Dialog from "@material-ui/core/Dialog"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import DataService from "../../Services/DataService"
import AlertToastr from "../../Utils/alert"
import IOSSwitch from "../IOSSwitch"
import {useQueryClient} from "react-query"

const SaveSegmentModal = props => {
    const {
        open,
        handleClose,
        isDuplicateSegmentMode,
        segment,
        parentSegment,
        onDuplicated,
        entityTitle,
    } = props

    const [name, setName] = useState("");
    const [disableButtons, setDisabledButtons] = useState(false);
    const [isUpdateDynamically, setIsUpdateDynamically] = useState(true);
    const params = useParams();
    const history = useNavigate();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (!open)
            return
        if (segment && !isDuplicateSegmentMode) {
            setName(segment.name)
            setIsUpdateDynamically(!segment.ignore_new_leads)
        } else {
            setName("")
            setIsUpdateDynamically(true)
        }
    }, [segment, isDuplicateSegmentMode, open])

    const handleCreate = () => {
        setDisabledButtons(true)
        if (isDuplicateSegmentMode && segment) {
            DataService.cloneSegment(segment.id, name, isUpdateDynamically).then(response => {
                setDisabledButtons(false)
                onDuplicated(response.data.data)
            })
                .catch((error) => {
                    setDisabledButtons(false)
                    if (error && error.response && error.response.data && error.response.data.errors)
                        AlertToastr.showErrorAlert(error.response.data.errors)
                })
        } else if (segment) {
            DataService.updateSegment(segment.id, name, isUpdateDynamically).then(response => {
                queryClient.invalidateQueries("segmentView").then()
                queryClient.invalidateQueries("subSegmentView").then()
                queryClient.invalidateQueries("segmentLeads").then()
                setDisabledButtons(false)
                handleClose()
            })
                .catch((error) => {
                    setDisabledButtons(false)
                    if (error && error.response && error.response.data && error.response.data.errors)
                        AlertToastr.showErrorAlert(error.response.data.errors)
                })
        } else
            if (parentSegment) {
                DataService.createChildSegment(parentSegment.id, name, isUpdateDynamically).then(async response => {
                    queryClient.invalidateQueries("segmentView").then()
                    history(`/u/${params.loggedUser}/audiences/${parentSegment.id}/segments/${response.data.data.id}`)
                })
                    .catch((error) => {
                        setDisabledButtons(false)
                        if (error && error.response && error.response.data && error.response.data.errors)
                            AlertToastr.showErrorAlert(error.response.data.errors)
                    })
            } else {
                queryClient.cancelQueries("segmentAggregations");
                DataService.createSegment(name, isUpdateDynamically).then(async response => {
                    history(`/u/${params.loggedUser}/audiences/${response.data.data.id}`)
                })
                .catch((error) => {
                    setDisabledButtons(false)
                    if (error && error.response && error.response.data && error.response.data.errors)
                        AlertToastr.showErrorAlert(error.response.data.errors)
                })
            }

    }

    const getActionName = () => {
        if (isDuplicateSegmentMode)
            return "Duplicate"
        if (!segment)
            return "Create"
        return "Edit"
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className="modal"
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className="mb15">{getActionName()} {entityTitle || "Audience"}</h2>
                        {/*<p>Use all of the current results to create a new audience.</p>*/}
                    </div>
                    <TextField
                        value={name}
                        onChange={e => setName(e.target.value)}
                        id="source"
                        label="Name"
                        variant="outlined"
                        margin="normal"
                    />
                    <div className="align-center wrapper content-justified">
                        Update dynamically
                        <IOSSwitch
                            color="primary"
                            checked={!!isUpdateDynamically}
                            onChange={() => {
                                setIsUpdateDynamically(!isUpdateDynamically)
                            }}
                        />
                    </div>
                    <div className="btnHolder hasPadTop twoBtn">
                        <div className="part">
                            <Button
                                disabled={!!disableButtons}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleCreate}
                            >
                                {segment && !isDuplicateSegmentMode ? "Save" : "Create"}
                            </Button>
                        </div>
                        <div className="part">
                            <Button
                                disabled={!!disableButtons}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default SaveSegmentModal
