import React from "react"
import { NavLink, Outlet } from "react-router-dom";
import { useAuth } from "../../Contexts/AuthProvider"
import LogoNctek from "../../assets/img/nctek.svg";
import LogoSegma from "../../assets/img/Segma.svg";
import Logo from "../../assets/img/Leadsta.svg";

const AuthLayout = () => {
    const { isLoggedIn } = useAuth()

    return (
        <div className="authLayout">
            <div className="authContent">
                <div className="formPart">
                    <div className="logoHolder">
                        <img
                            className="image-center"
                            height="100"
                            src={window.location.host.includes("nctek.segma.io") ? LogoNctek : window.location.host.includes("segma.io") ? LogoSegma : Logo}
                            alt="logo"
                        />
                    </div>
                    {!isLoggedIn && <ul className="authFormNav mb30">
                        <li className="authFormNavItem">
                            <NavLink to="/signin" exact="true">
                                Login
                            </NavLink>
                        </li>
                        <li className="authFormNavItem">
                            <NavLink to="/register">
                                Register
                            </NavLink>
                        </li>
                    </ul>}
                    <Outlet />
                </div>
            </div>
        </div>

    )
}

export default AuthLayout
