import React from 'react'
import { replaceStatus } from '../helpers'

const LeadStatus = ({status}) => {

    if(!status){
        return null;
    }

    return (
        <div className={`chipLike ${replaceStatus(status)}`}>
            <span className='chipLikeLabel'>{replaceStatus(status)}</span>
        </div>
    )
}

export default LeadStatus
