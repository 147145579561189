import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {useAppDispatch} from '../../Contexts/AppContext'
import ExitIcon from '../../assets/img/exit_to_app.svg'
import EventEmitter from '../../Utils/EventEmitter'
import {useMutation, useQueryClient} from 'react-query'
import {relookupLead, removeLeads, updateLeadsStatus} from '../../api'
import ConfirmModal from "../../components/modals/ConfirmModal";
import DeleteModal from '../../components/modals/DeleteModal';
import Settings from '../../assets/img/settings.svg'
import {replaceStatus, statusContextMenu} from '../../helpers'
import AlertToastr from '../../Utils/alert'
import RelookupEmailLeadModal from "../../components/modals/leads/lookup/RelookupEmailLeadModal";
import RelookupSmsLeadModal from "../../components/modals/leads/lookup/RelookupSmsLeadModal";
import RelookupSmsDNCLeadModal from "../../components/modals/leads/lookup/RelookupSmsDNCLeadModal";

const LeadPageActions = ({lead, loading, onDelete}) => {

    const {setContextMenu} = useAppDispatch()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [showLookupModalEmail, setShowLookupModalEmail] = useState(false)
    const [showLookupModalSMS, setShowLookupModalSMS] = useState(false)
    const [showLookupModalSMSDNC, setShowLookupModalSMSDNC] = useState(false)
    const deleteLeadsMutation = useMutation(removeLeads)
    const relookupLeadMutation = useMutation(relookupLead);
    const updateLeadsStatusMutation = useMutation(updateLeadsStatus)
    const queryClient = useQueryClient()

    useEffect(() => {
        setContextMenu([
            {
                label: 'Remove Lead',
                icon: ExitIcon,
                action: () => EventEmitter.emit('LeadDelete'),
                disabled: loading
            },
            {
                label: updateLeadsStatusMutation.isLoading ? 'Updating...' : 'Set status',
                icon: Settings,
                disabled: updateLeadsStatusMutation.isLoading || !lead || loading,
                iconStyle: {transform: 'rotate(45deg)'},
                items: statusContextMenu().filter(item => lead && item.label !== replaceStatus(lead.status))
            },
            {
                label: 'Relookup',
                icon: Settings,
                actionSMS: () => EventEmitter.emit('RelookupLeadSMS'),
                actionSmsDNC: () => EventEmitter.emit('RelookupLeadSMSDnc'),
                actionEmail: () => EventEmitter.emit('RelookupLeadEmail'),
                disabled: updateLeadsStatusMutation.isLoading || !lead || loading || lead.status === 'active',
            }
        ])
    }, [setContextMenu, loading, updateLeadsStatusMutation.isLoading, lead])

    const updateItemsStatus = useCallback(async status => {
        if (!lead) return
        const response = await updateLeadsStatusMutation.mutateAsync({ids: [lead.id], status})
        const {info, errors} = response
        if (info && info.length) {
            info.forEach(message => AlertToastr.showAlert(message))
        }
        if (errors && errors.length) {
            // errors.forEach(message => AlertToastr.showErrorAlert(message))
        }
        queryClient.invalidateQueries('leadView').then()
    }, [queryClient, updateLeadsStatusMutation, lead])
    const lookupItemsSMS = async () => {
        const data = {
            id: lead.id,
            type: "lead_phone"
        }
        await relookupLeadMutation.mutateAsync(data)
            .then(() => {

                setShowLookupModalSMS(false)
                AlertToastr.showAlert('Lookup has been started')
            })
            .catch(error => {
                if (error && error.response && error.response.data && error.response.data.errors) {
                    AlertToastr.showErrorAlert(error.response.data.errors)
                }
            })
    }

    useEffect(() => {
        const onEvent = () => setShowLookupModalEmail(true)

        const listener = EventEmitter.addListener('RelookupLeadEmail', onEvent)
        return () => {
            listener.remove()
        }
    }, [relookupLeadMutation])
    useEffect(() => {
        const onEvent = () => setShowLookupModalSMS(true)

        const listener = EventEmitter.addListener('RelookupLeadSMS', onEvent)
        return () => {
            listener.remove()
        }
    }, [relookupLeadMutation])
    useEffect(() => {
        const onEvent = () => setShowLookupModalSMSDNC(true)

        const listener = EventEmitter.addListener('RelookupLeadSMSDnc', onEvent)
        return () => {
            listener.remove()
        }
    }, [relookupLeadMutation])
    useEffect(() => {
        const onEvent = () => setShowDeleteModal(true)

        const listener = EventEmitter.addListener('LeadDelete', onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowConfirmModal(true)

        const listener = EventEmitter.addListener('RelookupLead', onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = (status) => updateItemsStatus(status)

        const listener = EventEmitter.addListener('LeadsBulkChangeStatus', onEvent)
        return () => {
            listener.remove()
        }
    }, [updateItemsStatus])

    const deleteItems = async () => {
        setShowDeleteModal(false)
        if (lead) {
            await deleteLeadsMutation.mutateAsync([lead.id])
            queryClient.invalidateQueries('leadsList').then(() => {
                if (onDelete) onDelete()
            })
        }

    }
    return (
        <Fragment>
            <DeleteModal
                loading={deleteLeadsMutation.isLoading}
                open={showDeleteModal}
                handleDelete={deleteItems}
                handleClose={() => setShowDeleteModal(false)}
                instance={'the lead'}
            />
            <RelookupSmsLeadModal
                open={showLookupModalSMS}
                handleClose={(e) => setShowLookupModalSMS(false)}
            />
            <RelookupSmsDNCLeadModal
                open={showLookupModalSMSDNC}
                handleClose={(e) => setShowLookupModalSMSDNC(false)}
            />
            <RelookupEmailLeadModal
                open={showLookupModalEmail}
                handleClose={(e) => setShowLookupModalEmail(false)}
            />
        </Fragment>
    )
}

export default LeadPageActions
