import React, { useState, useEffect } from 'react'
import TextField from "@material-ui/core/TextField";
import { useNavigate } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import useApiCall from '../../Hooks/useApiCall'
import authEndpoints from '../../Endpoints/auth.endpoints'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import { useAuth } from '../../Contexts/AuthProvider'
import AlertToastr from '../../Utils/alert'

const SourcesLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} to="/" {...props} />)

const ChangePasswordPage = () => {
    const [oldPassword, setOldPassword] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [request, changePassword] = useApiCall()
    const history = useNavigate();
    const { userId } = useAuth()

    const onChangeClicked = (e) => {
        e.preventDefault()
        changePassword({
            ...authEndpoints.changePassword(userId),
            data: {
                current_password: oldPassword,
                password: password,
                password_confirmation: confirmPassword
            }
        })
    }

    useEffect(() => {
        if (request.error) {
            AlertToastr.showErrorAlert(request.error.message)
        }
    }, [request.error])

    useEffect(() => {
        if (!request.response)
            return
        AlertToastr.showAlert('Password updated successfully!')
        history(`/`)

    }, [request.response, history])

    return (
        <div className='formHolder'>
            <p className='mb20'>Change password</p>
            <form noValidate autoComplete="off">
                <TextField
                    id="oldPassword"
                    label="Old password"
                    variant="outlined"
                    type="password"
                    value={oldPassword}
                    onChange={e => setOldPassword(e.target.value)}
                    margin='normal'
                />
                <TextField
                    id="password"
                    label="Password"
                    variant="outlined"
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    margin='normal'
                />
                <TextField
                    id="confirmPassword"
                    label="Confirm password"
                    variant="outlined"
                    type="password"
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                    margin='normal'
                />
                <div className="btnHolder mt20 mb15">
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth
                        onClick={onChangeClicked}
                    >
                        Change
                    </Button>
                </div>
                <Link component={SourcesLink} className="text-mute text-semi-bold linkColorNormal cursor-pointer">
                    Back
                </Link>
            </form>
        </div>
    )
}

export default ChangePasswordPage
