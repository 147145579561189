import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

const CampaignClickSearch = ({setSearch}) => {
    const [column, setColumn] = useState('');
    const [value, setValue] = useState('');

    const handleKeyPress = event => {
        if (event.key !== "Enter") {
            return;
        }
        updateSearch();
    }

    const updateSearch = () => {
        if (value) {
            setSearch({column: column, value: value})
        }
        if (!value && !column) {
            clearSearch();
        }
    }

    const clearSearch = () => {
        setSearch({column: null, value: null})
        setValue('');
        setColumn('');
    }

    const handleChangeSelect = event => {
        setColumn(event.target.value)
    }

    const handleChangeValue = event => {
        const newValue = event.target.value;

        setValue(newValue);
        if (value && !newValue) {
            clearSearch();
        }
    }

    useEffect(() => {
        updateSearch();
    }, [column])

    return (
        <div className="searchHolder">
            <div className="inputHolder" style={{width: "200px"}}>
                <FormControl variant="outlined" margin="none">
                    <InputLabel id="search_column_value">Column</InputLabel>
                    <Select
                        value={column ?? ''}
                        onChange={handleChangeSelect}
                    >
                        <MenuItem value="id">ID</MenuItem>
                        <MenuItem value="lead_id">Lead Id</MenuItem>
                        <MenuItem value="user_agent">User agent</MenuItem>
                        <MenuItem value="click_date">Click date</MenuItem>
                        <MenuItem value="ip">Ip address</MenuItem>
                        <MenuItem value="country">Country</MenuItem>
                        <MenuItem value="region_name">Region</MenuItem>
                        <MenuItem value="city">City</MenuItem>
                        <MenuItem value="internet_provider">ISP</MenuItem>
                        <MenuItem value="asname">Asname</MenuItem>
                        <MenuItem value="mobile">Mobile</MenuItem>
                        <MenuItem value="proxy">Proxy</MenuItem>
                        <MenuItem value="referer">Referer</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className="inputHolder" style={{width: "300px"}}>
                <TextField
                    value={value}
                    onChange={handleChangeValue}
                    onKeyPress={handleKeyPress}
                    id="value"
                    label="Value (type enter to apply)"
                    variant="outlined"
                    margin="none"
                />
            </div>
        </div>
    );
};

export default CampaignClickSearch;
