import React, {Fragment, useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import {useQuery} from "react-query";
import {getActiveEmailLookupGateways} from "../../../api";
import {Controller, useForm} from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import IOSSwitch from "../../IOSSwitch";

const EmailLookupConfirmModal = ({open, message, loading, handleClose, handleConfirm}) => {

    const [emailLookupGateways, setEmailLookupGateways] = useState([])

    const emailLookupGatewaysRequest = useQuery(
        ['activeEmailLookupGateways'],
        () => getActiveEmailLookupGateways(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (emailLookupGatewaysRequest.isSuccess) {
            setEmailLookupGateways(emailLookupGatewaysRequest.data.data)
        }
    }, [emailLookupGatewaysRequest.isSuccess, emailLookupGatewaysRequest.data])

    const {control, watch} = useForm();

    const lookup_gateway_id = watch('lookup_gateway_id');

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className='modal'
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>{message}</h2>
                    </div>
                    <Controller
                        name={'lookup_gateway_id'}
                        defaultValue=""
                        control={control}
                        render={({onChange, value}) => {
                            return (
                                <TextField
                                    select
                                    onChange={onChange}
                                    value={value}
                                    variant="outlined"
                                    margin="normal"
                                    label="Email Lookup Gateway"
                                >
                                    <MenuItem value="" disabled>
                                        Select gateway
                                    </MenuItem>
                                    {emailLookupGateways.map((gateway) => {
                                        return <MenuItem key={gateway.id} value={gateway.id}>
                                            {gateway.name}
                                        </MenuItem>
                                    })}
                                </TextField>
                            );
                        }}
                    />

                    <div className="btnHolder hasPadTop twoBtn">
                        <div className="part">
                            <Button
                                disabled={loading}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => {
                                    handleConfirm(lookup_gateway_id)
                                }}
                            >Yes</Button>
                        </div>
                        <div className="part">
                            <Button
                                disabled={loading}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default EmailLookupConfirmModal;
