import React, {useEffect, useMemo, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import {
    getAttributesAll,
    getCampaign,
    getCampaignLists,
    getCampaignSegments,
    getCampaignSources, getCampaignSpamEmailGroups,
    getCampaignSteps,
    getSegments, getSMSGateways,
    getSources,
} from '../api'

const CampaignStateContext = React.createContext(undefined)
const CampaignDispatchContext = React.createContext(undefined)

const CampaignProvider = ({children}) => {

    const {campaignId} = useParams()
    const [campaign, setCampaign] = useState(null)
    const [segments, setSegments] = useState([])
    const [sources, setSources] = useState([])
    const [lists, setLists] = useState([])
    const [spamEmailGroups, setSpamEmailGroups] = useState([])
    const [steps, setSteps] = useState([])
    const [availableSegments, setAvailableSegments] = useState([])
    const [availableSources, setAvailableSources] = useState([])
    const [availableAttributes, setAvailableAttributes] = useState([])
    const [smsStatuses, setSmsStatuses] = useState([])
    const [emailStatuses, setEmailStatuses] = useState([])
    const [engagements, setEngagements] = useState([])

    const campaignRequest = useQuery(
        ['campaignView', campaignId],
        () => getCampaign(campaignId),
        {keepPreviousData: true}
    )
    useEffect(() => {
        if (campaignRequest.isSuccess) setCampaign(campaignRequest.data.data)
    }, [campaignRequest.isSuccess, campaignRequest.data])

    const campaignSegmentsRequest = useQuery(
        ['campaignSegmentsView', campaignId],
        () => getCampaignSegments(campaignId),
        {keepPreviousData: true}
    )
    useEffect(() => {
        if (campaignSegmentsRequest.isSuccess) setSegments(campaignSegmentsRequest.data.data)
    }, [campaignSegmentsRequest.isSuccess, campaignSegmentsRequest.data])

    const campaignSpamEmailGroupRequest = useQuery(
        ['campaignSpamEmailGroupsView', campaignId],
        () => getCampaignSpamEmailGroups(campaignId),
        {keepPreviousData: true}
    )
    useEffect(() => {
            if (campaignSpamEmailGroupRequest.isSuccess) {
                setSpamEmailGroups(campaignSpamEmailGroupRequest.data.data)
            }
        }, [campaignSpamEmailGroupRequest.isSuccess, campaignSpamEmailGroupRequest.data]
    )

    const campaignSourcesRequest = useQuery(
        ['campaignSourcesView', campaignId],
        () => getCampaignSources(campaignId),
        {keepPreviousData: true}
    )
    useEffect(() => {
        if (campaignSourcesRequest.isSuccess) {
            setSources(campaignSourcesRequest.data.data)
        }
    }, [campaignSourcesRequest.isSuccess, campaignSourcesRequest.data])

    const campaignListsRequest = useQuery(
        ['campaignListsView', campaignId],
        () => getCampaignLists(campaignId),
        {keepPreviousData: true}
    )
    useEffect(() => {
        if (campaignListsRequest.isSuccess) {
            setLists(campaignListsRequest.data.data)
        }
    }, [campaignListsRequest.isSuccess, campaignListsRequest.data])


    const campaignStepsRequest = useQuery(
        ['campaignStepsView', campaignId],
        () => getCampaignSteps(campaignId),
        {keepPreviousData: true}
    )
    useEffect(() => {
        if (campaignStepsRequest.isSuccess) setSteps(campaignStepsRequest.data.data)
    }, [campaignStepsRequest.isSuccess, campaignStepsRequest.data])

    const attributesRequest = useQuery(
        ['attributes'],
        () => getAttributesAll(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (attributesRequest.isSuccess) setAvailableAttributes(attributesRequest.data.data)
    }, [attributesRequest.isSuccess, attributesRequest.data])

    const campaignHasResources = useMemo(() => {
        if (campaignSegmentsRequest.isSuccess && campaignSourcesRequest.isSuccess) {

            return !!(!segments || !sources || segments.length || sources.length)
        }
        return undefined
    }, [
        campaignSegmentsRequest.isSuccess,
        campaignSourcesRequest.isSuccess,
        segments,
        sources
    ])

    const availableSegmentsRequest = useQuery(
        ['segmentsList'],
        () => getSegments(1, 1000),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (availableSegmentsRequest.isSuccess)
            setAvailableSegments(availableSegmentsRequest.data.data)
    }, [
        availableSegmentsRequest.isSuccess, availableSegmentsRequest.data
    ])

    const availableSourcesRequest = useQuery(
        ['sourcesList'],
        () => getSources(1, 100),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (availableSourcesRequest.isSuccess)
            setAvailableSources(availableSourcesRequest.data.data)
    }, [
        availableSourcesRequest.isSuccess, availableSourcesRequest.data
    ])

    const [smsGateways, setSmsGateways] = useState([]);

    const listRequest = useQuery(
        ['smsGateways', []],
        () => getSMSGateways([]),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (listRequest.isSuccess) {
            setSmsGateways(listRequest.data.data)
        }
    }, [listRequest.isSuccess, listRequest.data])

    useEffect(() => {
        if (!campaign || !campaign.pool_id) {
            return;
        }
        if (smsGateways.length) {
            smsGateways.map((gateway) => {
                if (campaign.setting_gateway_id === gateway.id) {
                    gateway.pools.map((pool) => {
                        if (pool.key === campaign.pool_id) {
                            campaign.pool_label = pool.label + '(' + pool.key + ')';
                        }
                    })
                }
            });
        } else {
            campaign.pool_label = campaign.pool_id;
        }
    }, [campaign, smsGateways]);

    return (
        <CampaignStateContext.Provider value={{
            campaign,
            segments,
            sources,
            lists,
            spamEmailGroups,
            steps,
            campaignRequest,
            campaignSegmentsRequest,
            campaignSpamEmailGroupRequest,
            campaignSourcesRequest,
            campaignListsRequest,
            campaignStepsRequest,
            campaignHasResources,
            availableSegments,
            availableSources,
            availableSegmentsRequest,
            availableSourcesRequest,
            availableAttributes,
            smsStatuses,
            emailStatuses,
            engagements
        }}>
            <CampaignDispatchContext.Provider value={{
                setSmsStatuses,
                setEngagements,
                setEmailStatuses,
            }}>
                {children}
            </CampaignDispatchContext.Provider>
        </CampaignStateContext.Provider>
    )
}

const useCampaignState = () => {
    const context = React.useContext(CampaignStateContext)
    if (context === undefined) {
        throw new Error('useCampaignState must be used within a CampaignProvider')
    }
    return context
}

const useCampaignDispatch = () => {
    const context = React.useContext(CampaignDispatchContext)
    if (context === undefined) {
        throw new Error('useCampaignDispatch must be used within a CampaignProvider')
    }
    return context
}

export {
    CampaignProvider,
    useCampaignState,
    useCampaignDispatch
}
