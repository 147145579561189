import React, {useMemo} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Badge, Tooltip} from "@material-ui/core";

const MAX_CHAR_7BIT_SINGLE = 160
const MAX_CHAR_7BIT_CONCAT = 153
const MAX_CHAR_UCS2_SINGLE = 70
const MAX_CHAR_UCS2_CONCAT = 67
const BASIC_ESC_CHARS = ['|', '^', '€', '{', '}', '[', '~', ']', '\\']
const BASIC_CHARS = [
    '@', 'Δ', ' ', '0', '¡', 'P', '¿', 'p',
    '£', '_', '!', '1', 'A', 'Q', 'a', 'q',
    '$', 'Φ', '"', '2', 'B', 'R', 'b', 'r',
    '¥', 'Γ', '#', '3', 'C', 'S', 'c', 's',
    'è', 'Λ', '¤', '4', 'D', 'T', 'd', 't',
    'é', 'Ω', '%', '5', 'E', 'U', 'e', 'u',
    'ù', 'Π', '&', '6', 'F', 'V', 'f', 'v',
    'ì', 'Ψ', "'", '7', 'G', 'W', 'g', 'w',
    'ò', 'Σ', '(', '8', 'H', 'X', 'h', 'x',
    'Ç', 'Θ', ')', '9', 'I', 'Y', 'i', 'y',
    "\n", 'Ξ', '*', ':', 'J', 'Z', 'j', 'z',
    'Ø', '+', ';', 'K', 'Ä', 'k', 'ä',
    'ø', 'Æ', ',', '<', 'L', 'Ö', 'l', 'ö',
    "\r", 'æ', '-', '=', 'M', 'Ñ', 'm', 'ñ',
    'Å', 'ß', '.', '>', 'N', 'Ü', 'n', 'ü',
    'å', 'É', '/', '?', 'O', '§', 'o', 'à',
]

const charsInSms = (chars = 0, isUnicode = false) => {
    if (isUnicode) {
        return chars <= MAX_CHAR_UCS2_SINGLE ? MAX_CHAR_UCS2_SINGLE : MAX_CHAR_UCS2_CONCAT
    }
    return chars <= MAX_CHAR_7BIT_SINGLE ? MAX_CHAR_7BIT_SINGLE : MAX_CHAR_7BIT_CONCAT
}

const escCharsCount = (escChars = 0, isUnicode = false) => {
    return isUnicode ? escChars : escChars * 2
}


const useStyles = makeStyles((theme) => ({
    badge: {
        fontSize: "0.70rem",
        backgroundColor: "#000",
        color: "white",
        width: 12,
        height: 12,
        minWidth: 12,
        borderRadius: 6,
        top: "-1px",
        cursor: "pointer"
    }
}));


const SmsCounter = ({text = "", shortUrlDomainName = '', styleParam = {}}) => {
    let message = text || ""

    const smsUsed = useMemo(() => {
        let [chars, escChars, templateChars, isUnicode] = [0, 0, 0, false]

        const templates = message.match(/{{.+?}}/g)
        let templateText = message;
        if (Array.isArray(templates) && templates.length > 0) {
            templates.forEach(template => {
                if (template === '{{link}}') {
                    templateChars += shortUrlDomainName.length + 9;// / and 8 symbols hash code
                } else if (template.match(/{{from([0-9]+)?-([0-9]+)?by([0-9]+)?}}/g)) {
                    const randomNumber = template.match(/^{{from([0-9]+)?-([0-9]+)?by([0-9]+)?}}$/);

                    templateChars += randomNumber[2].length;
                } else {
                    templateChars += template.length
                }
                templateText = templateText.replace(new RegExp(template, "g"), '')
            })
        }

        for (let char of templateText) {
            if (!isUnicode && ![...BASIC_CHARS, ...BASIC_ESC_CHARS].includes(char)) isUnicode = true

            if (BASIC_ESC_CHARS.includes(char)) {
                escChars += 1
            } else {
                chars += 1
            }
        }
        const realChars = chars + templateChars + escCharsCount(escChars, isUnicode)
        const realCharsInSms = charsInSms(realChars, isUnicode)
        const realParts = Math.ceil(realChars / realCharsInSms)

        return {charts: realChars, parts: realParts, size: realCharsInSms}
    }, [message, shortUrlDomainName.length])

    const setStyle = {
        fontSize: 10, position: "relative", top: -15, left: 15, color: "rgb(175, 177, 179)",
        ...styleParam
    }

    const classes = useStyles();

    if (!(typeof text === 'string' || text instanceof String)) {
        return null
    }

    return (
        <div style={setStyle}>
            <span>{smsUsed.charts} out of {smsUsed.size}</span>
            <span style={{marginRight: 10}}>, {smsUsed.parts} part</span>

            <Tooltip title={<InfoTooltip/>} placement="right">
                <Badge
                    color="primary"
                    classes={{badge: classes.badge}}
                    badgeContent={
                        <span>?</span>
                    }
                >
                </Badge>
            </Tooltip>

        </div>
    )
}

const InfoTooltip = () => {
    return (
        <>
            <p style={{marginBottom: 10}}>Each part is charged as a separate message when sent</p>
            <table width={200}>
                <thead>
                <tr>
                    <th style={{textAlign: "left"}}><b>Parts</b></th>
                    <th style={{textAlign: "left"}}><b>Symbols</b></th>
                </tr>
                </thead>
                <tbody>
                <tr style={{textAlign: "left"}}>
                    <td>1</td>
                    <td>160</td>
                </tr>
                <tr style={{textAlign: "left"}}>
                    <td>2</td>
                    <td>306</td>
                </tr>
                <tr style={{textAlign: "left"}}>
                    <td>3</td>
                    <td>459</td>
                </tr>
                <tr style={{textAlign: "left"}}>
                    <td>n</td>
                    <td>153*n</td>
                </tr>
                </tbody>
            </table>
        </>
    )
}

export default SmsCounter
