import {segmentFilterExpression, systemSegmentFilterField, segmentFilterType} from "../enums";

const EQUALS = {
    value: segmentFilterExpression.EQUALS,
    label: "Equals"
};

const NOT_EQUALS = {
    value: segmentFilterExpression.NOT_EQUALS,
    label: "Not Equals"
};

const CONTAINS = {
    value: segmentFilterExpression.CONTAINS,
    label: "Contains"
};

const NOT_CONTAIN = {
    value: segmentFilterExpression.NOT_CONTAIN,
    label: "Not Contain"
};

const LIKE = {
    value: segmentFilterExpression.LIKE,
    label: "Contains"
};

const NOT_LIKE = {
    value: segmentFilterExpression.NOT_LIKE,
    label: "Not Contains"
};

const EMPTY = {
    value: segmentFilterExpression.EMPTY,
    label: "Empty"
};

const NOT_EMPTY = {
    value: segmentFilterExpression.NOT_EMPTY,
    label: "Not Empty"
};

const UNIQUE = {
    value: segmentFilterExpression.UNIQUE,
    label: "Unique"
};

const FIRST_APPEARED = {
    value: segmentFilterExpression.FIRST_APPEARED,
    label: "First Appeared"
};

const RANGE = {
    value: segmentFilterExpression.DATE_RANGE,
    label: "Range"
};

const CUSTOM_DATES = {
    value: segmentFilterExpression.CUSTOM_DATES,
    label: "Custom Dates"
}

export const DEFAULT_EXPRESSION = "_DEFAULT";

const defaultDataExpressionOptions = [
    EQUALS,
    NOT_EQUALS,
    UNIQUE,
    EMPTY,
    NOT_EMPTY
];
const defaultSystemExpressionOptions = [EQUALS, NOT_EQUALS];
const defaultCampaignExpressionOptions = [EQUALS];
const defaultAttributeExpressionOptions = [
    LIKE,
    NOT_LIKE,
    EQUALS,
    NOT_EQUALS,
    EMPTY,
    NOT_EMPTY,
];

export const expressionOptions = {
    [segmentFilterType.DATA]: {
        [DEFAULT_EXPRESSION]: defaultDataExpressionOptions,
        [systemSegmentFilterField.LIST]: [
            ...defaultDataExpressionOptions,
            FIRST_APPEARED,
        ]
    },
    [segmentFilterType.SYSTEM_ATTRIBUTES]: {
        [DEFAULT_EXPRESSION]: defaultSystemExpressionOptions,
        [systemSegmentFilterField.CARRIER]: [
            LIKE,
            NOT_LIKE,
            EQUALS,
            NOT_EQUALS,
            EMPTY,
            NOT_EMPTY,
        ],
        [systemSegmentFilterField.CARRIER_TYPE]: [
            EQUALS,
            NOT_EQUALS,
            EMPTY,
            NOT_EMPTY,
        ],
        [systemSegmentFilterField.CREATED_AT]: [
            RANGE,
            CUSTOM_DATES,
        ],
        [systemSegmentFilterField.LAST_DAY_EMAIL_CLICKED]: [
            EQUALS,
        ],
        [systemSegmentFilterField.LAST_DAY_SMS_CLICKED]: [
            EQUALS,
        ],
        [systemSegmentFilterField.NON_DELIVERED_SMS_LEADS]: [
            EQUALS,
        ],
        [systemSegmentFilterField.WEEK_OF_YEAR_IMPORTED]: [
            EQUALS,
        ],
        [systemSegmentFilterField.WEEK_OF_YEAR_SMS_CLICKED]: [
            EQUALS,
        ],
    },
    [segmentFilterType.CAMPAIGN_ATTRIBUTES]: {
        [DEFAULT_EXPRESSION]: defaultCampaignExpressionOptions,
        [systemSegmentFilterField.CAMPAIGNS]: [
            EQUALS,
            NOT_EQUALS,
            EMPTY,
            NOT_EMPTY,
        ],
        [systemSegmentFilterField.SMS_CLICKED]: [
            EQUALS,
            NOT_EQUALS,
        ],
        [systemSegmentFilterField.EMAIL_CLICKED]: [
            EQUALS,
            NOT_EQUALS,
        ],
        [systemSegmentFilterField.EMAIL_OPENED]: [
            EQUALS,
            NOT_EQUALS,
        ],
    },
    [segmentFilterType.ATTRIBUTES]: {
        [DEFAULT_EXPRESSION]: defaultAttributeExpressionOptions,
    },
};
