import React from "react";
import Alert from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";

export default function InfoBoard() {
  return (
    <Alert severity="warning" icon={false} className="mb20">
      Note! Add Twilio Webhooks:
      <br />
      <br />
      Incoming Messages:
      <br />
      <Link
        href="https://flow-data.leadsta.com/twilio-sms-receive-webhook"
        underline="none"
      >
        https://flow-data.leadsta.com/twilio-sms-receive-webhook
      </Link>
      <br />
      <br />
      Delivery Status Callback:
      <br />
      <Link
        href="https://flow-data.leadsta.com/twilio-sms-callback-webhook"
        underline="none"
      >
        https://flow-data.leadsta.com/twilio-sms-callback-webhook
      </Link>
    </Alert>
  );
}
