import React from 'react';
import Chip from '@material-ui/core/Chip';

const filtersMap = [
    {key: 'clicked', label: 'Clicked'},
    {key: 'unsubscribed', label: 'Unsubscribed'}
]

const LeadLogFilters = ({filters, onUpdate}) => {

    return (
        <div className="filterRow">
            <div className="buttons">
                {
                    filtersMap.map(f => {
                        const variant = filters[f.key] ? undefined : 'outlined';
                        return (
                            <Chip
                                key={f.key}
                                style={{marginLeft: '5px'}}
                                variant={variant}
                                label={f.label}
                                onClick={() => onUpdate(prev => ({...prev, [f.key]: !prev[f.key]}))}
                            />
                        )
                    })
                }
            </div>
        </div>
    );
};

export default LeadLogFilters;
