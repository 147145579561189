import states from "./states";

export const apiAuthBaseUrl = process.env.REACT_APP_AUTH_API_URL
export const apiDataBaseUrl = process.env.REACT_APP_DATA_API_URL
export const apiDataDomain = process.env.REACT_APP_DATA_API_DOMAIN

export const leadStatusesOptions = [
    {id: "1", name: "Imported"},
    {id: "2", name: "Blacklisted"},
    {id: "3", name: "Flagged"},
    {id: "4", name: "DNC"},
]

export const leadTypeOptions = [
    {id: "", name: "All"},
    {id: "1", name: "Unique Lead"},
    {id: "2", name: "Duplicate Lead"},
]

export const phoneStatusesOptions = [
    {id: "1", name: "Pending"},
    {id: "2", name: "Active"},
    {id: "3", name: "Inactive"},
    {id: "4", name: "Unsubscribed"},
]

export const phoneCarrierStatusesOptions = [
    {id: "1", name: "Pending"},
    {id: "2", name: "Active"},
    {id: "3", name: "Inactive"},
]

export const phoneCampaignStatusesOptions = [
    {id: "1", name: "Subscribed"},
    {id: "2", name: "Unsubscribed"},
    {id: "3", name: "Suppressed"},
    {id: "4", name: "Blacklisted"},
    {id: "5", name: "Federal DNC"},
    {id: "6", name: "State DNC"},
]


export const emailStatusesOptions = [
    {id: "1", name: "Pending"},
    {id: "2", name: "Active"},
    {id: "3", name: "Inactive"},
    {id: "4", name: "Unsubscribed"},
]

export const campaignEngagementOptions = [
    {id: "engaged", name: "Clicked"},
    {id: "unsubscribe", name: "Unsubscribe"},
    {id: "opened", name: "Opened"},
]

export const segmentEngagementOptions = [
    {id: "engaged", name: "SMS Clicked"},
    {id: "unsubscribe", name: "Unsubscribe"},
    {id: "opened", name: "Opened"},
    {id: "email_clicked", name: "Email Clicked"},
]

export const emailCampaignsSenderTypes = [
    {id: "domain", value: "Domain"},
    {id: "email", value: "Email address"},
    {id: "group", value: "Sender Group"},
]

export const emailClicksTrackingVersions = [
    {id: "wrap", value: "Wrap"},
    {id: "token", value: "Token"},
];

export const campaignSmsStatusOptions = [
    {id: "pending", name: "Pending"},
    {id: "queued", name: "Queued"},
    {id: "sent", name: "Sent"},
    {id: "delivered", name: "Delivered"},
    {id: "blocked_by_carrier", name: "Blocked"},
    {id: "failed", name: "Failed"},
    {id: "inactive_device", name: "Inactive device"},
    {id: "cancelled", name: "Cancelled"},
    {id: "cool_off_period", name: "Cool off period"},
    {id: "cool_off_inactive_device", name: "Cool off inactive device"},
    {id: "daily_limit", name: "Daily Limit"},
]

export const campaignEmailStatusOptions = [
    {id: 1, name: "Pending"},
    {id: 2, name: "Error"},
    {id: 3, name: "Sent"},
    {id: 4, name: "Delivered"},
    {id: 5, name: "Cancelled"},
    {id: 6, name: "Complaint"},
    {id: 7, name: "Bounce"},
    {id: 8, name: "Queued"},
]

export const engagementPeriodOptions = [
    {id: "", name: "All Time"},
    {id: "last_24_hours", name: "24 Hours"},
    {id: "last_7_days", name: "Last 7 Days"},
    {id: "last_30_days", name: "Last 30 Days"},
    {id: "last_90_days", name: "Last 90 Days"},
    {id: "this_month", name: "This Month"},
    {id: "this_year", name: "This Year"},
    {id: "custom", name: "Custom"}
]

export const smsStatusPeriodOptions = [
    {id: "last_hour", name: "Last hour"},
    {id: "last_24_hours", name: "24 Hours"},
    {id: "last_5_days", name: "Last 5 Days"},
    {id: "last_7_days", name: "Last 7 Days"},
    {id: "last_30_days", name: "Last Month"},
    {id: "this_year", name: "This Year"},
    {id: "all_time", name: "All Time"}
]

export const lastDayClickedOptions = [
    {id: "monday", name: "Monday"},
    {id: "tuesday", name: "Tuesday"},
    {id: "wednesday", name: "Wednesday"},
    {id: "thursday", name: "Thursday"},
    {id: "friday", name: "Friday"},
    {id: "saturday", name: "Saturday"},
    {id: "sunday", name: "Sunday"},
];

export const carrierTypesOptions = [
    {id: "fixed line", name: "Fixed Line"},
    {id: "mobile", name: "Mobile"},
    {id: "voip", name: "Voip"},
    {id: "other", name: "Other"},
];

export const weekOfYearOptions = Array.from({length: 52}, (_, i) => i + 1).map((week) => ({id: week, name: week}));

export const storageKeys = {
    leadsHiddenColumnsInList: "leadsHiddenColumnsInList",
    leadsHiddenColumnsInSegment: "leadsHiddenColumnsInSegment",
    leadsHiddenColumnsInLeads: "leadsHiddenColumnsInLeads",
    leadsHiddenColumnsInCampaign: "leadsHiddenColumnsInCampaign",
    leadsHiddenColumnsInCampaignAudience: "leadsHiddenColumnsInCampaignAudience",
    leadsHiddenColumnsInCampaignsList: "leadsHiddenColumnsInCampaignsList",
}

export const dateRangeOptions = [
    { id: "today", name: "Today" },
    { id: "yesterday", name: "Yesterday" },
    { id: "this_week", name: "This Week" },
    { id: "last_week", name: "Last Week" },
    { id: "this_month", name: "This Month" },
    { id: "last_month", name: "Last Month" },
    { id: "this_year", name: "This Year" },
    // { id: "", name: "All Time" },
];

export const statesOptions = states.map((state) => ({ id: state, name: state }))
