import React, {useState, useEffect, useMemo, useCallback} from "react";
import {useParams} from "react-router-dom";
import {useQuery, useQueryClient} from "react-query";
import {getSegments, refreshSegments} from "../../api";
import SearchInput from "../../components/SearchInput";
import {useAppDispatch} from "../../Contexts/AppContext";
import BasicTable from "../../components/BasicTable.jsx";
import SegmentsPageActions from "./SegmentsPageActions";
import {toLocalFormat} from "../../Utils/dates";
import useStickyState from "../../Hooks/useStickyState";
import {useQueryState} from "use-location-state";
import {audienceLinkWrapper} from "../../helpers";
import LoadingValue from "../../components/LoadingValue";
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import AlertToastr from "../../Utils/alert";

const SegmentsPage = () => {
    const params = useParams();
    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useStickyState([{key: "created_at", direction: "desc"}], "segmentSortBy");
    const [page, setPage] = useQueryState("page", 1);
    const [limit, setLimit] = useQueryState("limit", 100);
    const {setBreadcrumbs, setSection} = useAppDispatch();
    const [selectedRows, setSelectedRows] = useState([]);
    const queryClient = useQueryClient();

    const audienceLink = useCallback((value) => {
        return `/u/${params.loggedUser}/audiences/${value}`;
    }, [params]);

    const tableColumns = useMemo(() => {
        return [
            {
                Header: "Audience Name",
                accessor: "name",
                Cell: ({ value, row }) => audienceLinkWrapper(audienceLink(row.original.id), value),
            },
            {
                Header: "Created Date",
                accessor: "created_at",
                Cell: ({ value, row }) => audienceLinkWrapper(audienceLink(row.original.id), toLocalFormat(value)),
            },
            {
                Header: "Segments",
                accessor: "segments_count",
                Cell: ({ row, value }) => audienceLinkWrapper(audienceLink(row.original.id) + '/segments', value),
            },
            {
                Header: "Lifetime Revenue",
                accessor: "revenue",
                Cell: ({ value, row }) => audienceLinkWrapper(audienceLink(row.original.id), <LoadingValue value={value}/>),
            },
            {
                Header: "Revenue Per Record",
                accessor: "revenue_per_record",
                Cell: ({ value, row }) => audienceLinkWrapper(audienceLink(row.original.id), <LoadingValue value={value}/>),
            },
            {
                Header: "Leads",
                accessor: "total_leads",
                disableSortBy: true,
                Cell: ({ value, row }) => audienceLinkWrapper(audienceLink(row.original.id), <LoadingValue value={value}/>),
            },
        ]
    }, []);

    const tableData = useMemo(() => list.map(item => ({ ...item, status: true })), [list]);


    const tableSorting = useMemo(() => {
        if (!sorting) {
            return null;
        }

        return sorting.map(item => ({
            id: item.key,
            desc: item.direction === "desc"
        }))
    }, [sorting]);

    const {isSuccess, data, isLoading, isFetching} = useQuery(
        ["segmentsList", page, limit, search, sorting],
        () => getSegments(page, limit, search, sorting),
        {keepPreviousData: true}
    );

    useEffect(() => {
        if (isSuccess) {
            setList(data.data)
            const paginationData = data.meta
            if (paginationData.last_page < page) {
                setPage(1)
            }
            setPagination(paginationData)
        }
    }, [isSuccess, data, page, setPage]);

    useEffect(() => {
        setBreadcrumbs([{label: "Audiences"}])
        setSection("segments")
    }, [setBreadcrumbs, setSection]);

    const refresh = () => {
        const ids = list.map((segments) => segments.id);

        refreshSegments(ids)
            .then(() => {
                queryClient.invalidateQueries("segmentsList")
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors)
            });
    };


    const renderSearchBlock = () => {
        return (
            <div className="row searchHolder">
                <div className="inputHolder">
                    <SearchInput
                        search={search}
                        onChange={s => setSearch(s)}
                    />
                </div>
                <div className="refresh-segments">
                    <Button className="refresh-button"
                            disabled={false}
                            variant="contained"
                            color="primary"
                            startIcon={<RefreshIcon/>}
                            size="small"
                            onClick={refresh}
                    >
                        Refresh
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <div className="pageHolder">
            <div className="pageTableBlock">
                {renderSearchBlock()}
                <div className="tableHolder hasCheckboxes rowClickable size">
                    <BasicTable
                        columns={tableColumns}
                        data={tableData}
                        loading={isLoading}
                        fetching={isFetching}
                        isSelectionColumn={true}
                        onSelectedRows={setSelectedRows}
                        onSort={setSorting}
                        initSort={tableSorting}
                        emptyDataText="No Audiences Found"
                        pagination={pagination}
                        pageLimit={limit}
                        onPageChange={setPage}
                        onLimitChange={setLimit}
                    />
                </div>
            </div>
            <SegmentsPageActions
                selectedRows={selectedRows}
            />
        </div>
    )
}

export default SegmentsPage
