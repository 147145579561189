import React, { Fragment } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { GuestRoute, PrivateRoute } from '../routes';
import AlertContainer from '../layouts/AlertContainer.component';
import AuthLayout from '../layouts/auth.layout';
import LoginPage from '../../pages/auth/login.page';
import RegisterPage from '../../pages/auth/register.page';
import ForgotPasswordPage from '../../pages/auth/ForgotPasswordPage';
import PasswordResetPage from '../../pages/auth/PasswordResetPage';
import MainLayout from '../layouts/main.layout';
import SourcesPage from '../../pages/sources/SourcesPage';
import SourcePage from '../../pages/sources/SourcePage';
import ListPage from '../../pages/sources/ListPage';
import LeadPage from '../../pages/sources/LeadPage';
import SegmentsPage from '../../pages/segments/SegmentsPage';
import SegmentWrapper from '../../pages/segments/SegmentWrapper';
import ChangePasswordPage from '../../pages/auth/ChangePasswordPage';
import LeadsList from '../../pages/LeadsList';
import ApiDocumentation from '../../pages/Documentation/ApiDocumentation';
import Settings from '../../pages/settings/Settings';
import Analytics from '../../pages/Analytics';
import Campaigns from '../../pages/campaigns/Campaigns';
import CampaignWrapper from '../../pages/campaigns/CampaignWrapper';
// import LookupManagement from "../../pages/lookup/LookupManagement";
import TestImport from '../../pages/TestImport';

const App = () => {
    return (
        <Fragment>
            <Routes>
                <Route path="/u/:loggedUser" element={
                    <PrivateRoute>
                        <MainLayout />
                    </PrivateRoute>
                }>
                    <Route index element={<SegmentsPage />} />

                    <Route path="campaigns">
                        <Route index element={<Campaigns />} />
                        <Route path=":campaignId/*" element={<CampaignWrapper />} />
                    </Route>

                    <Route path="audiences">
                        <Route path=":segmentId/*" element={<SegmentWrapper />} />
                    </Route>

                    <Route path="leads">
                        <Route index element={<LeadsList />} />
                        <Route path=":leadId" element={<LeadPage />} />
                    </Route>

                    <Route path="sources">
                        <Route index element={<SourcesPage />} />
                        <Route path=":sourceId" element={<SourcePage />} />
                        <Route path=":sourceId/:listId" element={<ListPage />} />
                    </Route>

                    <Route path="analytics" element={<Analytics />} />
                    <Route path="settings/*" element={<Settings />} />
                    <Route path="doc" element={<ApiDocumentation />} />
                </Route>

                <Route path="/add-account" element={
                    <PrivateRoute>
                        <AuthLayout />
                    </PrivateRoute>
                }>
                    <Route index element={<LoginPage />} />
                </Route>

                <Route path="/change-password" element={
                    <PrivateRoute>
                        <AuthLayout/>
                    </PrivateRoute>
                }>
                    <Route index element={<ChangePasswordPage />} />
                </Route>


                <Route element={
                    <GuestRoute>
                        <AuthLayout />
                    </GuestRoute>
                }>
                    <Route path="/signin" element={<LoginPage />} />
                    <Route path="/register" element={<RegisterPage />} />
                    <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                    <Route path="/reset-password" element={<PasswordResetPage />} />
                </Route>

                <Route
                    path="/test/import"
                    element={<TestImport />}
                />

                <Route path="*" element={<Navigate to="/u/0/" replace />} />
            </Routes>
            <AlertContainer />
        </Fragment>
    );
};

export default App;
