import React, {useEffect, useMemo, useState} from 'react'
import {useMutation, useQueryClient} from "react-query";
import {Controller, useForm} from "react-hook-form";
import {storeSMSGateway, updateSMSGateway} from "../../../api";
import AlertToastr from "../../../Utils/alert";
import TextField from "@material-ui/core/TextField";
import ReactHookFormSelect from "../../../components/form/ReactHookFormSelect";
import MenuItem from "@material-ui/core/MenuItem";
import InfoBoard from "../../../components/layouts/InfoBoard";
import {useAppState} from "../../../Contexts/AppContext";
import Button from "@material-ui/core/Button";
import GatewayType from "../../../Enums/Gateways/GatewayType";
import IconButton from "@material-ui/core/IconButton";
import {AddCircleRounded, Close} from "@material-ui/icons";


const SmsFormComponents = ({defaultValues, handleClose}) => {

    const [gatewayOptions, setGatewayOptions] = useState([]);

    const {config} = useAppState()
    const gatewaysAttributes = useMemo(() => config['setting_gateway_fields'] || {}, [config])
    const queryClient = useQueryClient();

    const smsGatewaysData = queryClient.getQueryData('smsGateways');
    useEffect(() => {
        if (smsGatewaysData) {
            setGatewayOptions(smsGatewaysData.campaign_gateway_list)
        }
    }, [smsGatewaysData])


    const {control, reset, handleSubmit, watch} = useForm({
        mode: "onTouched",
        defaultValues: defaultValues ? defaultValues : {},
    });

    const storeMutation = useMutation(storeSMSGateway);
    const updateMutation = useMutation(updateSMSGateway);
    const type = watch("type");

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    useEffect(() => {
        if (storeMutation.error) {
            AlertToastr.showErrorResponseAlert(storeMutation.error);
        }
    }, [storeMutation.error]);

    useEffect(() => {
        if (updateMutation.error) {
            AlertToastr.showErrorResponseAlert(updateMutation.error);
        }
    }, [updateMutation.error]);

    const renderGatewayTextField = (key) => {
        if (
            !type ||
            !gatewaysAttributes[type] ||
            !Array.isArray(gatewaysAttributes[type]) ||
            !gatewaysAttributes[type].includes(key)
        ) {
            return null;
        }

        if (type === GatewayType.GATEWAY1 ||
            type === GatewayType.GATEWAY7 ||
            type === GatewayType.GATEWAY8 ||
            type === GatewayType.GATEWAY9 ||
            type === GatewayType.GATEWAY10 ||
            type === GatewayType.GATEWAY11 ||
            type === GatewayType.GATEWAY12 ||
            type === GatewayType.GATEWAY13 ||
            type === GatewayType.GATEWAY14 ||
            type === GatewayType.GATEWAY15
        ) {
            return null;
        }

        const keyToLabel = {
            sid: "Sid",
            token: "Token",
            sys_id: "Id",
            auth_token: "Token",
            api_key: 'Api Key',
            account_id: 'Account ID'
        };

        return (
            <Controller
                key={type + key}
                name={key}
                control={control}
                defaultValue=""
                render={({onChange, value}) => {
                    return (
                        <TextField
                            name={key}
                            onChange={onChange}
                            value={value}
                            variant="outlined"
                            margin="normal"
                            label={keyToLabel.hasOwnProperty(key) ? keyToLabel[key] : key}
                        />
                    );
                }}
            />
        );
    };

    const [apiKey, setApiKey] = useState(null)
    const [appId, setAppId] = useState(null)
    const [apiSecret, setApiSecret] = useState(null)
    const [token, setToken] = useState(null)
    const [senders, setSenders] = useState([{sender: null}])
    const [sysId, setSysId] = useState(null);
    const [authToken, setAuthToken] = useState(null);
    const [gateway1Senders, setGateway1Senders] = useState({
        pools: [{id: null, name: null}],
        numbers: [{number: null, type: null}]
    })
    const [gateway9Senders, setGateway9Senders] = useState([{name: null, api_key: null, account_id: null}])
    const [gateway12DidGroups, setGateway12DidGroups] = useState([{id: null, name: null}])
    const [gateway12Dids, setGateway12Dids] = useState([{number: null}])
    const [gateway13Phones, setGateway13Phones] = useState([{number: null, name: null}])
    const [gateway14Phones, setGateway14Phones] = useState([{number: null}])
    const [gateway15Phones, setGateway15Phones] = useState([{number: null}])
    const [baseUrl, setBaseUrl] = useState(null)

    useEffect(() => {
        if (defaultValues) {
            setApiKey(defaultValues.api_key)
            setToken(defaultValues.token)

            if (defaultValues.type === GatewayType.GATEWAY7) {
                let tempSenders = [];
                if (defaultValues.senders) {
                    defaultValues.senders.map(item => {
                        tempSenders.push({sender: item})
                    })
                }
                setSenders(tempSenders)
            }
            if (defaultValues.type === GatewayType.GATEWAY9) {
                setGateway9Senders(defaultValues.senders)
            }
            if (defaultValues.type === GatewayType.GATEWAY1) {
                setSysId(defaultValues.params.sys_id)
                setAuthToken(defaultValues.params.auth_token)
                setGateway1Senders({
                    pools: [...defaultValues.params.senders.pools],
                    numbers: [...defaultValues.params.senders.numbers]
                })
            }

            if (defaultValues.type === GatewayType.GATEWAY8) {
                setSenders(defaultValues.senders)
            }
            if (defaultValues.type === GatewayType.GATEWAY10) {
                setSenders(defaultValues.senders)
            }
            if (defaultValues.type === GatewayType.GATEWAY11) {
                setApiKey(defaultValues.api_key);
                setApiSecret(defaultValues.api_secret);
            }
            if (defaultValues.type === GatewayType.GATEWAY12) {
                setToken(defaultValues.token);
                setApiSecret(defaultValues.api_secret);
                setGateway12Dids(defaultValues.senders.dids);
                setGateway12DidGroups(defaultValues.senders.did_groups);
            }

            if (defaultValues.type === GatewayType.GATEWAY13) {
                setApiKey(defaultValues.api_key);
                setGateway13Phones(defaultValues.senders);
            }

            if (defaultValues.type === GatewayType.GATEWAY14) {
                setAppId(defaultValues.params.app_id);
                setGateway14Phones(defaultValues.params.senders);
                setBaseUrl(defaultValues.params.base_url);
            }
            if (defaultValues.type === GatewayType.GATEWAY15) {
                setApiKey(defaultValues.params.api_key);
                setGateway15Phones(defaultValues.params.senders);
            }

        } else {
            setApiKey('');
            setApiSecret('');
            setAuthToken('');
            setSysId('');
        }
    }, [defaultValues])


    const onSubmit = handleSubmit(async (data) => {
        if (data['type'] === GatewayType.GATEWAY7) {
            data['api_key'] = apiKey;
            data['senders'] = [];
            senders.map(sender => data.senders.push(sender.sender))
        }
        if (data['type'] === GatewayType.GATEWAY1) {
            data['auth_token'] = authToken;
            data['sys_id'] = sysId
            data['senders'] = gateway1Senders;
        }
        if (data['type'] === GatewayType.GATEWAY8) {
            data['senders'] = [];
            data['token'] = token;
            senders.map(sender => data.senders.push(sender))
        }

        if (data['type'] === GatewayType.GATEWAY9) {
            data['senders'] = [];
            gateway9Senders.map(sender => data.senders.push(sender))
        }

        if (data['type'] === GatewayType.GATEWAY10) {
            data['senders'] = [];
            data.token = token;
            senders.map(sender => data.senders.push(sender))
        }

        if (data['type'] === GatewayType.GATEWAY11) {
            data['api_key'] = apiKey;
            data['api_secret'] = apiSecret;
        }

        if (data['type'] === GatewayType.GATEWAY12) {
            data['token'] = token;
            data['api_secret'] = apiSecret;
            data['senders'] = {
                dids: gateway12Dids,
                did_groups: gateway12DidGroups
            }
        }

        if (data['type'] === GatewayType.GATEWAY13) {
            data['api_key'] = apiKey;
            data['senders'] = gateway13Phones
        }

        if (data['type'] === GatewayType.GATEWAY14) {
            data['app_id'] = appId;
            data['senders'] = gateway14Phones
            data['base_url'] = baseUrl
        }
        if (data['type'] === GatewayType.GATEWAY15) {
            data['api_key'] = apiKey;
            data['senders'] = gateway15Phones
        }

        if (defaultValues) {
            await updateMutation.mutateAsync({id: defaultValues.id, ...data})
        } else {
            await storeMutation.mutateAsync(data);
        }

        reset();
        handleClose();
        queryClient.invalidateQueries("smsGateways").then();
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ReactHookFormSelect
                variant="outlined"
                margin="normal"
                id="gateway-select"
                name="type"
                label="Gateway"
                control={control}
                defaultValue=""
                disabled={!!defaultValues}
            >
                <MenuItem value="" disabled>
                    Select Gateway
                </MenuItem>
                {gatewayOptions.map((option) => (
                    <MenuItem key={option.key} value={option.key}>
                        {option.label}
                    </MenuItem>
                ))}
            </ReactHookFormSelect>
            <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({onChange, value}) => {
                    return (
                        <TextField
                            onChange={onChange}
                            value={value}
                            variant="outlined"
                            margin="normal"
                            label="Name"
                        />
                    );
                }}
            />
            {type === "twilio" && <InfoBoard/>}
            {gatewaysAttributes &&
                gatewaysAttributes.hasOwnProperty(type) && (
                    <>
                        {gatewaysAttributes[type].map((field) =>
                            renderGatewayTextField(field)
                        )}
                    </>
                )
            }

            {type === GatewayType.GATEWAY9 &&
                <>
                    {gateway9Senders.map((item, key) => (item.name !== undefined &&
                        <div className="wrapper content-around align-center senders-input-group" key={key}>
                            <TextField
                                value={item.name ?? ''}
                                id={'senders_name_' + key}
                                onChange={e => {
                                    gateway9Senders[key].name = e.target.value
                                    setGateway9Senders([...gateway9Senders]);
                                }}
                                label="Name"
                                variant="outlined"
                                margin='none'
                                name={"senders[" + key + "]"}
                            />
                            <TextField
                                value={item.account_id ?? ''}
                                id={'senders_account_id_' + key}
                                onChange={e => {
                                    gateway9Senders[key].account_id = e.target.value
                                    setGateway9Senders([...gateway9Senders]);
                                }}
                                label="Account ID"
                                variant="outlined"
                                margin='none'
                                name={"senders[" + key + "]"}
                            />
                            <TextField
                                value={item.api_key ?? ''}
                                id={'senders_api_key_' + key}
                                onChange={e => {
                                    gateway9Senders[key].api_key = e.target.value
                                    setGateway9Senders([...gateway9Senders]);
                                }}
                                label="Api Key"
                                variant="outlined"
                                margin='none'
                                name={"senders[" + key + "]"}
                            />
                            <IconButton style={{marginLeft: 5}} size="small" onClick={() => {
                                gateway9Senders.splice(key, 1);
                                setGateway9Senders([...gateway9Senders]);
                            }}>
                                <Close fontSize="inherit"/>
                            </IconButton>
                        </div>
                    ))}

                    <div className="align-center wrapper content-justified">
                        <Button
                            variant="outlined"
                            name="add_sender"
                            startIcon={<AddCircleRounded/>}
                            onClick={(e) => {
                                setGateway9Senders([...gateway9Senders, {name: null, api_key: null, account_id: null}]);
                            }}
                        >Add Sender</Button>
                    </div>
                </>
            }
            {type === GatewayType.GATEWAY1 &&
                <>
                    <TextField
                        value={sysId}
                        onChange={e => {
                            setSysId(e.target.value)
                        }}
                        id="sys_id"
                        label="Sys ID"
                        variant="outlined"
                        margin='normal'
                        name="sys_id"
                    />
                    <TextField
                        value={authToken}
                        onChange={e => {
                            setAuthToken(e.target.value)
                        }}
                        id="auth_token"
                        label="Auth Token"
                        variant="outlined"
                        margin='normal'
                        name="auth_token"
                    />
                    {gateway1Senders.pools.map((item, key) => (item.id !== undefined &&
                        <div className="wrapper content-around align-center" key={key}>
                            <TextField
                                value={item.name ?? ''}
                                id={'senders_pools_name_' + key}
                                onChange={e => {
                                    gateway1Senders.pools[key].name = e.target.value
                                    setGateway1Senders({
                                        pools: [...gateway1Senders.pools],
                                        numbers: [...gateway1Senders.numbers]
                                    });
                                }}
                                label="Name"
                                variant="outlined"
                                margin='normal'
                                name={"senders_pools_id[" + key + "]"}
                            />
                            <TextField
                                value={item.id ?? ''}
                                id={'senders_pools_id_' + key}
                                onChange={e => {
                                    gateway1Senders.pools[key].id = e.target.value
                                    setGateway1Senders({
                                        pools: [...gateway1Senders.pools],
                                        numbers: [...gateway1Senders.numbers]
                                    });
                                }}
                                label="ID"
                                variant="outlined"
                                margin='normal'
                                name={"senders_pools_id[" + key + "]"}
                            />
                            <IconButton style={{marginLeft: 5}} size="small" onClick={() => {
                                gateway1Senders.pools.splice(key, 1);
                                setGateway1Senders({
                                    pools: [...gateway1Senders.pools],
                                    numbers: [...gateway1Senders.numbers]
                                });
                            }}>
                                <Close fontSize="inherit"/>
                            </IconButton>
                        </div>
                    ))}

                    <div className="align-center wrapper content-justified">
                        <Button
                            variant="outlined"
                            name="add_sender"
                            startIcon={<AddCircleRounded/>}
                            onClick={(e) => {
                                gateway1Senders.pools = [...gateway1Senders.pools, {id: null, name: null}]
                                setGateway1Senders({
                                    pools: [...gateway1Senders.pools],
                                    numbers: [...gateway1Senders.numbers]
                                });
                            }}
                        >Add Sender</Button>
                    </div>


                    {gateway1Senders.numbers.map((item, key) => (item.number !== undefined &&
                        <div className="wrapper content-around align-center" key={key}>
                            <TextField
                                value={item.number ?? ''}
                                id={'senders_numbers_number_' + key}
                                onChange={e => {
                                    gateway1Senders.numbers[key].number = e.target.value
                                    setGateway1Senders({
                                        pools: [...gateway1Senders.pools],
                                        numbers: [...gateway1Senders.numbers]
                                    });
                                }}
                                label="Number"
                                variant="outlined"
                                margin='normal'
                                name={"senders_numbers_number[" + key + "]"}
                            />
                            <TextField
                                value={item.type ?? ''}
                                id={'senders_numbers_type_' + key}
                                onChange={e => {
                                    gateway1Senders.numbers[key].type = e.target.value
                                    setGateway1Senders({
                                        pools: [...gateway1Senders.pools],
                                        numbers: [...gateway1Senders.numbers]
                                    });
                                }}
                                label="Type"
                                variant="outlined"
                                margin='normal'
                                name={"senders_numbers_type[" + key + "]"}
                            />
                            <IconButton style={{marginLeft: 5}} size="small" onClick={() => {
                                gateway1Senders.numbers.splice(key, 1);
                                setGateway1Senders({
                                    pools: [...gateway1Senders.pools],
                                    numbers: [...gateway1Senders.numbers]
                                });
                            }}>
                                <Close fontSize="inherit"/>
                            </IconButton>
                        </div>
                    ))}

                    <div className="align-center wrapper content-justified">
                        <Button
                            variant="outlined"
                            name="add_number"
                            startIcon={<AddCircleRounded/>}
                            onClick={(e) => {
                                gateway1Senders.numbers = [...gateway1Senders.numbers, {number: null, type: null}]
                                setGateway1Senders({
                                    pools: [...gateway1Senders.pools],
                                    numbers: [...gateway1Senders.numbers]
                                });
                            }}
                        >Add Number</Button>
                    </div>
                </>
            }

            {type === GatewayType.GATEWAY7 &&
                <>
                    <TextField
                        value={apiKey}
                        onChange={e => {
                            setApiKey(e.target.value)
                        }}
                        id="api_key"
                        label="API Key"
                        variant="outlined"
                        margin='normal'
                        name="api_key"
                    />
                    {senders.map((item, key) => (item.sender !== undefined &&
                        <div className="wrapper content-around align-center" key={key}>
                            <TextField
                                value={item.sender ?? ''}
                                id={'senders_' + key}
                                onChange={e => {
                                    senders[key].sender = e.target.value
                                    setSenders([...senders]);
                                }}
                                label="Sender"
                                variant="outlined"
                                margin='normal'
                                name={"senders[" + key + "]"}
                            />
                            <IconButton style={{marginLeft: 5}} size="small" onClick={() => {
                                senders.splice(key, 1);
                                setSenders([...senders]);
                            }}>
                                <Close fontSize="inherit"/>
                            </IconButton>
                        </div>
                    ))}

                    <div className="align-center wrapper content-justified">
                        <Button
                            variant="outlined"
                            name="add_sender"
                            startIcon={<AddCircleRounded/>}
                            onClick={(e) => {
                                setSenders([...senders, {sender: null}]);
                            }}
                        >Add Sender</Button>
                    </div>
                </>
            }

            {type === GatewayType.GATEWAY8 &&
                <>
                    <TextField
                        value={token}
                        onChange={e => {
                            setToken(e.target.value)
                        }}
                        id="token"
                        label="Token"
                        variant="outlined"
                        margin='normal'
                        name="token"
                    />

                    {senders.map((item, key) => (item.sender !== undefined &&
                        <div className="wrapper content-around align-center senders-input-group" key={key}>
                            <TextField
                                value={item.name ?? ''}
                                id={'senders_name_' + key}
                                onChange={e => {
                                    senders[key].name = e.target.value
                                    setSenders([...senders]);
                                }}
                                label="Name"
                                variant="outlined"
                                margin='normal'
                                name={"senders[" + key + "]"}
                            />
                            <TextField
                                value={item.sender ?? ''}
                                id={'senders_sender_' + key}
                                onChange={e => {
                                    senders[key].sender = e.target.value
                                    setSenders([...senders]);
                                }}
                                label="Sender"
                                variant="outlined"
                                margin='normal'
                                name={"senders[" + key + "]"}
                            />
                            <IconButton style={{marginLeft: 5}} size="small" onClick={() => {
                                senders.splice(key, 1);
                                setSenders([...senders]);
                            }}>
                                <Close fontSize="inherit"/>
                            </IconButton>
                        </div>
                    ))}

                    <div className="align-center wrapper content-justified">
                        <Button
                            variant="outlined"
                            name="add_sender"
                            startIcon={<AddCircleRounded/>}
                            onClick={(e) => {
                                setSenders([...senders, {sender: null}]);
                            }}
                        >Add Sender</Button>
                    </div>
                </>
            }

            {type === GatewayType.GATEWAY10 &&
                <>
                    <TextField
                        value={token}
                        onChange={e => {
                            setToken(e.target.value)
                        }}
                        id="token"
                        label="Api Key"
                        variant="outlined"
                        margin='normal'
                        name="token"
                    />

                    {senders.map((item, key) => (item.sender !== undefined &&
                        <div className="wrapper content-around align-center senders-input-group" key={key}>
                            <TextField
                                value={item.name ?? ''}
                                id={'senders_name_' + key}
                                onChange={e => {
                                    senders[key].name = e.target.value
                                    setSenders([...senders]);
                                }}
                                label="Name"
                                variant="outlined"
                                margin='normal'
                                name={"senders[" + key + "]"}
                            />
                            <TextField
                                value={item.sender ?? ''}
                                id={'senders_profile_id_' + key}
                                onChange={e => {
                                    senders[key].sender = e.target.value
                                    setSenders([...senders]);
                                }}
                                label="Profile ID"
                                variant="outlined"
                                margin='normal'
                                name={"senders[" + key + "]"}
                            />
                            <IconButton style={{marginLeft: 5}} size="small" onClick={() => {
                                senders.splice(key, 1);
                                setSenders([...senders]);
                            }}>
                                <Close fontSize="inherit"/>
                            </IconButton>
                        </div>
                    ))}

                    <div className="align-center wrapper content-justified">
                        <Button
                            variant="outlined"
                            name="add_sender"
                            startIcon={<AddCircleRounded/>}
                            onClick={(e) => {
                                setSenders([...senders, {sender: null}]);
                            }}
                        >Add Sender</Button>
                    </div>
                </>
            }

            {type === GatewayType.GATEWAY11 &&
                <>
                    <TextField
                        value={apiKey}
                        onChange={e => {
                            setApiKey(e.target.value)
                        }}
                        id="api_key"
                        label="Api Key"
                        variant="outlined"
                        margin='normal'
                        name="api_key"
                    />
                    <TextField
                        value={apiSecret}
                        onChange={e => {
                            setApiSecret(e.target.value)
                        }}
                        id="api_secret"
                        label="Api Secret"
                        variant="outlined"
                        margin='normal'
                        name="api_secret"
                    />
                </>
            }

            {type === GatewayType.GATEWAY12 &&
                <>
                    <TextField
                        value={token}
                        onChange={e => {
                            setToken(e.target.value)
                        }}
                        id="token"
                        label="Api Token"
                        variant="outlined"
                        margin='normal'
                        name="token"
                    />
                    <TextField
                        value={apiSecret}
                        onChange={e => {
                            setApiSecret(e.target.value)
                        }}
                        id="api_secret"
                        label="Api Secret"
                        variant="outlined"
                        margin='normal'
                        name="api_secret"
                    />

                    {gateway12DidGroups.map((item, key) => (item.id !== undefined &&
                        <div className="wrapper content-around align-center senders-input-group" key={key}>
                            <TextField
                                value={item.id ?? ''}
                                id={'gateway12_groups_id_' + key}
                                onChange={e => {
                                    gateway12DidGroups[key].id = e.target.value
                                    setGateway12DidGroups([...gateway12DidGroups]);
                                }}
                                label="Group ID"
                                variant="outlined"
                                margin='none'
                                name={"gateway12_groups_id[" + key + "]"}
                            />
                            <TextField
                                value={item.name ?? ''}
                                id={'gateway12_groups_name_' + key}
                                onChange={e => {
                                    gateway12DidGroups[key].name = e.target.value
                                    setGateway12DidGroups([...gateway12DidGroups]);
                                }}
                                label="Group Name"
                                variant="outlined"
                                margin='none'
                                name={"gateway12_groups_name[" + key + "]"}
                            />
                            <IconButton style={{marginLeft: 5}} size="small" onClick={() => {
                                gateway12DidGroups.splice(key, 1);
                                setGateway12DidGroups([...gateway12DidGroups]);
                            }}>
                                <Close fontSize="inherit"/>
                            </IconButton>
                        </div>
                    ))}

                    <div className="align-center wrapper content-justified">
                        <Button
                            variant="outlined"
                            name="add_sender"
                            startIcon={<AddCircleRounded/>}
                            onClick={(e) => {
                                setGateway12DidGroups([...gateway12DidGroups, {id: null, name: null}]);
                            }}
                        >Add Group</Button>
                    </div>


                    {gateway12Dids.map((item, key) => (item.number !== undefined &&
                        <div className="wrapper content-around align-center senders-input-group" key={key}>
                            <TextField
                                value={item.number ?? ''}
                                id={'gateway12_numbers_number_' + key}
                                onChange={e => {
                                    gateway12Dids[key].number = e.target.value
                                    setGateway12Dids([...gateway12Dids]);
                                }}
                                label="Number"
                                variant="outlined"
                                margin='none'
                                name={"gateway12_numbers_number[" + key + "]"}
                            />
                            <IconButton style={{marginLeft: 5}} size="small" onClick={() => {
                                gateway12Dids.splice(key, 1);
                                setGateway12Dids([...gateway12Dids]);
                            }}>
                                <Close fontSize="inherit"/>
                            </IconButton>
                        </div>
                    ))}

                    <div className="align-center wrapper content-justified">
                        <Button
                            variant="outlined"
                            name="add_sender"
                            startIcon={<AddCircleRounded/>}
                            onClick={(e) => {
                                setGateway12Dids([...gateway12Dids, {number: null}]);
                            }}
                        >Add Number</Button>
                    </div>
                </>
            }

            {type === GatewayType.GATEWAY13 &&
                <>
                    <TextField
                        value={apiKey}
                        onChange={e => {
                            setApiKey(e.target.value)
                        }}
                        id="api_key"
                        label="License Key"
                        variant="outlined"
                        margin='normal'
                        name="api_key"
                    />

                    {gateway13Phones.map((item, key) => (item.number !== undefined &&
                        <div className="wrapper content-around align-center senders-input-group" key={key}>
                            <TextField
                                value={item.number ?? ''}
                                id={'gateway13phones_' + key}
                                onChange={e => {
                                    gateway13Phones[key].number = e.target.value
                                    setGateway13Phones([...gateway13Phones]);
                                }}
                                label="Phone"
                                variant="outlined"
                                margin='none'
                                name={"gateway13phones_[" + key + "]"}
                            />
                            <TextField
                                value={item.name ?? ''}
                                id={'gateway13phones_name_' + key}
                                onChange={e => {
                                    gateway13Phones[key].name = e.target.value
                                    setGateway13Phones([...gateway13Phones]);
                                }}
                                label="Name"
                                variant="outlined"
                                margin='none'
                                name={"gateway13phones_name_[" + key + "]"}
                            />
                            <IconButton style={{marginLeft: 5}} size="small" onClick={() => {
                                gateway13Phones.splice(key, 1);
                                setGateway13Phones([...gateway13Phones]);
                            }}>
                                <Close fontSize="inherit"/>
                            </IconButton>
                        </div>
                    ))}


                    <div className="align-center wrapper content-justified">
                        <Button
                            variant="outlined"
                            name="add_sender"
                            startIcon={<AddCircleRounded/>}
                            onClick={(e) => {
                                setGateway13Phones([...gateway13Phones, {number: null}]);
                            }}
                        >Add Number</Button>
                    </div>
                </>
            }

            {type === GatewayType.GATEWAY14 &&
                <>
                    <TextField
                        value={baseUrl}
                        onChange={e => {
                            setBaseUrl(e.target.value)
                        }}
                        id="base_url"
                        label="Base URL"
                        variant="outlined"
                        margin='normal'
                        name="base_url"
                    />
                    <TextField
                        value={appId}
                        onChange={e => {
                            setAppId(e.target.value)
                        }}
                        id="app_id"
                        label="App ID"
                        variant="outlined"
                        margin='normal'
                        name="app_id"
                    />

                    {gateway14Phones.map((item, key) => (item.number !== undefined &&
                        <div className="wrapper content-around align-center senders-input-group" key={key}>
                            <TextField
                                value={item.number ?? ''}
                                id={'gateway14phones_' + key}
                                onChange={e => {
                                    gateway14Phones[key].number = e.target.value
                                    setGateway14Phones([...gateway14Phones]);
                                }}
                                label="Phone"
                                variant="outlined"
                                margin='none'
                                name={"gateway14phones_[" + key + "]"}
                            />
                            <IconButton style={{marginLeft: 5}} size="small" onClick={() => {
                                gateway14Phones.splice(key, 1);
                                setGateway14Phones([...gateway14Phones]);
                            }}>
                                <Close fontSize="inherit"/>
                            </IconButton>
                        </div>
                    ))}


                    <div className="align-center wrapper content-justified">
                        <Button
                            variant="outlined"
                            name="add_sender"
                            startIcon={<AddCircleRounded/>}
                            onClick={(e) => {
                                setGateway14Phones([...gateway14Phones, {number: null}]);
                            }}
                        >Add Number</Button>
                    </div>
                </>
            }

            {type === GatewayType.GATEWAY15 &&
                <>
                    <TextField
                        value={apiKey}
                        onChange={e => {
                            setApiKey(e.target.value)
                        }}
                        id="api_key"
                        label="Api Key"
                        variant="outlined"
                        margin='normal'
                        name="api_key"
                    />

                    {gateway15Phones.map((item, key) => (item.number !== undefined &&
                        <div className="wrapper content-around align-center senders-input-group" key={key}>
                            <TextField
                                value={item.number ?? ''}
                                id={'gateway15phones_' + key}
                                onChange={e => {
                                    gateway15Phones[key].number = e.target.value
                                    setGateway15Phones([...gateway15Phones]);
                                }}
                                label="Phone"
                                variant="outlined"
                                margin='none'
                                name={"gateway15phones_[" + key + "]"}
                            />
                            <IconButton style={{marginLeft: 5}} size="small" onClick={() => {
                                gateway15Phones.splice(key, 1);
                                setGateway15Phones([...gateway15Phones]);
                            }}>
                                <Close fontSize="inherit"/>
                            </IconButton>
                        </div>
                    ))}


                    <div className="align-center wrapper content-justified">
                        <Button
                            variant="outlined"
                            name="add_sender"
                            startIcon={<AddCircleRounded/>}
                            onClick={(e) => {
                                setGateway15Phones([...gateway15Phones, {number: null}]);
                            }}
                        >Add Number</Button>
                    </div>
                </>
            }

            <div className="btnHolder hasPadTop twoBtn">
                <div className="part">
                    <Button
                        disabled={storeMutation.isLoading || updateMutation.isLoading}
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                        onClick={(e) => {
                        }}
                    >
                        Save
                    </Button>
                </div>
                <div className="part">
                    <Button
                        disabled={storeMutation.isLoading || updateMutation.isLoading}
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </form>
    );
}

export default SmsFormComponents
