import React, {useEffect, useMemo, useState} from "react"
import {useAppDispatch} from "../Contexts/AppContext"
import PlusCircle from "../assets/img/plus_circle.svg";
import EventEmitter from "../Utils/EventEmitter";
import ShortUrlDomainItemContextMenu from "../components/ShortUrlDomainItemContextMenu";
import ListTable from "../components/ListTable/ListTable";
import {useQuery, useQueryClient} from "react-query";
import DeleteModal from "../components/modals/DeleteModal";
import CreateRuleModal from "../components/modals/rules/CreateRuleModal";
import RuleService from "../Services/Rules/RuleService";
import RuleType from "../Enums/Rules/RuleType";
import {toServerFormat} from "../Utils/dates";
import RuleCoverage from "../Enums/Rules/RuleCoverage";
import moment from "moment";

const Rules = () => {
    const queryClient = useQueryClient()
    const {setBreadcrumbs, setSection} = useAppDispatch();
    const {setContextMenu} = useAppDispatch();
    const [rules, setRules] = useState([]);
    const [currentModel, setCurrentRule] = useState(null);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        setBreadcrumbs([
            {label: "Settings", link: "./settings"},
            {label: "Rules"}
        ])
        setSection("settings")
    }, [setBreadcrumbs, setSection])

    useEffect(() => {
        setContextMenu([
            {
                label: "Add New Rule",
                icon: PlusCircle,
                action: () => EventEmitter.emit("RuleCreate"),
            },
        ])
    }, [setContextMenu])

    useEffect(() => {
        const onEvent = () => setShowCreateModal(true)

        const listener = EventEmitter.addListener("RuleCreate", onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    const showDeleteConfirmation = (rule) => {
        setCurrentRule(rule)
        setShowDeleteModal(true)
    }

    const handleEditRule = (rule) => {
        setCurrentRule(rule)
        setShowUpdateModal(true)
    }

    const rulesRequest = useQuery(
        ["rulesList"],
        () => RuleService.list(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (rulesRequest.isSuccess) {
            setRules(rulesRequest.data.data.data)
        }
    }, [rulesRequest.isSuccess, rulesRequest.data])

    const tableColumns = useMemo(() => {
        return [
            {
                Header: "Name",
                accessor: "name",
            },
            {
                Header: "Type",
                accessor: "type",
                Cell: ({value}) => {
                    return RuleType.ALL[value]
                }
            },
            {
                Header: "Active",
                accessor: "is_active",
                Cell: ({value}) => {
                    return value ? "Yes" : "No";
                }
            },
            {
                Header: "Params",
                accessor: "params",
                Cell: ({value, row}) => {
                    if (row.original.type === RuleType.SMS_COOL_OFF) {
                        return <span className={"rule_params"}>
                            Sms Cool Off Period: {value.period} minutes
                        </span>
                    }
                    if (row.original.type === RuleType.SMS_INACTIVE_DEVICE) {
                        return <span className={"rule_params"}>
                            Sms Inactive Cool Off Period: {value.period} days
                        </span>
                    }
                    if (row.original.type === RuleType.EMAIL_COOL_OFF) {
                        return <span className={"rule_params"}>
                            Email Cool Off Period: {value.period} hours
                        </span>
                    }
                    if (row.original.type === RuleType.RESET_FREQUENCY) {
                        return <span className={"rule_params"}>
                            Reset frequency {value.frequency_count} per {value.frequency_period} <br/>
                            At: {moment(value.reset_time, 'hh:mm').format('hh:mm A')}
                        </span>
                    }
                    if (row.original.type === RuleType.INCREASE_FREQUENCY) {
                        return <span className={"rule_params"}>
                            Increase Frequency: <br/>
                            Profit: {value.profit_percent}% <br/>
                            Increase step: {value.increase_percent}% <br/>
                            Max Frequency: {value.max_frequency} per min<br/>
                        </span>
                    }

                    return <></>
                }
            },
            {
                Header: "Coverage",
                accessor: "coverage",
                Cell: ({value}) => {
                    return RuleCoverage.ALL[value];
                }
            },
            {
                Header: "Created At",
                accessor: "created_at",
                Cell: ({value}) => {
                    return toServerFormat(value)
                }
            },
            {
                Header: "",
                id: "actions",
                className: "text-right",
                Cell: ({value, row}) => {
                    return (
                        <ShortUrlDomainItemContextMenu
                            item={row.original}
                            onEdit={() => handleEditRule(row.original)}
                            onDelete={() => showDeleteConfirmation(row.original)}
                        />
                    )
                }
            },
        ]
    }, [])

    return <>
        <ListTable
            columns={tableColumns}
            data={rules}
            loading={rulesRequest.isLoading}
            fetching={rulesRequest.isFetching}
            onSort={(sort) => {
            }}
            emptyDataText="No Rules Found"
            pagination={null}
            pageLimit={100}
            onPageChange={1}
            onLimitChange={(l) => {
            }}
            disableSearchBlock={true}
        />

        <CreateRuleModal
            rule={null}
            handleClose={() => {
                setCurrentRule(null)
                setShowCreateModal(false)
            }}
            open={showCreateModal}
        />
        <CreateRuleModal
            rule={currentModel}
            handleClose={() => {
                setCurrentRule(null)
                setShowUpdateModal(false)
            }}
            open={showUpdateModal}
        />
        <DeleteModal
            loading={false}
            open={showDeleteModal}
            handleDelete={() => {
                RuleService.delete(currentModel.id).then(() => {
                    setCurrentRule(null)
                    setShowDeleteModal(false)
                    queryClient.invalidateQueries("rulesList").then()
                })
            }}
            handleClose={() => setShowDeleteModal(false)}
            instance="Rule"
        />
    </>
}

export default Rules
