import React, {useMemo} from 'react';
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {AddCircleRounded, Close} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";

const PoolSenders = ({poolSenders, gateways, setPoolSenders}) => {

    const defaultSenderObject = useMemo(() => {
        return {
            id: null,
            gateway_id: null,
            sender_type: null,
            sender_name: null,
        }
    }, [])

    return (
        <div>
            {poolSenders.map((sender, index) => {
                return <div key={index} style={{marginBottom: '10px'}}>
                    <div className="wrapper content-around align-top" style={{border: '1px solid #e5e5e5', borderRadius: '10px'}}>
                        <div className="f-grow-1" style={{paddingLeft: '10px'}}>
                            <FormControl
                                disabled={!gateways}
                                variant="outlined"
                                margin="normal"
                            >
                                <InputLabel id={'Gateway-label'}>{'Gateway'}</InputLabel>
                                <Select
                                    value={sender.gateway_id ?? ''}
                                    onChange={e => {
                                        poolSenders[index] = {...sender, gateway_id: e.target.value}
                                        setPoolSenders(poolSenders)
                                    }}
                                >
                                    {gateways.map((gateway) => (
                                        <MenuItem
                                            key={gateway.id}
                                            value={gateway.id}
                                        >
                                            {gateway.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {sender.gateway_id &&
                                <FormControl variant="outlined" margin="normal">
                                    <InputLabel id={'Sender-label'}>{'Sender'}</InputLabel>
                                    <Select
                                        value={sender.sender_type ? sender.sender_type + '|||' + sender.sender : ''}
                                        onChange={e => {
                                            let value = e.target.value.split('|||')
                                            let senderName = null
                                            if (value[0] === 'phone') {
                                                gateways.map(gateway => {
                                                    if (gateway.id === sender.gateway_id) {
                                                        gateway.from.map(phone => {
                                                            if (phone.key === value[1]) {
                                                                senderName = phone.label
                                                            }
                                                        })
                                                    }
                                                })

                                            } else {
                                                gateways.map(gateway => {
                                                    if (gateway.id === sender.gateway_id) {
                                                        gateway.pools.map(pool => {
                                                            if (pool.key === value[1]) {
                                                                senderName = pool.label
                                                            }
                                                        })
                                                    }
                                                })

                                            }
                                            poolSenders[index] = {
                                                ...sender,
                                                sender_name: senderName,
                                                sender_type: value[0],
                                                sender: value[1]
                                            }
                                            setPoolSenders(poolSenders)
                                        }}
                                    >
                                        <MenuItem
                                            disabled
                                            key={'Phones'}
                                            value={'Phones'}
                                        >
                                            Phones
                                        </MenuItem>
                                        {gateways.map(gateway => {
                                            if (gateway.id === sender.gateway_id) {
                                                return gateway.from.map((phone) => (
                                                        <MenuItem
                                                            key={phone.key}
                                                            value={'phone|||' + phone.key}
                                                        >
                                                            {phone.label}
                                                        </MenuItem>
                                                    )
                                                )
                                            }
                                        })

                                        }
                                        <MenuItem
                                            disabled
                                            key={'Pools'}
                                            value={'Pools'}
                                        >
                                            Pools
                                        </MenuItem>
                                        {gateways && gateways.map(gateway => {
                                            if (gateway.id === sender.gateway_id) {
                                                return gateway.pools.map((pool) => {
                                                    return (
                                                        <MenuItem
                                                            key={pool.key}
                                                            value={'pool|||' + pool.key}
                                                        >
                                                            {pool.label}
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        })
                                        }
                                    </Select>
                                </FormControl>
                            }
                        </div>
                        <IconButton style={{marginLeft: 5}} size="small" onClick={() => {
                            poolSenders.splice(index, 1);
                            setPoolSenders([...poolSenders]);
                        }}>
                            <Close fontSize="inherit" />
                        </IconButton>
                    </div>
                </div>
            })}

            <div className="align-center wrapper content-justified">
                <Button
                    color="info"
                    variant="outlined"
                    name="add_sender"
                    startIcon={<AddCircleRounded />}
                    onClick={(e) => {
                        setPoolSenders([...poolSenders, defaultSenderObject]);
                    }}
                >Add Sender</Button>
            </div>
        </div>
    );
};

export default PoolSenders;