import React, {Fragment, useMemo, useState} from 'react'
import Chip from '@material-ui/core/Chip'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {Divider} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'


const useStyles = makeStyles({
    source: {
        fontWeight: 'bold'
    },
    list: {
        paddingTop: '3px',
        paddingBottom: '3px',
    }
});

const FilterListsRow = ({label, items, selectedItems, onChange, loading}) => {

    const classes = useStyles()

    const [setMenuAnchorEl, onSetMenuAnchorElChange] = useState(null)
    const availableItems = useMemo(() => {
        const outputItems = [];
        if (!items) {
            return []
        }

        items.forEach((item, index) => {
            const filteredLists = [...item.lists.filter(item => {
                return !selectedItems.find(selectedItem => selectedItem.id === item.id)
            })]
            outputItems.push(item)
            if (filteredLists.length) {
                filteredLists.forEach(item => {
                    outputItems.push(item)
                })
            }
            outputItems.push({
                id: `divider${index}`,
                divider: true
            })

        })

        outputItems.pop()

        return outputItems;
    }, [items, selectedItems])

    const handleDeleteItem = item => {
        const newItems = [...selectedItems.filter(i => i.id !== item.id)]
        if (onChange) {
            onChange(newItems)
        }
    }

    const handleAddItem = item => {
        onSetMenuAnchorElChange(null)
        const newItems = [...selectedItems, item]
        if (onChange) {
            onChange(newItems)
        }
    }

    return (
        <Fragment>
            <div className="filterRow">
                <div>
                    {(selectedItems || []).map((item) => {
                        const itemLabel = item.name

                        return (
                            <Chip
                                disabled={loading}
                                style={{marginBottom: '5px'}}
                                key={item.id}
                                label={itemLabel}
                                onDelete={() => {
                                    handleDeleteItem(item)
                                }}
                            />
                        )
                    })}
                    <Chip
                        style={{marginBottom: '5px'}}
                        disabled={!availableItems.length || loading || selectedItems.length > 0}
                        label={label || 'Add Item'}
                        icon={<AddCircleOutlineIcon/>}
                        onClick={e => {
                            onSetMenuAnchorElChange(e.target)
                        }}
                    />
                </div>
                <Menu
                    anchorEl={setMenuAnchorEl}
                    open={Boolean(setMenuAnchorEl)}
                    onClose={() => onSetMenuAnchorElChange(null)}
                >
                    {
                        availableItems.map((option) => {
                            if (option.divider) {
                                return <Divider key={option.id}/>
                            }

                            return (

                                <MenuItem
                                    disabled={!option.source_id}
                                    className={!option.source_id ? classes.source : classes.list}
                                    dense={true}
                                    key={option.source_id + option.id}
                                    value={option}
                                    onClick={(e) => {
                                        handleAddItem(option)
                                    }}
                                >
                                    <span style={{paddingLeft: !option.source_id ? '0' : '20px'}}>
                                       {option.name}
                                    </span>
                                </MenuItem>
                            )
                        })
                    }
                </Menu>
            </div>
        </Fragment>

    )
}

export default FilterListsRow
