import React, {Fragment, useEffect, useState} from 'react'
import {useTable, useSortBy, useRowSelect, useGlobalFilter} from 'react-table'
import ListLoader from '../loaders/ListLoader'
import IndeterminateCheckbox from '../IndeterminateCheckbox'
import TableEmptyListMessage from '../TableEmptyListMessage'
import ListPagination from '../ListPagination'
import SearchInput from '../SearchInput'
import './ListTable.css'
import Button from '@material-ui/core/Button'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Popover from '@material-ui/core/Popover'
import Checkbox from '@material-ui/core/Checkbox'
import Scroll from 'react-scroll'
import ErrorIcon from '@material-ui/icons/Error'

const pluralize = require('pluralize')


const ListTable = props => {

    const {
        tableWrapperRef,
        columns,
        data,
        loading,
        fetching,
        isSelectionColumn,
        onSelectedRows,
        onRowClick,
        onSort,
        emptyDataText,
        pagination,
        pageLimit,
        onPageChange,
        onLimitChange,
        onSearch,
        onHideColumns,
        recordsInfo,
        disableSearchBlock = false,
        disableTopBorder = false,
        hiddenColumnsStorageKey = null,
        initSort,
        defaultHiddenColumns = [],
        additionalFilterComponent = null,
        additionalSearchCssClass = null,
    } = props

    const [anchorImportEl, setAnchorImportEl] = useState(null)
    const isColumnPopoverOpen = Boolean(anchorImportEl)

    const tableInstance = useTable({
            columns,
            data,
            manualSortBy: true,
            manualGlobalFilter: true,
            initialState: {
                sortBy: initSort ? initSort : [],
                hiddenColumns: defaultHiddenColumns.concat(JSON.parse(localStorage.getItem(hiddenColumnsStorageKey)) || [])
            }
        },

        useGlobalFilter,
        useSortBy,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push((columns) => {
                return isSelectionColumn ? [
                    {
                        id: 'selection',
                        Header: ({getToggleAllRowsSelectedProps}) => (
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        ),
                        Cell: ({row}) => (
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        ),
                    },
                    ...columns
                ] : columns
            })
        }
    )


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        state: {sortBy, globalFilter, hiddenColumns},
        setGlobalFilter,
        allColumns,
        visibleColumns,
        toggleHideAllColumns,
    } = tableInstance

    useEffect(() => {
        if (hiddenColumnsStorageKey) {
            localStorage.setItem(hiddenColumnsStorageKey, JSON.stringify(hiddenColumns))
        }
    }, [hiddenColumns, hiddenColumnsStorageKey])

    useEffect(() => {
        if (onHideColumns) {
            onHideColumns(visibleColumns.filter(item => item.id !== 'selection').map(item => item.id))
        }
    }, [visibleColumns, onHideColumns])

    useEffect(() => {
        if (onSearch)
            onSearch(globalFilter || '')
    }, [globalFilter, onSearch])

    useEffect(() => {
        if (onSelectedRows)
            onSelectedRows(selectedFlatRows.map(row => row.original.id))
    }, [selectedFlatRows, onSelectedRows])

    useEffect(() => {
        return onSort(sortBy.map(item => (
            {
                key: item.id,
                direction: item.desc ? 'desc' : 'asc'
            }
        )))
    }, [sortBy, onSort])

    const renderSearchBlock = () => {
        if (!onSearch) return null

        return (
            <div className="searchHolder">
                { additionalFilterComponent }
                <div className="inputHolder">
                    <SearchInput
                        search={globalFilter}
                        onChange={setGlobalFilter}
                    />
                </div>
                {recordsInfo && !!recordsInfo.length && (
                    <div style={{marginLeft: '10px'}}>
                        {
                            recordsInfo.map((item, index) => {
                                return (
                                    <span key={index} style={{fontSize: '14px'}}>
                                        <span style={{fontWeight: 'bold'}}>{item.count}</span>&nbsp;
                                        <span style={{opacity: '.5'}}>
                                            {item.notNoun ? item.label : pluralize(item.label, item.count)}
                                            {recordsInfo.length - 1 === index ? '' : ','}
                                        </span>&nbsp;
                                    </span>
                                )
                            })
                        }
                    </div>
                )}
            </div>
        )
    }

    const renderEmptyList = () => {
        if (fetching) {
            return <TableEmptyListMessage>{emptyDataText}</TableEmptyListMessage>
        }

        if (!data.length) {
            return <TableEmptyListMessage>{emptyDataText}</TableEmptyListMessage>
        }
        if (!pagination || pagination['total'] > 0) return null
        return <TableEmptyListMessage>{emptyDataText}</TableEmptyListMessage>
    }

    const renderRows = () => {
        if (loading || fetching) {
            return <></>;
        }

        return rows.map(row => {
            prepareRow(row)
            return (
                <tr {...row.getRowProps()}>
                    {
                        row.cells.map(cell => {
                            return (
                                <td {...cell.getCellProps({
                                    className: cell.column.className
                                })} onClick={() => {
                                    if (cell.value !== undefined && onRowClick) {
                                        onRowClick(cell.row.original)
                                    }
                                }}>
                                    {cell.render('Cell')}
                                </td>
                            )
                        })
                    }
                </tr>
            )
        })
    }

    const renderPagination = () => {
        if (!pagination) return null

        if (pagination && pagination['total'] > 0) {
            return (
                <ListPagination
                    limit={pageLimit}
                    pagination={pagination}
                    onChange={p => {
                        Scroll.animateScroll.scrollToTop()
                        onPageChange(p)
                    }}
                    onLimitChange={onLimitChange}
                />
            )
        }
        return null
    }

    const renderColumnsSelector = () => {
        return (
            <Fragment>
                <Button
                    startIcon={hiddenColumns.length ? <ErrorIcon color='disabled'/> : null}
                    endIcon={<ArrowDropDownIcon/>}
                    onClick={(e) => setAnchorImportEl(e.currentTarget)}
                    color={hiddenColumns.length ? 'primary' : 'default'}
                >
                    Columns
                </Button>
                <Popover
                    open={isColumnPopoverOpen}
                    anchorEl={anchorImportEl}
                    onClose={() => setAnchorImportEl(null)}
                    className='importPopover'
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <div className="importPopoverContent">
                        <div className="ipTitleWrapper">
                            <div className="title">Columns</div>
                            <div onClick={() => toggleHideAllColumns(false)}
                                 style={{opacity: hiddenColumns.length ? 1 : 0.3}}
                                 className={hiddenColumns.length ? 'linkColorNormal text-semi-bold text-mute clickable' : 'text-semi-bold text-mute'}>
                                Reset
                            </div>
                        </div>
                        <div className="fileProgressHolder">
                            {
                                allColumns
                                    .filter(column => {
                                        return column.id !== 'selection' && column.id !== 'actions'
                                    })
                                    .map(column => {
                                        const isDisabled = column.Header === 'Phone' || column.Header === 'Email'
                                        return (
                                            <div key={column.id}>
                                                <label>
                                                    <Checkbox
                                                        {...column.getToggleHiddenProps()}
                                                        disabled={isDisabled}
                                                        color="primary"
                                                    />
                                                    {column.Header}
                                                </label>
                                            </div>
                                        )
                                    })
                            }
                        </div>
                    </div>
                </Popover>
            </Fragment>
        )
    }

    return (
        <div className="pageTableBlock">
            {
                !disableSearchBlock && (
                    <div className={`searchHolder hasFilter ${additionalSearchCssClass ? additionalSearchCssClass : ''}`}>
                        {renderSearchBlock()}
                        <div className="userMenuHolder">
                            {renderColumnsSelector()}
                        </div>
                    </div>
                )
            }
            <div className={`tableHolder hasCheckboxes rowClickable${disableTopBorder ? '' : ' noBorderTop'}`} ref={tableWrapperRef}>
                <table {...getTableProps()}>
                    {(!loading && !fetching) && <thead>
                    {
                        headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    headerGroup.headers.map(column => {
                                        const sortClass = column.isSorted ? (column.isSortedDesc ? 'hasArrow' : 'hasArrow up') : ''
                                        const columnClass = `${column.className} ${sortClass}`
                                        return (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                                className={columnClass}
                                            >
                                                {column.render('Header')}
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        ))
                    }
                    </thead>}
                    <tbody {...getTableBodyProps()}>
                    {(loading || fetching) && <ListLoader/>}
                    {renderEmptyList()}
                    {renderRows()}
                    </tbody>
                </table>
                {renderPagination()}
            </div>
        </div>
    )
}

export default ListTable
