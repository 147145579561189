import React, { Fragment, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useAppDispatch } from '../../Contexts/AppContext'
import { useNavigate } from 'react-router-dom'
import Exit from '../../assets/img/exit.svg'
import EventEmitter from '../../Utils/EventEmitter'
import EditIcon from '../../assets/img/settings.svg'
import ExitIcon from '../../assets/img/exit_to_app.svg'
import Delete from '../../assets/img/delete.svg'
import ImportFileModal from '../../components/modals/ImportFileModal'
import SaveListModal from '../../components/modals/SaveListModal'
import DeleteModal from '../../components/modals/DeleteModal'
import DataService from '../../Services/DataService'
import AlertToastr from '../../Utils/alert'
import { removeLeads } from '../../api'
import { useAuth } from '../../Contexts/AuthProvider';

const ListPageActions = ({source, list, selectedRows, loading}) => {

    const {setContextMenu} = useAppDispatch()
    const {loggedUser} = useAuth()
    const history = useNavigate()
    const [showImportModal, setShowImportModal] = useState(false)
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showDeleteLeadsModal, setShowDeleteLeadsModal] = useState(false)
    const queryClient = useQueryClient()

    const deleteLeadsMutation = useMutation(removeLeads)

    useEffect(() => {
        const items = [
            {
                label: 'Import File',
                icon: Exit,
                action: () => EventEmitter.emit('ImportFile'),
                disabled: loading
            },
            {
                label: 'Edit List',
                icon: EditIcon,
                action: () => EventEmitter.emit('ListEdit'),
                disabled: loading
            },
            {
                label: 'Remove list',
                icon: ExitIcon,
                action: () => EventEmitter.emit('ListDelete'),
                disabled: loading
            }
        ]

        if (selectedRows.length) {
            items.push(                {
                label: 'Delete Selection',
                icon: Delete,
                action: () => EventEmitter.emit('LeadsBulkDeletion'),
                iconStyle: {transform: 'rotate(45deg)'}
            })
        }

        setContextMenu(items)
    }, [setContextMenu, loading, selectedRows])

    //listener: import file
    useEffect(() => {
        const onEvent = () => setShowImportModal(true)

        const listener = EventEmitter.addListener('ImportFile', onEvent)
        return () => { listener.remove() }
    }, [])

    //listener: list edit
    useEffect(() => {
        const onEvent = () => setShowSaveModal(true)

        const listener = EventEmitter.addListener('ListEdit', onEvent)
        return () => { listener.remove() }
    }, [])

    //listener: list delete
    useEffect(() => {
        const onEvent = () => setShowDeleteModal(true)

        const listener = EventEmitter.addListener('ListDelete', onEvent)
        return () => { listener.remove() }
    }, [])

    //listener: leads bulk delete
    useEffect(() => {
        const onEvent = () => setShowDeleteLeadsModal(true)

        const listener = EventEmitter.addListener('LeadsBulkDeletion', onEvent)
        return () => { listener.remove() }
    }, [])

    const deleteList = () => {
        setShowDeleteModal(false)
        DataService.deleteList(list.id)
            .then(response => {
                history(`/u/${loggedUser}/sources/${source.id}`)
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors)
            })
    }

    const deleteItems = async () => {
        setShowDeleteLeadsModal(false)
        await deleteLeadsMutation.mutateAsync(selectedRows)
        queryClient.invalidateQueries('listLeads').then()
    }

    return (
        <Fragment>
            <ImportFileModal
                source={source}
                list={list}
                open={showImportModal}
                handleClose={() => setShowImportModal(false)}
            />
            <SaveListModal
                open={showSaveModal}
                handleClose={(e) => setShowSaveModal(false)}
                list={list}/>
            <DeleteModal
                open={showDeleteModal}
                handleDelete={deleteList}
                handleClose={() => setShowDeleteModal(false)}
                instance={'the list'}
            />
            <DeleteModal
                open={showDeleteLeadsModal}
                handleDelete={deleteItems}
                handleClose={() => setShowDeleteLeadsModal(false)}
                instance={'selected leads'}
            />
        </Fragment>
    )
}

export default ListPageActions
