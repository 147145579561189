import React from 'react'
import InlineLoader from './loaders/InlineLoader'

const SourceHeader = ({ source, loading }) => {

    const renderName = () => {
        return loading ? <InlineLoader size={30} /> : source?.name
    }

    const items = [
        {
            label: 'Total Leads',
            count: source?.total_leads
        },
        {
            label: 'Unique Today',
            count: source?.unique_today
        },
        {
            label: 'Total today',
            count: source?.added_today
        }
    ]

    return (
        <div className="pageHeadingBlockWrapper">
            <div className="part main">
                <div className="partContent">
                    <div className="hasSwitch mb15">
                        <h2 className="textPrimary">{renderName()}</h2>
                    </div>
                </div>
            </div>
            {
                items.map(item => (
                    <div className="part" key={item.label}>
                        <div className="partContent">
                            <h2 className="mb10">{item.label}</h2>
                            <div>{loading ? <InlineLoader /> : item.count}</div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default SourceHeader
