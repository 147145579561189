import React, {useState, Fragment} from 'react'
import {Outlet} from 'react-router-dom';
import HeaderComponent from './Header.component'
import Popover from '@material-ui/core/Popover'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import {useAppState} from '../../Contexts/AppContext'
import {useAuth} from '../../Contexts/AuthProvider';
import MenuItem from '@material-ui/core/MenuItem'
import MainMenuItem from '../menus/MenuItem'
import Menu from '@material-ui/core/Menu'
import Collapse from '@material-ui/core/Collapse'
import BreadcrumbItem from "../menus/BreadcrumbItem";

const ContextMenuItem = ({item}) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const [openCollapse, setOpenCollapse] = React.useState(false)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleopenCollapse = () => {
        if (!openCollapse) {
            setOpenCollapse(true)
        } else {
            setOpenCollapse(false)
        }
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    return !item.items ? (
        item.label === "Run Lookup" || item.label === "Relookup" ? (
            <>
                <div
                    className="menuListItem"
                    onClick={!item.disabled ? handleopenCollapse : () => {
                    }}
                    style={item.disabled ? {opacity: '.3', cursor: 'wait'} : {}}
                >
                    <img
                        src={item.icon}
                        className="ico"
                        alt={item.label}
                        style={item.iconStyle || {}}
                    />
                    <span className="caption">{item.label}</span>
                </div>
                <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                    <div
                        className={"menuListItem"}
                        style={{display: "flex", padding: "10px 15px 10px 48px"}}
                        onClick={!item.disabled ? item.actionSMS : () => {
                        }}
                    >
                        <span className="caption">SMS Lookup</span>
                    </div>
                    <div
                        className={"menuListItem"}
                        style={{display: "flex", padding: "10px 15px 10px 48px"}}
                        onClick={!item.disabled ? item.actionSmsDNC : () => {
                        }}
                    >
                        <span className="caption">SMS DNC</span>
                    </div>
                    <div
                        className={"menuListItem"}
                        style={{display: "flex", paddingLeft: "48px", marginTop: 0}}
                        onClick={!item.disabled ? item.actionEmail : () => {
                        }}
                    >
                        <span className="caption">Email Lookup</span>
                    </div>
                </Collapse>
            </>
        ) : (
            <div
                className="menuListItem"
                onClick={!item.disabled ? item.action : () => {
                }}
                style={item.disabled ? {opacity: '.3', cursor: 'wait'} : {}}
            >
                <img
                    src={item.icon}
                    className="ico"
                    alt={item.label}
                    style={item.iconStyle || {}}
                />
                <span className="caption">{item.label}</span>
            </div>
        )
    ) : (
        <Fragment>
            <div
                className="menuListItem"
                onClick={!item.disabled ? handleClick : () => {
                }}
                style={item.disabled ? {opacity: '.3', cursor: 'wait'} : {}}
            >
                <img
                    src={item.icon}
                    className="ico"
                    alt={item.label}
                    style={item.iconStyle || {}}
                />
                <span className="caption">{item.label}</span>
            </div>
            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {
                    item.items.map(menuItem => {
                        return (
                            <MenuItem
                                key={menuItem.label}
                                onClick={() => {
                                    if (menuItem.action) {
                                        menuItem.action()
                                    }
                                    setAnchorEl(null)
                                }}
                            >
                                {menuItem.label}
                            </MenuItem>
                        )
                    })
                }

            </Menu>
        </Fragment>

    )
}

const MainLayout = props => {
    const {children, source, list, lead, segment, segmentLead} = props
    const [anchorEl, setAnchorEl] = useState(null)
    const {mainMenu, contextMenu, breadcrumbs} = useAppState()
    const handleMobileAsideMenuClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const open = Boolean(anchorEl)
    const handleMobileAsideMenuClose = () => {
        setAnchorEl(null)
    }

    const renderMainMenu = () => {
        return (
            <div className='menuList'>
                {mainMenu.map((item) => (
                    <MainMenuItem
                        key={item.label}
                        item={item}
                    />
                ))}
            </div>
        )
    }

    const renderAsideMenu = () => {
        return (
            <div className='menuList'>
                {contextMenu.map((item) => {
                    return <ContextMenuItem key={item.label} item={item}/>
                })}
                {/* {
          !!contextLinks.length && (
            <div className='menuListItem'>
              <img src={CaretImg} alt='download' className='ico' />
              <span className='btn' onClick={redirectDownloadFile}>
                Download
              </span>
            </div>
          )
        } */}
            </div>
        )
    }


    return (
        <div className='mainLayout'>
            <HeaderComponent
                source={source}
                list={list}
                lead={lead}
                segment={segment}
                segmentLead={segmentLead}
            />

            <aside className='mainMenu'>{renderMainMenu()}</aside>
            <aside className='asideMenu'>{renderAsideMenu()}</aside>
            <main className='mainLayoutContent'>
                <div className='mobileSubMenu'>
                    <h3>
                        <ol className='breadcrumbs'>
                            {breadcrumbs.map((item, idx) => (
                                <BreadcrumbItem item={item} key={idx}/>
                            ))}
                        </ol>
                    </h3>
                    <div className='mobileAsideMenu'>
                        <IconButton onClick={handleMobileAsideMenuClick}>
                            <MoreVertIcon/>
                        </IconButton>
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleMobileAsideMenuClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            {renderAsideMenu()}
                            {/*<List component='nav' aria-label='main mailbox folders' className='popoverMenu'>*/}
                            {/*    <ListItem button>*/}
                            {/*        <ListItemIcon>*/}
                            {/*            <SettingsIcon/>*/}
                            {/*        </ListItemIcon>*/}
                            {/*        <ListItemText primary='Import File'/>*/}
                            {/*    </ListItem>*/}
                            {/*    <ListItem button>*/}
                            {/*        <ListItemIcon>*/}
                            {/*            <ExitToAppIcon/>*/}
                            {/*        </ListItemIcon>*/}
                            {/*        <ListItemText primary='Add New Source'/>*/}
                            {/*    </ListItem>*/}
                            {/*</List>*/}
                        </Popover>
                    </div>
                </div>
                <div className='mainContainer'>
                    <Outlet/>
                </div>
            </main>
        </div>
    )
}

export default MainLayout
