import React, {useEffect} from 'react'
import {useMutation, useQueryClient} from "react-query";
import {Controller, useForm} from "react-hook-form";
import {storeEmailGateway, updateEmailGateway} from "../../../api";
import TextField from "@material-ui/core/TextField";
import ReactHookFormSelect from "../../../components/form/ReactHookFormSelect";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";


const EmailFormComponents = ({handleClose, defaultValues}) => {
    const queryClient = useQueryClient();
    const {control, handleSubmit, reset, watch} = useForm({
        mode: "onTouched",
        defaultValues: defaultValues ? defaultValues : {},
    });

    const storeMutationEmail = useMutation(storeEmailGateway);
    const updateMutationEmail = useMutation(updateEmailGateway);

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    const onSubmit = handleSubmit(async (data) => {
        if (!data) {
            return;
        }
        const newData = {
            active: data.active,
            name: data.name,
            type: data.type,
            params: {
                key: data.key,
                region: data.region,
                secret: data.secret
            }
        }
        if (defaultValues) {
            await updateMutationEmail.mutateAsync({id: defaultValues.id, ...newData})
        } else {
            await storeMutationEmail.mutateAsync(newData);
        }

        reset();
        handleClose();
        queryClient.invalidateQueries("emailGateways").then();
    });

    const type = watch('type', defaultValues ? defaultValues.type : null);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ReactHookFormSelect
                variant="outlined"
                margin="normal"
                id="gateway-select"
                name="type"
                label="Gateway"
                control={control}
                defaultValue=""
                disabled={!!defaultValues}
            >
                <MenuItem value="" disabled>
                    Select Gateway
                </MenuItem>
                <MenuItem value={"aws_ses"}>
                    AWS Ses
                </MenuItem>
            </ReactHookFormSelect>
            <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({onChange, value}) => {
                    return (
                        <TextField
                            onChange={onChange}
                            value={value}
                            variant="outlined"
                            margin="normal"
                            label="Name"
                        />
                    );
                }}
            />

            {type === 'aws_ses' &&
                <>
                    <Controller
                        name="key"
                        control={control}
                        defaultValue=""
                        render={({onChange, value}) => {
                            return (
                                <TextField
                                    onChange={onChange}
                                    value={value}
                                    variant="outlined"
                                    margin="normal"
                                    label="Key"
                                />
                            );
                        }}
                    />
                    <Controller
                        name="secret"
                        control={control}
                        defaultValue=""
                        render={({onChange, value}) => {
                            return (
                                <TextField
                                    onChange={onChange}
                                    value={value}
                                    variant="outlined"
                                    margin="normal"
                                    label="Secret"
                                />
                            );
                        }}
                    />
                    <Controller
                        name="region"
                        control={control}
                        defaultValue=""
                        render={({onChange, value}) => {
                            return (
                                <TextField
                                    onChange={onChange}
                                    value={value}
                                    variant="outlined"
                                    margin="normal"
                                    label="Region"
                                />
                            );
                        }}
                    />
                </>
            }

            <div className="btnHolder hasPadTop twoBtn">
                <div className="part">
                    <Button
                        disabled={storeMutationEmail.isLoading || updateMutationEmail.isLoading}
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                        onClick={(e) => {
                        }}
                    >
                        Save
                    </Button>
                </div>
                <div className="part">
                    <Button
                        disabled={storeMutationEmail.isLoading || updateMutationEmail.isLoading}
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </form>
    );
}

export default EmailFormComponents
