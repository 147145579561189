import React, {useEffect, useMemo, useState} from "react"
import {useAppDispatch} from "../Contexts/AppContext"
import PlusCircle from "../assets/img/plus_circle.svg";
import EventEmitter from "../Utils/EventEmitter";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {deleteCampaignRoute, getRoutes, getSMSGateways} from "../api";
import ShortUrlDomainItemContextMenu from "../components/ShortUrlDomainItemContextMenu";
import ListTable from "../components/ListTable/ListTable";
import DeleteModal from "../components/modals/DeleteModal";
import CreateRouteModal from "../components/modals/routes/CreateRouteModal";
import Chip from "@material-ui/core/Chip";
import {Tooltip} from "@material-ui/core";

const CampaignRoutes = () => {
    const queryClient = useQueryClient()
    const {setBreadcrumbs, setSection} = useAppDispatch();
    const {setContextMenu} = useAppDispatch();
    const [routes, setRoutes] = useState([]);
    const [currentModel, setCurrentModel] = useState({
        id: null,
        name: "",
        default: {
            gateway_id: null,
            sender_type: null,
            sender: "",
            sender_name: "",
        },
        groups: []
    });
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [gateways, setGateways] = useState();

    const gatewaysRequest = useQuery(["gateways"], () => getSMSGateways(), {keepPreviousData: true})

    useEffect(() => {
        if (gatewaysRequest.isSuccess) {
            setGateways(gatewaysRequest.data.data)
        }
    }, [gatewaysRequest.isSuccess, gatewaysRequest.data, gateways])

    const defaultRouteModel = useMemo(() => {
        return {
            id: null,
            name: "",
            default: {
                gateway_id: undefined,
                sender_type: null,
                sender: "",
                sender_name: "",
                frequency: 0
            },
            groups: []
        }
    }, [])

    useEffect(() => {
        setBreadcrumbs([
            {label: "Settings", link: "./settings"},
            {label: "Campaign Routes"}
        ])
        setSection("settings")
    }, [setBreadcrumbs, setSection])

    useEffect(() => {
        setContextMenu([
            {
                label: "Add New Route",
                icon: PlusCircle,
                action: () => EventEmitter.emit("CampaignRouteCreate"),
            },
        ])
    }, [setContextMenu])

    useEffect(() => {
        const onEvent = () => {
            setShowCreateModal(true)
            setCurrentModel(defaultRouteModel)
        }

        const listener = EventEmitter.addListener("CampaignRouteCreate", onEvent)
        return () => {
            listener.remove()
        }
    }, [setShowCreateModal, setCurrentModel, defaultRouteModel])


    const showDeleteConfirmation = (attr) => {
        setCurrentModel(attr)
        setShowDeleteModal(true)
    }

    const handleEditRoute = (attr) => {
        setCurrentModel(attr)
        setShowUpdateModal(true)
    }

    const deleteCampaignRouteRequest = useMutation(deleteCampaignRoute)
    const modelLabel = currentModel ? `"${currentModel.name}" campaign route` : "campaign route"
    const handleDeleteCampaignRoute = async () => {
        if (!currentModel) {
            return;
        }

        await deleteCampaignRouteRequest.mutateAsync(currentModel.id)
        setShowDeleteModal(false)
        await queryClient.invalidateQueries("campaignRouteList")
        setCurrentModel(defaultRouteModel)
    }

    const tableColumns = useMemo(() => {
        return [
            {
                Header: "ID",
                accessor: "id",
            },
            {
                Header: "Name",
                accessor: "name"
            },
            {
                Header: "Default",
                accessor: "default",
                Cell: ({value, row}) => {
                    return (
                        <div>
                            <span><b>Frequency</b>: {value.frequency}</span><br/>
                            {gateways && gateways.map(gateway => {
                                if (gateway.id === value.gateway_id) {
                                    return <span key={gateway.id}><b>SMS Gateway Name</b>: {gateway.name}<br/></span>
                                } else {
                                    return null;
                                }
                            })
                            }
                            <span><b>Sender</b>: {value.sender_name}</span><br/>
                            <span><b>Sender type:</b> {value.sender_type === "phone" ? "Phone" : "Pool"}</span>
                        </div>
                    )
                }
            },
            {
                Header: 'Estimated frequency',
                accessor: 'estimated_frequency',
                Cell: ({row}) => {
                    let frequency = row.original.default.frequency;

                    row.original.groups.map(group => {
                        frequency += group.frequency
                    })

                    return '' + frequency + '/min';
                }
            },
            {
                Header: "Groups",
                accessor: "groups",
                Cell: ({value, row}) => {
                    if (!value.length) {
                        return <span>Empty</span>
                    }
                    return value.map(group => {
                        return (
                            <Tooltip title={
                                <div key={group.group_id}>
                                    <span><b>Group: </b>{group.group.name}</span><br/>
                                    <span><b>SMS Gateway Name: </b>
                                        {gateways && gateways.map(gateway => {
                                            if (gateway.id === group.gateway_id) {
                                                return gateway.name;
                                            }
                                        })}
                                    </span><br/>
                                    <span><b>Sender: </b> {group.sender_name}</span><br/>
                                    <span><b>Sender type: </b>{group.sender_type}</span><br/>
                                    <span><b>Frequency: </b>{group.frequency}</span><br/>
                                </div>
                            } placement="top-start" arrow>
                                <Chip label={group.group.name} variant="outlined" style={{marginLeft: "5px"}}/>
                            </Tooltip>
                        )
                    })
                }
            },
            {
                Header: "",
                id: "actions",
                className: "text-right",
                Cell: ({value, row}) => {
                    return (
                        <ShortUrlDomainItemContextMenu
                            item={row.original}
                            onEdit={() => handleEditRoute(row.original)}
                            onDelete={() => showDeleteConfirmation(row.original)}
                        />
                    )
                }
            }
        ]
    }, [gateways])

    const routeRequest = useQuery(
        ["campaignRouteList"],
        () => getRoutes(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (routeRequest.isSuccess) {
            setRoutes(routeRequest.data.data)
        }
    }, [routeRequest.isSuccess, routeRequest.data])

    return (
        <div className="pageHolder">
            <ListTable
                columns={tableColumns}
                data={routes}
                loading={routeRequest.isLoading}
                fetching={routeRequest.isFetching}
                onSort={(sort) => {
                }}
                emptyDataText="No Routes Found"
                pagination={null}
                pageLimit={100}
                onPageChange={1}
                onLimitChange={(l) => {
                }}
                disableSearchBlock={true}
            />

            <CreateRouteModal
                open={showCreateModal}
                close={() => {
                    setShowCreateModal(false)
                }}
                route={currentModel}
                setRoute={setCurrentModel}
            />

            <CreateRouteModal
                open={showUpdateModal}
                close={() => {
                    setShowUpdateModal(false)
                }}
                route={currentModel}
                setRoute={setCurrentModel}
            />
            <DeleteModal
                loading={deleteCampaignRouteRequest.isLoading}
                open={showDeleteModal}
                handleDelete={handleDeleteCampaignRoute}
                handleClose={() => setShowDeleteModal(false)}
                instance={modelLabel}
            />
        </div>
    )
}

export default CampaignRoutes
