import React, {useEffect, useState} from "react";
import ListTable from "../../../components/ListTable/ListTable";
import AddIntegrationModal from "./AddIntegrationModal";
import DeleteModal from "../../../components/modals/DeleteModal";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getEmailLookupGateways, removeLookupGateway} from "../../../api";
import DisabledValue from "../../../components/DisabledValue";
import ShortUrlDomainItemContextMenu from "../../../components/ShortUrlDomainItemContextMenu";

const EmailLookupList = () => {

    const [list, setList] = useState([])
    const [sorting, setSorting] = useState();
    const [currentModel, setCurrentModel] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);

    const queryClient = useQueryClient();

    const listRequest = useQuery(
        ["emailLookupGateways"],
        () => getEmailLookupGateways(),
        {keepPreviousData: true}
    )


    useEffect(() => {
        if (listRequest.isSuccess) {
            setList(listRequest.data.data)
        }
    }, [listRequest.isSuccess, listRequest.data])

    const deleteRequest = useMutation(removeLookupGateway)


    const showDeleteConfirmation = (item) => {
        setCurrentModel(item)
        setShowDeleteModal(true)
    }

    const deleteItem = async () => {
        if (!currentModel) {
            return;
        }

        await deleteRequest.mutateAsync(currentModel.id)
        setShowDeleteModal(false)
        await queryClient.invalidateQueries("emailLookupGateways")
        setCurrentModel(null)
    }

    const handleEditItem = item => {
        if (item.default) {
            return;
        }
        setCurrentModel({
            connection_type: "email_lookup",
            ...item,
            ...item.params,
        })
        setShowUpdateModal(true)
    }


    const tableColumns = [
        {
            Header: "Email Lookup Gateway Name",
            accessor: "name",
            className: "integrationTableColumn",
        },
        {
            Header: "Integration",
            accessor: "type",
            className: "integrationTableColumn",
        },
        {
            Header: "Status",
            accessor: "active",
            Cell: ({value, row}) => {
                const label = (
                    <div className={value ? "chipLike success" : "chipLike"}>
                        <span className="chipLikeLabel">{value ? "Active" : "Inactive"}</span>
                    </div>
                )
                return row.original.default ? <DisabledValue value={label}/> : label
            },
            className: "integrationTableColumn",
        },
        {
            Header: "Force Lookup",
            accessor: "is_force",
            Cell: ({row}) => {
                return row.original.is_force ? "Yes" : "No"
            },
            className: "integrationTableColumn",
        },
        {
            Header: "",
            id: "actions",
            className: "text-right",
            Cell: ({value, row}) => {
                return (
                    <ShortUrlDomainItemContextMenu
                        item={row.original}
                        onEdit={() => handleEditItem(row.original)}
                        onDelete={() => showDeleteConfirmation(row.original)}
                    />
                )
            }
        }
    ]

    return <div>
        <ListTable
            columns={tableColumns}
            data={list}
            loading={listRequest.isLoading}
            fetching={listRequest.isFetching}
            onSort={setSorting}
            emptyDataText={"No Email Lookup Gateways"}
            disablePagination
            disableSearchBlock={true}
            onRowClick={handleEditItem}
        />
        <AddIntegrationModal
            showModal={showUpdateModal}
            handleClose={() => {
                setShowUpdateModal(false)
            }}
            integrationModel={currentModel}
        >
        </AddIntegrationModal>
        <DeleteModal
            open={showDeleteModal}
            handleDelete={deleteItem}
            handleClose={() => setShowDeleteModal(false)}
            instance={currentModel ? `"${currentModel.name}" Email Lookup Gateway` : "Email Lookup Gateway"}
        />
    </div>
}

export default EmailLookupList
