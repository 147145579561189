const GATEWAY1 = 'gateway1';
const GATEWAY7 = 'gateway7';
const GATEWAY8 = 'gateway8';
const GATEWAY9 = 'gateway9';
const GATEWAY10 = 'gateway10';
const GATEWAY11 = 'gateway11';
const GATEWAY12 = 'gateway12';
const GATEWAY13 = 'gateway13';
const GATEWAY14 = 'gateway14';
const GATEWAY15 = 'gateway15';

export default {
    GATEWAY1,
    GATEWAY7,
    GATEWAY8,
    GATEWAY9,
    GATEWAY10,
    GATEWAY11,
    GATEWAY12,
    GATEWAY13,
    GATEWAY14,
    GATEWAY15,
}
