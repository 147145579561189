import React, {Fragment, useMemo, useState} from 'react'
import Chip from '@material-ui/core/Chip'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const FilterRow = ({label, items, selectedItems, onChange, loading}) => {

    const [setMenuAnchorEl, onSetMenuAnchorElChange] = useState(null)
    const availableItems = useMemo(() => {
        if (!items) return []
        if (!selectedItems) return items

        return [...items.filter(item => {
            return !selectedItems.find(selectedItem => selectedItem.id === item.id)
        })]
    }, [items, selectedItems])

    const handleDeleteItem = item => {
        const newItems = [...selectedItems.filter(i => i.id !== item.id)]
        if (onChange) onChange(newItems)
    }

    const handleAddItem = item => {
        onSetMenuAnchorElChange(null)
        const newItems = [...selectedItems, item]
        if (onChange) onChange(newItems)
    }

    return (
        <Fragment>
            <div className="filterRow">
                <div>
                    {(selectedItems || []).map((item) => {
                        return (
                            <Chip
                                disabled={loading}
                                style={{marginBottom: '5px'}}
                                key={item.id}
                                label={item.name}
                                onDelete={() => {
                                    handleDeleteItem(item)
                                }}
                            />
                        )
                    })}
                    <Chip
                        style={{marginBottom: '5px'}}
                        disabled={!availableItems.length || loading || selectedItems.length > 0}
                        label={label || 'Add Item'}
                        icon={<AddCircleOutlineIcon/>}
                        onClick={e => {
                            onSetMenuAnchorElChange(e.target)
                        }}
                    />
                </div>
                <Menu
                    anchorEl={setMenuAnchorEl}
                    open={Boolean(setMenuAnchorEl)}
                    onClose={() => onSetMenuAnchorElChange(null)}
                >
                    {
                        availableItems.map((option) => {
                            return (
                                <MenuItem
                                    dense={true}
                                    key={option.id}
                                    value={option}
                                    onClick={(e) => {
                                        handleAddItem(option)
                                    }}
                                >
                                    {option.name}
                                </MenuItem>
                            )
                        })
                    }
                </Menu>
            </div>
        </Fragment>

    )
}

export default FilterRow
