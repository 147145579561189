import React from 'react'
import Loader from 'react-loader-spinner'

const InlineLoader = ({ size = 17, style = {} }) => {
    return (
        <Loader
            style={style}
            type="ThreeDots"
            color="#0576ED"
            height={size}
            width={size}
        />
    )
}

export default InlineLoader
