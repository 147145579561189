import {useEffect, useState} from "react";
import {useQuery} from "react-query";

import {useAppState} from "../../Contexts/AppContext";
import {getAttributesAll, getCampaignsListNames} from "../../api";
import {
    segmentEngagementOptions,
    campaignSmsStatusOptions,
    campaignEmailStatusOptions,
    leadStatusesOptions,
    leadTypeOptions,
    engagementPeriodOptions,
    lastDayClickedOptions,
    phoneStatusesOptions,
    emailStatusesOptions,
    smsStatusPeriodOptions,
    carrierTypesOptions,
    dateRangeOptions,
    statesOptions,
    phoneCarrierStatusesOptions,
    phoneCampaignStatusesOptions,
    weekOfYearOptions,
} from "../../config";


const useSegmentFilterAvailableOptions = () => {
    const {availableSources} = useAppState();
    const [campaignOptions, setCampaignOptions] = useState([]);
    const [attributeOptions, setAttributeOptions] = useState([]);

    /*
     * Fetch Campaigns Options
     */
    const campaignsListNamesRequest = useQuery(
        ["campaignsListNames"],
        () => getCampaignsListNames(),
        {keepPreviousData: true}
    );

    useEffect(() => {
        if (campaignsListNamesRequest.isSuccess) {
            setCampaignOptions(campaignsListNamesRequest.data.data.map(item => ({
                id: item.id,
                name: item.name,
                type: item.type,
            })))
        }
    }, [campaignsListNamesRequest.isSuccess, campaignsListNamesRequest.data]);


    /*
     * Fetch Attributes Options
     */
    const attributesRequest = useQuery(
        ["attributes"],
        () => getAttributesAll(),
        {keepPreviousData: true}
    );

    useEffect(() => {
        if (attributesRequest.isSuccess) {
            setAttributeOptions(attributesRequest.data.data)
        }
    }, [attributesRequest.isSuccess, attributesRequest.data]);

    return {
        sourceOptions: availableSources,
        campaignOptions,
        attributeOptions,
        segmentEngagementOptions,
        campaignSmsStatusOptions,
        campaignEmailStatusOptions,
        leadStatusesOptions,
        leadTypeOptions,
        phoneStatusesOptions,
        phoneCarrierStatusesOptions,
        phoneCampaignStatusesOptions,
        emailStatusesOptions,
        engagementPeriodOptions,
        lastDayClickedOptions,
        weekOfYearOptions,
        smsStatusPeriodOptions,
        carrierTypesOptions,
        dateRangeOptions,
        statesOptions,
    };
}

export default useSegmentFilterAvailableOptions;
