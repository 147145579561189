export const systemSegmentFilterField = {
    SOURCE: "source",
    LIST: "list",
    DUPLICATES: "duplicates",
    LEAD_STATUS: "lead_status",
    PHONE_STATUS: "phone_status",
    LEAD_EMAIL_STATUS: "lead_email_status",
    LEAD_PHONE_STATUS: "lead_phone_status",
    LEAD_PHONE_CARRIER_STATUS: "lead_phone_carrier_status",
    LEAD_PHONE_CAMPAIGN_STATUS: "lead_phone_campaign_status",
    CARRIER: "carrier",
    CARRIER_NAME: "carrier_name",
    CARRIER_TYPE: "carrier_type",
    LAST_DAY_CLICKED: "last_day_clicked",
    LAST_DAY_EMAIL_CLICKED: "last_day_email_clicked",
    LAST_DAY_SMS_CLICKED: "last_day_sms_clicked",
    CREATED_AT: "created_at",
    DAY_IMPORT: "day_import",
    CAMPAIGNS: "campaign",
    CAMPAIGNS_EMAIL: "campaigns_email",
    CAMPAIGNS_SMS: "campaigns_sms",
    CAMPAIGN_SENT_EMAIL_STATUS: "campaign_sent_email_status",
    CAMPAIGN_SENT_SMS_STATUS: "campaign_sent_sms_status",
    SMS_CLICKED: "sms_clicked",
    EMAIL_CLICKED: "email_clicked",
    SMS_UNSUBSCRIBED: "sms_unsubscribed",
    EMAIL_UNSUBSCRIBED: "email_unsubscribed",
    EMAIL_OPENED: "email_opened",
    ENGAGEMENT_PERIOD: "engagement_period",
    STATE: "state",
    NON_DELIVERED_SMS_LEADS: "non_delivered_sms_leads",
    WEEK_OF_YEAR_SMS_CLICKED :"week_of_year_sms_clicked",
    WEEK_OF_YEAR_IMPORTED: "week_of_year_imported",
}
