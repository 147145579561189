import Api from './Api.service'
import {apiDataBaseUrl} from '../config'

class DataService {

    getUserInfo = async () => {
        try {
            return await Api.get(`${apiDataBaseUrl}/user`).then((response) => response.data);
        } catch (e) {
            console.log('error while fetching user info')
        }
    }

    createSource = async (name) => {
        try {
            return await Api.post(`${apiDataBaseUrl}/sources`, {name: name})
        } catch (e) {
            throw e
        }
    }

    getSource = async (sourceId) => {
        try {
            return await Api.get(`${apiDataBaseUrl}/sources/${sourceId}`)
        } catch (e) {
            console.log('error while fetching source')
        }
    }

    createList = async (sourceId, name, status) => {
        try {
            return await Api.post(`${apiDataBaseUrl}/sources/${sourceId}/lists`, {name: name, status: status})
        } catch (e) {
            throw e
        }
    }

    getSources = async () => {
        try {
            return await Api.get(`${apiDataBaseUrl}/sources?limit=100`)
        } catch (e) {
            console.log('error while fetching sources')
        }
    }

    getSourcesWithList = async () => {
        try {
            return await Api.get(`${apiDataBaseUrl}/all-sources-with-lists?limit=100`)
        } catch (e) {
            console.log('error while fetching sources')
        }
    }

    getList = async (listId) => {
        try {
            return await Api.get(`${apiDataBaseUrl}/sources/lists/${listId}`)
        } catch (e) {
            console.log('error while fetching list')
        }
    }

    uploadFile = async (listId, file) => {
        try {
            let fd = new FormData();
            fd.append('file', file)
            fd.append('list_id[0]', listId)
            return await Api.post(`${apiDataBaseUrl}/imports`, fd)
        } catch (e) {
            throw e
        }
    }

    getImportById = async (importId) => {
        try {
            return await Api.get(`${apiDataBaseUrl}/imports/${importId}`)
        } catch (e) {
            console.log('error while fetching import')
        }
    }

    createLeadsAttributes = async (attrList) => {
        try {
            return await Api.post(`${apiDataBaseUrl}/lead-attributes`, {list: attrList})
        } catch (e) {
            throw e
        }
    }

    getAllLeadsAttributes = async () => {
        try {
            return await Api.get(`${apiDataBaseUrl}/lead-attributes-all`)
        } catch (e) {
            console.log('error while fetching leads attributes')
        }
    }

    syncImportAttributes = async (
        syncImportId,
        attrList,
        checkMobileStatus = false,
        checkEmailStatus = false,
        emailLookupGatewayId = null,
        smsLookupGatewayId = null,
        checkSmsDNC = false,
        smsDNCGatewayId = null
    ) => {
        try {
            const data = {list: attrList}
            if (checkMobileStatus) {
                data['check_mobile_status'] = true
            }
            if (checkEmailStatus) {
                data['check_email_status'] = true;
            }
            if (checkSmsDNC) {
                data['check_sms_dnc'] = true;
            }
            if (emailLookupGatewayId) {
                data['email_lookup_gateway_id'] = emailLookupGatewayId;
            }
            if (smsLookupGatewayId) {
                data['sms_lookup_gateway_id'] = smsLookupGatewayId;
            }
            if (checkSmsDNC) {
                data['sms_dnc_gateway_id'] = smsDNCGatewayId;
            }

            return await Api.post(`${apiDataBaseUrl}/sync-import/${syncImportId}/import-attributes/multiple`, data)
        } catch (e) {
            throw e
        }
    }

    runImport = async (importId) => {
        try {
            return await Api.post(`${apiDataBaseUrl}/imports/${importId}/dispatch`)
        } catch (e) {
            throw e
        }
    }

    syncLeads = async (syncImportId) => {
        try {
            return await Api.put(`${apiDataBaseUrl}/sync-import/${syncImportId}`, {status: 'pending'})
        } catch (e) {
            throw e
        }
    }

    getSyncImportById = async (importId) => {
        try {
            return await Api.get(`${apiDataBaseUrl}/imports/${importId}/sync-import`)
        } catch (e) {
            console.log('error while fetching import')
        }
    }

    getActiveImports = async () => {
        try {
            return await Api.get(`${apiDataBaseUrl}/imports/active`)
        } catch (e) {
            console.log('error while fetching active imports')
        }
    }

    updateLeadValue = async (leadValueId, value) => {
        try {
            return await Api.put(`${apiDataBaseUrl}/lead-values/${leadValueId}`, {value: value})
        } catch (e) {
            throw e
        }
    }

    updateEmailLeadValue = async (leadValueId, value) => {
        try {
            return await Api.put(`${apiDataBaseUrl}/lead-emails/${leadValueId}`, {value: value})
        } catch (e) {
            throw e
        }
    }

    updateLeadsPhoneStatus = async (ids, status) => {
        try {
            return await Api.put(`${apiDataBaseUrl}/lead-phones/${ids}/status?status=${status}`)
        } catch (e) {
            throw e
        }

    }
    updateLeadsEmailStatus = async (ids, status) => {
        try {
            return await Api.put(`${apiDataBaseUrl}/lead-emails/${ids}/status?status=${status}`)
        } catch (e) {
            throw e
        }

    }

    updatePhoneLeadValue = async (leadValueId, value) => {
        try {
            return await Api.put(`${apiDataBaseUrl}/lead-phones/${leadValueId}`, {value: value})
        } catch (e) {
            throw e
        }
    }

    deleteLists = async (selectedListsIds) => {
        let idParams = selectedListsIds.map((l, index) => `ids[${index}]=${l}`).join('&')
        try {
            return await Api.delete(`${apiDataBaseUrl}/sources/lists/batch?${idParams}`)
        } catch (e) {
            throw Error('Removing lists error')
        }
    }

    deleteList = async (listId) => {
        try {
            return await Api.delete(`${apiDataBaseUrl}/sources/lists/${listId}`)
        } catch (e) {
            throw Error('Removing list error')
        }
    }

    deleteSources = async (selectedSourcesIds) => {
        let idParams = selectedSourcesIds.map((l, index) => `ids[${index}]=${l}`).join('&')
        try {
            return await Api.delete(`${apiDataBaseUrl}/sources/batch?${idParams}`)
        } catch (e) {
            throw Error('Removing sources error')
        }
    }

    deleteSource = async (sourceId) => {
        try {
            return await Api.delete(`${apiDataBaseUrl}/sources/${sourceId}`)
        } catch (e) {
            throw Error('Removing source error')
        }
    }

    updateSource = async (sourceId, sourceName, status) => {
        try {
            return await Api.put(`${apiDataBaseUrl}/sources/${sourceId}`, {name: sourceName, status: status})
        } catch (e) {
            throw e
        }
    }

    updateList = async (listId, listName, status) => {
        try {
            return await Api.put(`${apiDataBaseUrl}/sources/lists/${listId}`, {name: listName, status: status})
        } catch (e) {
            throw e
        }
    }

    createSegment = async (name, isUpdateDynamically) => {
        try {
            return await Api.post(`${apiDataBaseUrl}/segments`, {name: name, ignore_new_leads: !isUpdateDynamically})
        } catch (e) {
            throw e
        }
    }

    createChildSegment = async (parentSegmentId, name, isUpdateDynamically) => {
        try {
            return await Api.post(`${apiDataBaseUrl}/segments/${parentSegmentId}/segments`, {
                name: name,
                ignore_new_leads: !isUpdateDynamically
            })
        } catch (e) {
            throw e
        }
    }

    updateSegment = async (segmentId, segmentName, isUpdateDynamically) => {
        try {
            return await Api.put(`${apiDataBaseUrl}/segments/${segmentId}`, {
                name: segmentName,
                ignore_new_leads: !isUpdateDynamically
            })
        } catch (e) {
            throw e
        }
    }

    cloneSegment = async (segmentId, segmentName, isUpdateDynamically) => {
        try {
            return await Api.post(`${apiDataBaseUrl}/segments/${segmentId}/clone`, {
                name: segmentName,
                ignore_new_leads: !isUpdateDynamically
            })
        } catch (e) {
            throw e
        }
    }

    exportToCSV = async ({segmentId, attributes, name, search, order}) => {
        try {
            return await Api.post(`${apiDataBaseUrl}/segments/${segmentId}/export`, {
                attributes,
                name,
                search,
                order
            })
        } catch (e) {
            throw e
        }
    }

    deleteSegment = async (segmentId) => {
        try {
            return await Api.delete(`${apiDataBaseUrl}/segments/${segmentId}`)
        } catch (e) {
            throw Error('Removing segment error')
        }
    }

    deleteSegments = async (selectedSegmentsIds) => {
        let idParams = selectedSegmentsIds.map((l, index) => `ids[${index}]=${l}`).join('&')
        try {
            return await Api.delete(`${apiDataBaseUrl}/segments/batch?${idParams}`)
        } catch (e) {
            throw Error('Removing segments error')
        }
    }

    createCampaignRoute = async (route) => {
        return await Api.post(`${apiDataBaseUrl}/routes`, route);
    }


    createCampaignPool = async (pool) => {
        return await Api.post(`${apiDataBaseUrl}/pools`, pool);
    }

    updateCampaignRoute = async (ID, route) => {
        return await Api.put(`${apiDataBaseUrl}/routes/${ID}`, route);
    }

    updateCampaignPool = async (ID, pool) => {
        return await Api.put(`${apiDataBaseUrl}/pools/${ID}`, pool);
    }

    createSpamEmailGroup = async (name, emails) => {
        return await Api.post(`${apiDataBaseUrl}/spam-email-groups`, {name: name, emails: emails});
    }

    updateSpamEmailGroup = async (id, name, emails) => {
        return await Api.put(`${apiDataBaseUrl}/spam-email-groups/${id}`, {name: name, emails: emails});
    }

    createSenderEmailGroup = async (name, usernames) => {
        return await Api.post(`${apiDataBaseUrl}/sender-email-groups`, {name: name, usernames: usernames});
    }

    updateSenderEmailGroup = async (id, name, usernames) => {
        return await Api.put(`${apiDataBaseUrl}/sender-email-groups/${id}`, {name: name, usernames: usernames});
    }
}

export default new DataService()
