import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import InlineLoader from "./loaders/InlineLoader";
import {useSegmentState} from "../Contexts/SegmentContext";

const AudienceHeader = () => {
    const {
        segment,
        segmentRequest,
        leadsCount
    } = useSegmentState();

    const params = useParams();
    const history = useNavigate();


    const renderName = () => {
        return segmentRequest.isLoading ? <InlineLoader size={30} /> : segment?.name
    }

    const items = [
        {
            label: "Total Leads",
            count: leadsCount,
            action: () => {
                if (segment) history(`/u/${params.loggedUser}/audiences/${segment?.id}`)
            },
            loading: leadsCount === undefined
        },
        {
            label: "Segments",
            count: segment?.segments?.length,
            action: () => {
                if (segment) history(`/u/${params.loggedUser}/audiences/${segment?.id}/segments`)
            },
            loading: segmentRequest.isLoading
        }
    ]

    return (
        <div className="pageHeadingBlock mb15">
            <div className="pageHeadingBlockWrapper">
                <div className="part main">
                    <div className="partContent">
                        <div className="hasSwitch mb15">
                            <h2 className="textPrimary">{renderName()}</h2>
                        </div>
                    </div>
                </div>
                {
                    items.map(item => (
                        <div className="part" key={item.label}>
                            <div className="partContent cursor-pointer" onClick={item.action}>
                                <h2 className="mb10">{item.label}</h2>
                                <div>{item.loading ? <InlineLoader /> : item.count}</div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default AudienceHeader
