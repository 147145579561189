import React, {useEffect, useState} from 'react'
import InlineLoader from './loaders/InlineLoader'
import {useQuery} from 'react-query'
import {getSourcesTotal} from '../api'

const SourcesHeader = () => {

    const [aggregations, setAggregations] = useState({
        total_leads: '-',
        unique_today: '-',
        added_today: '-'
    })

    const {isLoading, data} = useQuery('sourcesTotal', getSourcesTotal, {keepPreviousData: true})

    useEffect(() => {
        if (!data) return
        setAggregations(data.data)
    }, [data])

    const items = [
        {
            label: 'Total Leads',
            count: aggregations['total_leads']
        },
        {
            label: 'Unique Today',
            count: aggregations['unique_today']
        },
        {
            label: 'Total today',
            count: aggregations['added_today']
        }
    ]

    return (
        <div className="pageHeadingBlockWrapper">
            {
                items.map(item => (
                    <div className="part" key={item.label}>
                        <div className="partContent">
                            <h2 className="mb10">{item.label}</h2>
                            <div>{isLoading ? <InlineLoader/> : item.count}</div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default SourcesHeader
