import React, {useEffect, useMemo, useState} from "react"
import {useAppDispatch} from "../Contexts/AppContext"
import PlusCircle from "../assets/img/plus_circle.svg";
import EventEmitter from "../Utils/EventEmitter";
import ShortUrlDomainItemContextMenu from "../components/ShortUrlDomainItemContextMenu";
import ListTable from "../components/ListTable/ListTable";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {deleteSpamEmailGroup, getSpamEmailGroups} from "../api";
import DeleteModal from "../components/modals/DeleteModal";
import AlertToastr from "../Utils/alert";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import IconButton from "@material-ui/core/IconButton";
import CreateSpamEmailGroupModal from "../components/modals/spam-email-groups/CreateSpamEmailGroupModal";

const SpamEmailGroups = () => {
    const queryClient = useQueryClient()
    const {setBreadcrumbs, setSection} = useAppDispatch();
    const {setContextMenu} = useAppDispatch();
    const [groups, setGroups] = useState([]);
    const [currentModel, setCurrentModel] = useState(null);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        setBreadcrumbs([
            {label: "Settings", link: "./settings"},
            {label: "Spam Email Groups"}
        ])
        setSection("settings")
    }, [setBreadcrumbs, setSection])

    useEffect(() => {
        setContextMenu([
            {
                label: "Add New Group",
                icon: PlusCircle,
                action: () => EventEmitter.emit("SpamEmailGroupCreate"),
            },
        ])
    }, [setContextMenu])

    useEffect(() => {
        const onEvent = () => setShowCreateModal(true)

        const listener = EventEmitter.addListener("SpamEmailGroupCreate", onEvent)
        return () => {
            listener.remove()
        }
    }, [])


    const showDeleteConfirmation = (group) => {
        setCurrentModel(group)
        setShowDeleteModal(true)
    }

    const handleEditGroup = (model) => {
        setCurrentModel(model)
        setShowUpdateModal(true)
    }

    const deleteSpamGroupRequest = useMutation(deleteSpamEmailGroup)
    const modelLabel = currentModel ? `"${currentModel.name}" spam email group` : "spam email group"
    const handleDeleteSpamEmailGroup = async () => {
        if (!currentModel) {
            return;
        }

        await deleteSpamGroupRequest.mutateAsync(currentModel.id).catch(error => {
            if (error && error.response && error.response.data && error.response.data.errors) {
                AlertToastr.showErrorAlert(error.response.data.errors)
            }
        })
        setShowDeleteModal(false)
        await queryClient.invalidateQueries("spamEmailGroupList")
        setCurrentModel(null)
    }

    const tableColumns = useMemo(() => {
        return [
            {
                Header: "Spam Email Group Name",
                accessor: "name"
            },
            {
                Header: "List Of Emails",
                accessor: "emails",
                Cell: ({value, row}) => <GroupsExpand group={row.original}/>
            },
            {
                Header: "",
                id: "actions",
                className: "text-right",
                Cell: ({value, row}) => {
                    return (
                        <ShortUrlDomainItemContextMenu
                            item={row.original}
                            onEdit={() => handleEditGroup(row.original)}
                            onDelete={() => showDeleteConfirmation(row.original)}
                        />
                    )
                }
            },
        ]
    }, [])

    const groupRequest = useQuery(
        ["spamEmailGroupList"],
        () => getSpamEmailGroups(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (groupRequest.isSuccess) {
            setGroups(groupRequest.data.data)
        }
    }, [groupRequest.isSuccess, groupRequest.data])

    return (
        <div className="pageHolder">
            <ListTable
                columns={tableColumns}
                data={groups}
                loading={groupRequest.isLoading}
                fetching={groupRequest.isFetching}
                onSort={(sort) => {
                }}
                emptyDataText="No Spam Email Groups Found"
                pagination={null}
                pageLimit={100}
                onPageChange={1}
                onLimitChange={(l) => {
                }}
                disableSearchBlock={true}
            />

            <CreateSpamEmailGroupModal
                open={showCreateModal}
                handleClose={() => {
                    setShowCreateModal(false)
                }}
            />

            <CreateSpamEmailGroupModal
                open={showUpdateModal}
                handleClose={() => {
                    setShowUpdateModal(false)
                }}
                spamEmailGroup={currentModel}
            />
            <DeleteModal
                loading={deleteSpamGroupRequest.isLoading}
                open={showDeleteModal}
                handleDelete={handleDeleteSpamEmailGroup}
                handleClose={() => setShowDeleteModal(false)}
                instance={modelLabel}
            />
        </div>
    )
}

const GroupsExpand = ({group}) => {
    const [open, setOpen] = useState(false)
    const styleSpan = {
        display: "inline-block",
        width: 250,
        textOverflow: "ellipsis",
        overflow: "hidden",
        verticalAlign: "middle"
    }

    if (!Array.isArray(group.emails) || group.emails.length === 0) {
        return null
    }

    return (
        <>
            <span style={styleSpan} key={group.emails[0].id} onClick={() => setOpen(!open)}>
                {group.emails[0].email}
            </span>

            {group.emails.length > 1 &&
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>}
                </IconButton>
            }

            {group.emails.length > 1 &&
                <Collapse in={open} timeout="auto" unmountOnExit>
                    {Array.isArray(group.emails) && group.emails.slice(1).map(email => {
                        return (<span key={email.id}>{email.email}<br/></span>)
                    })}
                </Collapse>
            }
        </>
    )
}

export default SpamEmailGroups
