import React, {useEffect, useState, useMemo, useCallback} from "react";
import {useQuery} from "react-query";
import {useParams} from "react-router-dom";
import {useQueryState} from "use-location-state";
import {getSegmentLeads} from "../api";
import ListTable from "./ListTable/ListTable";
import {segmentLeadLinkWrapper} from "../helpers";
import {toLocalFormat } from "../Utils/dates";
import LeadStatus from "./LeadStatus";
import {storageKeys} from "../config";

const SegmentLeads = ({segmentId, setSelectedRows, setVisibleColumns, onSearch, onSorting}) => {
    const params = useParams();
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState([]);
    const [page, setPage] = useQueryState("page", 1);;
    const [limit, setLimit] = useQueryState("limit", 50)
    const [leads, setLeads] = useState([]);
    const [leadsAttributes, setLeadsAttributes] = useState([]);
    const [pagination, setPagination] = useState(null);

    const segmentLeadLink = useCallback((value) => {
        return `/u/${params.loggedUser}/leads/${value}`;
    }, [params]);

    const tableColumns = useMemo(() => {
        const attributes = leadsAttributes.map(attr => {
            return {
                Header: attr.name,
                accessor: `${attr.id}`,
                Cell: ({ value, row }) => segmentLeadLinkWrapper(segmentLeadLink(row.original.id), value, row),
            }
        })
        return [
            {
                Header: "ID",
                accessor: "id",
                Cell: ({ value, row }) => segmentLeadLinkWrapper(segmentLeadLink(row.original.id), value, row),
            },
            {
                Header: "Updated Date",
                accessor: "updated_at",
                Cell: ({ value, row }) => segmentLeadLinkWrapper(segmentLeadLink(row.original.id), toLocalFormat(value), row),
            },
            ...attributes,
            {
                Header: "Revenue",
                accessor: "revenue",
            },
            {
                Header: "Duplicates",
                accessor: "duplicates_count",
                Cell: ({ value, row }) => segmentLeadLinkWrapper(segmentLeadLink(row.original.id), value, row),
            },
            {
                Header: "Status",
                accessor: "status",
                className: "col-sticky text-right",
                Cell: ({ value }) => <LeadStatus status={value} />
            }
        ]
    }, [leadsAttributes, segmentLeadLinkWrapper]);

    const tableValues = useMemo(() => {
        if (!leads) return []
        return leads.map(item => {
            const leadValues = item.lead_values
            const newItem = {...item}
            leadValues.forEach(value => {
                const attrId = value && value.attribute && value.attribute.id
                if (attrId)
                    newItem[attrId] = newItem[attrId]
                        ? `${newItem[attrId]}; ${value.value}`
                        : value.value
            })
            delete newItem["lead_values"]
            return newItem
        })
    }, [leads]);

    const tableRecordsInfo = useMemo(() => {
        const info = []
        if (pagination) {
            if (pagination.total)
                info.push({count: pagination.total, label: "lead"})
            if (pagination.total_blacklisted)
                info.push({count: pagination.total_blacklisted, label: "blacklisted lead"})
        }
        return info
    }, [pagination]);

    const {isSuccess, data, isLoading, isFetching} = useQuery(
        ["segmentLeads", segmentId, page, limit, search, sorting],
        () => getSegmentLeads(segmentId, page, limit, search, sorting),
        {keepPreviousData: true}
    );

    useEffect(() => {
        onSorting(sorting)
    }, [sorting, onSorting]);

    useEffect(() => {
        onSearch(search)
    }, [search, onSearch]);

    useEffect(() => {
        if (isSuccess) {
            setLeads(data.data)
            const paginationData = data.meta
            if (paginationData.last_page < page) {
                setPage(1)
            }
            setPagination(paginationData)
            if (data.attributes) {
                setLeadsAttributes(data.attributes)
            }
        }
    }, [isSuccess, data, page, setPage]);

    return (
        <ListTable
            columns={tableColumns}
            data={tableValues}
            loading={isLoading}
            fetching={isFetching}
            isSelectionColumn={false}
            onSelectedRows={setSelectedRows}
            onSort={setSorting}
            emptyDataText="No Leads Found"
            pagination={pagination}
            pageLimit={limit}
            onPageChange={setPage}
            onLimitChange={setLimit}
            onSearch={setSearch}
            onHideColumns={setVisibleColumns}
            recordsInfo={tableRecordsInfo}
            hiddenColumnsStorageKey={storageKeys.leadsHiddenColumnsInSegment}
        />
    )
}

export default SegmentLeads
