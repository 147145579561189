import React from 'react'
import SmsStep from "./Campaign/Step/SmsStep";
import EmailStep from "./Campaign/Step/EmailStep";
import SplitStep from "./Campaign/Step/SplitStep";

const CampaignStepItem = (
    {
        item,
        onEdit = () => {
        },
        onCopy = () => {
        },
        onDelete = () => {
        },
        disableEdit = false,
        disableDelete = false
    }) => {

    if (item.type === 'sms') {
        return <SmsStep {...{item, onEdit, onCopy, onDelete, disableEdit, disableDelete}}></SmsStep>
    }

    if (item.type === 'email') {
        return <EmailStep {...{item, onEdit, onCopy, onDelete, disableEdit, disableDelete}}></EmailStep>
    }

    if (item.type === 'split') {
        return <SplitStep {...{item, onEdit, onCopy, onDelete, disableEdit, disableDelete}} />
    }

    alert('Undefined step type. Please call to developers')

    return null;
}

export default CampaignStepItem
