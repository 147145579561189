import Api from "../Api.service";
import {apiDataBaseUrl} from "../../config";
import RuleType from "../../Enums/Rules/RuleType";

const API_BASE_PATH = `${apiDataBaseUrl}/rules/`;


class RuleService {

    list = async () => {
        return await Api.get(API_BASE_PATH)
    }

    activeSmsCoolOffList = async () => {
        return await Api.get(API_BASE_PATH, {type: RuleType.SMS_COOL_OFF, is_active: 1})
    }

    activeInactiveDeviceList = async () => {
        return await Api.get(API_BASE_PATH, {type: RuleType.SMS_INACTIVE_DEVICE, is_active: 1})
    }

    activeResetFrequencyList = async () => {
        return await Api.get(API_BASE_PATH, {type: RuleType.RESET_FREQUENCY, is_active: 1})
    }

    activeIncreaseFrequencyList = async () => {
        return await Api.get(API_BASE_PATH, {type: RuleType.INCREASE_FREQUENCY, is_active: 1})
    }

    activeEmailCoolOffList = async () => {
        return await Api.get(API_BASE_PATH, {type: RuleType.EMAIL_COOL_OFF, is_active: 1})
    }

    create = async (type, period, isActive, coverage, name) => {
        return await Api.post(API_BASE_PATH, {
            type: type,
            params: {
                period: parseInt(period),
            },
            is_active: isActive,
            name: name,
            coverage: coverage,
        });
    }

    createResetFrequencyRule = async (type, isActive, name, frequencyCount, resetTime) => {
        return await Api.post(API_BASE_PATH, {
            type: type,
            params: {
                frequency_period: 'min',
                reset_time: resetTime,
                frequency_count: frequencyCount
            },
            is_active: isActive,
            name: name,
            coverage: 1,
        });
    }

    createIncreaseFrequencyRule = async (type, isActive, name, profitPercent, increasePercent, maxFrequency) => {
        return await Api.post(API_BASE_PATH, {
            type: type,
            params: {
                max_frequency: maxFrequency,
                profit_percent: profitPercent,
                increase_percent: increasePercent
            },
            is_active: isActive,
            name: name,
            coverage: 1,
        });
    }

    updateResetFrequencyRule = async (id, type, isActive, name, frequencyCount, resetTime) => {
        return await Api.put(API_BASE_PATH + id, {
            type: type,
            params: {
                frequency_period: 'min',
                reset_time: resetTime,
                frequency_count: frequencyCount
            },
            is_active: isActive,
            name: name,
            coverage: 1,
        });
    }

    updateIncreaseFrequencyRule = async (id, type, isActive, name, profitPercent, increasePercent, maxFrequency) => {
        return await Api.put(API_BASE_PATH + id, {
            type: type,
            params: {
                max_frequency: maxFrequency,
                profit_percent: profitPercent,
                increase_percent: increasePercent
            },
            is_active: isActive,
            name: name,
            coverage: 1,
        });
    }

    update = async (id, type, period, isActive, coverage, name) => {
        return await Api.put(API_BASE_PATH + id, {
            type: type,
            params: {
                period: parseInt(period),
            },
            is_active: isActive,
            name: name,
            coverage: coverage,
        });
    }

    delete = async (id) => {
        await Api.delete(API_BASE_PATH + id)
    }

}

export default new RuleService();