import React, {useState, useEffect} from 'react'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import AlertToastr from '../../../Utils/alert'
import {useQueryClient} from "react-query";
import RuleType from "../../../Enums/Rules/RuleType";
import RuleService from "../../../Services/Rules/RuleService";
import MenuItem from "@material-ui/core/MenuItem";
import ReactHookFormSelect from "../../form/ReactHookFormSelect";
import {useForm} from "react-hook-form";
import IOSSwitch from "../../IOSSwitch";
import RuleCoverage from "../../../Enums/Rules/RuleCoverage";
import moment from "moment-timezone";
import {MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment'
import {hoursMinutes} from "../../../Utils/dates";


const CreateSpamEmailGroupModal = ({open, handleClose, rule}) => {

    const [disableButtons, setDisabledButtons] = useState(false);
    const queryClient = useQueryClient();
    const [name, setName] = useState('');
    const [coverage, setCoverage] = useState(RuleCoverage.ONLY_USER_ACCOUNT)
    const [coolOffPeriod, setCoolOffPeriod] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const [resetTime, setResetTime] = useState(moment().toDate());
    const [frequencyCount, setFrequencyCount] = useState(null)
    const [profitPercent, setProfitPercent] = useState(null)
    const [increasePercent, setIncreasePercent] = useState(null)
    const [maxFrequency, setMaxFrequency] = useState(null)

    const setDefaults = () => {
        setCoolOffPeriod(0);
    }

    useEffect(() => {
        setDefaults()
    }, []);

    useEffect(() => {
        if (rule) {
            setName(rule.name)
            setCoolOffPeriod(rule.params.period);
            setIsActive(rule.is_active)
            setCoverage(rule.coverage)
            setFrequencyCount(rule.params.frequency_count)
            setResetTime(moment(rule.params.reset_time, 'hh:mm'))
            setProfitPercent(rule.params.profit_percent)
            setIncreasePercent(rule.params.increase_percent)
            setMaxFrequency(rule.params.max_frequency)
        } else {
            setDefaults()
        }
    }, [rule])


    const handleCreate = () => {
        setDisabledButtons(true)
        if (type === RuleType.RESET_FREQUENCY) {
            RuleService.createResetFrequencyRule(type, isActive, name, parseInt(frequencyCount), hoursMinutes(resetTime))
                .then(response => {
                    setDefaults();
                    setDisabledButtons(false)
                    handleClose(response.data.data)
                    queryClient.invalidateQueries('rulesList').then()
                })
                .catch((error) => {
                    setDisabledButtons(false)
                    if (error && error.response && error.response.data && error.response.data.errors) {
                        AlertToastr.showErrorAlert(error.response.data.errors)
                    }
                })

        } else if (type === RuleType.INCREASE_FREQUENCY) {
            RuleService.createIncreaseFrequencyRule(type, isActive, name, parseInt(profitPercent), parseInt(increasePercent), parseInt(maxFrequency))
                .then(response => {
                    setDefaults();
                    setDisabledButtons(false)
                    handleClose(response.data.data)
                    queryClient.invalidateQueries('rulesList').then()
                })
                .catch((error) => {
                    setDisabledButtons(false)
                    if (error && error.response && error.response.data && error.response.data.errors) {
                        AlertToastr.showErrorAlert(error.response.data.errors)
                    }
                })
        } else {
            RuleService.create(type, coolOffPeriod, isActive, formCoverage, name)
                .then(response => {
                    setDefaults();
                    setDisabledButtons(false)
                    handleClose(response.data.data)
                    queryClient.invalidateQueries('rulesList').then()
                })
                .catch((error) => {
                    setDisabledButtons(false)
                    if (error && error.response && error.response.data && error.response.data.errors) {
                        AlertToastr.showErrorAlert(error.response.data.errors)
                    }
                })
        }
    }

    const handleSave = () => {
        setDisabledButtons(true)
        if (type === RuleType.RESET_FREQUENCY) {
            RuleService.updateResetFrequencyRule(rule.id, type, isActive, name, parseInt(frequencyCount), hoursMinutes(resetTime))
                .then(response => {
                    setDisabledButtons(false)
                    setDefaults()
                    handleClose()
                    queryClient.invalidateQueries('rulesList').then()
                })
                .catch((error) => {
                    setDisabledButtons(false)
                    if (error && error.response && error.response.data && error.response.data.errors) AlertToastr.showErrorAlert(error.response.data.errors)
                })
        } else if (type === RuleType.INCREASE_FREQUENCY) {
            RuleService.updateIncreaseFrequencyRule(rule.id, type, isActive, name, parseInt(profitPercent), parseInt(increasePercent), parseInt(maxFrequency))
                .then(response => {
                    setDisabledButtons(false)
                    setDefaults()
                    handleClose()
                    queryClient.invalidateQueries('rulesList').then()
                })
                .catch((error) => {
                    setDisabledButtons(false)
                    if (error && error.response && error.response.data && error.response.data.errors) AlertToastr.showErrorAlert(error.response.data.errors)
                })
        } else {
            RuleService.update(rule.id, type, coolOffPeriod, isActive, formCoverage, name)
                .then(response => {
                    setDisabledButtons(false)
                    setDefaults()
                    handleClose()
                    queryClient.invalidateQueries('rulesList').then()
                })
                .catch((error) => {
                    setDisabledButtons(false)
                    if (error && error.response && error.response.data && error.response.data.errors) AlertToastr.showErrorAlert(error.response.data.errors)
                })
        }
    }

    const {
        control,
        watch,
        register,
    } = useForm({
        mode: 'onTouched'
    })

    const formCoverage = watch('coverage', rule?.coverage);
    const type = watch('type', rule?.type);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className='modal'
        >
            <div className="modalBody">
                <div className="modalTitleHolder mb30">
                    <h2 className='mb15'>{rule ? 'Edit' : 'Create New'} Rule</h2>
                </div>
                <TextField
                    id="name"
                    label="Name"
                    variant="outlined"
                    type="text"
                    margin="normal"
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
                <ReactHookFormSelect
                    disabled={!!(rule)}
                    variant="outlined"
                    margin="normal"
                    id="type-select"
                    name="type"
                    label="Type"
                    defaultValue={type}
                    control={control}
                    multiple={false}
                >
                    {RuleType.ALL.map((label, key) => (
                        <MenuItem key={key} value={key}>
                            {label}
                        </MenuItem>
                    ))}
                </ReactHookFormSelect>

                {(type === RuleType.SMS_COOL_OFF) && <TextField
                    value={coolOffPeriod}
                    onChange={e => {
                        setCoolOffPeriod(e.target.value)
                    }}
                    id="coolOffPeriod"
                    label="Cool Off Period(minutes)"
                    variant="outlined"
                    margin='normal'
                    type='number'
                />}

                {(type === RuleType.EMAIL_COOL_OFF) && <TextField
                    value={coolOffPeriod}
                    onChange={e => {
                        setCoolOffPeriod(e.target.value)
                    }}
                    id="coolOffPeriod"
                    label="Cool Off Period(hours)"
                    variant="outlined"
                    margin='normal'
                    type='number'
                />}

                {type === RuleType.SMS_INACTIVE_DEVICE && <TextField
                    value={coolOffPeriod}
                    onChange={e => {
                        setCoolOffPeriod(e.target.value)
                    }}
                    id="inactiveCoolOffPeriod"
                    label="Inactive Cool Off Period(days)"
                    variant="outlined"
                    margin='normal'
                    type='number'
                />}

                {type === RuleType.RESET_FREQUENCY && <>
                    <TextField
                        inputRef={register}
                        name="frequency_count"
                        label="Frequency per MIN"
                        value={frequencyCount}
                        onChange={(event) => {
                            setFrequencyCount(event.target.value)
                        }}
                        variant="outlined"
                        margin="normal"
                        className="freqAdornment"
                    />
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <TimePicker
                            format="hh:mm A"
                            margin="normal"
                            onChange={(newValue) => setResetTime(newValue)}
                            value={resetTime}
                            label="Reset Time"
                            inputVariant="outlined"
                            variant="inline"
                        />
                    </MuiPickersUtilsProvider>
                </>}

                {type === RuleType.INCREASE_FREQUENCY && <>
                    <TextField
                        value={profitPercent}
                        onChange={e => {
                            setProfitPercent(e.target.value)
                        }}
                        id="profit_percent"
                        label="Profit Percent, %"
                        variant="outlined"
                        margin='normal'
                        type='number'
                    />
                    <TextField
                        value={increasePercent}
                        onChange={e => {
                            setIncreasePercent(e.target.value)
                        }}
                        id="increase_percent"
                        label="Increase Percent, %"
                        variant="outlined"
                        margin='normal'
                        type='number'
                    />
                    <TextField
                        value={maxFrequency}
                        onChange={e => {
                            setMaxFrequency(e.target.value)
                        }}
                        id="max_frequency"
                        label="Max Frequency Per Min"
                        variant="outlined"
                        margin='normal'
                        type='number'
                    />
                </>}

                {(type !== RuleType.RESET_FREQUENCY && type !== RuleType.INCREASE_FREQUENCY) &&
                    <ReactHookFormSelect
                        disabled={false}
                        variant="outlined"
                        margin="normal"
                        id="type-select"
                        name="coverage"
                        label="Coverage"
                        defaultValue={coverage}
                        control={control}
                        multiple={false}
                    >
                        {RuleCoverage.ALL.map((label, key) => (
                            <MenuItem key={key} value={key}>
                                {label}
                            </MenuItem>
                        ))}
                    </ReactHookFormSelect>
                }

                <div className="align-center wrapper content-justified">
                    Active
                    <IOSSwitch
                        color="primary"
                        checked={isActive}
                        onChange={() => {
                            setIsActive(!isActive)
                        }}
                    />
                </div>

                <div className="btnHolder hasPadTop twoBtn">
                    <div className="part">
                        <Button
                            disabled={!!disableButtons}
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={(e) => {
                                rule ? handleSave() : handleCreate()
                            }}
                        >
                            {rule ? 'Save' : 'Create'}
                        </Button>
                    </div>
                    <div className="part">
                        <Button
                            disabled={disableButtons}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default CreateSpamEmailGroupModal
