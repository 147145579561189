import React, {useState} from 'react';
import {useCampaignState} from "../../../Contexts/CampaignContext";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {toServerFormat} from "../../../Utils/dates";
import SmsCounter from "../../modals/SmsCounter";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Row from "./Row";
import TestSmsModal from "../../modals/TestSmsModal";

const SmsStep = (
    {
        item,
        onEdit = () => {
        },
        onDelete = () => {
        },
        onCopy = () => {
        },
        disableEdit = false,
        disableDelete = false,
        parentItem = null
    }) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [showDetails, setShowDetails] = useState(false)
    const [showTestSmsModal, setShowTestSmsModal] = useState(false)
    const {campaign} = useCampaignState()
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleEdit = () => {
        setAnchorEl(null)
        onEdit({...item, parentItem})
    }

    const handleCopy = () => {
        setAnchorEl(null);
        onCopy({...item, parentItem})
    }

    const handleTestSms = () => {
        setAnchorEl(null)
        setShowTestSmsModal(true);
    }

    const handleDelete = () => {
        setAnchorEl(null)
        onDelete({...item, parentItem})
    }

    return (
        <>
            <li className="stepsListItem">
                <div className="sliContent">
                    <div className="sliTitleHolder">
                        <div className="sliTitle">
                            {item?.name}
                            <span className={`status ${item?.status}`} style={{paddingLeft: '5px'}}>
                            ({item?.status_label})
                        </span>
                        </div>
                        <div className="sliSubMenu">
                            <IconButton aria-label="expand row" size="small" sx={{height: "5px"}}
                                        onClick={() => setShowDetails(s => !s)}>
                                {showDetails ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>}
                            </IconButton>
                            <IconButton onClick={handleClick}>
                                <MoreVertIcon/>
                            </IconButton>
                        </div>
                        {
                            item && (
                                <Menu
                                    id="fade-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={open}
                                    onClose={handleClose}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <MenuItem
                                        disabled={disableEdit}
                                        onClick={handleEdit}>
                                        Edit
                                    </MenuItem>
                                    <MenuItem
                                        disabled={!campaign ||
                                            !item || !item.hasOwnProperty("message") || !item.message}
                                        onClick={handleTestSms}>
                                        Test Sms
                                    </MenuItem>
                                    <MenuItem
                                        disabled={disableEdit}
                                        onClick={handleCopy}
                                    >
                                        Copy
                                    </MenuItem>
                                    <MenuItem
                                        disabled={disableDelete}
                                        onClick={handleDelete}
                                    >
                                        Delete
                                    </MenuItem>

                                </Menu>
                            )
                        }
                    </div>
                    {!Boolean(parentItem) && <Row label="Start Time:" value={toServerFormat(item.started_at)}></Row>}
                    {!Boolean(parentItem) && <Row label="Delay:" value={item.delay_label}></Row>}
                    <Row
                        label={item?.message}
                        value=" "
                        valueIsGray={true}
                    ></Row>

                    <div style={{position: "relative"}}>
                        <SmsCounter
                            text={item?.message}
                            styleParam={{position: "absolute", top: -15, right: 15, left: "initial"}}
                        />
                    </div>

                    <Collapse in={showDetails} timeout="auto" unmountOnExit>
                        <Box>
                            <Row label="Cost:" value={item.statistics?.cost_formatted}></Row>
                            <Row label="Revenue:" value={item.statistics?.revenue_formatted}></Row>
                            <Row label="Revenue By Bot:" value={item.statistics?.revenue_by_bot_formatted}></Row>
                            <Row label="Revenue By Expired:" value={item.statistics?.revenue_by_expired_formatted}></Row>
                            <Row label="Revenue By State:" value={item.statistics?.revenue_by_state_filter_formatted}></Row>
                            <Row label="Total Conversations:" value={item.statistics?.total_conversations}></Row>
                            <Row label="Profit:" value={item.statistics?.profit_formatted}></Row>
                            <Row label="Sent:" value={item.statistics?.sent}></Row>
                            <Row label="Delivered:" value={item.statistics?.delivered}></Row>
                            <Row label="Delivered, %:" value={item.statistics?.delivered_percent + ' %'}></Row>
                            <Row label="Clicked:" value={item.statistics?.clicked}></Row>
                            <Row label="Clicked, %:" value={item.statistics?.clicked_percent + ' %'}></Row>
                            <Row label="Errors:" value={item.statistics?.total_errors}></Row>
                            <Row label="Spam:" value={item.statistics?.spam_count}></Row>
                            <Row label="Unsubscribe:" value={item.statistics?.unsubscribed}></Row>
                            <Row label="Unsubscribe, %:" value={item.statistics?.unsubscribed_percent + ' %'}></Row>
                            <Row label="Cool offs:" value={item.statistics?.cool_offs}></Row>
                            <Row label="Cool offs inactive device:"
                                 value={item.statistics?.cool_offs_inactive_device}></Row>
                            <Row label="Limit Cap:" value={item.limit_cap}></Row>
                            <Row label="Redirect URL:" value={item.redirect_to}></Row>
                            <Row label="Domain:" value={item.short_url_domain_label}></Row>
                        </Box>
                    </Collapse>

                </div>
            </li>
            {showTestSmsModal && <TestSmsModal
                open={showTestSmsModal}
                step_id={item.id}
                text={item.message}
                handleClose={() => {
                    setShowTestSmsModal(false)
                }}
            />}
        </>
    )
}

export default SmsStep
