import React, {useEffect} from 'react'
import SelectRow from './filters/SelectRow'
import {campaignSmsStatusOptions, campaignEngagementOptions, campaignEmailStatusOptions} from '../config'
import {useCampaignDispatch, useCampaignState} from '../Contexts/CampaignContext'

const CampaignStatisticFilters = ({onChange}) => {

    const {smsStatuses, engagements, campaign, emailStatuses} = useCampaignState()
    const {setSmsStatuses, setEngagements, setEmailStatuses} = useCampaignDispatch()

    useEffect(() => {
        if (onChange) {
            const filters = {}
            if (smsStatuses.length) {
                filters['sms_status'] = smsStatuses
            }
            if (emailStatuses.length) {
                filters['email_status'] = emailStatuses;
            }
            if (engagements.length) {
                filters['engagement'] = engagements
            }
            onChange(filters)
        }
    }, [smsStatuses, engagements, emailStatuses, onChange])

    return (
        <div className="segmentsFilterHolder">
            <div className="segmentsFilter">
                {campaign && campaign.type === 'email' ?
                    <SelectRow
                        items={campaignEmailStatusOptions}
                        selectedItems={emailStatuses}
                        onChange={setEmailStatuses}
                        entityLabel={'Email Status'}
                    /> :
                    <SelectRow
                        items={campaignSmsStatusOptions}
                        selectedItems={smsStatuses}
                        onChange={setSmsStatuses}
                        entityLabel={'Sms Status'}
                    />
                }
                <SelectRow
                    items={campaignEngagementOptions}
                    selectedItems={engagements}
                    onChange={setEngagements}
                    entityLabel={'Engagement'}
                />
            </div>
        </div>
    )
}

export default CampaignStatisticFilters
