const ONLY_USER_ACCOUNT = 1;
const ALL_SERVICE_ACCOUNTS = 2;
const ONLY_CAMPAIGN = 3;

let ALL = [];
ALL[ONLY_USER_ACCOUNT] = 'Only this account'
ALL[ONLY_CAMPAIGN] = 'Only campaign'
ALL[ALL_SERVICE_ACCOUNTS] = 'Any account (System wide)'

export default {
    ONLY_USER_ACCOUNT,
    ALL_SERVICE_ACCOUNTS,
    ONLY_CAMPAIGN,
    ALL
}