import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useAppDispatch } from '../Contexts/AppContext'
import EventEmitter from '../Utils/EventEmitter'
import Delete from '../assets/img/delete.svg'
import Settings from '../assets/img/settings.svg'
import DeleteModal from '../components/modals/DeleteModal'
import { useMutation, useQueryClient } from 'react-query'
import { removeLeads, updateLeadsStatus } from '../api'
import AlertToastr from '../Utils/alert'
import { statusContextMenu } from '../helpers'

const LeadsListActions = ({selectedRows, loading}) => {

    const {setContextMenu} = useAppDispatch()
    const [showDeleteLeadsModal, setShowDeleteLeadsModal] = useState(false)
    const queryClient = useQueryClient()

    const updateLeadsStatusMutation = useMutation(updateLeadsStatus)
    const deleteLeadsMutation = useMutation(removeLeads)

    useEffect(() => {
        if (updateLeadsStatusMutation.error) {
            AlertToastr.showErrorResponseAlert(updateLeadsStatusMutation.error)
        }
    }, [updateLeadsStatusMutation.error])

    useEffect(() => {
        if (deleteLeadsMutation.error) {
            AlertToastr.showErrorResponseAlert(deleteLeadsMutation.error)
        }
    }, [deleteLeadsMutation.error])

    useEffect(() => {
        const items = [
            {
                label: 'Delete Selection',
                icon: Delete,
                disabled: !selectedRows.length,
                action: () => EventEmitter.emit('LeadsBulkDeletion'),
                iconStyle: {transform: 'rotate(45deg)'}
            },
            {
                label: updateLeadsStatusMutation.isLoading ? 'Updating...' : 'Set status',
                icon: Settings,
                show: false,
                disabled: !selectedRows.length || updateLeadsStatusMutation.isLoading,
                iconStyle: {transform: 'rotate(45deg)'},
                items: statusContextMenu()
            }
        ]
        setContextMenu(items)
    }, [setContextMenu, loading, selectedRows, updateLeadsStatusMutation.isLoading])

    const updateItemsStatus = useCallback(async status => {
        const response = await updateLeadsStatusMutation.mutateAsync({ids: selectedRows, status})
        const {info, errors} = response
        if (info && info.length) {
            info.forEach(message => AlertToastr.showAlert(message))
        }
        if (errors && errors.length) {
            errors.forEach(message => AlertToastr.showErrorAlert(message))
        }

        queryClient.invalidateQueries('leadsList').then()
    }, [queryClient, selectedRows,updateLeadsStatusMutation])

    //listener: leads bulk delete
    useEffect(() => {
        const onEvent = () => setShowDeleteLeadsModal(true)

        const listener = EventEmitter.addListener('LeadsBulkDeletion', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = (status) => updateItemsStatus(status)

        const listener = EventEmitter.addListener('LeadsBulkChangeStatus', onEvent)
        return () => { listener.remove() }
    }, [updateItemsStatus])

    const deleteItems = async () => {
        setShowDeleteLeadsModal(false)
        await deleteLeadsMutation.mutateAsync(selectedRows)
        queryClient.invalidateQueries('leadsList').then()
    }

    return (
        <Fragment>
            <DeleteModal
                loading={deleteLeadsMutation.isLoading}
                open={showDeleteLeadsModal}
                handleDelete={deleteItems}
                handleClose={() => setShowDeleteLeadsModal(false)}
                instance={'selected leads'}
            />
        </Fragment>
    )
}

export default LeadsListActions
