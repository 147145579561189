import React, {useEffect} from "react"
import {useAppDispatch} from "../Contexts/AppContext"
import LookupList from "./lookup/LookupList";

const LookupManagement = () => {
    const {setBreadcrumbs, setSection} = useAppDispatch()

    useEffect(() => {
        setBreadcrumbs([
            {label: "Settings", link: "./settings"},
            {label: "Lookup Management"}
        ])
        setSection("settings")
    }, [setBreadcrumbs, setSection])

    return (
        <div className="pageHolder">
            <LookupList/>
        </div>
    )
}

export default LookupManagement
