import React, {useEffect, useState} from 'react'
import Dialog from '@material-ui/core/Dialog'
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useMutation, useQuery} from "react-query";
import {Controller, useForm} from "react-hook-form";
import {TextField} from "@material-ui/core";
import {useCampaignState} from "../../../../Contexts/CampaignContext";
import AlertToastr from "../../../../Utils/alert";
import {getSenderEmailGroup, testEmailCampaign} from "../../../../api";

const TestEmailModal = (
    {
        open,
        step,
        handleClose = () => {
        }
    }) => {
    const {campaign} = useCampaignState()
    const [toEmail, setToEmail] = useState("")
    const [senderGroup, setSenderGroup] = useState();
    const {control, watch} = useForm({
        mode: 'onTouched',
        defaultValues: step
    })


    const senderGroupRequest = useQuery(
        ['senderEmailGroup', step.sender_email_group_id],
        () => {
            if (step.sender_type !== 'group') {
                return null;
            }

            return getSenderEmailGroup(step.sender_email_group_id)
        },
        {keepPreviousData: true}
    )
    useEffect(() => {
            if (step.sender_type === 'group' && senderGroupRequest.isSuccess) {
                if (senderGroupRequest.data) {
                    setSenderGroup(senderGroupRequest.data.data)
                } else {
                    console.log(senderGroupRequest);
                }
            }
        }, [senderGroupRequest.isSuccess, senderGroupRequest.data]
    )

    useEffect(() => {
        if (!senderGroup) {
            return;
        }

        const username = senderGroup.usernames[Math.floor(Math.random() * senderGroup.usernames.length)];
        step.from_name = username.from_name;
        step.sender = username.user_name + '@' + step.sender_domain;
    }, [senderGroup])

    const message = watch('message')
    const fromName = watch('from_name')
    const sender = watch('sender')
    const subject = watch('subject')

    const testEmailRequest = useMutation(testEmailCampaign)

    const handleSentEmail = async () => {
        try {
            let params = {
                id: campaign.id,
                step_id: step.id,
                to_email: toEmail,
                from_name: fromName,
                sender: sender,
                subject: subject,
                message: message
            }

            const data = await testEmailRequest.mutateAsync(params)
            AlertToastr.showAlert(data.data)
            handleClose()
        } catch (error) {
            AlertToastr.showErrorResponseAlert(error)
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} className='modal'>
            <div className="modalBody">
                <div className="modalTitleHolder mb30">
                    <h2 className='mb15'>Test Email</h2>
                </div>

                <TextField
                    value={toEmail}
                    onChange={e => setToEmail(e.target.value)}
                    id="email_address"
                    label="To Email"
                    variant="outlined"
                    margin='normal'
                    placeholder="email@domain.com"
                />

                <Controller
                    name="from_name"
                    control={control}
                    defaultValue=''
                    render={({onChange, onBlur, value}) => (
                        <>
                            <TextField
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                label="From Name"
                                variant="outlined"
                                margin='normal'
                            />
                        </>
                    )}
                />

                <Controller
                    name="sender"
                    control={control}
                    defaultValue=''
                    render={({onChange, onBlur, value}) => (
                        <>
                            <TextField
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                label="Sender"
                                variant="outlined"
                                margin='normal'
                            />
                        </>
                    )}
                />
                <Controller
                    name="subject"
                    control={control}
                    defaultValue=''
                    render={({onChange, onBlur, value}) => (
                        <>
                            <TextField
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                label="Subject"
                                variant="outlined"
                                margin='normal'
                            />
                        </>
                    )}
                />


                <Controller
                    name="message"
                    control={control}
                    defaultValue=''
                    render={({onChange, onBlur, value}) => (
                        <>
                            <TextField
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                label="Message"
                                multiline
                                rows={4}
                                variant="outlined"
                                margin='normal'
                            />
                        </>
                    )}
                />

                <div className="btnHolder hasPadTop twoBtn">
                    <div className="part">
                        <Button
                            disabled={testEmailRequest.isLoading}
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleSentEmail}
                        >
                            {testEmailRequest.isLoading ? <CircularProgress size={24} color="inherit"/> : 'Send'}
                        </Button>
                    </div>
                    <div className="part">
                        <Button disabled={testEmailRequest.isLoading} variant="outlined" color="primary" fullWidth
                                onClick={handleClose}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default TestEmailModal
