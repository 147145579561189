import React  from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import SegmentLeadsPage from "./SegmentLeadsPage";
import SubSegmentsPage from "./SubSegmentsPage";
import SubsSegmentViewPage from "./SubsSegmentViewPage";

const SegmentPage = () => {
    return (
        <div className="pageHolder">
            <Routes>
                <Route path="leads" element={<SegmentLeadsPage />} />
                <Route path="segments" element={<SubSegmentsPage />} />
                <Route path="segments/:subsegmentId/leads" element={<SubsSegmentViewPage />} />
                <Route path="segments/:subsegmentId" element={<Navigate to="./leads" replace />} />
                <Route path="" element={<Navigate to="./leads" replace />} />
            </Routes>
        </div >
    )
}

export default SegmentPage
