import React, {useEffect, useMemo, useState} from "react"
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getEmailGateways, removeEmailGateway} from "../../../api";
import DisabledValue from "../../../components/DisabledValue";
import ShortUrlDomainItemContextMenu from "../../../components/ShortUrlDomainItemContextMenu";
import ListTable from "../../../components/ListTable/ListTable";
import DeleteModal from "../../../components/modals/DeleteModal";
import AddIntegrationModal from "./AddIntegrationModal";


const EmailList = () => {

    const [showUpdateEmailModal, setShowUpdateEmailModal] = useState(false)
    const [showDeleteModalEmail, setShowDeleteModalEmail] = useState(false)
    const [currentModel, setCurrentModel] = useState(false)
    const [listEmail, setlistEmail] = useState([])
    const [sorting, setSorting] = useState([])
    const queryClient = useQueryClient()


    const listEmailRequest = useQuery(
        ["emailGateways"],
        () => getEmailGateways(),
        {keepPreviousData: true}
    )

    const deleteRequestEmail = useMutation(removeEmailGateway)

    useEffect(() => {
        if (listEmailRequest.isSuccess) {
            setlistEmail(listEmailRequest.data.data)
        }
    }, [listEmailRequest.isSuccess, listEmailRequest.data])


    const handleEditEmailItem = item => {
        if (item.default) return;
        setCurrentModel({
            connection_type: "email",
            ...item,
            ...item.params
        })
        setShowUpdateEmailModal(true)
    }

    const showDeleteConfirmationEmail = (item) => {
        setCurrentModel(item)
        setShowDeleteModalEmail(true)
    }

    const deleteItemEmail = async () => {
        if (!currentModel) return;

        await deleteRequestEmail.mutateAsync(currentModel.id)
        setShowDeleteModalEmail(false)
        await queryClient.invalidateQueries("emailGateways")
        setCurrentModel(null)
    }


    const tableEmailColumns = useMemo(() => {
        return [
            {
                Header: "Email Gateway Name",
                accessor: "name",
                Cell: ({value, row}) => row.original.default ? <DisabledValue value={value}/> : value,
                className: "integrationTableColumn"
            },
            {
                Header: "Integration",
                accessor: "type",
                Cell: ({value, row}) => row.original.default ? <DisabledValue value={value}/> : value,
                className: "integrationTableColumn",
            },
            {
                Header: "Status",
                accessor: "active",
                Cell: ({value, row}) => {
                    const label = (
                        <div className={value ? "chipLike success" : "chipLike"}>
                            <span className="chipLikeLabel">{value ? "Active" : "Inactive"}</span>
                        </div>
                    )
                    return row.original.default ? <DisabledValue value={label}/> : label
                },
                className: "integrationTableColumn",
            },
            {
                Header: "",
                id: "actions",
                className: "text-right",
                Cell: ({value, row}) => {
                    if (row.original.default) return null;
                    return (
                        <ShortUrlDomainItemContextMenu
                            item={row.original}
                            onEdit={() => handleEditEmailItem(row.original)}
                            onDelete={() => showDeleteConfirmationEmail(row.original)}
                        />
                    )
                },
            }
        ]
    }, [])

    return (
        <div className="pageHolder">
            <ListTable
                columns={tableEmailColumns}
                data={listEmail}
                loading={listEmailRequest.isLoading}
                fetching={listEmailRequest.isFetching}
                onSort={setSorting}
                emptyDataText={"No Email Gateway"}
                disablePagination
                disableSearchBlock={true}
                onRowClick={handleEditEmailItem}
            />

            <AddIntegrationModal
                showModal={showUpdateEmailModal}
                handleClose={() => {
                    setShowUpdateEmailModal(false)
                }}
                integrationModel={currentModel}
            >
            </AddIntegrationModal>
            <DeleteModal
                open={showDeleteModalEmail}
                handleDelete={deleteItemEmail}
                handleClose={() => setShowDeleteModalEmail(false)}
                instance={currentModel ? `"${currentModel.name}" Email gateway` : "Email gateway"}
            />
        </div>
    )
}

export default EmailList
