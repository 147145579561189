import React, {useState, useEffect} from 'react';
import {Navigate} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import useApiCall from '../../Hooks/useApiCall';
import authEndpoints from '../../Endpoints/auth.endpoints';
import {useAuth} from '../../Contexts/AuthProvider';
import AlertToastr from '../../Utils/alert';
import {Alert} from '@material-ui/lab';
import MenuItem from "@material-ui/core/MenuItem";
import ReactHookFormSelect from "../../components/form/ReactHookFormSelect";
import {useForm} from "react-hook-form";
import UserIndustry from "../../Enums/Users/UserIndustry";

const RegisterPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [request, register] = useApiCall();
    const {login, isLoggedIn} = useAuth();
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const onRegisterClicked = (e) => {
        e.preventDefault();
        register({
            ...authEndpoints.register(),
            data: {
                name: name,
                email: email,
                password: password,
                password_confirmation: confirmPassword,
                phone_number: phone,
                industry: industry,
            },
        });
    };

    useEffect(() => {
        setPhone('+1');
    }, [])

    useEffect(() => {
        if (request.error) {
            AlertToastr.showErrorAlert(request.error.message);
        }
    }, [request.error]);

    useEffect(() => {
        if (!request.response)
            return;

        setShowSuccessMessage(true);
        // login(request.response.data.token, request.response.data.user, true)

    }, [request.response, login]);

    const {control, watch} = useForm({mode: 'onTouched'})
    const industry = watch('industry');

    const CheckboxLabel = () => {
        return (
            <span>I agree to the <a href="/" className="linkPrimary">Terms of Service</a> and <a href="/"
                                                                                                 className="linkPrimary">Privacy Policy</a></span>
        );
    };

    if (isLoggedIn) {
        return <Navigate to="/"/>;
    }

    if (showSuccessMessage) {
        return (
            <div className="formHolder">
                <Alert>
                    Your account is pending verification. We will let you know when it’s active.
                </Alert>
            </div>
        );
    }

    return (
        <div className="formHolder">
            <p className="mb20">For registration please enter your name, email and password.</p>
            <form noValidate autoComplete="off">
                <TextField
                    id="name"
                    label="Full name"
                    variant="outlined"
                    type="text"
                    margin="normal"
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
                <TextField
                    id="phone_number"
                    label="Phone number"
                    variant="outlined"
                    type="text"
                    margin="normal"
                    value={phone}
                    onChange={e => setPhone(e.target.value)
                    }
                />
                <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    type="email"
                    margin="normal"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    autoComplete="username"
                />
                <ReactHookFormSelect
                    variant="outlined"
                    margin="normal"
                    id="type-select"
                    name="industry"
                    label="Industry"
                    control={control}
                    defaultValue=''
                >
                    {UserIndustry.ALL.map((label, key) => {
                        return <MenuItem key={key} value={key}>
                            {label}
                        </MenuItem>
                    })}
                </ReactHookFormSelect>
                <TextField
                    // error
                    id="password"
                    label="Password"
                    variant="outlined"
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    margin="normal"
                    autoComplete="new-password"
                />
                <TextField
                    // error
                    id="confirmPassword"
                    label="Confirm password"
                    variant="outlined"
                    type="password"
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                    margin="normal"
                    autoComplete="new-password"
                />
                <div className="mtm4">
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label={<CheckboxLabel/>}
                    />
                </div>
                <div className="btnHolder mt20 mb15">
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth
                        onClick={onRegisterClicked}
                    >
                        Register
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default RegisterPage;
