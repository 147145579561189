import React, { useEffect, useMemo, useState } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import ListTable from "../../components/ListTable/ListTable";
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {getCampaignStatisticsByStep} from "../../api";
import {useCampaignState} from "../../Contexts/CampaignContext";

const STAT_TAB_GENERAL = 'general'
const STAT_TAB_BY_STEP = 'by_step'

const CampaignTabsStatistics = ({children}) => {
    const [statTab, setStatTab] = useState(STAT_TAB_GENERAL)
    const {steps} = useCampaignState()

    const isMultiSteps = useMemo(() => {
        if(Array.isArray(steps) && steps.reduce((p, c) => { return p + (c.type === 'split' ? 2 : 1) }, 0) > 1) {
            return true
        }

        return false
    }, [steps])

    const handleSetTab = (event, tab) => {
        setStatTab(tab)
    }

    if(!isMultiSteps) {
        return children
    }

    return (
        <>
            <Tabs value={statTab}
                  onChange={handleSetTab}
                  indicatorColor='primary'
                  className='pageTabs biggerPad'>

                <Tab label="General" value={STAT_TAB_GENERAL} />
                <Tab label="By Step" value={STAT_TAB_BY_STEP} />
            </Tabs>

            {statTab === STAT_TAB_GENERAL && children}
            {statTab === STAT_TAB_BY_STEP && <StatTabByStep />}
        </>
    )
}

const StatTabByStep = () => {
    const {campaignId} = useParams()

    const [campaignStatByStep, setCampaignStatByStep] = useState([])

    const campaignStatByStepRequest = useQuery(
        ['campaignStatisticsByStep', campaignId],
        () => getCampaignStatisticsByStep(campaignId),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (campaignStatByStepRequest.isSuccess) {
            setCampaignStatByStep(campaignStatByStepRequest.data.data)
        }
    }, [campaignStatByStepRequest.isSuccess, campaignStatByStepRequest.data])

    const tableColumns = [
        {Header: 'ID', accessor: 'id',},
        {Header: 'Name', accessor: 'name',},
        {Header: 'Clicked', accessor: 'clicked'},
        {Header: 'Records', accessor: 'records'},
        {Header: 'Sent', accessor: 'sent'},
        {Header: 'Delivered', accessor: 'delivered'},
        {Header: 'Errors', accessor: 'errors'},
    ]

    const tableValues = useMemo(() => {
        return campaignStatByStep
    }, [campaignStatByStep])

    return (
        <div className="pageHeadingBlock mt10" style={{paddingTop: '10px', minHeight: 362, maxHeight: 362, overflowY: "scroll"}}>
            <ListTable
                columns={tableColumns}
                data={tableValues}
                loading={campaignStatByStepRequest.isLoading}
                fetching={campaignStatByStepRequest.isFetching}
                emptyDataText='No Stats'
                onSort={() => {}}
                disableSearchBlock={true}
            />
        </div>
    )
}

export default CampaignTabsStatistics
