import React, { useState, useEffect, useMemo } from "react";
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import {useParams} from "react-router-dom";
import {useQuery, useQueryClient} from "react-query";
import {useQueryState} from "use-location-state";
import { getCampaignOpenAt } from "../../api";
import {toLocalFormat} from "../../Utils/dates";
import CampaignClickSearch from "./clicks/CampaignClickSearch";
import ListTable from "../../components/ListTable/ListTable";
import AlertToastr from "../../Utils/alert";

const CampaignOpenAt = () => {
    const [clicks, setClicks] = useState([]);
    const [page, setPage] = useQueryState("page", 1);
    const [limit, setLimit] = useQueryState("limit", 20);
    const [pagination, setPagination] = useState(null);
    const [sorting, setSorting] = useState([{key: "id", direction: "desc"}]);
    const [search, setSearch] = useState({column: null, "value": null})
    const {campaignId, loggedUser} = useParams();
    const queryClient = useQueryClient();
    const tableColumns = useMemo(() => {
        return [
            {
                Header: "ID",
                accessor: "id",
            },
            {
                Header: "Lead ID",
                accessor: "lead_id",
                Cell: ({value, row}) => {
                    return <a target="_blank" href={`/u/${loggedUser}/leads/${value}`}>{value}</a>
                }
            },
            {
                Header: "Open date",
                accessor: "opened_at",
                Cell: ({value, row}) => {
                    return toLocalFormat(value)
                }
            },
            {
                Header: "IP",
                accessor: "ip",
                Cell: ({value, row}) => {
                    return row.original.ip_address.ip
                }
            },
            {
                Header: "Country",
                accessor: "country",
                Cell: ({value, row}) => {
                    return row.original.ip_address.country
                }
            },
            {
                Header: "Region",
                accessor: "region_name",
                Cell: ({value, row}) => {
                    return row.original.ip_address.region_name
                }
            },
            {
                Header: "City",
                accessor: "city",
                Cell: ({value, row}) => {
                    return row.original.ip_address.city
                }
            },
            {
                Header: "ISP",
                accessor: "internet_provider",
                Cell: ({value, row}) => {
                    return row.original.ip_address.internet_provider
                }
            },
            {
                Header: "ASNAME",
                accessor: "asname",
                Cell: ({value, row}) => {
                    return row.original.ip_address.asname
                }
            },
            {
                Header: "Mobile",
                accessor: "mobile",
                Cell: ({value, row}) => {
                    return row.original.ip_address.mobile ? "Yes" : "No"
                }
            },
            {
                Header: "Proxy",
                accessor: "proxy",
                Cell: ({value, row}) => {
                    return row.original.ip_address.proxy ? "Yes" : "No"
                }
            },
            {
                Header: "User agent",
                accessor: "user_agent",
            },
            {
                Header: "Referer",
                accessor: "referer",
            }
        ];

    }, []);

    const tableValues = useMemo(() => {
        return clicks;
    }, [clicks]);

    const tableSorting = useMemo(() => {
        if (!sorting) return null;

        return sorting.map(item => ({
            id: item.key, desc: item.direction === "desc"
        }))
    }, [sorting])

    const campaignOpenAtRequest = useQuery(
        ["campaignOpenAtView", campaignId, page, limit, sorting, search],
        () => {
            return getCampaignOpenAt(campaignId, page, limit, sorting, search)
                .catch(error => {
                        if (error && error.response && error.response.data && error.response.data.errors) {
                            AlertToastr.showErrorAlert(error.response.data.errors)
                        }

                        return null;
                    }
                )
        },
        {keepPreviousData: true},
    );
    useEffect(() => {
        if (campaignOpenAtRequest.isSuccess) {
            if (campaignOpenAtRequest.data === null) {
                return;
            }

            setClicks(campaignOpenAtRequest.data.data);
            const paginationData = campaignOpenAtRequest.data.meta;
            if (paginationData.last_page < page) {
                setPage(1);
            }
            setPagination(paginationData);
        }
    }, [campaignOpenAtRequest.isSuccess, campaignOpenAtRequest.data, page, setPage]);

    const handleItemClick = item => {
        window.open(`/u/${loggedUser}/leads/${item?.lead_id}`)
    }
    return (
        <div className="campaignsPageHolder">
            <div className="timeLine">
                <div className="timeLineHeading mb15">
                    <h2>Open at</h2>
                    <Button
                        disabled={campaignOpenAtRequest.isFetching}
                        variant="contained"
                        color="primary"
                        startIcon={<RefreshIcon/>}
                        size="small"
                        onClick={() => queryClient.invalidateQueries("campaignOpenAtView")}
                    >
                        Refresh
                    </Button>
                </div>
                <CampaignClickSearch setSearch={setSearch}></CampaignClickSearch>
                <ListTable
                    columns={tableColumns}
                    data={tableValues}
                    loading={campaignOpenAtRequest.isLoading}
                    fetching={campaignOpenAtRequest.isFetching}
                    isSelectionColumn={false}
                    onRowClick={handleItemClick}
                    onSort={setSorting}
                    emptyDataText="Open At Not Found"
                    pagination={pagination}
                    pageLimit={limit}
                    onPageChange={setPage}
                    onLimitChange={setLimit}
                    initSort={tableSorting}
                    disableSearchBlock
                />
            </div>
        </div>
    )
}
export default CampaignOpenAt;
