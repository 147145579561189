import React, {Fragment, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {useAppDispatch, useAppState} from "../../Contexts/AppContext"
import EventEmitter from "../../Utils/EventEmitter"
import ExitIcon from "../../assets/img/exit_to_app.svg"
import EditIcon from "../../assets/img/settings.svg"
import CopyIcon from "../../assets/img/copy.svg"
import LeadsExportModal from "../../components/modals/LeadsExportModal"
import SaveSegmentModal from "../../components/modals/SaveSegmentModal"
import DeleteModal from "../../components/modals/DeleteModal"
import DataService from "../../Services/DataService"
import AlertToastr from "../../Utils/alert"
import CreateSegmentIco from "../../assets/img/layers_alt.svg"
import Delete from "../../assets/img/delete.svg"
import {useMutation, useQueryClient} from "react-query"
import DoughnutChart from "../../assets/img/doughnut_chart.svg";
import ConfirmModal from "../../components/modals/ConfirmModal";
import {refreshSegments, segmentsBatchLookup} from "../../api";
import Chevron from "../../assets/img/chevron_big_right.svg";
import SmsLookupConfirmModal from "../../components/modals/lookups/SmsLookupConfirmModal";
import EmailLookupConfirmModal from "../../components/modals/lookups/EmailLookupConfirmModal";
import SmsDNCConfirmModal from "../../components/modals/lookups/SmsDNCConfirmModal";

const SubSegmentsPageActions = ({segment, loading, selectedRows, visibleColumns, search, sorting}) => {
    const {exportFiles} = useAppState()
    const {setContextMenu, setContextLinks} = useAppDispatch()
    const history = useNavigate()
    const params = useParams()
    const [showExportModal, setShowExportModal] = useState(false)
    const [showSaveSegmentModal, setShowSaveSegmentModal] = useState(false)
    const [showUpdateSegmentModal, setShowUpdateSegmentModal] = useState(false)
    const [showDuplicateModal, setShowDuplicateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showDeleteSegmentsModal, setShowDeleteSegmentsModal] = useState(false)
    const [showRefreshConfirmModal, setShowRefreshConfirmModal] = useState(false);
    const queryClient = useQueryClient()
    const [showRunLookupSmsModal, setShowRunLookupSmsModal] = useState(false);
    const [showRunLookupSmsDNCModal, setShowRunLookupSmsDNCModal] = useState(false);
    const [showRunLookupEmailModal, setShowRunLookupEmailModal] = useState(false);
    const lookupMutation = useMutation(segmentsBatchLookup)

    useEffect(() => {
        if (!segment) return

        if (exportFiles && exportFiles[segment.id]) {
            const fileData = exportFiles[segment.id]
            const enabled = fileData.type === "export-completed"
            setContextLinks([
                {
                    label: enabled ? "Download" : "Generating file...",
                    url: fileData.url,
                    disabled: !enabled
                }
            ])
        }
    }, [segment, exportFiles, setContextLinks])

    useEffect(() => {

        const items = [
            {
                label: "Edit Audience",
                icon: EditIcon,
                action: () => EventEmitter.emit("SegmentEdit"),
                disabled: loading
            },
            {
                label: "Duplicate Audience",
                icon: CopyIcon,
                action: () => EventEmitter.emit("SegmentDuplicate"),
                disabled: loading
            },
            {
                label: "Delete Audience",
                icon: ExitIcon,
                action: () => EventEmitter.emit("SegmentDelete"),
                disabled: loading
            },
            {
                label: "Create Segment",
                icon: CreateSegmentIco,
                action: () => EventEmitter.emit("SegmentCreate"),
                disabled: loading
            }
        ]
        if (selectedRows.length) {
            items.push({
                label: "Refresh Selection",
                icon: DoughnutChart,
                action: () => EventEmitter.emit("SegmentBulkRefresh"),
                iconStyle: {transform: "rotate(45deg)"}
            })
        }
        if (selectedRows.length) {
            items.push({
                label: "Delete Selection",
                icon: Delete,
                action: () => EventEmitter.emit("SegmentBulkDeletion"),
                iconStyle: {transform: "rotate(45deg)"}
            })

            items.push({
                label: 'Run Lookup',
                icon: Chevron,
                actionSMS: () => EventEmitter.emit("SegmentRunLookupSMS"),
                actionSmsDNC: () => EventEmitter.emit("SegmentRunSmsDNC"),
                actionEmail: () => EventEmitter.emit("SegmentRunLookupEmail"),
            })
        }
        setContextMenu(items)
    }, [setContextMenu, loading, selectedRows])


    useEffect(() => {
        const onEvent = () => setShowExportModal(true)

        const listener = EventEmitter.addListener("SegmentExport", onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowUpdateSegmentModal(true)

        const listener = EventEmitter.addListener("SegmentEdit", onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowDuplicateModal(true)

        const listener = EventEmitter.addListener("SegmentDuplicate", onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowDeleteModal(true)

        const listener = EventEmitter.addListener("SegmentDelete", onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowSaveSegmentModal(true)

        const listener = EventEmitter.addListener("SegmentCreate", onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowDeleteSegmentsModal(true)

        const listener = EventEmitter.addListener("SegmentBulkDeletion", onEvent)
        return () => {
            listener.remove()
        }
    }, [])


    useEffect(() => {
        const onEvent = () => setShowRefreshConfirmModal(true)

        const listener = EventEmitter.addListener("SegmentBulkRefresh", onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    const deleteSegment = () => {
        setShowDeleteModal(false)
        DataService.deleteSegment(segment.id)
            .then(response => {
                history(`/u/${params.loggedUser}/`)
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors)
            })
    }

    const deleteItems = () => {
        setShowDeleteSegmentsModal(false)
        DataService.deleteSegments(selectedRows)
            .then(() => {
                queryClient.invalidateQueries("subsegmentsList").then()
                queryClient.invalidateQueries("segmentView").then()
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors)
            })
    }
    useEffect(() => {
        const onEvent = () => setShowRunLookupSmsModal(true);

        const listener = EventEmitter.addListener('SegmentRunLookupSMS', onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowRunLookupSmsDNCModal(true);

        const listener = EventEmitter.addListener('SegmentRunSmsDNC', onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowRunLookupEmailModal(true);

        const listener = EventEmitter.addListener('SegmentRunLookupEmail', onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    const lookupItemsSMS = async (gatewayId) => {
        const data = {
            ids: [...selectedRows],
            type: 1,
            setting_gateway_id: gatewayId
        }
        try {
            await lookupMutation.mutateAsync(data)
            setShowRunLookupSmsModal(false)
            AlertToastr.showAlert("Lookup has been started")
        } catch (error) {
            AlertToastr.showErrorResponseAlert(error)
        }
    }

    const lookupItemsSmsDNC = async (gatewayId) => {
        const data = {
            ids: [...selectedRows],
            type: 3,
            setting_gateway_id: gatewayId
        }
        try {
            await lookupMutation.mutateAsync(data)
            setShowRunLookupSmsDNCModal(false)
            AlertToastr.showAlert("Lookup has been started")
        } catch (error) {
            AlertToastr.showErrorResponseAlert(error)
        }
    }
    const lookupItemsEmail = async (gatewayId) => {
        const data = {
            ids: [...selectedRows],
            type: 2,
            setting_gateway_id: gatewayId,
        }
        try {
            await lookupMutation.mutateAsync(data);
            setShowRunLookupEmailModal(false)
            AlertToastr.showAlert("Lookup has been started")
        } catch (error) {
            AlertToastr.showErrorResponseAlert(error)
        }
    }

    return (
        <Fragment>
            <ConfirmModal
                open={showRefreshConfirmModal}
                handleClose={() => setShowRefreshConfirmModal(false)}
                message="Do you want to refresh selected segments?"
                handleConfirm={() => {
                    refreshSegments(selectedRows)
                        .then(() => {
                            setShowRefreshConfirmModal(false);
                            AlertToastr.showAlert("Segments added to refresh queue");
                        })
                        .catch((error) => {
                            if (error && error.response && error.response.data && error.response.data.errors)
                                AlertToastr.showErrorAlert(error.response.data.errors)
                        })
                }}
            >
            </ConfirmModal>
            <LeadsExportModal
                search={search}
                sorting={sorting}
                visibleColumns={visibleColumns}
                open={showExportModal}
                handleClose={() => setShowExportModal(false)}
            />
            <SaveSegmentModal
                open={showSaveSegmentModal}
                handleClose={() => setShowSaveSegmentModal(false)}
                parentSegment={segment}
                isDuplicateSegmentMode={false}
                entityTitle="Segment"
            />
            <SaveSegmentModal
                open={showUpdateSegmentModal}
                handleClose={() => setShowUpdateSegmentModal(false)}
                segment={segment}
                isDuplicateSegmentMode={false}
            />
            <SaveSegmentModal
                open={showDuplicateModal}
                handleClose={() => setShowDuplicateModal(false)}
                segment={segment}
                isDuplicateSegmentMode={true}
                onDuplicated={newSegment => {
                    console.log(newSegment);
                    setShowDuplicateModal(false);
                    history(`/u/${params.loggedUser}/audiences/${newSegment.id}`)
                }}
            />
            <DeleteModal
                open={showDeleteModal}
                handleDelete={deleteSegment}
                handleClose={(e) => setShowDeleteModal(false)}
                instance="audience"/>

            <DeleteModal
                open={showDeleteSegmentsModal}
                handleDelete={deleteItems}
                handleClose={(e) => setShowDeleteModal(false)}
                instance="selected segments"/>
            <SmsLookupConfirmModal
                loading={lookupMutation.isLoading}
                message='Do you want to lookup sms leads in selected segments?'
                open={showRunLookupSmsModal}
                handleClose={(e) => setShowRunLookupSmsModal(false)}
                handleConfirm={(gatewayId) => {
                    lookupItemsSMS(gatewayId)
                }}
            />
            <SmsDNCConfirmModal
                loading={lookupMutation.isLoading}
                message='Do you want to lookup sms DNC in selected segments?'
                open={showRunLookupSmsDNCModal}
                handleClose={(e) => setShowRunLookupSmsDNCModal(false)}
                handleConfirm={(gatewayId) => {
                    lookupItemsSmsDNC(gatewayId)
                }}
            />
            <EmailLookupConfirmModal
                open={showRunLookupEmailModal}
                handleClose={(e) => setShowRunLookupEmailModal(false)}
                handleConfirm={(gatewayId) => {
                    lookupItemsEmail(gatewayId)
                }}
                loading={lookupMutation.isLoading}
                message='Do you want to lookup email leads in selected segments?'
            />
        </Fragment>
    )
}

export default SubSegmentsPageActions
