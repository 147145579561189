import React, {Fragment, useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {useQuery, useQueryClient} from "react-query";
import {getSMSGateways} from "../../../api";
import DataService from "../../../Services/DataService";
import AlertToastr from "../../../Utils/alert";
import PoolSenders from "./PoolSenders";

const CreatePoolModal = ({open, close, pool, setPool}) => {

    const queryClient = useQueryClient();
    const [disableButtons, setDisabledButtons] = useState(false)
    const [gateways, setGateways] = useState([])

    const gatewaysRequest = useQuery(['gateways'], () => getSMSGateways(), {keepPreviousData: true})

    useEffect(() => {
        if (gatewaysRequest.isSuccess) {
            setGateways(gatewaysRequest.data.data)
        }
    }, [gatewaysRequest.isSuccess, gatewaysRequest.data, setGateways])

    const handleSave = () => {
        setDisabledButtons(true);
        DataService.updateCampaignPool(pool.id, pool)
            .then(response => {
                setDisabledButtons(false)
                queryClient.invalidateQueries('campaignPoolList')
                close()
            })
            .catch((error) => {
                setDisabledButtons(false)
                if (error && error.response && error.response.data && error.response.data.errors) {
                    AlertToastr.showErrorAlert(error.response.data.errors)
                }
            })
    }

    const setPoolSenders = (senders) => {
        setPool({...pool, senders: [...senders]})
    }


    const handleCreate = () => {
        setDisabledButtons(true);
        DataService.createCampaignPool(pool)
            .then(response => {
                setDisabledButtons(false)
                queryClient.invalidateQueries('campaignPoolList')
                close()
            })
            .catch((error) => {
                setDisabledButtons(false)
                if (error && error.response && error.response.data && error.response.data.errors) {
                    AlertToastr.showErrorAlert(error.response.data.errors)
                }
            })
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={close}
                className='modal'
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>{pool.id ? 'Edit' : 'Create'} Pool</h2>
                    </div>
                    <TextField
                        value={pool.name}
                        onChange={e => {
                            setPool({...pool, name: e.target.value})
                        }}
                        id="name"
                        label="Name"
                        variant="outlined"
                        margin='normal'
                    />

                    <h3 style={{marginBottom: '10px'}}>Senders: </h3>

                    <PoolSenders
                        poolSenders={pool.senders}
                        gateways={gateways}
                        setPoolSenders={setPoolSenders}
                    >
                    </PoolSenders>

                    <div className="btnHolder hasPadTop twoBtn">
                        <div className="part">
                            <Button
                                disabled={disableButtons}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={(e) => {
                                    pool.id ? handleSave() : handleCreate()
                                }}
                            >
                                {pool.id ? 'Save' : 'Create'}
                            </Button>
                        </div>
                        <div className="part">
                            <Button
                                disabled={disableButtons}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={close}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>

            </Dialog>
        </Fragment>
    );
};

export default CreatePoolModal;