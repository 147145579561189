import React, {useCallback, useEffect, useMemo, useState} from "react"
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppState} from "../../Contexts/AppContext"
import SaveCampaignModal from "../../components/modals/SaveCampaignModal"
import Copy from "../../assets/img/copy.svg"
import EditIcon from "../../assets/img/settings.svg"
import EventEmitter from "../../Utils/EventEmitter"
import ExitIcon from "../../assets/img/exit_to_app.svg"
import CampIcon from "../../assets/img/camp.svg"
import DeleteModal from "../../components/modals/DeleteModal"
import {useMutation, useQueryClient} from "react-query"
import {copyCampaigns, expireLinksByCampaign, removeCampaign, updateCampaign} from "../../api"
import {MuiPickersUtilsProvider} from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"
import Settings from "../../assets/img/settings.svg"
import AlertToastr from "../../Utils/alert"
import CreateSegmentIco from "../../assets/img/layers_alt.svg"
import SaveCampaignSegmentModal from "../../components/modals/SaveCampaignSegmentModal"
import {useCampaignState} from "../../Contexts/CampaignContext"
import ConfirmModal from "../../components/modals/ConfirmModal";

const CampaignActions = ({campaign, loading}) => {
    const params = useParams();
    const history = useNavigate();
    const queryClient = useQueryClient();
    const {setContextMenu} = useAppDispatch();
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showCopyModal, setShowCopyModal] = useState(false);
    const [showExpireLinksModal, setShowExpireLinksModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showCampaignCreateSegmentModal, setShowCampaignCreateSegmentModal] = useState(false);
    const campaignRemoveRequest = useMutation(removeCampaign);
    const campaignCopyRequest = useMutation(copyCampaigns);
    const expireLinksRequest = useMutation(expireLinksByCampaign);
    const {smsStatuses, engagements} = useCampaignState();
    const {config} = useAppState();

    const campaignStatuses = useMemo(() => {
        return config["campaign_manual_status_list"] || [];
    }, [config]);

    const campaignVisibleStatuses = useMemo(() => {
        if (!campaign) {
            return []
        }

        const list = campaignStatuses.map((item) => ({
            ...item,
            status: item.key,
            action: () => EventEmitter.emit("CampaignChangeStatus", item.key),
        }));

        return campaign.status ? list.filter(item => item.key !== campaign.status) : list
    }, [campaignStatuses, campaign])
    const updateCampaignMutation = useMutation(updateCampaign);

    const updateItemStatus = useCallback(async (status) => {
        if (campaign) {
            try {
                await updateCampaignMutation.mutateAsync({
                    id: campaign.id,
                    status
                })
                queryClient.invalidateQueries("campaignView").then()
                queryClient.invalidateQueries("campaignsList").then()
            } catch (error) {
                AlertToastr.showErrorResponseAlert(error)
            }
        }
    }, [queryClient, updateCampaignMutation, campaign]);

    useEffect(() => {
        const items = [
            {
                label: "Edit Campaign",
                icon: EditIcon,
                action: () => EventEmitter.emit("CampaignEdit"),
                disabled: loading || ["active", "completed", "failed"].includes(campaign?.status)
            },
            {
                label: "Copy Campaign",
                icon: Copy,
                action: () => EventEmitter.emit("CampaignCopy"),
                disabled: loading
            },
            {
                label: "Remove Campaign",
                icon: ExitIcon,
                action: () => EventEmitter.emit("CampaignDelete"),
                disabled: loading
            },
            {
                label: "Expire All Links",
                icon: CampIcon,
                action: () => EventEmitter.emit("CampaignExpireAllLinks"),
                disabled: loading
            },
            {
                label: updateCampaignMutation.isLoading ? "Updating..." : "Set status",
                icon: Settings,
                disabled: !campaign || updateCampaignMutation.isLoading,
                iconStyle: {transform: "rotate(45deg)"},
                items: campaignVisibleStatuses
            }
        ]
        if (campaign && (["completed", "failed", "paused"].includes(campaign.status))) {
            const filtersDisabled = !smsStatuses.length && !engagements.length
            items.push({
                label: "Create Segment",
                icon: CreateSegmentIco,
                disabled: !campaign || updateCampaignMutation.isLoading || filtersDisabled,
                action: () => EventEmitter.emit("CampaignSegmentCreate")
            })
        }

        setContextMenu(items)
    }, [
        setContextMenu,
        loading,
        campaign,
        updateCampaignMutation.isLoading,
        campaignVisibleStatuses,
        smsStatuses,
        engagements
    ]);

    //listener: campaign edit
    useEffect(() => {
        const onEvent = () => setShowSaveModal(true)

        const listener = EventEmitter.addListener("CampaignEdit", onEvent)
        return () => {
            listener.remove()
        }
    }, []);

    useEffect(() => {
        const onEvent = () => setShowCopyModal(true)

        const listener = EventEmitter.addListener("CampaignCopy", onEvent)
        return () => {
            listener.remove()
        }
    }, []);

    useEffect(() => {
        const onEvent = () => setShowExpireLinksModal(true)

        const listener = EventEmitter.addListener("CampaignExpireAllLinks", onEvent)
        return () => {
            listener.remove()
        }
    }, []);

    useEffect(() => {
        const onEvent = () => setShowCampaignCreateSegmentModal(true)

        const listener = EventEmitter.addListener("CampaignSegmentCreate", onEvent)
        return () => {
            listener.remove()
        }
    }, []);

    useEffect(() => {
        const onEvent = (status) => updateItemStatus(status)

        const listener = EventEmitter.addListener("CampaignChangeStatus", onEvent)
        return () => {
            listener.remove()
        }
    }, [updateItemStatus]);


    const deleteCampaign = async () => {
        await campaignRemoveRequest.mutateAsync(campaign.id)
        await queryClient.invalidateQueries("campaignsList")
        history(`/u/${params.loggedUser}/campaigns`);
    };

    const copyCampaign = async () => {
        await campaignCopyRequest.mutateAsync([campaign.id])
        await queryClient.invalidateQueries("campaignsList")
        history(`/u/${params.loggedUser}/campaigns`);
    };

    const expireLinks = async () => {
        await expireLinksRequest.mutateAsync(campaign.id)
        setShowExpireLinksModal(false);
    };

    return (
        <>
            {
                campaign && (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <SaveCampaignModal
                            defaultValues={campaign}
                            open={showSaveModal}
                            handleClose={(event) => {
                                setShowSaveModal(false)
                            }}
                        />
                        <SaveCampaignSegmentModal
                            campaign={campaign}
                            open={showCampaignCreateSegmentModal}
                            handleClose={(event) => {
                                setShowCampaignCreateSegmentModal(false)
                            }}
                        />

                    </MuiPickersUtilsProvider>
                )
            }

            <DeleteModal
                loading={campaignRemoveRequest.isLoading}
                open={showDeleteModal}
                handleDelete={deleteCampaign}
                handleClose={() => setShowDeleteModal(false)}
                instance={"the campaign"}
            />

            <ConfirmModal
                open={showCopyModal}
                handleClose={() => setShowCopyModal(false)}
                message="Do you want to copy campaign?"
                handleConfirm={copyCampaign}
            >
            </ConfirmModal>
            <ConfirmModal
                open={showExpireLinksModal}
                handleClose={() => setShowExpireLinksModal(false)}
                message="Do you want to expire links by campaign?"
                handleConfirm={expireLinks}
            >
            </ConfirmModal>
        </>
    )
}

export default CampaignActions
