const FINTECH = 1;
const INSURANCE = 2;
const HOME_IMPROVEMENT = 3;
const SAAS = 4;
const LEAD_GENERATION = 5;
const TRAVEL = 6;
const E_COMMERCE = 7;
const OTHER = 8;

let ALL = [];
ALL[FINTECH] = 'Fintech'
ALL[INSURANCE] = 'Insurance'
ALL[HOME_IMPROVEMENT] = 'Home Improvement'
ALL[SAAS] = 'SAAS'
ALL[LEAD_GENERATION] = 'Lead Generation'
ALL[TRAVEL] = 'Travel'
ALL[E_COMMERCE] = 'E-commerce'
ALL[OTHER] = 'Other'

export default {
    FINTECH,
    INSURANCE,
    HOME_IMPROVEMENT,
    SAAS,
    LEAD_GENERATION,
    TRAVEL,
    E_COMMERCE,
    OTHER,
    ALL
}