import React, {useState, useEffect, Fragment} from 'react'
import {ClickAwayListener, Menu, MenuItem, TextField} from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import Button from '@material-ui/core/Button'
import TimeLineComponent from './TimeLineComponent'
import AlertToastr from '../../Utils/alert'
import DataService from '../../Services/DataService'
import Chip from '@material-ui/core/Chip'
import {useNavigate, useParams} from 'react-router-dom'
import Loader from 'react-loader-spinner'
import {useAppDispatch} from '../../Contexts/AppContext'
import {useQuery, useQueryClient} from 'react-query'
import {
    getLead,
    getLeadLogs,
    getLeadSegments,
    getLeadSources,
    getLeadCampaigns,
    getList,
    getSegment,
    getCampaign,
    getSource
} from '../../api'
import {formatLeadAttributes, formatLeadLog, replaceStatus, replaceStatusPhone} from '../../helpers'
import PageLoader from '../../components/loaders/PageLoader'
import LeadPageActions from './LeadPageActions'
import {useQueryState} from 'use-location-state';
import LeadLogFilters from '../../components/LeadLogFilters';
import DetailsAttrsTable from '../../components/DetailsAttrsTable';
import {toLocalFormat2} from '../../Utils/dates';

const LeadPage = () => {
    const {leadId, segmentId, sourceId, campaignId, listId, loggedUser} = useParams()
    const [lead, setLead] = useState(null)
    const [list, setList] = useState(null)
    const [source, setSource] = useState(null)
    const [segment, setSegment] = useState(null)
    const [campaign, setCampaign] = useState(null)
    const [leadAttributes, setLeadAttributes] = useState([])
    const [logInfo, setLogInfo] = useState([])
    const [segments, setSegments] = useState([])
    const [sources, setSources] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const {setBreadcrumbs, setSection} = useAppDispatch()
    const navigate = useNavigate();
    const queryClient = useQueryClient()
    const [logFilters, setLogFilters] = useState({
        unsubscribed: false,
        clicked: false,
    })

    //logs pagination
    const [page, setPage] = useQueryState('page', 1)
    const [limit, setLimit] = useQueryState('limit', 20)
    const [pagination, setPagination] = useState(null);

    //drop down
    const [anchorElPhone, setAnchorElPhone] = React.useState(null);
    const [anchorElEmail, setAnchorElEmail] = React.useState(null);
    const [currentIdEmail, setCurrentIdEmail] = useState(0);
    const [currentIdPhone, setCurrentIdPhone] = useState(0);

    const openPhone = Boolean(anchorElPhone);
    const openEmail = Boolean(anchorElEmail);
    const openPhoneMenu = (id, status, event) => {
        setAnchorElPhone(event?.currentTarget);
        setCurrentIdPhone(id)
    };
    const openEmailMenu = (id, status, event) => {
        if (status === 1) {
            return
        }
        setAnchorElEmail(event?.currentTarget);
        setCurrentIdEmail(id)
    };
    const handleClosePhone = async (attr, newStatus) => {
        DataService.updateLeadsPhoneStatus(attr.id, newStatus).then(r => {
            queryClient.invalidateQueries('leadView').then()
            queryClient.invalidateQueries('leadLogs').then()
            setAnchorElPhone(null);
            AlertToastr.showAlert("Status updated successfully!").then()
        }).catch((error) => {
            if (error && error.response && error.response.data && error.response.data.message)
                AlertToastr.showErrorAlert(error.response.data.message)
        })
    };
    const handleCloseEmail = async (attr) => {
        setAnchorElEmail(null);
        if (attr.status === 4) {
            DataService.updateLeadsEmailStatus(attr.id, 1).then(r => {
                queryClient.invalidateQueries('leadView').then()
                queryClient.invalidateQueries('leadLogs').then()
                AlertToastr.showAlert("Status updated successfully!").then()
            })
                .catch((error) => {
                    if (error && error.response && error.response.data && error.response.data.message)
                        AlertToastr.showErrorAlert(error.response.data.message)
                })
        } else {
            DataService.updateLeadsEmailStatus(attr.id, 4).then(r => {
                queryClient.invalidateQueries('leadView').then()
                queryClient.invalidateQueries('leadLogs').then()
                AlertToastr.showAlert("Status updated successfully!").then()
            })
                .catch((error) => {
                    if (error && error.response && error.response.data && error.response.data.message)
                        AlertToastr.showErrorAlert(error.response.data.message)
                })
        }
    };

    useEffect(() => {
        if (segmentId) {
            setSection('segments')
            if (segment) {
                setBreadcrumbs([
                    {label: 'Audiences', link: `./`},
                    {label: segment.name, link: `./audiences/${segmentId}`},
                    {label: leadId}
                ])
            } else {
                setBreadcrumbs([
                    {label: 'Audiences', link: `./`},
                    {label: segmentId, link: `./audiences/${segmentId}`, loading: true},
                    {label: leadId}
                ])
            }
        } else if (campaignId) {
            setSection('campaigns')
            if (campaigns) {
                setBreadcrumbs([
                    {label: 'Campaigns', link: `./campaigns`},
                    {label: campaign.name, link: `./campaigns/${campaignId}`},
                    {label: leadId}
                ])
            } else {
                setBreadcrumbs([
                    {label: 'Campaigns', link: `./campaigns`},
                    {label: campaignId, link: `./campaigns/${campaignId}`, loading: true},
                    {label: leadId}
                ])
            }
        } else if (listId) {
            setSection('sources')
            if (!list && !source) {
                setBreadcrumbs([
                    {label: 'Sources', link: `./sources`},
                    {label: sourceId, link: `./sources/${sourceId}`, loading: true},
                    {label: listId, link: `./sources/${sourceId}/${listId}`, loading: true},
                    {label: leadId}
                ])
            } else {
                setBreadcrumbs([
                    {label: 'Sources', link: `./sources`},
                    {label: source && source.name, link: `./sources/${sourceId}`},
                    {label: list && list.name, link: `./sources/${sourceId}/${listId}`},
                    {label: leadId}
                ])
            }
        } else {
            setSection('leads')
            setBreadcrumbs([
                {label: 'Leads', link: `./leads`},
                {label: leadId}
            ])
        }


    }, [
        leadId,
        segmentId,
        campaignId,
        sourceId,
        listId,
        segment,
        campaign,
        campaigns,
        list,
        source,
        setBreadcrumbs,
        setSection
    ])

    // Lead
    const leadRequest = useQuery(
        ['leadView', leadId],
        () => getLead(leadId),
        {keepPreviousData: true}
    )
    useEffect(() => {
        if (leadRequest.isSuccess) {
            const leadModel = leadRequest.data.data
            setLead(leadModel)
            if (leadModel.lead_values) {
                setLeadAttributes(formatLeadAttributes(leadModel.lead_values))
            }
        }

    }, [leadRequest.isSuccess, leadRequest.data])

    // Lead's audiences
    const leadSegmentsRequest = useQuery(
        ['leadSegments', leadId],
        () => getLeadSegments(leadId),
        {keepPreviousData: true}
    )
    useEffect(() => {
        if (leadSegmentsRequest.isSuccess)
            setSegments(leadSegmentsRequest.data.data)
    }, [leadSegmentsRequest.isSuccess, leadSegmentsRequest.data])

    // Lead's campaigns
    const leadCampaignsRequest = useQuery(
        ['leadCampaigns', leadId],
        () => getLeadCampaigns(leadId),
        {keepPreviousData: true}
    )
    useEffect(() => {
        if (leadCampaignsRequest.isSuccess)
            setCampaigns(leadCampaignsRequest.data.data)
    }, [leadCampaignsRequest.isSuccess, leadCampaignsRequest.data])

    // Lead's sources
    const leadSourcesRequest = useQuery(
        ['leadSources', leadId],
        () => getLeadSources(leadId),
        {keepPreviousData: true}
    )
    useEffect(() => {
        if (leadSourcesRequest.isSuccess)
            setSources(leadSourcesRequest.data.data)
    }, [leadSourcesRequest.isSuccess, leadSourcesRequest.data])

    // Lead's sources
    const leadLogsRequest = useQuery(
        ['leadLogs', leadId, page, limit, logFilters],
        () => getLeadLogs(leadId, page, limit, logFilters),
        {keepPreviousData: true, enabled: !leadCampaignsRequest.isFetching}
    )

    useEffect(() => {
        if (leadLogsRequest.isSuccess) {
            setLogInfo(formatLeadLog(leadLogsRequest.data.data))
            const paginationData = leadLogsRequest.data.meta
            if (paginationData.last_page < page) {
                setPage(1)
            }
            setPagination(paginationData)
        }
    }, [leadLogsRequest.isSuccess, leadLogsRequest.data, page, setPage])

    // Segment
    const segmentRequest = useQuery(
        ['segmentView', segmentId],
        () => getSegment(segmentId),
        {keepPreviousData: true, enabled: !!segmentId}
    )
    useEffect(() => {
        if (segmentRequest.isSuccess)
            setSegment(segmentRequest.data.data)
    }, [segmentRequest.isSuccess, segmentRequest.data])

    // Campaign
    const campaignRequest = useQuery(
        ['campaignView', campaignId],
        () => getCampaign(campaignId),
        {keepPreviousData: true, enabled: !!campaignId}
    )
    useEffect(() => {
        if (campaignRequest.isSuccess)
            setCampaign(campaignRequest.data.data)
    }, [campaignRequest.isSuccess, campaignRequest.data])

    // Source
    const sourceRequest = useQuery(
        ['sourceView', sourceId],
        () => getSource(sourceId),
        {keepPreviousData: true, enabled: !!sourceId}
    )
    useEffect(() => {
        if (sourceRequest.isSuccess)
            setSource(sourceRequest.data.data)
    }, [sourceRequest.isSuccess, sourceRequest.data])

    // List
    const listRequest = useQuery(
        ['listView', listId],
        () => getList(listId),
        {keepPreviousData: true, enabled: !!listId}
    )
    useEffect(() => {
        if (listRequest.isSuccess)
            setList(listRequest.data.data)
    }, [listRequest.isSuccess, listRequest.data])

    const saveAttrValue = (attr) => {
        switch (attr.type) {
            case 'string':
                DataService.updateLeadValue(attr.id, attr.value).then(r => {
                    queryClient.invalidateQueries('leadView').then()
                    queryClient.invalidateQueries('leadLogs').then()
                })
                    .catch((error) => {
                        if (error && error.response && error.response.data && error.response.data.message)
                            AlertToastr.showErrorAlert(error.response.data.message)
                    })
                break
            case 'email':
                DataService.updateEmailLeadValue(attr.id, attr.value).then(r => {
                    queryClient.invalidateQueries('leadView').then()
                    queryClient.invalidateQueries('leadLogs').then()
                })
                    .catch((error) => {
                        if (error && error.response && error.response.data && error.response.data.message)
                            AlertToastr.showErrorAlert(error.response.data.message)
                    })
                break
            case 'phone':
                DataService.updatePhoneLeadValue(attr.id, attr.value).then(r => {
                    queryClient.invalidateQueries('leadView').then()
                    queryClient.invalidateQueries('leadLogs').then()
                })
                    .catch((error) => {
                        if (error && error.response && error.response.data && error.response.data.message)
                            AlertToastr.showErrorAlert(error.response.data.message)
                    })
                break
            default:
                break
        }
    }

    const renderLeadAttributes = () => {
        return (
            <Fragment>
                {leadAttributes.map((attr, index) => {
                    return <div className="tr" key={index}>
                        <div className="td">{attr.name}</div>
                        <div className="td">
                            <ClickAwayListener onClickAway={(e) => {
                                if (!attr.isEditing)
                                    return
                                setLeadAttributes(attributes => {
                                    const currentAttribute = attributes.find(value => value.id === attr.id && value.type === attr.type)
                                    currentAttribute.isEditing = false
                                    return [...attributes]
                                })
                                if (attr.value !== attr.newValue) {
                                    setLeadAttributes(attributes => {
                                        const currentAttribute = attributes.find(value => value.id === attr.id && value.type === attr.type)
                                        currentAttribute.value = currentAttribute.newValue
                                        return [...attributes]
                                    })
                                    saveAttrValue(attr)
                                }
                            }}>
                                <div className="editTextOnClick">
                                    <div className="captionPart">
                                        {
                                            attr.isEditing ?
                                                <TextField
                                                    defaultValue={attr.newValue}
                                                    autoFocus={true}
                                                    onChange={(e) => {
                                                        setLeadAttributes(attributes => {
                                                            const currentAttribute = attributes.find(value => value.id === attr.id && value.type === attr.type)
                                                            currentAttribute.newValue = e.target.value
                                                            return [...attributes]
                                                        })
                                                    }}
                                                /> :
                                                <span className='caption' onClick={(e) => {
                                                    setLeadAttributes(attributes => {
                                                        attributes.find(value => value.id === attr.id && value.type === attr.type).isEditing = true
                                                        return [...attributes]
                                                    })
                                                }}>{attr.value}
                                                </span>
                                        }
                                        {attr.type === 'phone' &&
                                            <div className="captionPart">
                                                <span className='caption'>
                                                    {attr.status_label && <div>Campaign Status:
                                                        <div
                                                            className={`phoneStatusLable ${replaceStatusPhone(attr.status_label)}`}
                                                            id={attr.id}
                                                            onClick={(e) => openPhoneMenu(attr.id, attr.status, e)}
                                                        >
                                                            {replaceStatusPhone(attr.status_label)}
                                                            <Menu
                                                                className='menuStatus'
                                                                id={attr.id}
                                                                anchorEl={anchorElPhone}
                                                                open={openPhone && currentIdPhone === attr.id}
                                                                onClose={() => {
                                                                    setAnchorElPhone(null)
                                                                }}
                                                            >
                                                                {attr.status !== 1 &&
                                                                    <MenuItem
                                                                        onClick={() => handleClosePhone(attr, 1)}>
                                                                        Subscribed
                                                                    </MenuItem>
                                                                }
                                                                {attr.status !== 2 &&
                                                                    <MenuItem
                                                                        onClick={() => handleClosePhone(attr, 2)}>
                                                                        Unsubscribed
                                                                    </MenuItem>
                                                                }
                                                                {attr.status !== 3 &&
                                                                    <MenuItem
                                                                        onClick={() => handleClosePhone(attr, 3)}>
                                                                        Suppressed
                                                                    </MenuItem>
                                                                }
                                                                {attr.status !== 4 &&
                                                                    <MenuItem
                                                                        onClick={() => handleClosePhone(attr, 4)}>
                                                                        Blacklisted
                                                                    </MenuItem>
                                                                }
                                                                {attr.status !== 5 &&
                                                                    <MenuItem
                                                                        onClick={() => handleClosePhone(attr, 5)}>
                                                                        Federal DNC
                                                                    </MenuItem>
                                                                }
                                                                {attr.status !== 6 &&
                                                                    <MenuItem
                                                                        onClick={() => handleClosePhone(attr, 6)}>
                                                                        State DNC
                                                                    </MenuItem>
                                                                }


                                                            </Menu>
                                                        </div>


                                                        <br/>
                                                    </div>}
                                                </span>
                                            </div>
                                        }
                                    </div>
                                    {
                                        attr.type === 'email' && (
                                            <div className="captionPart">
                                                <span className='caption'>
                                                    {/*{ attr.ban && <Fragment><span style={{background: '#3c3c3c', color: '#fff', borderRadius: '4px', padding: '1px 4px'}}>blacklisted</span><br /></Fragment>}*/}
                                                    {attr.status_label && <div>Status:
                                                        <div
                                                            className={`phoneStatusLable ${replaceStatusPhone(attr.status_label)}`}
                                                            id={attr.id}
                                                            onClick={(e) => openEmailMenu(attr.id, attr.status, e)}
                                                        >
                                                            {replaceStatusPhone(attr.status_label)}
                                                        </div>

                                                        <Menu
                                                            className='menuStatus'
                                                            id={attr.id}
                                                            anchorEl={anchorElEmail}
                                                            open={openEmail && currentIdEmail === attr.id}
                                                            onClose={() => {
                                                                setAnchorElEmail(null)
                                                            }}
                                                        >
                                                            <MenuItem
                                                                onClick={() => handleCloseEmail(attr)}>{attr.status === 4 ? "Pending" : "Unsubscribe"}</MenuItem>

                                                        </Menu>

                                                        <br/></div>}
                                                    {attr.result &&
                                                        <Fragment>Result: <span>{attr.result}</span><br/></Fragment>}
                                                    {attr.carrier_name &&
                                                        <Fragment>Carrier: <span>{attr.carrier_name}</span><br/></Fragment>}
                                                    {attr.carrier_type && <Fragment>Carrier
                                                        type: <span>{attr.carrier_type}</span><br/></Fragment>}
                                                    {attr.unsubscribed && <Fragment>Unsubscribed<br/></Fragment>}
                                                </span>
                                            </div>
                                        )
                                    }
                                    {
                                        attr.type === 'phone' && (
                                            <div className="captionPart">
                                                <span className='caption'>
                                                    {attr.carrier_status_label && <div>Carrier Status:
                                                        <div
                                                            className={`phoneStatusLable ${attr.carrier_status_label}`}
                                                            id={attr.id}
                                                        >
                                                            {attr.carrier_status_label}
                                                        </div>

                                                        <br/></div>}
                                                    {attr.carrier_name &&
                                                        <Fragment>Carrier: <span>{attr.carrier_name}</span><br/></Fragment>}
                                                    {attr.carrier_type && <Fragment>Carrier
                                                        type: <span>{attr.carrier_type}</span><br/></Fragment>}
                                                    {attr.state &&
                                                        <Fragment>State: <span>{attr.state}</span><br/></Fragment>}
                                                    {attr.city &&
                                                        <Fragment>City: <span>{attr.city}</span><br/></Fragment>}
                                                    {attr.timezone &&
                                                        <Fragment>Timezone: <span>{attr.timezone}</span><br/></Fragment>}
                                                    {attr.lookup_reason &&
                                                        <Fragment>Lookup reason: <span>{attr.lookup_reason}</span><br/></Fragment>}
                                                    {attr.lookup_action &&
                                                        <Fragment>Lookup action: <span>{attr.lookup_action}</span><br/></Fragment>}
                                                    {attr.black_list_alliance_phone_message && <Fragment>BA
                                                        message: <span>{attr.black_list_alliance_phone_message}</span><br/></Fragment>}
                                                    {attr.black_list_alliance_phone_code && <Fragment>BA
                                                        code: <span>{attr.black_list_alliance_phone_code}</span><br/></Fragment>}
                                                    {attr.unsubscribed && <Fragment>Unsubscribed<br/></Fragment>}
                                                </span>
                                            </div>
                                        )
                                    }
                                </div>
                            </ClickAwayListener>
                        </div>
                    </div>
                })}
            </Fragment>
        )
    }

    const renderLeadSegments = () => {
        if (leadSegmentsRequest.isLoading) {
            return (<div className="tr">
                <div className="td">Audiences</div>
                <div className="td">
                    <div>
                        <Loader type="ThreeDots" color="#0576ED" height={30} width={30}/>
                    </div>
                </div>
            </div>)
        }

        if (!segments) return null

        const items = segments.filter(item => !item.parent_segment_id)

        if (items.length === 0) return null

        return (
            <div className="tr">
                <div className="td">Audiences</div>
                <div className="td">
                    {items.map((segment) => {
                        return (
                            <Chip
                                key={segment.id}
                                label={segment.name}
                                onClick={() => navigate(`/u/${loggedUser}/audiences/${segment.id}`)}
                                className='slChip'
                            />
                        )
                    })}
                </div>
            </div>
        )
    }

    const renderLeadSubsegments = () => {
        if (leadSegmentsRequest.isLoading) {
            return (<div className="tr">
                <div className="td">Segments</div>
                <div className="td">
                    <div>
                        <Loader type="ThreeDots" color="#0576ED" height={30} width={30}/>
                    </div>
                </div>
            </div>)
        }
        if (!segments) return null

        const items = segments.filter(item => !!item.parent_segment_id)

        if (items.length === 0) return null

        return (
            <div className="tr">
                <div className="td">Segments</div>
                <div className="td">
                    {items.map((segment) => {
                        return (
                            < Chip
                                key={segment.id}
                                label={segment.name}
                                onClick={() => navigate(`/u/${loggedUser}/audiences/${segment.parent_segment_id}/segments/${segment.id}`)}
                                className='slChip'
                            />
                        )
                    })}
                </div>
            </div>
        )
    }

    const renderLeadCampaigns = () => {
        if (leadCampaignsRequest.isLoading) {
            return (<div className="tr">
                <div className="td">Campaigns</div>
                <div className="td">
                    <div>
                        <Loader type="ThreeDots" color="#0576ED" height={30} width={30}/>
                    </div>
                </div>
            </div>)
        }
        if (!campaigns || campaigns.length === 0) return null
        return (
            <div className="tr">
                <div className="td">Campaigns</div>
                <div className="td">
                    {campaigns.map((campaign) => {
                        return (
                            < Chip
                                key={campaign.id}
                                label={campaign.name}
                                onClick={() => navigate(`/u/${loggedUser}/campaigns/${campaign.id}`)}
                                className='slChip'
                            />
                        )
                    })}
                </div>
            </div>
        )
    }

    const renderLeadSources = () => {
        if (leadSourcesRequest.isLoading) {
            return (<div className="tr">
                <div className="td">Sources</div>
                <div className="td">
                    <div>
                        <Loader type="ThreeDots" color="#0576ED" height={30} width={30}/>
                    </div>
                </div>
            </div>)
        }
        if (!sources || sources.length === 0) return null

        return (
            <div className="tr">
                <div className="td">Sources</div>
                <div className="td">
                    {sources.map((source) => {
                        return (
                            <Chip
                                key={source.id}
                                label={source.name}
                                onClick={() => navigate(`/u/${loggedUser}/sources/${source.id}`)}
                                className='slChip'
                            />
                        )
                    })}
                </div>
            </div>
        )
    }

    const renderLeadLists = () => {
        if (leadSourcesRequest.isLoading) {
            return (<div className="tr">
                <div className="td">Sources</div>
                <div className="td">
                    <div>
                        <Loader type="ThreeDots" color="#0576ED" height={30} width={30}/>
                    </div>
                </div>
            </div>)
        }
        if (!sources || sources.length === 0) return null
        let lists = []

        sources.forEach(source => {
            if (source.lists) {
                source.lists.forEach(list => {
                    const listPresent = lists.find(item => item.id === list.id)
                    if (!listPresent) lists.push(list)
                })
            }
        })

        return (
            <div className="tr">
                <div className="td">Lists</div>
                <div className="td">
                    {lists.map((list) => {
                        return (
                            <Chip
                                key={list?.processing_document_list?.id}
                                label={list?.processing_document_list?.name}
                                onClick={() => navigate(`/u/${loggedUser}/sources/${list.source_id}/${list?.processing_document_list?.id}`)}
                                className='slChip'
                            />
                        )
                    })}
                </div>
            </div>
        )
    }

    const renderLeadTags = () => {
        if (lead && lead.status) {


            const statusClass = `tagLikeChip ${replaceStatus(lead.status)}`


            return (
                <div className="tr">
                    <div className="td">Status</div>
                    <div className="td">
                        <Chip
                            label={replaceStatus(lead.status)}
                            color='primary'
                            className={statusClass}
                        />
                    </div>
                </div>
            )
        }
        return null
    }

    const redirectToList = () => {

        let redirectToUrl = `/u/${loggedUser}/leads`;

        if (segmentId)
            redirectToUrl = `/u/${loggedUser}/audiences/${segmentId}`
        else if (campaignId)
            redirectToUrl = `/u/${loggedUser}/campaigns/${campaignId}/audience`
        else if (listId && sourceId) {
            redirectToUrl = `/u/${loggedUser}/sources/${sourceId}/${listId}`
        }

        navigate(redirectToUrl, {replace: true});
    }

    return (
        <div className="pageHolder">
            <div className="leadPageWrapper">
                {/*<DetailsAttrsTable title="Lead Attributes" loading={leadRequest.isLoading}>*/}
                {/*    <div className="tr">*/}
                {/*        <div className="td">Lead ID</div>*/}
                {/*        <div className="td">{lead?.id}</div>*/}
                {/*    </div>*/}
                {/*    {renderLeadAttributes()}*/}
                {/*</DetailsAttrsTable>*/}
                <div className='leadBox'>
                    <DetailsAttrsTable title="Lead Attributes" isLoading={leadRequest.isLoading}>
                        <div className="tr">
                            <div className="td">Lead ID</div>
                            <div className="td">{lead?.id}</div>
                        </div>
                        {renderLeadAttributes()}
                    </DetailsAttrsTable>
                    <DetailsAttrsTable title="Locations" isLoading={leadRequest.isLoading}>
                        {renderLeadSegments()}
                        {renderLeadSubsegments()}
                        {renderLeadSources()}
                        {renderLeadLists()}
                        {renderLeadCampaigns()}
                        {renderLeadTags()}
                    </DetailsAttrsTable>
                    <DetailsAttrsTable title="Revenue" isLoading={leadRequest.isLoading}>
                        <div className="tr">
                            <div className="td">Lifetime Value</div>
                            <div className="td">${lead?.revenue}</div>
                        </div>
                        <div className="tr">
                            <div className="td">Total Conversations</div>
                            <div className="td">{lead?.total_conversations}</div>
                        </div>
                    </DetailsAttrsTable>
                    <DetailsAttrsTable title="System Attributes" isLoading={leadRequest.isLoading}>
                        <div className="tr">
                            <div className="td">Created At</div>
                            <div className="td">{toLocalFormat2(lead?.created_at)}</div>
                        </div>
                        <div className="tr">
                            <div className="td">Last Clicked</div>
                            <div className="td">{toLocalFormat2(lead?.last_click_at) || '-'}</div>
                        </div>
                        <div className="tr">
                            <div className="td">Total Clicks</div>
                            <div className="td">{lead?.total_clicks || '0'}</div>
                        </div>
                    </DetailsAttrsTable>
                </div>

                <div className="timeLineHolder">
                    <div className="timeLine">
                        <div className="timeLineHeading mb15">
                            <h2>Timeline</h2>
                            <Button
                                disabled={leadLogsRequest.isFetching}
                                variant="contained"
                                color="primary"
                                startIcon={<RefreshIcon/>}
                                size='small'
                                onClick={(e) => {
                                    setPage(1)
                                    queryClient.invalidateQueries('leadLogs').then()
                                }}
                            >
                                Refresh
                            </Button>
                        </div>

                        <LeadLogFilters filters={logFilters} onUpdate={setLogFilters}/>
                        {
                            leadLogsRequest.isLoading
                                ? <PageLoader/>
                                : (
                                    <TimeLineComponent
                                        logInfo={logInfo}
                                        pagination={pagination}
                                        pageLimit={limit}
                                        onPageChange={setPage}
                                        onLimitChange={setLimit}
                                    />
                                )}
                    </div>
                </div>
            </div>
            <LeadPageActions
                lead={lead}
                loading={leadRequest.isLoading}
                onDelete={redirectToList}
            />
        </div>
    )
}

export default LeadPage
