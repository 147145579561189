import React, { useEffect, useMemo, useState, useCallback } from "react"
import SourcesHeader from "../../components/SourcesHeader"
import LoadingValue from "../../components/LoadingValue"
import { useQueries, useQuery } from "react-query"
import SearchInput from "../../components/SearchInput"
import { getSourceAggregations, getSources } from "../../api"
import { useAppDispatch } from "../../Contexts/AppContext"
import SourcesPageActions from "./SourcesPageActions"
import BasicTable from "../../components/BasicTable"
import TableProgressCell from "../../components/TableProgressCell"
import { useQueryState } from "use-location-state";
import { sourceLinkWrapper } from "../../helpers";
import { useParams } from "react-router-dom";

const SourcesPage = () => {
    const params = useParams();
    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState([]);
    const [page, setPage] = useQueryState("page", 1);
    const [limit, setLimit] = useQueryState("limit", 50);
    const {setBreadcrumbs, setSection} = useAppDispatch();
    const [selectedRows, setSelectedRows] = useState([]);

    const sourceLink = useCallback((value) => {
        return `/u/${params.loggedUser}/sources/${value}`
    }, [params]);

    const tableColumns = useMemo(() => {
        return [
            {
                Header: "Source Name",
                accessor: "name",
                Cell: ({ value, row }) => sourceLinkWrapper(sourceLink(row.original.id), value),
            },
            {
                Header: "Total Leads",
                accessor: "total_leads",
                disableSortBy: true,
                Cell: ({ value, row }) => sourceLinkWrapper(sourceLink(row.original.id), <LoadingValue value={value}/>),
            },
            {
                Header: "Unique Today",
                accessor: "unique_today",
                disableSortBy: true,
                Cell: ({ value, row }) => sourceLinkWrapper(sourceLink(row.original.id), <LoadingValue value={value}/>),
            },
            {
                Header: "Total today",
                accessor: "added_today",
                disableSortBy: true,
                Cell: ({ value, row }) => {
                    return sourceLinkWrapper(sourceLink(row.original.id), <LoadingValue value={value}/>);
                }
            },
            {
                Header: "Flagged",
                accessor: "total_flagged_leads",
                disableSortBy: true,
                Cell: ({ value, row }) => sourceLinkWrapper(sourceLink(row.original.id), <LoadingValue value={value}/>),
            },
            {
                Header: "Blacklisted",
                accessor: "total_blacklisted_leads",
                disableSortBy: true,
                Cell: ({ value, row }) => sourceLinkWrapper(sourceLink(row.original.id), <LoadingValue value={value}/>),
            },
            {
                Header: "Active Phones/Emails",
                accessor: "percentage_verified",
                disableSortBy: true,
                Cell: ({ value, row }) => {
                    const {percentage_verified, percentage_lookup, id} = row.original
                    const progress = <TableProgressCell value={percentage_verified} value2={percentage_lookup} />
                    return sourceLinkWrapper(
                        sourceLink(id),
                        <LoadingValue value={value} renderedValue={progress} />,
                    );
                }
            },
        ]
    }, []);
    const tableData = useMemo(() => list.map(item => ({...item, status: true})), [list]);

    const {isSuccess, data, isLoading, isFetching} = useQuery(
        ["sourcesList", page, limit, search, sorting],
        () => getSources(page, limit, search, sorting),
        {keepPreviousData: true}
    )

    const sourceAggregationsRequests = useQueries(list.map(item => {
        return {
            queryKey: ["sourceAggregations", item.id],
            queryFn: () => getSourceAggregations(item.id),
            cacheTime: 0,
            refetchOnMount: false,
        }
    }))

    useEffect(() => {
        sourceAggregationsRequests.forEach(req => {
            if (req.isSuccess) {
                setList(prev => {
                    const updatedItem = req.data.data.data;
                    const newList = [...prev]
                    const index = newList.findIndex(s => s.id === updatedItem.id)
                    if (index !== -1) {
                        newList[index] = {
                            ...newList[index],
                            total_leads: updatedItem.total_leads,
                            unique_today: updatedItem.unique_today,
                            added_today: updatedItem.added_today,
                            total_blacklisted_leads: updatedItem.total_blacklisted_leads,
                            total_flagged_leads: updatedItem.total_flagged_leads,
                            percentage_lookup: updatedItem.percentage_lookup,
                            percentage_verified: updatedItem.percentage_verified,
                        }
                    }
                    return newList
                })
            }
        })
    }, [sourceAggregationsRequests])

    useEffect(() => {
        setBreadcrumbs([{label: "Sources"}])
        setSection("sources")
    }, [setBreadcrumbs, setSection])

    useEffect(() => {
        if (isSuccess) {
            setList(data.data)
            const paginationData = data.meta
            if (paginationData.last_page < page) {
                setPage(1)
            }
            setPagination(paginationData)
        }
    }, [isSuccess, data, page, setPage])

    const renderSearchBlock = () => {
        return (
            <div className="searchHolder">
                <div className="inputHolder">
                    <SearchInput
                        search={search}
                        onChange={setSearch}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className="pageHolder">
            <div className="pageHeadingBlock mb15">
                <SourcesHeader/>
            </div>
            <div className="pageTableBlock">
                {renderSearchBlock()}
                <div className="tableHolder hasCheckboxes rowClickable size">
                    <BasicTable
                        columns={tableColumns}
                        data={tableData}
                        loading={isLoading}
                        fetching={isFetching}
                        isSelectionColumn={true}
                        onSelectedRows={setSelectedRows}
                        onSort={setSorting}
                        emptyDataText="No Sources Found"
                        pagination={pagination}
                        pageLimit={limit}
                        onPageChange={setPage}
                        onLimitChange={setLimit}
                    />
                </div>
            </div>
            <SourcesPageActions
                selectedRows={selectedRows}
                bulkDone={() => {
                    setList(prev => [...prev])
                }}
            />
        </div>
    )
}

export default SourcesPage
