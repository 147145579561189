import React from "react";

import SegmentFilterSelectValue from "./SegmentFilterSelectValue";
import SegmentFilterInputValue from "./SegmentFilterInputValue";
import SegmentFilterDateValue from "./SegmentFilterDateValue";
import {useSegmentFilterDispatch} from "./SegmentFilterContext";
import {segmentFilterValue, segmentFilterExpression} from "./enums";

const SegmentFilterValues = ({ valueMap, filter, hasError, onValueMapChange, }) => {
    const {getFilterValuesOptionsHandler} = useSegmentFilterDispatch();

    if (filter.expression === segmentFilterExpression.EMPTY || filter.expression === segmentFilterExpression.NOT_EMPTY) {
        return null;
    }

    if (valueMap.valueType === segmentFilterValue.DATE) {
        return (
            <SegmentFilterDateValue
                valueMap={valueMap}
                filter={filter}
                onValueMapChange={onValueMapChange}
            />
        );
    }

    if (valueMap.valueType === segmentFilterValue.MULTI_SELECT) {
        return (
            <SegmentFilterSelectValue
                multiple={true}
                valueMap={valueMap}
                filter={filter}
                valueOptions={getFilterValuesOptionsHandler(filter, valueMap)}
                onValueMapChange={onValueMapChange}
            />
        );
    }

    if (valueMap.valueType === segmentFilterValue.SELECT) {
        return (
            <SegmentFilterSelectValue
                multiple={false}
                valueMap={valueMap}
                filter={filter}
                valueOptions={getFilterValuesOptionsHandler(filter, valueMap)}
                onValueMapChange={onValueMapChange}
            />
        );
    }

    if (valueMap.valueType === segmentFilterValue.MULTI_STRING) {
        return (
            <SegmentFilterInputValue
                valueMap={valueMap}
                filter={filter}
                hasError={hasError}
                onValueMapChange={onValueMapChange}
            />
        );
    }

    return null;
};

export default SegmentFilterValues;
