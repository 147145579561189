import React, {Fragment, useEffect, useMemo, useRef, useState} from "react"
import {Controller, useForm} from "react-hook-form"
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {useAppState} from "../../Contexts/AppContext"
import {useMutation, useQuery, useQueryClient} from "react-query"
import {
    getActiveEmailLookupGateways, getActiveSmsLookupGateways,
    getEmailGateways, getPools,
    getRoutes,
    getSMSGateways, getSpamEmailGroups,
    storeCampaign,
    updateCampaign
} from "../../api"
import AlertToastr from "../../Utils/alert"
import MenuItem from "@material-ui/core/MenuItem"
import ReactHookFormSelect from "../form/ReactHookFormSelect"
import {useNavigate, useParams} from "react-router-dom"
import InputAdornment from "@material-ui/core/InputAdornment"
import {KeyboardDateTimePicker, TimePicker} from "@material-ui/pickers"
import moment from "moment-timezone"
import {hoursMinutes, toServerDatetimeFormat} from "../../Utils/dates"
import IOSSwitch from "../IOSSwitch";
import RuleService from "../../Services/Rules/RuleService";


const SaveCampaignModal = props => {
    const {
        open,
        handleClose,
        defaultValues,
    } = props

    const {config, configIsLoading} = useAppState()
    const campaignFrequencies = useMemo(() => config["campaign_frequency_period_list"] || [], [config])
    const [userGateways, setUserGateways] = useState([])
    const [emailGateways, setEmailGateways] = useState([])
    const [routes, setRoutes] = useState([])
    const [campaignPools, setCampaignPools] = useState([])
    const [spamEmailGroups, setSpamEmailGroups] = useState([]);
    const [smsCoolOffRules, setSmsCoolOffRules] = useState([]);
    const [inactiveDeviceRules, setInactiveDeviceRules] = useState([]);
    const [resetFrequencyRules, setResetFrequencyRules] = useState([]);
    const [increaseFrequencyRules, setIncreaseFrequencyRules] = useState([]);
    const [emailCoolOffRules, setEmailCoolOffRules] = useState([]);
    const [smsDefaultRules, setSmsDefaultRules] = useState([]);
    const [emailDefaultRules, setEmailDefaultRules] = useState([]);

    const mockCampaignTypes = [
        {key: "sms", label: "Sms"},
        {key: "automation", label: "SMS Trigger"},
        {key: "email", label: "Email"},
        {key: "email_automation", label: "Email Trigger"},
    ]
    const senderTypes = [{key: "gateway", label: "Gateway"}, {key: "route", label: "Route"}, {
        key: 'pool',
        label: 'Pool'
    }];
    const senderTypesEmail = [{key: "gateway", label: "Gateway"}];

    const activeUserGateways = useMemo(() => {
        return userGateways.filter(item => item.active && !item.allow_lookup);
    }, [userGateways])

    const queryClient = useQueryClient()
    const history = useNavigate()
    const params = useParams()
    const timezones = useMemo(() => config["timezones_js"] || [], [config])

    const {
        control,
        register,
        handleSubmit,
        reset,
        watch,
        setValue
    } = useForm({
        mode: "onTouched",
        defaultValues: defaultValues
    })

    const nameRef = useRef()
    const emailRef = useRef()

    const storeCampaignMutation = useMutation(storeCampaign)
    const updateCampaignMutation = useMutation(updateCampaign)
    const userGatewaysRequest = useQuery(
        ["smsGateways"],
        () => getSMSGateways(),
        {keepPreviousData: true}
    )

    const listEmailRequest = useQuery(
        ["emailGateways"],
        () => getEmailGateways(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (listEmailRequest.isSuccess) {
            const {data} = listEmailRequest.data
            setEmailGateways(data)
        }
    }, [listEmailRequest.isSuccess, listEmailRequest.data])

    useEffect(() => {
        if (userGatewaysRequest.isSuccess) {
            const {data} = userGatewaysRequest.data
            setUserGateways(data)
        }
    }, [userGatewaysRequest.isSuccess, userGatewaysRequest.data])

    const routesRequest = useQuery(
        ["routes"],
        () => getRoutes(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (routesRequest.isSuccess) {
            setRoutes(routesRequest.data.data)
        }
    }, [routesRequest.isSuccess])

    const campaignPoolsRequest = useQuery(
        ["campaignPools"],
        () => getPools(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (campaignPoolsRequest.isSuccess) {
            setCampaignPools(campaignPoolsRequest.data.data)
        }
    }, [campaignPoolsRequest.isSuccess])

    const smsCoolOffRulesRequest = useQuery(
        ["sms_cool_off_rules"],
        () => RuleService.activeSmsCoolOffList(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (smsCoolOffRulesRequest.isSuccess) {
            setSmsCoolOffRules(smsCoolOffRulesRequest.data.data.data)
        }
    }, [smsCoolOffRulesRequest.isSuccess])

    const emailCoolOffRulesRequest = useQuery(
        ["email_cool_off_rules"],
        () => RuleService.activeEmailCoolOffList(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (emailCoolOffRulesRequest.isSuccess) {
            setEmailCoolOffRules(emailCoolOffRulesRequest.data.data.data)
        }
    }, [emailCoolOffRulesRequest.isSuccess])

    const inactiveDeviceRulesRequest = useQuery(
        ["inactive_device_rules"],
        () => RuleService.activeInactiveDeviceList(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (inactiveDeviceRulesRequest.isSuccess) {
            setInactiveDeviceRules(inactiveDeviceRulesRequest.data.data.data)
        }
    }, [inactiveDeviceRulesRequest.isSuccess])

    const resetFrequencyRulesRequest = useQuery(
        ["reset_frequency_rules"],
        () => RuleService.activeResetFrequencyList(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (resetFrequencyRulesRequest.isSuccess) {
            setResetFrequencyRules(resetFrequencyRulesRequest.data.data.data)
        }
    }, [resetFrequencyRulesRequest.isSuccess])

    const increaseFrequencyRulesRequest = useQuery(
        ["increase_frequency_rules"],
        () => RuleService.activeIncreaseFrequencyList(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (increaseFrequencyRulesRequest.isSuccess) {
            setIncreaseFrequencyRules(increaseFrequencyRulesRequest.data.data.data)
        }
    }, [increaseFrequencyRulesRequest.isSuccess])

    const rules = watch("rules", []);

    useEffect(() => {
        checkSelectRulesToUnique(smsCoolOffRules);
        checkSelectRulesToUnique(inactiveDeviceRules);
        checkSelectRulesToUnique(emailCoolOffRules);

    }, [rules])

    const checkSelectRulesToUnique = (items) => {
        let found = false;
        items.map((rule) => {
            if (rules.includes(rule.id)) {
                if (found) {
                    let tmpData = rules;
                    var index = tmpData.indexOf(rule.id);
                    if (index !== -1) {
                        tmpData.splice(index, 1);
                    }
                    setValue("rules", tmpData);
                } else {
                    found = true;
                }
            }
        })
    }

    useEffect(() => {
        if (defaultValues) {
            if (defaultValues.type === 'sms' || defaultValues.type === 'automation') {
                let tmpRules = [];
                if (defaultValues.cool_off_rule_id) {
                    tmpRules.push(defaultValues.cool_off_rule_id)
                }
                if (defaultValues.inactive_device_rule_id) {
                    tmpRules.push(defaultValues.inactive_device_rule_id)
                }
                if (defaultValues.set_frequency_rule_id) {
                    tmpRules.push(defaultValues.set_frequency_rule_id)
                }
                if (defaultValues.increase_frequency_rule_id) {
                    tmpRules.push(defaultValues.increase_frequency_rule_id)
                }
                setSmsDefaultRules([...tmpRules])
            }

            if (defaultValues.type === 'email' || defaultValues.type === 'email_automation') {
                let tmpRules = [];
                if (defaultValues.cool_off_rule_id) {
                    tmpRules.push(defaultValues.cool_off_rule_id)
                }
                setEmailDefaultRules([...tmpRules])
            }
        }
    }, [defaultValues])

    useEffect(() => {
        if (!defaultValues) {
            let tmpSmsRules = [];
            let tmpEmailRules = [];
            if (smsCoolOffRules.length) {
                tmpSmsRules.push(smsCoolOffRules[0].id)
            }
            if (inactiveDeviceRules.length) {
                tmpSmsRules.push(inactiveDeviceRules[0].id)
            }
            setSmsDefaultRules([...tmpSmsRules])

            if (emailCoolOffRules.length) {
                tmpEmailRules.push(emailCoolOffRules[0].id)
            }
            setEmailDefaultRules([...tmpEmailRules])

        }
    }, [smsCoolOffRules, inactiveDeviceRules, emailCoolOffRules])


    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues)
        }
    }, [defaultValues, reset])

    useEffect(() => {
        if (storeCampaignMutation.error) {
            AlertToastr.showErrorResponseAlert(storeCampaignMutation.error)
        }
    }, [storeCampaignMutation.error])

    useEffect(() => {
        if (updateCampaignMutation.error) {
            AlertToastr.showErrorResponseAlert(updateCampaignMutation.error)
        }
    }, [updateCampaignMutation.error])

    const spamEmailGroupRequest = useQuery(
        ["spamEmailGroupList"],
        () => getSpamEmailGroups(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (spamEmailGroupRequest.isSuccess) {
            setSpamEmailGroups(spamEmailGroupRequest.data.data)
        }
    }, [spamEmailGroupRequest.isSuccess, spamEmailGroupRequest.data])

    const title = defaultValues ? "Update Campaign" : "Create Campaign"
    const gatewayId = watch("setting_gateway_id")
    const poolId = watch("pool_id")
    const msSid = watch("ms_sid")
    const type = watch("type");
    const senderType = watch("sender_type");
    const onlyVerifiedLeads = watch("only_verified_leads");
    const sendTestSpamEmails = watch("send_test_spam_emails");
    const daytimeLimitation = watch("daytime_limitation");

    const onSubmit = handleSubmit(async data => {
        const userGateway = userGateways.find(item => item.id === data.setting_gateway_id)
        const userGatewayType = userGateway?.type
        const emailGateway = emailGateways.find(item => item.id === data.setting_gateway_id)
        const eamilGatewayType = emailGateway?.type

        if (type === "sms" || type === "automation") {
            if (!data.need_send_by_timezones) {
                data.need_send_by_timezones = false;
            }

            data.cool_off_rule_id = null;
            data.inactive_device_rule_id = null;
            data.set_frequency_rule_id = null;
            data.increase_frequency_rule_id = null;
            rules.map(ruleId => {
                let coolOffRule = smsCoolOffRules.find(rule => rule.id === ruleId)
                let inactiveRule = inactiveDeviceRules.find(rule => rule.id === ruleId)
                let resetFrequencyRule = resetFrequencyRules.find(rule => rule.id === ruleId)
                let increaseFrequencyRule = increaseFrequencyRules.find(rule => rule.id === ruleId)
                if (coolOffRule) {
                    data.cool_off_rule_id = ruleId;
                }
                if (inactiveRule) {
                    data.inactive_device_rule_id = ruleId;
                }
                if (resetFrequencyRule) {
                    data.set_frequency_rule_id = ruleId;
                }
                if (increaseFrequencyRule) {
                    data.increase_frequency_rule_id = ruleId;
                }

            })
        }

        if (type === "email" || type === "email_automation") {
            data.cool_off_rule_id = null;
            rules.map(ruleId => {
                let coolOffRule = emailCoolOffRules.find(rule => rule.id === ruleId)
                if (coolOffRule) {
                    data.cool_off_rule_id = ruleId;
                }
            })
        }

        if (defaultValues) {
            if (data.started_at) {
                data.started_at = toServerDatetimeFormat(data.started_at)
            }
            if (data.run_daily_from) {
                data.run_daily_from = hoursMinutes(data.run_daily_from);
            }
            if (data.run_daily_to) {
                data.run_daily_to = hoursMinutes(data.run_daily_to);
            }
            const updateResponse = await updateCampaignMutation.mutateAsync({
                id: defaultValues.id,
                gateway: type === "email" || type === "email_automation" ? eamilGatewayType : userGatewayType,
                ...data
            })
            reset(updateResponse.data)
            handleClose()
            queryClient.invalidateQueries("campaignView").then()
            queryClient.invalidateQueries("campaignsList").then()
        } else {
            if (data.started_at) {
                data.started_at = toServerDatetimeFormat(data.started_at)
            }
            if (data.run_daily_from) {
                data.run_daily_from = hoursMinutes(data.run_daily_from);
            }
            if (data.run_daily_to) {
                data.run_daily_to = hoursMinutes(data.run_daily_to);
            }

            try {
                if (type === "email" || type === "email_automation") {
                    const response = await storeCampaignMutation.mutateAsync({
                        gateway: eamilGatewayType,
                        ...data

                    })
                    history(`/u/${params.loggedUser}/campaigns/${response.data.id}`)
                    queryClient.invalidateQueries("campaignsList").then()
                } else {
                    const response = await storeCampaignMutation.mutateAsync({
                        gateway: userGatewayType,
                        ...data
                    })
                    history(`/u/${params.loggedUser}/campaigns/${response.data.id}`)
                    queryClient.invalidateQueries("campaignsList").then()
                }
            } catch (e) {
                console.log(e)
            }
        }
    })
    useEffect(() => {
        if (Boolean(poolId)) setValue("ms_sid", "")
    }, [poolId, setValue])

    useEffect(() => {
        if (Boolean(msSid)) setValue("pool_id", "")
    }, [msSid, setValue])

    const availableSids = useMemo(() => {
        const foundGateway = activeUserGateways.find(g => g.id === gatewayId)
        return foundGateway ? foundGateway.from : []
    }, [gatewayId, activeUserGateways])

    const availablePools = useMemo(() => {
        const foundGateway = activeUserGateways.find(g => g.id === gatewayId)
        return foundGateway ? foundGateway.pools : []
    }, [gatewayId, activeUserGateways])


    const [emailLookupGateways, setEmailLookupGateways] = useState([]);

    const emailLookupGatewaysRequest = useQuery(
        ["activeEmailLookupGateways"],
        () => getActiveEmailLookupGateways(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (emailLookupGatewaysRequest.isSuccess) {
            setEmailLookupGateways(emailLookupGatewaysRequest.data.data)
        }
    }, [emailLookupGatewaysRequest.isSuccess, emailLookupGatewaysRequest.data])


    const [smsLookupGateways, setSmsLookupGateways] = useState([]);
    const smsLookupGatewaysRequest = useQuery(
        ["activeSmsLookupGateways"],
        () => getActiveSmsLookupGateways(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (smsLookupGatewaysRequest.isSuccess) {
            setSmsLookupGateways(smsLookupGatewaysRequest.data.data)
        }
    }, [smsLookupGatewaysRequest.isSuccess, smsLookupGatewaysRequest.data])

    const automationTriggersList = useMemo(() => config["campaign_automation_triggers"] || [], [config])

    return (
        <Fragment>
            <Dialog
                open={open}
                keepMounted={false}
                onClose={handleClose}
                className="modal"
                onEntered={() => {
                    nameRef.current.focus()
                }}
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className="mb15">{title}</h2>
                    </div>
                    <form onSubmit={onSubmit}>
                        <TextField
                            inputRef={(e) => {
                                register(e)
                                nameRef.current = e
                            }}
                            name="name"
                            label="Campaign Name"
                            variant="outlined"
                            margin="normal"
                        />
                        <ReactHookFormSelect
                            disabled={configIsLoading || !!defaultValues}
                            variant="outlined"
                            margin="normal"
                            id="type-select"
                            name="type"
                            label="Campaign Type"
                            control={control}
                            defaultValue={mockCampaignTypes[0] && mockCampaignTypes[0].key}
                        >
                            {mockCampaignTypes.map((option) => (
                                <MenuItem key={option.key} value={option.key}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>
                        {(type === "automation" || type === "email_automation") && <ReactHookFormSelect
                            disabled={configIsLoading || (defaultValues && defaultValues.is_started)}
                            variant="outlined"
                            margin="normal"
                            id="type-select"
                            name="triggers"
                            label="Trigger"
                            control={control}
                            defaultValue={defaultValues?.triggers ? defaultValues.triggers : []}
                            multiple={true}
                        >
                            {automationTriggersList.map((trigger) => (
                                <MenuItem key={trigger.id} value={trigger.id}>
                                    {trigger.name}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>
                        }

                        {type === "email" || type === "email_automation" ? <>
                                <ReactHookFormSelect
                                    disabled={configIsLoading || (defaultValues && defaultValues.is_started)}
                                    variant="outlined"
                                    margin="normal"
                                    id="type-select"
                                    name="sender_type"
                                    label="Sender type"
                                    control={control}
                                    defaultValue={senderTypesEmail[0].key}
                                >

                                    {senderTypesEmail.map((option) => (
                                        <MenuItem key={option.key} value={option.key}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </ReactHookFormSelect>
                                <ReactHookFormSelect
                                    disabled={listEmailRequest.isLoading}
                                    variant="outlined"
                                    margin="normal"
                                    id="gateway-select"
                                    name="setting_gateway_id"
                                    label="Gateway"
                                    control={control}
                                    defaultValue={emailGateways[0] ? emailGateways[0].id : ""}
                                >
                                    {emailGateways.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </ReactHookFormSelect>
                                <TextField
                                    inputRef={register}
                                    name="frequency_count"
                                    label="Frequency"
                                    variant="outlined"
                                    margin="normal"
                                    className="freqAdornment"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <ReactHookFormSelect
                                                    disabled={configIsLoading}
                                                    variant="standard"
                                                    margin="normal"
                                                    id="frequency-select"
                                                    name="frequency_period"
                                                    control={control}
                                                    defaultValue={campaignFrequencies[0] && campaignFrequencies[0].key}
                                                >
                                                    {campaignFrequencies.map((option) => (
                                                        <MenuItem key={option.key} value={option.key}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </ReactHookFormSelect>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <ReactHookFormSelect
                                    variant="outlined"
                                    margin="normal"
                                    id="rules"
                                    name="rules"
                                    label="Rules"
                                    control={control}
                                    multiple={true}
                                    defaultValue={emailDefaultRules}
                                >
                                    <MenuItem disabled value="">
                                        Cool Off Rules
                                    </MenuItem>
                                    {emailCoolOffRules.map(rule => {
                                        return (
                                            <MenuItem key={rule.id} value={rule.id}>
                                                {rule.name}
                                            </MenuItem>
                                        )
                                    })}
                                </ReactHookFormSelect>
                            </>
                            :
                            <>
                                <ReactHookFormSelect
                                    disabled={configIsLoading || (defaultValues && defaultValues.is_started)}
                                    variant="outlined"
                                    margin="normal"
                                    id="type-select"
                                    name="sender_type"
                                    label="Sender type"
                                    control={control}
                                    defaultValue={defaultValues && defaultValues.route_id ? "route" : (defaultValues && defaultValues.campaign_pool_id) ? 'pool' : senderTypes[0].key}
                                >
                                    {senderTypes.map((option) => (
                                        <MenuItem key={option.key} value={option.key}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </ReactHookFormSelect>

                                {(senderType && senderType === "route" && routes) && <ReactHookFormSelect
                                    disabled={routesRequest.isLoading || (defaultValues && defaultValues.is_started)}
                                    variant="outlined"
                                    margin="normal"
                                    id="type-select"
                                    name="route_id"
                                    label="Route"
                                    control={control}
                                    defaultValue={routes[0] && routes[0].id}
                                >
                                    {routes.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </ReactHookFormSelect>
                                }
                                {(senderType && senderType === "pool" && campaignPools) && <ReactHookFormSelect
                                    disabled={routesRequest.isLoading || (defaultValues && defaultValues.is_started)}
                                    variant="outlined"
                                    margin="normal"
                                    id="type-select"
                                    name="campaign_pool_id"
                                    label="Campaign Pool"
                                    control={control}
                                    defaultValue={campaignPools[0] && campaignPools[0].id}
                                >
                                    {campaignPools.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </ReactHookFormSelect>
                                }
                                {(senderType && senderType === "gateway" && activeUserGateways.length > 0) &&
                                    <ReactHookFormSelect
                                        disabled={userGatewaysRequest.isLoading}
                                        variant="outlined"
                                        margin="normal"
                                        id="gateway-select"
                                        name="setting_gateway_id"
                                        label="Gateway"
                                        control={control}
                                        defaultValue={activeUserGateways.length === 1 ? activeUserGateways[0].id : ""}
                                    >
                                        {activeUserGateways.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </ReactHookFormSelect>}

                                {Array.isArray(availableSids) && availableSids.length > 0 &&
                                    <ReactHookFormSelect
                                        variant="outlined"
                                        margin="normal"
                                        id="ms_sid"
                                        name="ms_sid"
                                        label="Messaging Service"
                                        control={control}
                                        defaultValue={availableSids.length === 1 && availableSids[0].key}
                                    >
                                        {availableSids.map(option => {
                                            return (
                                                <MenuItem key={option.key} value={option.key}>
                                                    {option.label}
                                                </MenuItem>
                                            )
                                        })}
                                    </ReactHookFormSelect>
                                }

                                {Array.isArray(availablePools) && availablePools.length > 0 &&
                                    <ReactHookFormSelect
                                        variant="outlined"
                                        margin="normal"
                                        id="pool_id"
                                        name="pool_id"
                                        label="List Pools"
                                        control={control}
                                        defaultValue={""}
                                    >
                                        {availablePools.map(pool => {
                                            return (
                                                <MenuItem key={pool.key} value={pool.key}>
                                                    {pool.label} ({pool.key})
                                                </MenuItem>
                                            )
                                        })}
                                    </ReactHookFormSelect>
                                }

                                {(senderType === 'gateway' || senderType === 'pool') && <TextField
                                    inputRef={register}
                                    name="frequency_count"
                                    label="Frequency"
                                    variant="outlined"
                                    margin="normal"
                                    className="freqAdornment"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <ReactHookFormSelect
                                                    disabled={configIsLoading}
                                                    variant="standard"
                                                    margin="normal"
                                                    id="frequency-select"
                                                    name="frequency_period"
                                                    control={control}
                                                    defaultValue={campaignFrequencies[0] && campaignFrequencies[0].key}
                                                >
                                                    {campaignFrequencies.map((option) => (
                                                        <MenuItem key={option.key} value={option.key}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </ReactHookFormSelect>
                                            </InputAdornment>
                                        ),
                                    }}
                                />}
                                <ReactHookFormSelect
                                    variant="outlined"
                                    margin="normal"
                                    id="rules"
                                    name="rules"
                                    label="Rules"
                                    control={control}
                                    multiple={true}
                                    defaultValue={smsDefaultRules}
                                >
                                    <MenuItem disabled value="">
                                        Cool Off Rules
                                    </MenuItem>
                                    {smsCoolOffRules.map(rule => {
                                        return (
                                            <MenuItem key={rule.id} value={rule.id}>
                                                {rule.name}
                                            </MenuItem>
                                        )
                                    })}
                                    <MenuItem disabled value="">
                                        Inactive Device Rules
                                    </MenuItem>
                                    {inactiveDeviceRules.map(rule => {
                                        return (
                                            <MenuItem key={rule.id} value={rule.id}>
                                                {rule.name}
                                            </MenuItem>
                                        )
                                    })}
                                    <MenuItem disabled value="">
                                        Reset Frequency Rules
                                    </MenuItem>
                                    {resetFrequencyRules.map(rule => {
                                        return (
                                            <MenuItem key={rule.id} value={rule.id}>
                                                {rule.name}
                                            </MenuItem>
                                        )
                                    })}
                                    <MenuItem disabled value="">
                                        Increase Frequency Rules
                                    </MenuItem>
                                    {increaseFrequencyRules.map(rule => {
                                        return (
                                            <MenuItem key={rule.id} value={rule.id}>
                                                {rule.name}
                                            </MenuItem>
                                        )
                                    })}
                                </ReactHookFormSelect>
                            </>
                        }
                        <Controller
                            name="started_at"
                            control={control}
                            defaultValue={moment().toDate()}
                            render={({onChange, onBlur, value}) => (
                                <KeyboardDateTimePicker
                                    format="MM-DD-YYYY hh:mm A"
                                    margin="normal"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    label="Start At"
                                    inputVariant="outlined"
                                    variant="inline"
                                />
                            )}
                        />

                        <ReactHookFormSelect
                            disabled={configIsLoading}
                            variant="outlined"
                            margin="normal"
                            id="type-select"
                            name="started_at_tz"
                            label="Timezone"
                            control={control}
                            defaultValue={moment.tz.guess()}
                        >
                            {timezones.map((option) => (
                                <MenuItem key={option.key} value={option.key}>
                                    {option.value}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>
                        <div className="align-center wrapper content-justified mt15">
                            <div style={{fontWeight: "600", fontSize: "14px"}}>Day parting</div>
                            <Controller
                                name="daytime_limitation"
                                control={control}
                                defaultValue={false}
                                render={({onChange, value}) => (
                                    <IOSSwitch
                                        disabled={(defaultValues && defaultValues.is_started)}
                                        color="primary"
                                        checked={value}
                                        onChange={(e) => onChange(e.target.checked)}
                                    />
                                )}
                            />
                        </div>

                        {daytimeLimitation &&
                            <div>
                                {(type === 'sms' || type === 'automation') &&
                                    <div className="align-center wrapper content-justified mt15">
                                        <div style={{fontWeight: "600", fontSize: "14px"}}>Send by timezones</div>
                                        <Controller
                                            name="need_send_by_timezones"
                                            control={control}
                                            defaultValue={false}
                                            render={({onChange, value}) => (
                                                <IOSSwitch
                                                    disabled={defaultValues && defaultValues.is_started}
                                                    color="primary"
                                                    checked={value}
                                                    onChange={(e) => onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </div>}

                                <Controller
                                    name="run_daily_from"
                                    control={control}
                                    defaultValue={moment().toDate()}
                                    render={({onChange, onBlur, value}) => {
                                        return <TimePicker
                                            disabled={defaultValues && defaultValues.is_started}
                                            format="hh:mm A"
                                            margin="normal"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            label="Run daily from"
                                            inputVariant="outlined"
                                            variant="inline"
                                        />
                                    }}
                                />
                                <Controller
                                    name="run_daily_to"
                                    control={control}
                                    defaultValue={moment().add(5, "h").toDate()}
                                    render={({onChange, onBlur, value}) => {
                                        return <TimePicker
                                            disabled={defaultValues && defaultValues.is_started}
                                            format="hh:mm A"
                                            margin="normal"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            label="Run daily to"
                                            inputVariant="outlined"
                                            variant="inline"
                                        />
                                    }}
                                />
                            </div>
                        }

                        <div className="align-center wrapper content-justified mt15">
                            <div style={{fontWeight: "600", fontSize: "14px"}}><b>
                                {(type === "email" || type === "email_automation") ? "Verified Emails" : "Verified Phones"}</b>
                                <br/>
                                <span>(turning this off will include unverified {(type === "email" || type === "email_automation") ? "emails" : "phones"})</span>
                            </div>
                            <Controller
                                name="only_verified_leads"
                                control={control}
                                defaultValue={true}
                                render={({onChange, value}) => (
                                    <IOSSwitch
                                        disabled={defaultValues && defaultValues.is_started}
                                        color="primary"
                                        checked={value}
                                        onChange={(e) => onChange(e.target.checked)}
                                    />
                                )}
                            />
                        </div>

                        {
                            onlyVerifiedLeads && type === "email_automation" &&
                            <Fragment>
                                <Controller
                                    name={"lookup_gateway_id"}
                                    control={control}
                                    defaultValue=""
                                    render={({onChange, value}) => {
                                        return (
                                            <TextField
                                                select
                                                onChange={onChange}
                                                value={value}
                                                variant="outlined"
                                                margin="normal"
                                                label="Email Lookup Gateway"
                                            >
                                                <MenuItem value="" disabled>
                                                    Select gateway
                                                </MenuItem>
                                                {emailLookupGateways.map((gateway) => {
                                                    return <MenuItem key={gateway.id} value={gateway.id}>
                                                        {gateway.name}
                                                    </MenuItem>
                                                })}
                                            </TextField>
                                        );
                                    }}
                                />

                            </Fragment>
                        }

                        {
                            onlyVerifiedLeads && type === "automation" &&
                            <Fragment>
                                <Controller
                                    name={"lookup_gateway_id"}
                                    control={control}
                                    defaultValue=""
                                    render={({onChange, value}) => {
                                        return (
                                            <TextField
                                                select
                                                onChange={onChange}
                                                value={value}
                                                variant="outlined"
                                                margin="normal"
                                                label="Sms Lookup Gateway"
                                            >
                                                <MenuItem value="" disabled>
                                                    Select gateway
                                                </MenuItem>
                                                {smsLookupGateways.map((gateway) => {
                                                    return <MenuItem key={gateway.id} value={gateway.id}>
                                                        {gateway.name}
                                                    </MenuItem>
                                                })}
                                            </TextField>
                                        );
                                    }}
                                />
                            </Fragment>
                        }

                        {
                            type === "email" && <div className="align-center wrapper content-justified mt15">
                                <div style={{fontWeight: "600", fontSize: "14px"}}><b>Send test spam emails</b>
                                </div>
                                <Controller
                                    name="send_test_spam_emails"
                                    control={control}
                                    defaultValue={false}
                                    render={({onChange, value}) => (
                                        <IOSSwitch
                                            color="primary"
                                            checked={value}
                                            onChange={(e) => onChange(e.target.checked)}
                                        />
                                    )}
                                />
                            </div>
                        }

                        {
                            sendTestSpamEmails && <ReactHookFormSelect
                                disabled={configIsLoading}
                                variant="outlined"
                                margin="normal"
                                id="type-select"
                                name="spam_email_groups"
                                label="Spam Email Group"
                                control={control}
                                defaultValue={[]}
                                multiple={true}
                            >
                                {spamEmailGroups.map(spamEmailGroup => (
                                    <MenuItem key={spamEmailGroup.id} value={spamEmailGroup.id}>
                                        {spamEmailGroup.name}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                        }
                        {
                            sendTestSpamEmails && <TextField
                                inputRef={(e) => {
                                    register(e)
                                    nameRef.current = e
                                }}
                                name="send_spam_email_interval"
                                label="Send Spam Emails Interval"
                                variant="outlined"
                                margin="normal"
                            />
                        }

                        <div className="btnHolder hasPadTop twoBtn">
                            <div className="part">
                                <Button
                                    disabled={storeCampaignMutation.isLoading || updateCampaignMutation.isLoading}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    type="submit"
                                    onClick={(e) => {
                                    }}
                                >
                                    {title}
                                </Button>
                            </div>
                            <div className="part">
                                <Button
                                    disabled={storeCampaignMutation.isLoading || updateCampaignMutation.isLoading}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default SaveCampaignModal
