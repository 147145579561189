import React, {useMemo} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const HCPie = ({campaignStat, currentFilter, campaign}) => {
    const options = useMemo(() => {

        if (!campaignStat) {
            return {}
        }

        if (!campaign) {
            return {}
        }

        const smsSeries = [
            {
                color: '#4D94F4',
                name: 'Delivered',
                y: campaignStat?.delivered,
                sliced: currentFilter === 'delivered',
                selected: currentFilter === 'delivered',
            },
            {
                name: 'Blocked by carrier',
                color: '#0576ED',
                y: campaignStat?.blocked,
                sliced: currentFilter === 'blocked',
                selected: currentFilter === 'blocked',
            },
            {
                color: '#A9CFFC',
                name: 'Inactive device',
                y: campaignStat?.inactive_device,
                sliced: currentFilter === 'inactive_device',
                selected: currentFilter === 'inactive_device',
            },
            {
                color: '#D8EBFF',
                name: 'Failed',
                y: campaignStat?.errors,
                sliced: currentFilter === 'errors',
                selected: currentFilter === 'errors',
            },
            {
                color: '#2a71c6',
                name: 'Sent',
                y: campaignStat?.sent,
                sliced: currentFilter === 'sent',
                selected: currentFilter === 'sent',
            },
        ];

        const emailSeries = [
            {
                name: 'Error',
                color: '#0576ED',
                y: campaignStat?.errors,
                sliced: currentFilter === 'errors',
                selected: currentFilter === 'errors',
            },
            {
                color: '#2a71c6',
                name: 'Sent',
                y: campaignStat?.sent,
                sliced: currentFilter === 'sent',
                selected: currentFilter === 'sent',
            },
            {
                color: '#4D94F4',
                name: 'Delivered',
                y: campaignStat?.delivered,
                sliced: currentFilter === 'delivered',
                selected: currentFilter === 'delivered',
            },
            {
                color: '#2a71c6',
                name: 'Cancelled',
                y: campaignStat?.cancelled,
                sliced: currentFilter === 'cancelled',
                selected: currentFilter === 'cancelled',
            },
            {
                color: '#053672',
                name: 'Bounced',
                y: campaignStat?.bounces,
                sliced: currentFilter === 'bounces',
                selected: currentFilter === 'bounces',
            },
            {
                color: '#1b324c',
                name: 'Complaint',
                y: campaignStat?.complaints,
                sliced: currentFilter === 'complaints',
                selected: currentFilter === 'complaints',
            },
        ]

        return {
            credits: {enabled: false},
            legend: {
                verticalAlign: 'top',
            },
            chart: {
                backgroundColor: '#fbfbfb',
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                height: 340
            },
            title: {
                text: 'Message Statuses'
            },
            tooltip: {
                pointFormat: '{point.y}, <b>{point.percentage:.1f}%</b>'
            },

            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            series: [{
                name: 'Messages',
                colorByPoint: true,
                data: (campaign && campaign.type === 'email') ? emailSeries : smsSeries,
            }]
        }
    }, [campaignStat, currentFilter, campaign])

    console.log(campaignStat);
    console.log(campaign);
    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}

export default HCPie
