import React, {useEffect} from 'react'
import {useMutation, useQueryClient} from "react-query";
import {Controller, useForm} from "react-hook-form";
import {storeLookupGateway, updateLookupGateway} from "../../../api";
import TextField from "@material-ui/core/TextField";
import ReactHookFormSelect from "../../../components/form/ReactHookFormSelect";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import AlertToastr from "../../../Utils/alert";
import IOSSwitch from "../../../components/IOSSwitch";


const SmsLookupFormComponents = ({handleClose, defaultValues}) => {
    const queryClient = useQueryClient();
    const {control, handleSubmit, reset, watch} = useForm({
        mode: "onTouched",
        defaultValues: defaultValues ? defaultValues : {},
    });

    const storeMutation = useMutation(storeLookupGateway);
    const updateMutation = useMutation(updateLookupGateway);

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    const onSubmit = handleSubmit(async (data) => {
        const newData = {
            name: data.name,
            type: data.type,
            is_force: data.is_force,
            params: {
                auth_token: data.auth_token,
                sys_id: data.sys_id,
                api_key: data.api_key
            }
        }
        if (defaultValues) {
            updateMutation.mutateAsync({id: defaultValues.id, ...newData}).then(() => {
                onSuccessSubmit();
            }).catch(error => {
                if (error && error.response && error.response.data && error.response.data.errors) {
                    AlertToastr.showErrorAlert(error.response.data.errors)
                }
            })
        } else {
            await storeMutation.mutateAsync(newData).then(() => {
                onSuccessSubmit();
            }).catch(error => {
                if (error && error.response && error.response.data && error.response.data.errors) {
                    AlertToastr.showErrorAlert(error.response.data.errors)
                }
            })
        }
    });

    const onSuccessSubmit = () => {
        reset();
        handleClose();
        queryClient.invalidateQueries("smsLookupGateways").then();
    }

    const type = watch('type', defaultValues ? defaultValues.type : null);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ReactHookFormSelect
                variant="outlined"
                margin="normal"
                id="gateway-select"
                name="type"
                label="Gateway"
                control={control}
                defaultValue=""
                disabled={!!defaultValues}
            >
                <MenuItem value="" disabled>
                    Select Gateway
                </MenuItem>
                <MenuItem value={"gateway1"}>
                    Gateway 1
                </MenuItem>
                <MenuItem value={"subscriber_verify"}>
                    Subscriber Verify
                </MenuItem>
            </ReactHookFormSelect>
            <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({onChange, value}) => {
                    return (
                        <TextField
                            onChange={onChange}
                            value={value}
                            variant="outlined"
                            margin="normal"
                            label="Name"
                        />
                    );
                }}
            />

            <div className="align-center wrapper content-justified mt15">
                <div style={{fontWeight: "600", fontSize: "14px"}}>Force Lookup(relookup all lead numbers without
                    service info)
                </div>
                <Controller
                    name="is_force"
                    control={control}
                    defaultValue={false}
                    render={({onChange, value}) => (
                        <IOSSwitch
                            color="primary"
                            checked={value}
                            onChange={(e) => onChange(e.target.checked)}
                        />
                    )}
                />
            </div>

            {type === 'gateway1' &&
                <>
                    <Controller
                        name="sys_id"
                        control={control}
                        defaultValue=""
                        render={({onChange, value}) => {
                            return (
                                <TextField
                                    onChange={onChange}
                                    value={value}
                                    variant="outlined"
                                    margin="normal"
                                    label="ID"
                                />
                            );
                        }}
                    />
                    <Controller
                        name="auth_token"
                        control={control}
                        defaultValue=""
                        render={({onChange, value}) => {
                            return (
                                <TextField
                                    onChange={onChange}
                                    value={value}
                                    variant="outlined"
                                    margin="normal"
                                    label="Token"
                                />
                            );
                        }}
                    />
                </>
            }

            {type === 'subscriber_verify' &&
                <>
                    <Controller
                        name="api_key"
                        control={control}
                        defaultValue=""
                        render={({onChange, value}) => {
                            return (
                                <TextField
                                    onChange={onChange}
                                    value={value}
                                    variant="outlined"
                                    margin="normal"
                                    label="Api Key"
                                />
                            );
                        }}
                    />
                </>
            }

            <div className="btnHolder hasPadTop twoBtn">
                <div className="part">
                    <Button
                        disabled={storeMutation.isLoading || updateMutation.isLoading}
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                        onClick={(e) => {
                        }}
                    >
                        Save
                    </Button>
                </div>
                <div className="part">
                    <Button
                        disabled={storeMutation.isLoading || updateMutation.isLoading}
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </form>
    );
}

export default SmsLookupFormComponents
