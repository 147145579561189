import React, { Fragment, useEffect, useState } from "react"
import { useAppDispatch } from "../../Contexts/AppContext"
import { useSegmentState } from "../../Contexts/SegmentContext"
import { useQuery, useQueryClient } from "react-query"
import { getSegment } from "../../api"
import { useParams } from "react-router-dom"
import SegmentLeads from "../../components/SegmentLeads"
import SegmentLeadsPageActions from "./SegmentLeadsPageActions"
import SegmentFilter from "../../components/SegmentFilter/SegmentFilter"
import SegmentFilterWrapper from "../../components/segmentFilterNew/SegmentFilterWrapper";

const SubsSegmentViewPage = () => {
    const queryClient = useQueryClient()
    const {subsegmentId} = useParams()
    const {setBreadcrumbs, setSection} = useAppDispatch()
    const {segment, segmentRequest} = useSegmentState()
    const [subSegment, setSubSegment] = useState()

    const [search, setSearch] = useState("")
    const [sorting, setSorting] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [visibleColumns, setVisibleColumns] = useState([])

    const subSegmentRequest = useQuery(
        ["subSegmentView", subsegmentId],
        () => getSegment(subsegmentId),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (subSegmentRequest.isSuccess) {
            setSubSegment(subSegmentRequest.data.data)
        }
    }, [
        subSegmentRequest.isSuccess,
        subSegmentRequest.data
    ])


    useEffect(() => {
        setBreadcrumbs([
            {label: "Audiences", link: `./`},
            {
                label: segment && segment.name,
                link: `./audiences/${segment?.id}/leads`,
                loading: segmentRequest.isLoading
            },
            {
                label: "Segments",
                link: `./audiences/${segment?.id}/segments`,
                loading: segmentRequest.isLoading
            },
            {
                label: subSegment && subSegment.name,
                loading: subSegmentRequest.isLoading
            },
        ])
        setSection("segments")
    }, [
        setBreadcrumbs,
        setSection,
        segment,
        segmentRequest.isLoading,
        subSegment,
        subSegmentRequest.isLoading
    ])

    const onSegmentUpdated = async () => {
        await queryClient.invalidateQueries(["segmentLeads", subsegmentId])
    }

    return (
        <Fragment>
            {/*<AudienceHeader />*/}
            {/*{subSegment && (*/}
            {/*    <SegmentFilter*/}
            {/*        segment={subSegment}*/}
            {/*        loading={subSegmentRequest.isLoading}*/}
            {/*        onSegmentUpdated={onSegmentUpdated}*/}
            {/*    />*/}
            {/*)}*/}
            {
                subSegment &&
                <SegmentFilterWrapper
                    segment={subSegment}
                    onSegmentUpdated={onSegmentUpdated}
                />
                // <SegmentFilterWrapper>
                //     <SegmentFilterNew
                //         segment={subSegment}
                //         // normalizedFilters={normalizeSegmentFilters}
                //         onSegmentUpdated={onSegmentUpdated}
                //     />
                // </SegmentFilterWrapper>
            }
            <SegmentLeads
                segmentId={subsegmentId}
                setSelectedRows={setSelectedRows}
                onSearch={setSearch}
                onSorting={setSorting}
                setVisibleColumns={setVisibleColumns}
            />
            <SegmentLeadsPageActions
                segment={subSegment}
                loading={subSegmentRequest.isLoading}
                search={search}
                sorting={sorting}
                selectedRows={selectedRows}
                visibleColumns={visibleColumns}
                entityTitle="Segment"
            />
        </Fragment>
    )
}

export default SubsSegmentViewPage
