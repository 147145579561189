import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react'
import {useAppDispatch, useAppState} from '../../Contexts/AppContext'
import PlusCircle from '../../assets/img/plus_circle.svg'
import EventEmitter from '../../Utils/EventEmitter'
import Run from '../../assets/img/caret.svg'
import LookupSaveModal from '../../components/modals/LookupSaveModal'
import {useMutation, useQueryClient} from 'react-query'
import {updateLookup, sourcesListLookup} from '../../api'
import AlertToastr from '../../Utils/alert'
import {useTranslation} from "react-i18next";
import EmailLookupConfirmModal from "../../components/modals/lookups/EmailLookupConfirmModal";
import SmsLookupConfirmModal from "../../components/modals/lookups/SmsLookupConfirmModal";

const LookupListActions = props => {
    const {t} = useTranslation();
    const {
        selectedRows,
        selectedModels,
        userGateways,
        statusOptions = []
    } = props
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [bulkRunIsLoading, setBulkRunIsLoading] = useState(false)
    const [showLookupModalEmail, setShowLookupModalEmail] = useState(false)
    const [showLookupModalSMS, setShowLookupModalSMS] = useState(false)

    const {availableSources} = useAppState()
    const {setContextMenu} = useAppDispatch()
    const queryClient = useQueryClient()

    const updateMutation = useMutation(updateLookup)
    const lookupMutation = useMutation(sourcesListLookup)

    const disableRunLookup = useMemo(() => {
        if (!selectedModels || !selectedModels.length) return true

        return !selectedModels.filter(item => item.status !== 1).length
    }, [selectedModels])

    useEffect(() => {
        const items = [
            {
                label: t('Lookup.Add new Lookup'),
                icon: PlusCircle,
                action: () => EventEmitter.emit('LookupAddNewSource'),
            },
            {
                label: t('Lookup.Run Lookup'),
                icon: Run,
                disabled: disableRunLookup || bulkRunIsLoading,
                actionSMS: () => EventEmitter.emit('RunLookupSMS'),
                actionEmail: () => EventEmitter.emit('RunLookupEmail'),
                iconStyle: {transform: 'rotate(-90deg)'}
            },
        ]
        setContextMenu(items)
    }, [
        selectedRows,
        setContextMenu,
        disableRunLookup,
        bulkRunIsLoading,
        t
    ])

    useEffect(() => {
        const onEvent = () => setShowSaveModal(true)

        const listener = EventEmitter.addListener('LookupAddNewSource', onEvent)
        return () => {
            listener.remove()
        }
    }, [])


    const runLookups = useCallback(async () => {
        setBulkRunIsLoading(true)
        await Promise.all(selectedModels.filter(item => item.status !== 1).map(item => {
            return updateMutation.mutateAsync({
                ...item, status: 1
            })
        }))
        AlertToastr.showAlert(t('Lookup.Selected lookups have been started'))
        queryClient.invalidateQueries('lookupList').then()
        setBulkRunIsLoading(false)
    }, [selectedModels, updateMutation, setBulkRunIsLoading, queryClient, t])

    useEffect(() => {
        const onEvent = () => runLookups()

        const listener = EventEmitter.addListener('LookupBulkRun', onEvent)
        return () => {
            listener.remove()
        }
    }, [runLookups])

    useEffect(() => {
        const onEvent = () => setShowLookupModalEmail(true)

        const listener = EventEmitter.addListener('RunLookupEmail', onEvent)
        return () => {
            listener.remove()
        }
    }, [lookupMutation, selectedRows])
    useEffect(() => {
        const onEvent = () => setShowLookupModalSMS(true)

        const listener = EventEmitter.addListener('RunLookupSMS', onEvent)
        return () => {
            listener.remove()
        }
    }, [lookupMutation, selectedRows])
    const lookupItemsSMS = async (gatewayId) => {
        const data = {
            ids: [...selectedRows],
            type: 1,
            setting_gateway_id: gatewayId
        }
        await lookupMutation.mutateAsync(data)
        setShowLookupModalSMS(false)
        AlertToastr.showAlert('Lookup has been started')
    }
    const lookupItemsEmail = (gatewayId) => {
        const data = {
            ids: [...selectedRows],
            type: 2,
            setting_gateway_id: gatewayId,
        }
        lookupMutation.mutate(data)
        setShowLookupModalEmail(false)
        AlertToastr.showAlert('Lookup has been started')
    }

    return (
        <Fragment>
            <LookupSaveModal
                open={showSaveModal}
                handleClose={() => setShowSaveModal(false)}
                sources={availableSources}
                userGateways={userGateways}
                statusOptions={statusOptions}
            />
            <SmsLookupConfirmModal
                loading={lookupMutation.isLoading}
                message='Do you want to lookup sms leads in selected sources?'
                open={showLookupModalSMS}
                handleClose={(e) => setShowLookupModalSMS(false)}
                handleConfirm={(gatewayId) => {
                    lookupItemsSMS(gatewayId)
                }}
            />
            <EmailLookupConfirmModal
                open={showLookupModalEmail}
                handleClose={(e) => setShowLookupModalEmail(false)}
                handleConfirm={(gatewayId) => {
                    lookupItemsEmail(gatewayId)
                }}
                loading={lookupMutation.isLoading}
                message='Do you want to lookup email leads in selected lists?'
            >
            </EmailLookupConfirmModal>

        </Fragment>
    )
}

export default LookupListActions
