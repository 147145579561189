import React, {useEffect} from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

const CampaignFilterType = ({ filterTypeParams, setFilterTypeParams }) => {
    useEffect(() => {
        setFilterTypeParams(getTypesFromStorage());
    }, []);

    const storeTypes = (data) => {
        window.localStorage.setItem("campaigns.search.types", JSON.stringify(data))
    }

    const getTypesFromStorage = () => {
        const json = window.localStorage.getItem("campaigns.search.types");

        if (!json) {
            return [];
        }

        return JSON.parse(json);
    };

    const handleChangeSelect = ({ target }) => {
        const { value } = target;
        setFilterTypeParams(value);
        storeTypes(value);
    };

    return (
            <div className="inputHolder">
                <FormControl variant="outlined" margin="none">
                    <InputLabel id="search_column_value" shrink>Select Types</InputLabel>
                    <Select
                        multiple
                        value={filterTypeParams}
                        onChange={handleChangeSelect}
                        style={{minWidth: 200}}
                        autoWidth
                    >
                        <MenuItem disabled>SMS</MenuItem>
                        <MenuItem value="sms">Sms Bulk</MenuItem>
                        <MenuItem value="automation">Sms Trigger</MenuItem>
                        <MenuItem disabled>Email</MenuItem>
                        <MenuItem value="email">Email Bulk</MenuItem>
                        <MenuItem value="email_automation">Email Trigger</MenuItem>
                        <MenuItem value="archived">Archived</MenuItem>
                    </Select>
                </FormControl>
            </div>
    );
};

export default CampaignFilterType;
