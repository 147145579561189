import Types from './types'

export const setSource = source => ({
    type: Types.SET_SOURCE,
    payload: source
})

export const setList = list => ({
    type: Types.SET_LIST,
    payload: list
})

export const setLead = lead => ({
    type: Types.SET_LEAD,
    payload: lead
})

export const setSources = sources => ({
    type: Types.SET_SOURCES,
    payload: sources
})

export const addSource = source => ({
    type: Types.ADD_SOURCE,
    payload: source
})

export const setLists = lists => ({
    type: Types.SET_LISTS,
    payload: lists
})

export const addList = list => ({
    type: Types.ADD_LIST,
    payload: list
})

export const setLeads = leads => ({
    type: Types.SET_LEADS,
    payload: leads
})

export const setRunDataUpdate = runDataUpdate => ({
    type: Types.SET_RUN_DATA_UPDATE,
    payload: runDataUpdate
})

export const setNewImport = newImport => ({
    type: Types.SET_NEW_IMPORT,
    payload: newImport
})

export const setLeadColumns = leadColumns => ({
    type: Types.SET_LEAD_COLUMNS,
    payload: leadColumns
})
