import React, { useCallback, useState, useEffect } from "react"
import Dashboard from "../assets/img/dashboard_02.svg"
import Camp from "../assets/img/camp.svg"
import IdCard from "../assets/img/id_card.svg"
import SettingsIcon from "../assets/img/settings.svg"
import APIDocumentationIcon from "../assets/img/api_documentation.svg"
import IcoData from "../assets/img/data.svg"
import { useQuery } from "react-query"
import {getConfig, getSourcesWithLists} from "../api"
import { useAuth } from "./AuthProvider"

const AppStateContext = React.createContext(undefined)
const AppDispatchContext = React.createContext(undefined)

const AppProvider = ({children}) => {

    const {userName} = useAuth()
    const [config, setConfig] = useState({})
    const [section, setSection] = useState(null)
    const [breadcrumbs, setBreadcrumbs] = useState([])
    const [mainMenu, setMainMenu] = useState([
        {label: "Audiences", link: "./", icon: Dashboard},
        {label: "Leads", link: "./leads", icon: IdCard},
        {label: "Data", link: "./sources", icon: IcoData},
        {label: "Campaigns", link: "./campaigns", icon: Camp},
        {label: "Settings", icon: SettingsIcon, link: "./settings"},
        {label: "API Documentation", icon: APIDocumentationIcon, link: "./doc"}
    ])
    const [contextMenu, setContextMenu] = useState([])
    const [contextLinks, setContextLinks] = useState([])
    const [exportFiles, setExportFiles] = useState({})
    const [visibleLeadsColumns, setVisibleLeadsColumns] = useState([])
    const [availableSources, setAvailableSources] = useState([])

    const [lookupDone, setLookupDone] = useState(false)
    const [lookupPlay, setLookupPlay] = useState(false)
    const [lookupInfo, setLookupInfo] = useState({count: 0, is_paid: false})

    const [warnings, setWarnings] = useState({})

    const configRequest = useQuery(
        ["generalConfig"],
        () => getConfig(),
        {keepPreviousData: true, enabled: !!userName}
    )

    useEffect(() => {
        if (configRequest.isSuccess) {
            setConfig(configRequest.data)
        }
    }, [configRequest.isSuccess, configRequest.data])

    const availableSourcesRequest = useQuery(
        ["availableSources"],
        () => getSourcesWithLists(),
        {keepPreviousData: true, enabled: !!userName}
    )


    useEffect(() => {
        if (availableSourcesRequest.isSuccess) {
            setAvailableSources(availableSourcesRequest.data.data)
        }
    }, [
        availableSourcesRequest.isSuccess, availableSourcesRequest.data
    ])

    const addExportFile = useCallback(data => {
        setExportFiles(files => {
            const newFiles = {...files}
            newFiles[data["segment_id"]] = data
            return newFiles
        })
    }, [])

    const removeExportFile = useCallback(segmentId => {
        setExportFiles(files => {
            const newFiles = {...files}
            delete newFiles[segmentId]
            return newFiles
        })
    }, [])

    return (
        <AppStateContext.Provider value={{
            breadcrumbs,
            contextMenu,
            contextLinks,
            mainMenu,
            section,
            visibleLeadsColumns,
            exportFiles,
            config,
            configIsLoading: configRequest.isLoading,
            availableSources,
            lookupDone,
            lookupInfo,
            lookupPlay,
            warnings,
        }}>
            <AppDispatchContext.Provider value={{
                setBreadcrumbs,
                setContextMenu,
                setContextLinks,
                setMainMenu,
                setSection,
                setVisibleLeadsColumns,
                addExportFile,
                removeExportFile,
                setLookupDone,
                setLookupInfo,
                setLookupPlay,
                setWarnings,
            }}>
                {children}
            </AppDispatchContext.Provider>
        </AppStateContext.Provider>
    )
}

const useAppState = () => {
    const context = React.useContext(AppStateContext)
    if (context === undefined) {
        throw new Error("useAppState must be used within a AppProvider")
    }
    return context
}

const useAppDispatch = () => {
    const context = React.useContext(AppDispatchContext)
    if (context === undefined) {
        throw new Error("useAppDispatch must be used within a AppProvider")
    }
    return context
}

export {
    AppProvider,
    useAppState,
    useAppDispatch
}
