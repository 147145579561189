import React, {useEffect, useMemo, useState} from "react"
import SmsList from "./connections/SmsList";
import EmailList from "./connections/EmailList";
import PlusCircle from "../../assets/img/plus_circle.svg";
import EventEmitter from "../../Utils/EventEmitter";
import {useAppDispatch, useAppState} from "../../Contexts/AppContext";
import AddIntegrationModal from "./connections/AddIntegrationModal";
import EmailLookupList from "./connections/EmailLookupList";
import SmsLookupList from "./connections/SmsLookupList";
import SmsDNCList from "./connections/SmsDCNList";

const Integrations = () => {
    const {setBreadcrumbs, setSection} = useAppDispatch()
    const {setContextMenu} = useAppDispatch()
    const {configIsLoading} = useAppState()
    const [showCreateConnectionModal, setShowCreateConnectionModal] = useState(false);

    useEffect(() => {
        setContextMenu([
            {
                label: "Add Integration",
                icon: PlusCircle,
                action: () => EventEmitter.emit("IntegrationCreate"),
                disabled: configIsLoading
            },
        ])
    }, [setContextMenu, configIsLoading])

    useEffect(() => {
        setBreadcrumbs([
            {label: "Settings", link: "./settings"},
            {label: "Integrations"},
        ])
        setSection("settings")
    }, [setBreadcrumbs, setSection])

    useEffect(() => {
        const onEvent = () => setShowCreateConnectionModal(true)
        const listener = EventEmitter.addListener("IntegrationCreate", onEvent)
        return () => {
            listener.remove()
        }
    }, [])


    return <div>
        <SmsList></SmsList>
        <EmailList></EmailList>
        <EmailLookupList></EmailLookupList>
        <SmsLookupList></SmsLookupList>
        <SmsDNCList></SmsDNCList>
        <AddIntegrationModal
            showModal={showCreateConnectionModal}
            handleClose={()=> {setShowCreateConnectionModal(false)}}
            integrationModel={null}
        >
        </AddIntegrationModal>
    </div>
}

export default Integrations
