import React, {Fragment, useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import AlertToastr from "../../../../Utils/alert";
import {useMutation, useQuery} from "react-query";
import {getActiveEmailLookupGateways, relookupLead} from "../../../../api";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {Controller, useForm} from "react-hook-form";
import {useParams} from "react-router-dom";

const RelookupEmailLeadModal = ({open, handleClose}) => {

    const {leadId} = useParams();
    const relookupLeadMutation = useMutation(relookupLead);
    const [emailLookupGateways, setEmailLookupGateways] = useState([])

    const emailLookupGatewaysRequest = useQuery(
        ['activeEmailLookupGateways'],
        () => getActiveEmailLookupGateways(),
        {keepPreviousData: true}
    )

    const {control, watch} = useForm();

    const lookup_gateway_id = watch('lookup_gateway_id');


    useEffect(() => {
        if (emailLookupGatewaysRequest.isSuccess) {
            setEmailLookupGateways(emailLookupGatewaysRequest.data.data)
        }
    }, [emailLookupGatewaysRequest.isSuccess, emailLookupGatewaysRequest.data])

    const lookupItem = async () => {
        const data = {
            id: leadId,
            type: "lead_email",
            lookup_gateway_id: lookup_gateway_id,
        }

        await relookupLeadMutation.mutateAsync(data)
            .then(() => {
                AlertToastr.showAlert('Lookup has been started')
                handleClose();
            })
            .catch(error => {
                if (error && error.response && error.response.data && error.response.data.errors) {
                    AlertToastr.showErrorAlert(error.response.data.errors)
                }
            })
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className='modal'
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>Do you want to relookup email lead?</h2>
                    </div>
                    <Controller
                        name={'lookup_gateway_id'}
                        defaultValue=""
                        control={control}
                        render={({onChange, value}) => {
                            return (
                                <TextField
                                    select
                                    onChange={onChange}
                                    value={value}
                                    variant="outlined"
                                    margin="normal"
                                    label="Email Lookup Gateway"
                                >
                                    <MenuItem value="" disabled>
                                        Select gateway
                                    </MenuItem>
                                    {emailLookupGateways.map((gateway) => {
                                        return <MenuItem key={gateway.id} value={gateway.id}>
                                            {gateway.name}
                                        </MenuItem>
                                    })}
                                </TextField>
                            );
                        }}
                    />
                    <div className="btnHolder hasPadTop twoBtn">
                        <div className="part">
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={lookupItem}
                            >
                                Yes
                            </Button>
                        </div>
                        <div className="part">
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default RelookupEmailLeadModal
