import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {useAppDispatch} from '../../Contexts/AppContext'
import EventEmitter from '../../Utils/EventEmitter'
import PlusCircle from '../../assets/img/plus_circle.svg'
import Copy from '../../assets/img/copy.svg'
import LayersAlt from '../../assets/img/layers_alt.svg'
import SaveCampaignModal from '../../components/modals/SaveCampaignModal'
import Delete from '../../assets/img/delete.svg'
import {useMutation, useQueryClient} from 'react-query'
import DeleteModal from '../../components/modals/DeleteModal'
import {
    archiveCampaigns,
    copyCampaigns,
    removeCampaigns,
    removeFromArchiveCampaigns,
    updateCampaignsStatus
} from '../../api'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import Settings from '../../assets/img/settings.svg'
import {campaignStatusContextMenu} from '../../helpers'
import AlertToastr from '../../Utils/alert'
import ConfirmModal from "../../components/modals/ConfirmModal";
import SetClickTriggerToCampaignsModal from "../../components/modals/SetClickTriggerToCampaignsModal";

const CampaignsActions = ({selectedRows}) => {

    const {setContextMenu} = useAppDispatch()
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showArchivedModal, setShowArchivedModal] = useState(false)
    const [showRemoveFromArchiveModal, setShowRemoveFromArchivedModal] = useState(false)
    const [showSetClickTriggerToStepsModal, setShowSetClickTriggerToStepsModal] = useState(false)
    const [showCopyCampaignsModal, setShowCopyCampaignsModal] = useState(false)
    const queryClient = useQueryClient()
    const campaignsRemoveRequest = useMutation(removeCampaigns)
    const campaignsArchiveRequest = useMutation(archiveCampaigns)
    const removeFromArchiveCampaignsRequest = useMutation(removeFromArchiveCampaigns)
    const updateCampaignsStatusMutation = useMutation(updateCampaignsStatus)
    const copyCampaignRequest = useMutation(copyCampaigns);


    useEffect(() => {
        if (updateCampaignsStatusMutation.error) {
            AlertToastr.showErrorResponseAlert(updateCampaignsStatusMutation.error)
        }
    }, [updateCampaignsStatusMutation.error])

    useEffect(() => {
        const items = [
            {
                label: 'Add New Campaign',
                icon: PlusCircle,
                action: () => EventEmitter.emit('CampaignCreate'),
            },
            {
                label: 'Copy Campaigns',
                icon: Copy,
                disabled: !selectedRows.length,
                action: () => EventEmitter.emit('CopyCampaigns')
            },
            {
                label: 'Delete Selection',
                icon: Delete,
                disabled: !selectedRows.length,
                action: () => EventEmitter.emit('CampaignBulkDeletion'),
                iconStyle: {transform: 'rotate(45deg)'}
            },
            {
                label: updateCampaignsStatusMutation.isLoading ? 'Updating...' : 'Set status',
                icon: Settings,
                disabled: !selectedRows.length || updateCampaignsStatusMutation.isLoading,
                iconStyle: {transform: 'rotate(45deg)'},
                items: campaignStatusContextMenu()
            },
            {
                label: 'Add to archive',
                icon: LayersAlt,
                disabled: !selectedRows.length,
                action: () => EventEmitter.emit('CampaignBulkArchived'),
                iconStyle: {transform: 'rotate(45deg)'}
            },
            {
                label: 'Remove from archive',
                icon: LayersAlt,
                disabled: !selectedRows.length,
                action: () => EventEmitter.emit('CampaignBulkRemoveFromArchive'),
                iconStyle: {transform: 'rotate(45deg)'}
            },
            {
                label: 'Set click trigger to steps  ',
                icon: LayersAlt,
                disabled: !selectedRows.length,
                action: () => EventEmitter.emit('CampaignBulkSetClickTriggerToSteps'),
                iconStyle: {transform: 'rotate(45deg)'}
            },
        ]
        setContextMenu(items)
    }, [setContextMenu, selectedRows, updateCampaignsStatusMutation.isLoading])

    const updateItemsStatus = useCallback(async status => {
        const response = await updateCampaignsStatusMutation.mutateAsync({ids: selectedRows, status})
        const {info, errors} = response
        if (info && info.length) {
            info.forEach(message => AlertToastr.showAlert(message))
        }
        if (errors && errors.length) {
            errors.forEach(message => AlertToastr.showErrorAlert(message))
        }

        queryClient.invalidateQueries('campaignsList').then()
    }, [queryClient, selectedRows, updateCampaignsStatusMutation])

    useEffect(() => {
        const onEvent = () => setShowSaveModal(true)

        const listener = EventEmitter.addListener('CampaignCreate', onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowCopyCampaignsModal(true)

        const listener = EventEmitter.addListener('CopyCampaigns', onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowDeleteModal(true)

        const listener = EventEmitter.addListener('CampaignBulkDeletion', onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowArchivedModal(true)

        const listener = EventEmitter.addListener('CampaignBulkArchived', onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowRemoveFromArchivedModal(true)

        const listener = EventEmitter.addListener('CampaignBulkRemoveFromArchive', onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowSetClickTriggerToStepsModal(true)

        const listener = EventEmitter.addListener('CampaignBulkSetClickTriggerToSteps', onEvent)
        return () => {
            listener.remove()
        }
    }, [])


    useEffect(() => {
        const onEvent = (status) => updateItemsStatus(status)

        const listener = EventEmitter.addListener('CampaignsBulkChangeStatus', onEvent)
        return () => {
            listener.remove()
        }
    }, [updateItemsStatus])

    const deleteItems = async () => {
        setShowDeleteModal(false)
        await campaignsRemoveRequest.mutateAsync(selectedRows)
        await queryClient.invalidateQueries('campaignsList')
    }

    const archiveItems = async () => {
        setShowArchivedModal(false)
        await campaignsArchiveRequest.mutateAsync(selectedRows)
        await queryClient.invalidateQueries('campaignsList')
    }

    const removeFromArchiveItems = async () => {
        setShowRemoveFromArchivedModal(false)
        await removeFromArchiveCampaignsRequest.mutateAsync(selectedRows)
        await queryClient.invalidateQueries('campaignsList')
    }

    const copyItems = async () => {
        setShowCopyCampaignsModal(false)
        await copyCampaignRequest.mutateAsync(selectedRows)
        await queryClient.invalidateQueries('campaignsList')
    }

    return (
        <Fragment>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <SaveCampaignModal
                    open={showSaveModal}
                    handleClose={(event) => {
                        setShowSaveModal(false)
                    }}
                />
            </MuiPickersUtilsProvider>
            <ConfirmModal
                open={showCopyCampaignsModal}
                handleClose={() => setShowCopyCampaignsModal(false)}
                message="Do you want to copy selected campaigns?"
                handleConfirm={copyItems}
            >
            </ConfirmModal>
            <DeleteModal
                open={showDeleteModal}
                handleDelete={deleteItems}
                handleClose={(e) => setShowDeleteModal(false)}
                instance='selected campaigns'/>
            <ConfirmModal
                open={showArchivedModal}
                handleClose={() => setShowArchivedModal(false)}
                message="Do you want to archive selected campaigns?"
                handleConfirm={archiveItems}
            >
            </ConfirmModal>
            <ConfirmModal
                open={showRemoveFromArchiveModal}
                handleClose={() => setShowRemoveFromArchivedModal(false)}
                message="Do you want to remove from archive selected campaigns?"
                handleConfirm={removeFromArchiveItems}
            >
            </ConfirmModal>
            <SetClickTriggerToCampaignsModal
                open={showSetClickTriggerToStepsModal}
                handleClose={() => setShowSetClickTriggerToStepsModal(false)}
                campaigns={selectedRows}
            />
        </Fragment>
    )
}

export default CampaignsActions
