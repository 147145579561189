import React, { useEffect, useMemo, useState } from 'react';
import { Link, Route, Routes } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ImportHistory from "../ImportHistory"
import IgnorePhoneFormat from "../IgnorePhoneFormat"
import Keywords from "../Keywords"
import SystemAttributes from "../SystemAttributes"
import ShortUrlDomains from "../ShortUrlDomains"
import LookupManagement from "../LookupManagement"
import ImportFile from "../ImportFile"
import {useLocation} from "react-router-dom"
import ImportApiKeys from "./ImportApiKeys";
import CampaignRoutes from "../CampaignRoutes";
import ExportFiles from "../ExportFiles";
import RevenueTracking from "./RevenueTracking";
import Integrations from "./Integrations";
import SpamEmailGroups from "../SpamEmailGroups";
import SenderEmailGroups from "../SenderEmailGroups";
import Rules from "../Rules";
import CampaignPools from "../CampaignPools";

const LinkTab = (props) => <Tab component={Link} {...props} />

const Settings = () => {
    const { pathname } = useLocation()

    const indexToTabName = useMemo(() => {
        return {
            // ["/"]: 0,
            ["/spam-email-groups"]: 1,
            ["/sender-email-groups"]: 2,
            ["/import-history"]: 3,
            ["/ignore-phone-format"]: 4,
            ["/rules"]: 5,
            ["/keywords"]: 6,
            ["/lookup-management"]: 7,
            ["/short-url-domains"]: 8,
            ["/integrations"]: 9,
            ["/revenue-tracking"]: 10,
            ["/import-api-keys"]: 11,
            ["/campaign-routes"]: 12,
            ["/campaign-pools"]: 13,
            ["/export-files"]: 14,
        }
    }, []);

    const [value, setValue] = useState(() => {

        for (let key in indexToTabName) {
            if (pathname.endsWith(key)) {
                return indexToTabName[key]
            }
        }

        return 0;
    });

    useEffect(() => {
        if (value !== 0 && (pathname.endsWith("settings/") || pathname.endsWith("settings"))) {
            setValue(0);
        }
    }, [pathname]);

    const handleTabChange = (value) => {
        setValue(value)
    };

    return (
        <div className="pageHolder">
            <Tabs
                value={value}
                onChange={(_, value) => handleTabChange(value)}
                indicatorColor="primary"
                className="pageTabs"
                variant="scrollable"
                scrollButtons="auto"
            >
                <LinkTab label="System Attributes" to="./" disableRipple/>
                <LinkTab label="Spam Emails" to="./spam-email-groups" disableRipple/>
                <LinkTab label="Sender Email Groups" to="./sender-email-groups" disableRipple/>
                <LinkTab label="Import History" to="./import-history" disableRipple/>
                <LinkTab label="Ignore Phone Format" to="./ignore-phone-format" disableRipple/>
                <LinkTab label="Rules" to="./rules" disableRipple/>
                <LinkTab label="Keywords" to="./keywords" disableRipple/>
                <LinkTab label="Lookup Management" to="./lookup-management" disableRipple/>
                <LinkTab label="Short Url Domains" to="./short-url-domains" disableRipple/>
                <LinkTab label="Integrations" to="./integrations" disableRipple/>
                <LinkTab label="Revenue Tracking" to="./revenue-tracking" disableRipple/>
                <LinkTab label="Import API Keys" to="./import-api-keys" disableRipple/>
                <LinkTab label="Campaign Routes" to="./campaign-routes" disableRipple/>
                <LinkTab label="Campaign Pools" to="./campaign-pools" disableRipple/>
                <LinkTab label="Export files" to="./export-files" disableRipple/>
            </Tabs>
            <Routes>
                <Route index element={<SystemAttributes />} />
                <Route path="ignore-phone-format" element={<IgnorePhoneFormat />}/>
                <Route path="rules" element={<Rules />}/>
                <Route path="spam-email-groups" element={<SpamEmailGroups />}/>
                <Route path="sender-email-groups" element={<SenderEmailGroups />}/>
                <Route path="keywords" element={<Keywords />}/>
                <Route path="lookup-management" element={<LookupManagement />}/>
                <Route path="short-url-domains" element={<ShortUrlDomains />}/>
                <Route path="integrations" element={<Integrations />}/>
                <Route path="revenue-tracking" element={<RevenueTracking />}/>
                <Route path="import-api-keys" element={<ImportApiKeys />}/>
                <Route path="campaign-routes" element={<CampaignRoutes />}/>
                <Route path="campaign-pools" element={<CampaignPools />}/>
                <Route path="export-files" element={<ExportFiles />}/>
                <Route path="import-history" element={<ImportHistory />}/>
                <Route path="import-history/:fileId" element={<ImportFile />}/>
            </Routes>
        </div>
    )
}

export default Settings
