import React, { useEffect, useMemo, useState } from "react";
import { Routes, Link, Route, useLocation } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { useAppDispatch, useAppState } from "../../Contexts/AppContext";
import { useCampaignState } from "../../Contexts/CampaignContext";
import InlineLoader from "../../components/loaders/InlineLoader";
import CampaignStart from "./CampaignStart";
import CampaignLeads from "./CampaignLeads";
import CampaignStatistics from "./CampaignStatistics";
import CampaignLogs from "./CampaignLogs";
import CampaignActions from "./CampaignActions";
import CampaignMessageLogs from "./CampaignMessageLogs";
import CampaignClicks from "./CampaignClicks";
import CampaignOpenAt from "./CampaignOpenAt";
import CampaignIncomingMessages from "./CampaignIncomingMessages";
import AlertToastr from "../../Utils/alert";
import { updateCampaign } from "../../api";
import CampaignQueuedLogs from "./CampaignQueuedLogs";
import CampaignSkippedLogs from "./CampaignSkippedLogs";
import CampaignRedirects from "./CampaignRedirects";
import CampaignWelcomeMessages from "./CampaignWelcomeMessages";

const CampaignStatusMenu = ({campaign}) => {
    const queryClient = useQueryClient()
    const {config} = useAppState()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const [localStatus, setLocalStatus] = useState(() => campaign && campaign.status)
    const allCampaignStatuses = useMemo(() => {
        return config["campaign_status_list"] || []
    }, [config])
    const campaignStatuses = useMemo(() => {
        return config["campaign_manual_status_list"] || []
    }, [config])
    const campaignVisibleStatuses = useMemo(() => {
        const list = campaignStatuses

        return localStatus ? list.filter(item => item.key !== localStatus) : list
    }, [campaignStatuses, localStatus])
    const localStatusLabel = useMemo(() => {
        const statusModel = allCampaignStatuses.find(item => item.key === localStatus)
        return statusModel ? statusModel.label : ""
    }, [localStatus, allCampaignStatuses])

    const updateCampaignMutation = useMutation(updateCampaign)

    useEffect(() => {
        if (campaign) setLocalStatus(campaign.status)
    }, [campaign])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }


    const handleClose = () => {
        setAnchorEl(null)
    }

    const setStatus = async (key, label) => {
        setAnchorEl(null)
        setLocalStatus(key)
        await handleChangeStatus(key)
    }

    const handleChangeStatus = async (status) => {
        if (campaign) {
            try {
                await updateCampaignMutation.mutateAsync({
                    id: campaign.id,
                    status
                })
                queryClient.invalidateQueries("campaignView").then()
                queryClient.invalidateQueries("campaignsList").then()
            } catch (error) {
                AlertToastr.showErrorResponseAlert(error)
                setLocalStatus(campaign.status)
            }
        }
    }


    return (
        <div className="campaignStatus">
            {updateCampaignMutation.isLoading ? <InlineLoader/> : (
                localStatusLabel && <Chip
                    className={`tagLikeChip ${localStatus}`}
                    onClick={handleClick}
                    label={localStatusLabel}
                    style={{margin: "0"}}
                />
            )}

            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                {
                    campaignVisibleStatuses.map(item => (
                        <MenuItem key={item.key} onClick={() => setStatus(item.key, item.label)}>{item.label}</MenuItem>
                    ))
                }
            </Menu>
        </div>
    )
}

const LinkTab = (props) => <Tab component={Link} {...props} />

const Campaign = () => {
    const { campaign, campaignRequest } = useCampaignState()
    const { setBreadcrumbs, setSection } = useAppDispatch()
    const { pathname } = useLocation()

    const indexToTabName = useMemo(() => {
        return {
            ["/"]: 0,
            ["/leads"]: 1,
            ["/statistics"]: 2,
            ["/logs"]: 3,
            ["/message-logs"]: 4,
            ["/queued-logs"]: 5,
            ["/skipped-logs"]: 6,
            ["/incoming-messages"]: 7,
            ["/welcome-messages"]: 8,
            ["/clicks"]: 9,
            ["/redirects"]: 10,
            ["/open-at"]: 11,
        }
    }, []);

    const [value, setValue] = useState(() => {
        for (let key in indexToTabName) {
            if (pathname.endsWith(key)) {
                return indexToTabName[key]
            }
        }

        return 0;
    })

    const handleTabChange = (value) => {
        setValue(value)
    }

    useEffect(() => {
        setBreadcrumbs([
            {label: "Campaigns", link: "./campaigns"},
            {label: campaign && campaign.name, loading: campaignRequest.isLoading}
        ])
        setSection("campaigns")
    }, [setBreadcrumbs, setSection, campaign, campaignRequest.isLoading])

    return (
        <div className="pageHolder">
            <div className="tabsWithStatusHolder">
                <Tabs
                    style={{flexGrow: 1}}
                    value={value}
                    onChange={(_, value) => handleTabChange(value)}
                    indicatorColor="primary"
                    variant="scrollable"
                    className="pageTabs biggerPad"
                >
                    <LinkTab label="Campaign" to="./" disableRipple/>
                    <LinkTab label="Leads" to="./leads" disableRipple/>
                    <LinkTab label="Statistics" to="./statistics" disableRipple/>
                    <LinkTab label="Logs" to="./logs" disableRipple/>
                    <LinkTab label="Message Logs" to="./message-logs" disableRipple/>
                    <LinkTab label="Queued Logs" to="./queued-logs" disableRipple/>
                    <LinkTab label="Skipped Logs" to="./skipped-logs" disableRipple/>
                    <LinkTab label="Incoming Messages" to="./incoming-messages" disableRipple/>
                    <LinkTab label="Welcome Messages" to="./welcome-messages" disableRipple/>
                    <LinkTab label="Clicks" to="./clicks" disableRipple/>
                    <LinkTab label="Redirects" to="./redirects" disableRipple/>
                    {(campaign?.type === "email" || campaign?.type === "email_automation") &&
                        <LinkTab label="Open at" to="./open-at" disableRipple/>}
                </Tabs>
                {campaign && <CampaignStatusMenu campaign={campaign}/>}
            </div>

            <Routes>
                <Route index element={<CampaignStart />}/>
                <Route path="leads" element={<CampaignLeads />}/>
                <Route path="statistics" element={<CampaignStatistics />}/>
                <Route path="logs" element={<CampaignLogs />}/>
                <Route path="message-logs" element={<CampaignMessageLogs />}/>
                <Route path="queued-logs" element={<CampaignQueuedLogs />}/>
                <Route path="skipped-logs" element={<CampaignSkippedLogs />}/>
                <Route path="incoming-messages" element={<CampaignIncomingMessages />}/>
                <Route path="welcome-messages" element={<CampaignWelcomeMessages />}/>
                <Route path="clicks" element={<CampaignClicks />}/>
                <Route path="redirects" element={<CampaignRedirects />}/>
                <Route path="open-at" element={<CampaignOpenAt />}/>
            </Routes>
            <CampaignActions
                campaign={campaign}
                loading={campaignRequest.isLoading}
            />
        </div>
    )
}

export default Campaign;
