import React, {Fragment, useEffect, useState} from 'react'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getClickTriggerCampaigns, setClickTriggerToCampaigns} from "../../api";
import MenuItem from "@material-ui/core/MenuItem";
import ReactHookFormSelect from "../form/ReactHookFormSelect";
import {useForm} from "react-hook-form";

const SetClickTriggerToCampaignsModal = props => {

    const queryClient = useQueryClient()
    const {open, handleClose, campaigns} = props

    const [clickTriggerCampaigns, setClickTriggerCampaigns] = useState([])

    const clickTriggerCampaignsRequest = useQuery(
        ['clickTriggerCampaigns'],
        () => getClickTriggerCampaigns(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (clickTriggerCampaignsRequest.isSuccess) {
            setClickTriggerCampaigns(clickTriggerCampaignsRequest.data.data)
        }
    }, [clickTriggerCampaignsRequest.isSuccess, clickTriggerCampaignsRequest.data])

    const {
        control,
        watch,
    } = useForm({
        mode: 'onTouched',
    })

    const setClickTriggerToCampaignsRequest = useMutation(setClickTriggerToCampaigns)
    const clickAutomationCampaignId = watch('click_automation_campaign_id');

    const setClickTriggerToCampaignsHandler = async () => {
        await setClickTriggerToCampaignsRequest.mutateAsync({
            campaigns,
            click_automation_campaign_id: clickAutomationCampaignId
        })
        await queryClient.invalidateQueries('campaignsList')
        handleClose(false)
    }


    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className='modal'
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>Do you want to set click trigger to campaigns?</h2>
                    </div>
                    <ReactHookFormSelect
                        disabled={false}
                        variant="outlined"
                        margin="dense"
                        id="type-select"
                        name="click_automation_campaign_id"
                        label="Click Automation Campaign ID"
                        defaultValue={''}
                        control={control}
                    >
                        <MenuItem value=""></MenuItem>
                        {clickTriggerCampaigns.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </ReactHookFormSelect>
                    <div className="btnHolder hasPadTop twoBtn">
                        <div className="part">
                            <Button
                                disabled={false}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={setClickTriggerToCampaignsHandler}
                            >
                                Save
                            </Button>
                        </div>
                        <div className="part">
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default SetClickTriggerToCampaignsModal
