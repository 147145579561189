import React from 'react'

const TableEmptyListMessage = ({children}) => {
    return (
        <tr>
            <td colSpan="100%">
                <p className="text-center" style={{paddingTop: '15px', color: 'rgb(144 144 144)'}}>{ children || 'No records found'}</p>
            </td>
        </tr>
    )
}

export default TableEmptyListMessage
