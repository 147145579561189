import React, { Fragment, useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import DataService from '../../Services/DataService'
import AlertToastr from '../../Utils/alert'
import { useQueryClient } from 'react-query'

const statusOptions = [
    {
        value: 'active',
        label: 'Active'
    },
    {
        value: 'inactive',
        label: 'Inactive'
    }
]

const SaveListModal = ({open, handleClose, sourceId, list}) => {

    const [name, setName] = useState('')
    const [status, setStatus] = useState('active')
    const [disableButtons, setDisabledButtons] = useState(false)
    const queryClient = useQueryClient()

    useEffect(() => {
        if (list) {
            setName(list.name)
            setStatus(list.status)
        } else {
            setName('')
            setStatus('active')
        }
    }, [list])

    const handleCreate = () => {
        setDisabledButtons(true)
        DataService.createList(sourceId, name, status).then(response => {
            queryClient.invalidateQueries('sourceLists').then()
            queryClient.invalidateQueries('availableSources').then()
            setName('')
            setDisabledButtons(false)
            handleClose()
        })
            .catch((error) => {
                setDisabledButtons(false)
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors)
            })
    }

    const handleSave = () => {
        setDisabledButtons(true)
        DataService.updateList(list.id, name, status).then(response => {
            queryClient.invalidateQueries('sourceLists').then()
            queryClient.invalidateQueries('listView').then()
            queryClient.invalidateQueries('availableSources').then()
            setName('')
            setDisabledButtons(false)
            handleClose()
        })
            .catch((error) => {
                setDisabledButtons(false)
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors)
            })
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className='modal'
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>{list ? 'Edit' : 'Create'} List</h2>
                    </div>
                    <form noValidate autoComplete="off">
                        <TextField
                            value={name}
                            onChange={e => setName(e.target.value)}
                            id="source"
                            label="Name"
                            variant="outlined"
                            margin='normal'
                        />
                        <TextField
                            value={status}
                            onChange={e => setStatus(e.target.value)}
                            id="status"
                            label="Status"
                            variant="outlined"
                            margin='normal'
                            select
                        >
                            {statusOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <div className="btnHolder hasPadTop twoBtn">
                            <div className="part">
                                <Button
                                    disabled={!!disableButtons}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={(e) => {
                                        list ? handleSave() : handleCreate()
                                    }}
                                >
                                    {list ? 'Save' : 'Create'}
                                </Button>
                            </div>
                            <div className="part">
                                <Button
                                    disabled={!!disableButtons}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default SaveListModal

