import React from 'react'

const Row = ({label, value, hide = false, valueIsGray = false}) => {
    if (!value || !label || !!hide) {
        return null
    }

    return (
        <div className="sliBodyHolder">
            <div className="caption">
                {label}
            </div>
            <div className='qnt' style={!valueIsGray ? {color: '#000'} : {}}>
                {`${value}`}
            </div>
        </div>
    )
}

export default Row