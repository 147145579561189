import React from 'react'
import { campaignStatusClassMap, snakeToTitle } from '../helpers'

const CampaignStatus = ({status}) => {

    const statusMap = campaignStatusClassMap()

    return (
        <div className={`chipLike ${statusMap[status]}`}>
            <span className='chipLikeLabel'>{snakeToTitle(status)}</span>
        </div>
    )
}

export default CampaignStatus
