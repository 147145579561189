import React from 'react';
import PageLoader from './loaders/PageLoader';

const DetailsAttrsTable = ({ title, children, isLoading }) => {

    return (
        <div className="leadDetailsHolder mb20">
            {title && (
                <h2>{title}</h2>
            )}
            <div className="leadDetails">
                <div className="leadDetailsTableLike">
                    {isLoading ? <PageLoader /> : <div className="tableLike">{children}</div>}
                </div>
            </div>
        </div>
    );
};

export default DetailsAttrsTable;
