import React from 'react'
import ReactHookFormSelect from "../../../components/form/ReactHookFormSelect";
import MenuItem from "@material-ui/core/MenuItem";
import {useForm} from "react-hook-form";
import Dialog from "@material-ui/core/Dialog";
import SmsFormComponents from "./SmsFormComponents";
import EmailFormComponents from "./EmailFormComponents";
import EmailLookupFormComponents from "./EmailLookupFormComponents";
import SmsLookupFormComponents from "./SmsLookupFormComponents";
import SmsDNCFormComponents from "./SmsDNCFormComponents";


const AddIntegrationModal = ({showModal, handleClose, integrationModel}) => {
    const {control, watch} = useForm({
        mode: "onTouched"
    });

    const type = watch('connection_type', integrationModel ? integrationModel.connection_type : null);

    return <Dialog open={showModal} onClose={handleClose} className="modal">
        <div className="modalBody">
            <div className="modalTitleHolder mb30">
                <h2 className="mb15">
                    {integrationModel ? "Edit Integration" : "Add Integration"}
                </h2>
            </div>
            <ReactHookFormSelect
                variant="outlined"
                margin="normal"
                id="type-select"
                name="connection_type"
                label="Type"
                control={control}
                defaultValue={integrationModel ? integrationModel.connection_type : ""}
                disabled={!!integrationModel}
            >
                <MenuItem value="sms">SMS</MenuItem>
                <MenuItem value="sms_lookup">SMS Lookup</MenuItem>
                <MenuItem value="sms_dnc">SMS DNC</MenuItem>
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="email_lookup">Email Lookup</MenuItem>
            </ReactHookFormSelect>

            {type === 'sms' &&
                <SmsFormComponents
                    handleClose={handleClose}
                    defaultValues={integrationModel}
                >
                </SmsFormComponents>
            }

            {type === 'email' &&
                <EmailFormComponents
                    handleClose={handleClose}
                    defaultValues={integrationModel}
                >
                </EmailFormComponents>
            }
            {type === 'email_lookup' &&
                <EmailLookupFormComponents
                    handleClose={handleClose}
                    defaultValues={integrationModel}
                >
                </EmailLookupFormComponents>
            }
            {type === 'sms_lookup' &&
                <SmsLookupFormComponents
                    handleClose={handleClose}
                    defaultValues={integrationModel}
                >
                </SmsLookupFormComponents>
            }

            {type === 'sms_dnc' &&
                <SmsDNCFormComponents
                    handleClose={handleClose}
                    defaultValues={integrationModel}
                >
                </SmsDNCFormComponents>
            }
        </div>
    </Dialog>
}

export default AddIntegrationModal
