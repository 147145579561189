import React, {useEffect, useState, Fragment} from "react";
import {useForm, Controller} from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Dialog from "@material-ui/core/Dialog";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
    storeKeywords,
    updateKeywords,
    getSMSGateways,
    getKeywordTriggerCampaigns
} from "../../api";
import AlertToastr from "../../Utils/alert";
import SmsCounter from "./SmsCounter";
import MenuItem from "@material-ui/core/MenuItem";
import ReactHookFormSelect from "../../components/form/ReactHookFormSelect";

const SaveKeywordModal = (props) => {
    const {open, handleClose, defaultValues} = props;

    const [userGateways, setUserGateways] = useState([]);
    const [phones, setPhones] = useState(
        defaultValues ? [...defaultValues.phones] : []
    );
    const [items, setItems] = useState(
        defaultValues ? [...defaultValues.values] : []
    );

    const [keywordAutomations, setKeywordAutomations] = useState([])


    const queryClient = useQueryClient();

    const userGatewaysRequest = useQuery(
        ["smsGateways"],
        () => getSMSGateways(),
        {keepPreviousData: true}
    );
    const {control, handleSubmit, reset, watch} = useForm({
        mode: "onTouched",
        defaultValues,
    });
    const gateway = watch("gateway_id");

    const storeMutation = useMutation(storeKeywords);
    const updateMutation = useMutation(updateKeywords);

    useEffect(() => {
        if (userGatewaysRequest.isSuccess) {
            const {data} = userGatewaysRequest.data;
            setUserGateways(data);
        }
    }, [userGatewaysRequest.isSuccess, userGatewaysRequest.data]);

    const keywordAutomationsRequest = useQuery(
        ['keywordsTriggerCampaigns'],
        () => getKeywordTriggerCampaigns(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (keywordAutomationsRequest.isSuccess) {
            setKeywordAutomations(keywordAutomationsRequest.data.data)
        }
    }, [keywordAutomationsRequest.isSuccess, keywordAutomationsRequest.data])

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    useEffect(() => {
        if (storeMutation.error) {
            AlertToastr.showErrorResponseAlert(storeMutation.error);
        }
    }, [storeMutation.error]);

    useEffect(() => {
        if (updateMutation.error) {
            AlertToastr.showErrorResponseAlert(updateMutation.error);
        }
    }, [updateMutation.error]);

    const handleAddValue = (event) => {
        let value = event.target.value.trim();

        if (
            ((event.type === "keydown" && event.code === "Enter") ||
                event.type === "blur") &&
            value
        ) {
            addValue(value);
            event.target.value = "";
        }
    };
    const addValue = (value) => {
        setItems([...items, value]);
    };

    const handleRemoveValue = (index) => {
        const newItemValues = [...items];
        newItemValues.splice(index, 1);
        setItems(newItemValues);
    };

    const onSubmit = handleSubmit(async (data) => {
        const copyData = {...data, values: [...items], phones: [...phones]};
        try {
            if (defaultValues) {
                await updateMutation.mutateAsync({id: defaultValues.id, copyData});
            } else {
                await storeMutation.mutateAsync(copyData);
            }
        } catch (e) {
            return
        }

        reset();
        setPhones([]);
        setItems([]);
        handleClose();
        queryClient.invalidateQueries("get-keywords").then();
    });
    const handleChangePhones = (event) => {
        setPhones(event.target.value);
    };
    return (
        <Dialog
            open={open}
            onClose={() => {
                setPhones([]);
                setItems([]);
                handleClose();
            }}
            className="modal"
        >
            <div className="modalBody">
                <div className="modalTitleHolder mb30">
                    <h2 className="mb15">
                        {defaultValues ? "Edit Keyword" : "Add Keyword"}
                    </h2>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {items.map((item, index) => {
                        return (
                            <Chip
                                key={index}
                                label={item}
                                onDelete={() => handleRemoveValue(index)}
                                style={{margin: "0 5px 10px 0"}}
                            />
                        );
                    })}
                    <TextField
                        variant="outlined"
                        placeholder="Enter keywords"
                        margin="none"
                        onKeyDown={handleAddValue}
                        onBlur={handleAddValue}
                    />
                    <ReactHookFormSelect
                        variant="outlined"
                        margin="normal"
                        id="gateway-select"
                        name="gateway_id"
                        label="Gateway"
                        control={control}
                        defaultValue={defaultValues ? defaultValues.gateway.id : ""}
                        onChange={(e) => {
                            console.log(e);
                        }}
                    >
                        {userGateways?.map((gateway) => {
                            return <MenuItem
                                key={gateway.id}
                                value={gateway.id}>
                                {gateway.name}
                            </MenuItem>;
                        })}
                    </ReactHookFormSelect>
                    <FormControl fullWidth>
                        <InputLabel variant="outlined">Phone Numbers</InputLabel>
                        <Select
                            variant="outlined"
                            value={phones}
                            id="gateway-select"
                            onChange={handleChangePhones}
                            label="Phone Numbers"
                            multiple
                        >
                            <MenuItem value={"*"}>All Phones</MenuItem>
                            {userGateways
                                .find((e) => e.id === gateway)
                                ?.phone_numbers.map((number) => {
                                    return (
                                        <MenuItem
                                            key={number.key}
                                            value={number.key}>
                                            {number.label}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                    <Controller
                        name="response_message"
                        control={control}
                        defaultValue=""
                        render={({onChange, onBlur, value}) => (
                            <>
                                <TextField
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value || ""}
                                    label="Response Message"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    margin="normal"
                                />
                                <div style={{position: "relative"}}>
                                    <SmsCounter
                                        text={value}
                                        shortUrlDomainName={{text: value}}
                                        styleParam={{
                                            position: "absolute",
                                            top: -28,
                                            right: 15,
                                            left: "initial",
                                        }}
                                    />
                                </div>
                            </>
                        )}
                    />

                    <ReactHookFormSelect
                        variant="outlined"
                        margin="normal"
                        id="action-select"
                        name="action"
                        label="Action"
                        control={control}
                        defaultValue=""
                    >
                        <MenuItem value=''>None</MenuItem>
                        <MenuItem value="unsubscribe">Unsubscribe</MenuItem>
                        <MenuItem value="subscribe">Subscribe</MenuItem>
                        <MenuItem value="blacklist">Blacklist</MenuItem>
                        <MenuItem value="flagged">Flagged</MenuItem>
                        <MenuItem value="imported">Imported</MenuItem>
                    </ReactHookFormSelect>

                    <ReactHookFormSelect
                        disabled={false}
                        variant="outlined"
                        margin="dense"
                        id="type-select"
                        name="automation_campaign_id"
                        label="Automation Campaign ID"
                        control={control}
                        defaultValue={defaultValues ? defaultValues.automation_campaign_id : ''}
                    >
                        <MenuItem value=""></MenuItem>
                        {keywordAutomations.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </ReactHookFormSelect>

                    <div className="btnHolder hasPadTop twoBtn">
                        <div className="part">
                            <Button
                                disabled={storeMutation.isLoading || updateMutation.isLoading}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleSubmit(onSubmit)}
                            >
                                {defaultValues ? "Edit" : "Create"}
                            </Button>
                        </div>
                        <div className="part">
                            <Button
                                disabled={storeMutation.isLoading || updateMutation.isLoading}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </Dialog>
    );
};

export default SaveKeywordModal;
