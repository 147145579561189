import React, {useEffect, useState, Fragment} from 'react'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import File from '../../assets/img/file_blank.svg'
import Button from '@material-ui/core/Button'
import ImportProgressModal from './ImportProgressModal'
import DataService from '../../Services/DataService'
import AlertToastr from '../../Utils/alert'
import IOSSwitch from '../IOSSwitch'
import {useMutation, useQuery} from 'react-query'
import {
    getActiveEmailLookupGateways,
    getActiveSmsDNCGateways,
    getActiveSmsLookupGateways,
    updateImportFileLeadStatuses
} from '../../api'
import ReactHookFormSelect from "../form/ReactHookFormSelect";
import {Controller, useForm} from "react-hook-form";
import {emailStatusesOptions, leadStatusesOptions, phoneStatusesOptions} from "../../config";

const ignoreOption = {
    id: 'ignore',
    name: 'Ignore'
}

const newAttrOption = {
    id: 'new',
    name: 'New'
}

const MatchFormatModal = ({open, handleClose, importInfo, filename, handleBack, mappingInfo, setNewImport}) => {
    const [attributeList, setAttributeList] = useState([])
    const [importModelList, setImportModelList] = useState([])
    const [attributesCount, setAttributesCount] = useState(0)
    const [importProgressOpen, setImportProgressOpen] = useState(false)
    const [isImportStarted, setIsImportStarted] = useState(false)
    const [checkMobileStatus, setCheckMobileStatus] = useState(false)
    const [checkSmsDNC, setCheckSmsDNC] = useState(false)
    const [checkEmailStatus, setCheckEmailStatus] = useState(false)

    const updateImportFileLeadStatusesMutation = useMutation(updateImportFileLeadStatuses)

    const {
        control,
        watch
    } = useForm({
        mode: 'onTouched',
    });

    const emailStatus = watch('email_status');
    const phoneStatus = watch('phone_status');
    const leadStatus = watch('lead_status');
    const emailLookupGatewayId = watch('email_lookup_gateway_id');
    const smsLookupGatewayId = watch('sms_lookup_gateway_id');
    const smsDNCGatewayId = watch('sms_dnc_gateway_id');

    useEffect(() => {
        async function fetchAttributes() {
            let response = await DataService.getAllLeadsAttributes()
            if (!response || !response.data || !response.data.data)
                return
            const defaultAttributes = [ignoreOption, newAttrOption]
            const attributes = defaultAttributes.concat(response.data.data.map((attr) => ({
                id: attr.id,
                name: attr.name
            })))
            setAttributesCount(response.data.data.length)
            if (!importInfo || !importInfo.attributes)
                return
            let sortedAttributes = [...importInfo.attributes]
            sortedAttributes.sort((a, b) => {
                if (a.col_position < b.col_position) {
                    return -1
                }
                if (a.col_position > b.col_position) {
                    return 1
                }
                return 0
            })
            const model = sortedAttributes.map((attr) => {
                const mappingAttr = mappingInfo.find(mapInfo => mapInfo.attribute_id === attr.id)
                let boundAttr = null
                if (mappingAttr)
                    boundAttr = attributes.find(attribute => attribute.id === mappingAttr.lead_attrbiute.id)
                return {
                    fileHeader: attr,
                    boundAttribute: boundAttr ? boundAttr : ignoreOption,
                    newAttributeName: attr.name
                }
            })
            setImportModelList(model)
            setAttributeList(attributes)
        }

        if (importInfo && mappingInfo)
            fetchAttributes().then()
    }, [importInfo, mappingInfo])

    const [emailLookupGateways, setEmailLookupGateways] = useState([])

    const emailLookupGatewaysRequest = useQuery(
        ['emailLookupGateways'],
        () => getActiveEmailLookupGateways(),
        {keepPreviousData: true}
    )


    useEffect(() => {
        if (emailLookupGatewaysRequest.isSuccess) {
            setEmailLookupGateways(emailLookupGatewaysRequest.data.data)
        }
    }, [emailLookupGatewaysRequest.isSuccess, emailLookupGatewaysRequest.data])

    const [smsLookupGateways, setSmsLookupGateways] = useState([])
    const [smsDNCGateways, setSmsDNCGateways] = useState([])

    const smsLookupGatewaysRequest = useQuery(
        ['smsLookupGateways'],
        () => getActiveSmsLookupGateways(),
        {keepPreviousData: true}
    )


    useEffect(() => {
        if (smsLookupGatewaysRequest.isSuccess) {
            setSmsLookupGateways(smsLookupGatewaysRequest.data.data)
        }
    }, [smsLookupGatewaysRequest.isSuccess, smsLookupGatewaysRequest.data])

    const smsDNCGatewaysRequest = useQuery(
        ['smsDNCGateways'],
        () => getActiveSmsDNCGateways(),
        {keepPreviousData: true}
    )


    useEffect(() => {
        if (smsDNCGatewaysRequest.isSuccess) {
            setSmsDNCGateways(smsDNCGatewaysRequest.data.data)
        }
    }, [smsDNCGatewaysRequest.isSuccess, smsDNCGatewaysRequest.data])

    const handleImport = async () => {
        setIsImportStarted(true)
        const newAttributesList = []
        let syncAttrList = []
        let position = attributesCount
        importModelList.forEach((importModel) => {
            if (importModel.boundAttribute.id === 'ignore') {
                return
            }
            const attribute = attributeList.find(a => a.name === importModel.newAttributeName)
            if (importModel.boundAttribute.id === 'new' && !attribute) {
                newAttributesList.push({sort_order: position, name: importModel.newAttributeName})
                position = position + 1
                syncAttrList.push({
                    processing_document_attribute_id: importModel.fileHeader.id,
                    lead_attribute_id: null,
                    leadName: importModel.newAttributeName
                })
            } else {
                syncAttrList.push({
                    processing_document_attribute_id: importModel.fileHeader.id,
                    lead_attribute_id: importModel.boundAttribute.id === 'new' ? attribute.id : importModel.boundAttribute.id
                })
            }
        })
        try {
            if (newAttributesList.length > 0) {
                let response = await DataService.createLeadsAttributes(newAttributesList)
                syncAttrList = syncAttrList.map((attr) => {
                    if (!attr.lead_attribute_id) {
                        const id = response.data.data.find(x => x.name === attr.leadName).id
                        return {
                            processing_document_attribute_id: attr.processing_document_attribute_id,
                            lead_attribute_id: id
                        }
                    } else {
                        return attr
                    }
                })
            }
            DataService.syncImportAttributes(
                importInfo.id,
                syncAttrList,
                checkMobileStatus,
                checkEmailStatus,
                emailLookupGatewayId,
                smsLookupGatewayId,
                checkSmsDNC,
                smsDNCGatewayId
            ).then(res => {
                DataService.runImport(importInfo.id).then(response => {
                    setNewImport({
                        fileId: importInfo.id,
                        status: 'Running',
                        filename: filename,
                        parsedLeadsCount: 0
                    })
                    AlertToastr.showAlert(`File ${filename} import started!`)
                    closeModal()
                })
                    .catch((error) => {
                        setIsImportStarted(false)
                        if (error && error.response && error.response.data && error.response.data.message)
                            AlertToastr.showErrorAlert(error.response.data.message)
                    })
            }).catch((error) => {
                setIsImportStarted(false)
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message)
            })

        } catch (error) {
            setIsImportStarted(false)
            if (error && error.response && error.response.data && error.response.data.errors)
                AlertToastr.showErrorAlert(error.response.data.errors)
        }
    }

    const onClose = () => {
        const model = importModelList.map((attr) => {
            const mappingAttr = mappingInfo.find(mapInfo => mapInfo.attribute_id === attr.fileHeader.id)
            let boundAttr = null
            if (mappingAttr)
                boundAttr = attributeList.find(attribute => attribute.id === mappingAttr.lead_attrbiute.id)
            return {
                ...attr,
                boundAttribute: boundAttr ? boundAttr : ignoreOption
            }
        })
        setImportModelList(model)
        setIsImportStarted(false)
        handleBack()
    }

    const closeModal = () => {
        setIsImportStarted(false)
        handleClose()
    }

    useEffect(async () => {
        if (!importInfo) {
            return;
        }

        setIsImportStarted(true);
        await updateImportFileLeadStatusesMutation.mutateAsync({
            id: importInfo.id,
            email_status: emailStatus ? parseInt(emailStatus) : null,
            phone_status: phoneStatus ? parseInt(phoneStatus) : null,
            lead_status: leadStatus ? parseInt(leadStatus) : null
        }).then(() => {
            setIsImportStarted(false)

        }).catch((error) => {
            setIsImportStarted(false)
            if (error && error.response && error.response.data && error.response.data.errors)
                AlertToastr.showErrorAlert(error.response.data.errors)
        })
    }, [emailStatus, phoneStatus, leadStatus]);

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={closeModal}
                className='modal'
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <ol className="breadcrumbs mb15">
                            <li className='breadcrumbsItem' onClick={(e) => onClose()}><h2><span>Import File</span></h2>
                            </li>
                            <li className='breadcrumbsItem active'><h2>Match Format</h2></li>
                        </ol>
                    </div>
                    <form noValidate autoComplete="off">
                        <ul className="fileList">
                            <li className="fileListItem">
                                <div className="icoHolder">
                                    <img src={File} alt=""/>
                                </div>
                                <div className="captionHolder">
                                    <div className="name text-mute text-semi-bold">{filename}</div>
                                    {/* <div className="qnt qnt text-light text-mute">380 items</div> */}
                                </div>
                            </li>
                        </ul>
                        <div className="align-center wrapper content-justified mt15">
                            <div style={{fontWeight: '600', fontSize: '14px'}}>Lookup phone numbers</div>

                            <IOSSwitch
                                color="primary"
                                checked={!!checkMobileStatus}
                                onChange={() => {
                                    setCheckMobileStatus(s => !s)
                                }}
                            />
                        </div>
                        {checkMobileStatus && <Controller
                            name={'sms_lookup_gateway_id'}
                            control={control}
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (
                                    <TextField
                                        select
                                        onChange={onChange}
                                        value={value}
                                        variant="outlined"
                                        margin="normal"
                                        label="Sms Lookup Gateway"
                                    >
                                        <MenuItem value="" disabled>
                                            Select gateway
                                        </MenuItem>
                                        {smsLookupGateways.map((gateway) => {
                                            return <MenuItem key={gateway.id} value={gateway.id}>
                                                {gateway.name}
                                            </MenuItem>
                                        })}
                                    </TextField>
                                );
                            }}
                        />
                        }
                        <div className="align-center wrapper content-justified mt15">
                            <div style={{fontWeight: '600', fontSize: '14px'}}>SMS DNC</div>

                            <IOSSwitch
                                color="primary"
                                checked={!!checkSmsDNC}
                                onChange={() => {
                                    setCheckSmsDNC(s => !s)
                                }}
                            />
                        </div>

                        {checkSmsDNC && <Controller
                            name={'sms_dnc_gateway_id'}
                            control={control}
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (
                                    <TextField
                                        select
                                        onChange={onChange}
                                        value={value}
                                        variant="outlined"
                                        margin="normal"
                                        label="Sms DNC Gateway"
                                    >
                                        <MenuItem value="" disabled>
                                            Select gateway
                                        </MenuItem>
                                        {smsDNCGateways.map((gateway) => {
                                            return <MenuItem key={gateway.id} value={gateway.id}>
                                                {gateway.name}
                                            </MenuItem>
                                        })}
                                    </TextField>
                                );
                            }}
                        />
                        }

                        <div className="align-center wrapper content-justified mt15">
                            <div style={{fontWeight: '600', fontSize: '14px'}}>Lookup emails</div>

                            <IOSSwitch
                                color="primary"
                                checked={!!checkEmailStatus}
                                onChange={() => {
                                    setCheckEmailStatus(s => !s)
                                }}
                            />
                        </div>

                        {checkEmailStatus && <Controller
                            name={'email_lookup_gateway_id'}
                            control={control}
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (
                                    <TextField
                                        select
                                        onChange={onChange}
                                        value={value}
                                        variant="outlined"
                                        margin="normal"
                                        label="Email Lookup Gateway"
                                    >
                                        <MenuItem value="" disabled>
                                            Select gateway
                                        </MenuItem>
                                        {emailLookupGateways.map((gateway) => {
                                            return <MenuItem key={gateway.id} value={gateway.id}>
                                                {gateway.name}
                                            </MenuItem>
                                        })}
                                    </TextField>
                                );
                            }}
                        />
                        }

                        <ReactHookFormSelect
                            disabled={false}
                            variant="outlined"
                            margin="normal"
                            id="type-select"
                            name="lead_status"
                            label="Lead Status"
                            control={control}
                            defaultValue={''}
                        >
                            {
                                leadStatusesOptions.map((status) => {
                                    return <MenuItem key={status.id} value={status.id}>
                                        {status.name}
                                    </MenuItem>
                                })
                            }

                        </ReactHookFormSelect>

                        <ReactHookFormSelect
                            disabled={false}
                            variant="outlined"
                            margin="normal"
                            id="type-select"
                            name="email_status"
                            label="Email Status"
                            control={control}
                        >
                            {
                                emailStatusesOptions.map((status) => {
                                    return <MenuItem key={status.id} value={status.id}>
                                        {status.name}
                                    </MenuItem>
                                })
                            }
                        </ReactHookFormSelect>

                        <ReactHookFormSelect
                            disabled={false}
                            variant="outlined"
                            margin="normal"
                            id="type-select"
                            name="phone_status"
                            label="Phone Status"
                            control={control}
                        >
                            {
                                phoneStatusesOptions.map((status) => {
                                    return <MenuItem key={status.id} value={status.id}>
                                        {status.name}
                                    </MenuItem>
                                })
                            }
                        </ReactHookFormSelect>
                        <div className="divider"/>
                        <div className="formatTableHolder">
                            <table className="formatTable">
                                <thead>
                                <tr>
                                    <th>CSV Headings</th>
                                    <th>Fields</th>
                                </tr>
                                </thead>
                                <tbody>
                                {importModelList.map((attribute) => {
                                    return (
                                        <tr key={attribute.fileHeader.id}>
                                            <td>{attribute.fileHeader.name}</td>
                                            <td>
                                                {attribute.boundAttribute.id !== 'new' &&
                                                    <TextField
                                                        disabled={isImportStarted}
                                                        value={attribute.boundAttribute}
                                                        onChange={(e) => setImportModelList(models => {
                                                            models.find(value => value.fileHeader.id === attribute.fileHeader.id).boundAttribute = e.target.value
                                                            return [...models]
                                                        })}
                                                        id="field"
                                                        variant="outlined"
                                                        select
                                                    >
                                                        {attributeList.map((option) => {
                                                            return <MenuItem key={option.id} value={option}>
                                                                {option.name}
                                                            </MenuItem>
                                                        })}
                                                    </TextField>}
                                                {attribute.boundAttribute.id === 'new' &&
                                                    <TextField
                                                        disabled={isImportStarted}
                                                        value={attribute.newAttributeName}
                                                        onChange={(e) => setImportModelList(models => {
                                                            models.find(value => value.fileHeader.id === attribute.fileHeader.id).newAttributeName = e.target.value
                                                            return [...models]
                                                        })}
                                                        id="field"
                                                        variant="outlined"
                                                    />}
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                                </tbody>
                            </table>
                        </div>
                        <div className="btnHolder hasPadTop twoBtn">
                            <div className="part">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    disabled={isImportStarted}
                                    onClick={handleImport}
                                >
                                    Import
                                </Button>
                            </div>
                            <div className="part">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    disabled={isImportStarted}
                                    onClick={closeModal}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
            <ImportProgressModal
                open={importProgressOpen}
                setOpen={setImportProgressOpen}
                importId={importInfo?.id}
                importSyncId={importInfo?.sync_import?.id}
                importFilename={filename}/>
        </Fragment>
    )
}

export default MatchFormatModal
