import {apiAuthBaseUrl, apiDataBaseUrl} from './config';
import axios from 'axios'
import Api from "./Services/Api.service";

export const fetchData = (url, params) => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    const queryParams = {...params}
    const {page, search, limit, order} = queryParams
    if (page !== undefined) {
        if (!page || page === 1) delete queryParams.page
    }
    if (search !== undefined) {
        if (!search) delete queryParams.search
    }
    if (limit !== undefined) {
        if (!limit) delete queryParams.limit
    }
    if (order !== undefined) {
        if (!order) {
            delete queryParams.order
        } else {
            let orderArray = Array.isArray(order) ? order : [order]
            queryParams.order = orderArray.map(orderItem => {
                const {key, direction} = orderItem
                return `${key},${direction}`
            })
        }
    }

    const promise = axios(`${apiDataBaseUrl}${url}`, {params: queryParams, cancelToken: source.token})
    promise.cancel = () => {
        source.cancel(`${url}was cancelled`)
    }
    return promise
}

export const getSourcesTotal = async () => {
    const {data} = await fetchData('/sources/total')
    return data
}

export const getCampaignStatisticsCounters = async () => {
    const {data} = await fetchData('/campaigns/statistics/counters')
    return data
}

export const getGatewaysTypes = async () => {
    const {data} = await fetchData('/config/email-gateway/setting')
    return data
}

export const getSources = async (page, limit, search, order) => {
    const {data} = await fetchData('/sources', {
        page,
        limit,
        search,
        order
    })
    return data
}

export const getSource = async (id) => {
    const {data} = await fetchData(`/sources/${id}`)
    return data
}

export const getSourceAggregations = (id) => {
    return fetchData(`/sources/${id}/aggregations`)
}

export const getList = async (id) => {
    const {data} = await fetchData(`/sources/lists/${id}`)
    return data
}

export const getListAggregations = (id) => {
    return fetchData(`/sources/lists/${id}/aggregations`)
}

export const getSourceLists = async (sourceId, page, limit, search, order) => {
    const {data} = await fetchData(`/sources/${sourceId}/lists`, {
        page,
        limit,
        search,
        order
    })
    return data
}

export const getSegments = async (page, limit, search, order) => {
    const {data} = await fetchData('/segments', {
        page,
        limit,
        search,
        order
    })
    return data
}

export const getSubsegments = async (segmentId, page, limit, search, order) => {
    const {data} = await fetchData(`/segments/${segmentId}/segments`, {
        page,
        limit,
        search,
        order
    })
    return data
}

export const getSegmentLeads = async (segmentId, page, limit, search, order) => {
    const {data} = await fetchData(`/segments/${segmentId}/leads`, {
        page,
        limit,
        search,
        order
    })
    return data
}

export const getListLeads = async (listId, page, limit, search, order) => {

    const {data} = await fetchData(`/lists/${listId}/leads`, {
        page,
        limit,
        search,
        order
    })
    return data
}

export const getLeadsList = (page, limit, search, order) => {
    return fetchData('/leads', {
        page,
        limit,
        search,
        order
    })
}

export const getImports = async (page, limit, order, search) => {
    const {data} = await fetchData('/imports', {
        page,
        limit,
        order,
        search
    })
    return data
}

export const getImportFile = async (id) => {
    const {data} = await fetchData(`/import-files/${id}`)
    return data
}

export const getImportFileHistory = async (fileId, page, limit, search, order) => {
    const {data} = await fetchData(`/import-files/${fileId}/history`, {
        page,
        limit,
        search,
        order
    })
    return data
}

export const getSegment = async (id) => {
    const {data} = await fetchData(`/segments/${id}`)
    return data
}

export const storeSegmentFilters = async ({id, filters}) => {
    const {data} = await axios.post(`${apiDataBaseUrl}/segments/${id}/filters`, filters)
    return data
}

export const getLead = async (id) => {
    const {data} = await fetchData(`/leads/${id}`)
    return data
}

export const removeLeads = async ids => {
    const {data} = await axios.delete(`${apiDataBaseUrl}/leads/batch`, {
        data: {ids}
    })
    return data
}

export const relookupLead = async obj => {
    const {data} = await axios.post(`${apiDataBaseUrl}/leads/` + obj.id + `/relookup`, {
        lead_type: obj.type,
        lookup_gateway_id: obj.lookup_gateway_id
    })

    return data
}

export const updateLeadsStatus = async ({ids, status}) => {
    const {data} = await axios.put(`${apiDataBaseUrl}/leads/batch-status`, {
        ids, status
    })
    return data
}

export const getLeadSegments = async (id) => {
    const {data} = await fetchData(`/leads/${id}/segments`)
    return data
}

export const getLeadSources = async (id) => {
    const {data} = await fetchData(`/leads/${id}/sources`)
    return data
}

export const getLeadCampaigns = async (id) => {
    const {data} = await fetchData(`/leads/${id}/campaigns`)
    return data
}

export const getLeadLogs = async (id, page, limit, filters) => {
    const params = {
        page, limit
    }

    Object.keys(filters).forEach(key => {
        if (filters[key]) {
            params[key] = filters[key]
        }
    })

    const {data} = await fetchData(`/leads/${id}/log`, params)
    return data
}

export const getConfig = async () => {
    const {data} = await fetchData('/config/general')
    return data
}

export const storeCampaign = async body => {
    const {data} = await axios.post(`${apiDataBaseUrl}/campaigns`, body)
    return data
}

export const getCampaigns = async (page, limit, search, types, period) => {
    const {data} = await fetchData('/campaigns', {
        page,
        limit,
        search,
        types,
        ...period
    });
    return data
}

export const getCompletedCampaigns = async () => {
    const {data} = await fetchData('/completed-campaigns')
    return data
}

export const getCampaignsListNames = async () => {
    const {data} = await fetchData('/campaigns/list-names')
    return data;
}

export const getCampaign = async (id) => {
    const {data} = await fetchData(`/campaigns/${id}`)
    return data
}

export const getCampaignStatistics = async (id) => {
    const {data} = await fetchData(`/campaigns/${id}/statistic`)
    return data
}

export const getCampaignStatisticsByStep = async (id) => {
    const {data} = await fetchData(`/campaigns/${id}/statistic-by-step`)
    return data
}

export const getCampaignStatisticsLeads = async (campaignId, page, limit, search, order, filterType, filters) => {
    const params = {
        page,
        limit,
        search,
        order,
        ...filters
    }
    if (filterType) {
        params['filter_type'] = filterType
    }

    const {data} = await fetchData(`/campaigns/${campaignId}/statistic-leads`, params)
    return data
}

export const getCampaignSegments = async (id) => {
    const {data} = await fetchData(`/campaigns/${id}/segments`)
    return data
}

export const getCampaignSources = async (id) => {
    const {data} = await fetchData(`/campaigns/${id}/sources`)
    return data
}

export const getCampaignLists = async (id) => {
    const {data} = await fetchData(`/campaigns/${id}/lists`)
    return data
}

export const getCampaignLogs = async (id, page, limit) => {
    const {data} = await fetchData(`/campaigns/${id}/campaign-logs`, {
        page, limit
    })
    return data
}

export const getCampaignMessageLogs = async (id, page, limit, order) => {
    const {data} = await fetchData(`/campaigns/${id}/message-logs`, {
        page, limit, order
    })
    return data
}

export const getCampaignQueuedMessageLogs = async (id, page, limit, order) => {
    const {data} = await fetchData(`/campaigns/${id}/queued-logs`, {
        page, limit, order
    })
    return data
}

export const getCampaignSkippedMessageLogs = async (id, page, limit, order) => {
    const {data} = await fetchData(`/campaigns/${id}/skipped-logs`, {
        page, limit, order
    })
    return data
}

export const getCampaignIncomingMessages = async (id, page, limit, order) => {
    const {data} = await fetchData(`/campaigns/${id}/incoming-messages`, {
        page, limit, order
    })
    return data
}

export const getCampaignWelcomeMessages = async (id, page, limit, order) => {
    const {data} = await fetchData(`/campaigns/${id}/welcome-messages`, {
        page, limit, order
    })
    return data
}

export const getCampaignClicks = async (id, page, limit, order, search) => {
    const {data} = await axios.get(`${apiDataBaseUrl}/campaigns/${id}/statistic/clicks`, {
        params: {
            page: page,
            limit: limit,
            order_column: order[0].key,
            order_direction: order[0].direction,
            search_column: search.column,
            search_value: search.value,
        }
    })
    return data
}
export const getCampaignRedirects = async (id, page, limit, order) => {
    const {data} = await fetchData(`/campaigns/${id}/redirect-logs`, {
        page, limit, order
    })
    return data
}

export const getCampaignOpenAt = async (id, page, limit, order, search) => {
    const {data} = await axios.get(`${apiDataBaseUrl}/campaigns/${id}/statistic/opens`, {
        params: {
            page: page,
            limit: limit,
            order_column: order[0].key,
            order_direction: order[0].direction,
            search_column: search.column,
            search_value: search.value,
        }
    })
    return data
}

export const getCampaignSteps = async (id) => {
    const {data} = await fetchData(`/campaigns/${id}/campaign-steps`)
    return data
}

export const storeCampaignSegments = async ({id, ...body}) => {
    const {data} = await axios.post(`${apiDataBaseUrl}/campaigns/${id}/segments`, body)
    return data
}

export const storeSegmentFromCampaign = async ({id, ...body}) => {
    const {data} = await axios.post(`${apiDataBaseUrl}/campaigns/${id}/create-segment`, body)
    return data
}

export const storeCampaignSources = async ({id, ...body}) => {
    const {data} = await axios.post(`${apiDataBaseUrl}/campaigns/${id}/sources`, body)
    return data
}

export const storeCampaignLists = async ({id, ...body}) => {
    const {data} = await axios.post(`${apiDataBaseUrl}/campaigns/${id}/lists`, body)
    return data
}

export const storeCampaignStep = async ({id, ...body}) => {
    const {data} = await axios.post(`${apiDataBaseUrl}/campaigns/${id}/campaign-steps`, body)
    return data
}

export const getCampaignLeads = async (campaignId, page, limit, search, order) => {
    const {data} = await fetchData(`/campaigns/${campaignId}/leads`, {
        page,
        limit,
        search,
        order
    })
    return data
}

export const updateCampaign = async ({id, ...body}) => {
    const {data} = await axios.put(`${apiDataBaseUrl}/campaigns/${id}`, body)
    return data
}

export const removeCampaign = async id => {
    const {data} = await axios.delete(`${apiDataBaseUrl}/campaigns/${id}`)
    return data
}

export const testSmsCampaign = async ({id, ...body}) => {
    const {data} = await axios.post(`${apiDataBaseUrl}/campaigns/${id}/test-sms`, body)
    return data
}

export const testEmailCampaign = async ({id, ...body}) => {
    const {data} = await axios.post(`${apiDataBaseUrl}/campaigns/${id}/test-email`, body)
    return data
}

export const updateCampaignStep = async ({id, ...body}) => {
    const {data} = await axios.put(`${apiDataBaseUrl}/campaign-steps/${id}`, body)
    return data
}

export const removeCampaignStep = async id => {
    const {data} = await axios.delete(`${apiDataBaseUrl}/campaign-steps/${id}`)
    return data
}

export const copyCampaignStep = async id => {
    const {data} = await axios.post(`${apiDataBaseUrl}/campaign-steps/${id}/copy`)
    return data
}

export const removeCampaigns = async ids => {
    const {data} = await axios.delete(`${apiDataBaseUrl}/campaigns/batch`, {
        data: {ids}
    })
    return data
}

export const archiveCampaigns = async ids => {
    const {data} = await axios.post(`${apiDataBaseUrl}/campaigns/batch/archive`, {
        ids
    })
    return data
}

export const removeFromArchiveCampaigns = async ids => {
    const {data} = await axios.delete(`${apiDataBaseUrl}/campaigns/batch/archive`, {
        data: {ids}
    })
    return data
}

export const setClickTriggerToCampaigns = async ({campaigns, click_automation_campaign_id}) => {
    const {data} = await axios.put(`${apiDataBaseUrl}/campaigns/click-trigger`, {
        campaigns,
        click_automation_campaign_id
    })
    return data
}

export const copyCampaigns = async ids => {
    const {data} = await axios.post(`${apiDataBaseUrl}/campaigns/copy`, {
        campaigns: ids
    })
    return data
}

export const expireLinksByCampaign = async id => {
    const {data} = await axios.post(`${apiDataBaseUrl}/campaigns/${id}/expire-links`)
    return data
}

export const updateCampaignsStatus = async ({ids, status}) => {
    const {data} = await axios.put(`${apiDataBaseUrl}/campaigns/batch-status`, {
        ids, status
    })
    return data
}

export const updateImportFileLeadStatuses = async ({id, ...body}) => {
    const {data} = await axios.put(`${apiDataBaseUrl}/imports/${id}/lead-statuses`, body)
    return data
}

export const getAttributes = async (page, limit, order) => {
    const {data} = await fetchData('/lead-attributes', {
        page,
        limit,
        order
    })
    return data
}

export const getAttributesAll = async (order) => {
    const {data} = await fetchData('/lead-attributes-all', {order})
    return data
}

export const storeAttribute = async body => {
    const {data} = await axios.post(`${apiDataBaseUrl}/lead-attributes`, body)
    return data
}

export const updateAttribute = async ({id, ...body}) => {
    const {data} = await axios.put(`${apiDataBaseUrl}/lead-attributes/${id}`, body)
    return data
}

export const removeAttribute = async id => {
    const {data} = await axios.delete(`${apiDataBaseUrl}/lead-attributes/${id}`)
    return data
}

export const getSMSGateways = async (order) => {
    const {data} = await fetchData('/config/gateway/setting', {limit: 10000, order})
    return data
}

export const getEmailGateways = async () => {
    const {data} = await fetchData('/config/email-gateway/setting')
    return data
}

export const getEmailLookupGateways = async () => {
    const {data} = await fetchData('/lookup/gateways/emails')
    return data
}

export const getSmsLookupGateways = async () => {
    const {data} = await fetchData('/lookup/gateways/sms')
    return data
}

export const getSmsDNCGateways = async () => {
    const {data} = await fetchData('/lookup/gateways/sms-dnc')
    return data
}

export const getActiveEmailLookupGateways = async () => {
    const {data} = await fetchData('/lookup/gateways/emails/active')
    return data
}

export const getActiveSmsLookupGateways = async () => {
    const {data} = await fetchData('/lookup/gateways/sms/active')
    return data
}

export const getActiveSmsDNCGateways = async () => {
    const {data} = await fetchData('/lookup/gateways/sms-dnc/active')
    return data
}

export const storeSMSGateway = async body => {
    const {data} = await axios.post(`${apiDataBaseUrl}/config/gateway/setting`, body)
    return data
}
export const storeEmailGateway = async body => {
    const {data} = await axios.post(`${apiDataBaseUrl}/config/email-gateway/setting`, body)
    return data
}

export const storeLookupGateway = async body => {
    const {data} = await axios.post(`${apiDataBaseUrl}/lookup/gateways`, body)
    return data
}

export const updateSMSGateway = async ({id, ...body}) => {
    const {data} = await axios.put(`${apiDataBaseUrl}/config/gateway/setting/${id}`, body)
    return data
}
export const updateEmailGateway = async ({id, ...body}) => {
    const {data} = await axios.put(`${apiDataBaseUrl}/config/email-gateway/setting/${id}`, body)
    return data
}

export const updateLookupGateway = async ({id, ...body}) => {
    const {data} = await axios.put(`${apiDataBaseUrl}/lookup/gateways/${id}`, body)
    return data
}

export const removeSMSGateway = async id => {
    const {data} = await axios.delete(`${apiDataBaseUrl}/config/gateway/setting/${id}`)
    return data
}
export const removeEmailGateway = async id => {
    const {data} = await axios.delete(`${apiDataBaseUrl}/config/email-gateway/setting/${id}`)
    return data
}

export const removeLookupGateway = async (id) => {
    const {data} = await axios.delete(`${apiDataBaseUrl}/lookup/gateways/${id}`)

    return data
}

export const getLookupList = async (page, limit, search, order) => {

    const {data} = await fetchData('/lookup', {
        page,
        limit,
        search,
        order
    })
    return data
}

export const updateLookupStatus = async ({id, status}) => {
    const {data} = await axios.post(`${apiDataBaseUrl}/lookup/${id}/status`, {status})
    return data
}

export const updateLookup = async ({id, ...body}) => {
    const {data} = await axios.put(`${apiDataBaseUrl}/lookup/${id}`, body)
    return data
}

export const storeLookup = async body => {
    const {data} = await axios.post(`${apiDataBaseUrl}/lookup`, body)
    return data
}

export const getPhoneValidationTemplate = async () => {
    const {data} = await fetchData('/validation-template/phone')
    return data
}

export const updatePhoneValidationTemplate = async values => {
    const {data} = await axios.put(`${apiDataBaseUrl}/validation-template/phone`, {values})
    return data
}

export const getSourcesWithLists = async () => {
    const {data} = await fetchData(`/all-sources-with-lists`, {
        limit: 100,
    })
    return data
}

export const getShortUrlDomains = async (page, limit, order) => {
    const {data} = await fetchData('/short-url-domains', {
        page,
        limit,
        order
    })
    return data
}
export const getPointIP = async () => {
    const {data} = await fetchData('/config/general',)
    return data
}

export const getShortUrlDomainsWithAccess = async () => {
    const {data} = await fetchData('/short-url-domains/has-access',)
    return data
}
export const getSendersWithAccess = async (id) => {
    if (id) {
        const {data} = await fetchData(`/config/email-gateway/${id}/senders`,)
        return data
    }
}

export const getEmailGatewayDomains = async (id) => {
    const {data} = await fetchData(`/config/email-gateway/${id}/domains`,)

    return data
}
export const storeShortUrlDomain = async body => {
    const {data} = await axios.post(`${apiDataBaseUrl}/short-url-domains`, body)
    return data
}

export const updateShortUrlDomain = async ({id, ...body}) => {
    const {data} = await axios.put(`${apiDataBaseUrl}/short-url-domains/${id}`, body)
    return data
}

export const removeShortUrlDomain = async id => {
    const {data} = await axios.delete(`${apiDataBaseUrl}/short-url-domains/${id}`)
    return data
}

export const enableHttpsShortUrlDomain = async id => {
    const {data} = await axios.post(`${apiDataBaseUrl}/short-url-domains/${id}/enable-https`)
    return data
}

export const sourcesLookupSMS = async request => {
    const {data} = await axios.post(`${apiDataBaseUrl}/sources/lookup`, request)
    return data
}
export const sourcesLookupEmail = async request => {
    const {data} = await axios.post(`${apiDataBaseUrl}/sources/lookup`, request)
    return data
}
export const sourcesListLookup = async obj => {
    const {data} = await axios.post(
        `${apiDataBaseUrl}/sources/lists/lookup`,
        {
            ids: obj.ids,
            type: obj.type,
            setting_gateway_id: obj.setting_gateway_id,
        }
    )
    return data
}

export const segmentsBatchLookup = async obj => {
    const {data} = await axios.post(
        `${apiDataBaseUrl}/segments/lookup/batch`,
        {
            ids: obj.ids,
            lead_type: obj.type,
            setting_gateway_id: obj.setting_gateway_id,
        }
    )
    return data
}

export const lookupStart = async () => {
    const {data} = await axios.post(`${apiDataBaseUrl}/lookup-start`)
    return data
}

export const lookupStop = async () => {
    const {data} = await axios.post(`${apiDataBaseUrl}/lookup-stop`)
    return data
}

export const resetPassword = async (body) => {
    const {data} = await axios.post(`${apiAuthBaseUrl}/password/reset`, body)
    return data
}

export const getImportLists = async (page, limit, order) => {
    const {data} = await fetchData('/import-lists', {
        page,
        limit,
        order
    })
    return data
}

export const storeImportList = async body => {
    const {data} = await axios.post(`${apiDataBaseUrl}/import-lists`, body)
    return data
}

export const removeImportList = async id => {
    const {data} = await axios.delete(`${apiDataBaseUrl}/import-lists/${id}`)
    return data
}

export const updateImportList = async ({id, ...body}) => {
    const {data} = await axios.put(`${apiDataBaseUrl}/import-lists/${id}`, body)
    return data
}

export const getClickTriggerCampaigns = async () => {
    const {data} = await axios.get(`${apiDataBaseUrl}/campaigns/automations/list-by-trigger`, {
        params: {
            trigger: 'campaign_click'
        }
    })
    return data
}

export const getTwoFATriggerCampaigns = async () => {
    const {data} = await axios.get(`${apiDataBaseUrl}/campaigns/automations/list-by-trigger`, {
        params: {
            trigger: 'two_fa_link_view'
        }
    })
    return data
}

export const getTmoLimitTriggerCampaigns = async () => {
    const {data} = await axios.get(`${apiDataBaseUrl}/campaigns/automations/list-by-trigger`, {
        params: {
            trigger: 'campaign_daily_limit'
        }
    })
    return data
}

export const getKeywordTriggerCampaigns = async () => {
    const {data} = await axios.get(`${apiDataBaseUrl}/campaigns/automations/list-by-trigger`, {
        params: {
            trigger: 'keyword'
        }
    })
    return data
}

export const getCampaignSpamEmailGroups = async (campaignId) => {
    const {data} = await axios.get(`${apiDataBaseUrl}/campaigns/${campaignId}/spam-email-groups`);

    return data;
}

export const getRoutes = async () => {
    const {data} = await fetchData('/routes')

    return data
}

export const getPools = async () => {
    const {data} = await fetchData('/pools')

    return data
}

export const getCarrierGroups = async () => {
    return fetchData('/carrier-groups');
}

export const deleteCampaignRoute = async (id) => {
    return await axios.delete(`${apiDataBaseUrl}/routes/${id}`)
}

export const deleteCampaignPool = async (id) => {
    return await axios.delete(`${apiDataBaseUrl}/pools/${id}`)
}

export const getKeywords = async () => {
    const {data} = await fetchData('/sms-keywords')
    return data
}
export const removeKeyword = async id => {
    const {data} = await axios.delete(`${apiDataBaseUrl}/sms-keywords/${id}`)
    return data
}
export const storeKeywords = async body => {
    const {data} = await axios.post(`${apiDataBaseUrl}/sms-keywords`, body)
    return data
}

export const updateKeywords = async (body) => {
    const {data} = await axios.put(`${apiDataBaseUrl}/sms-keywords/${body.id}`, body.copyData)
    return data
}

export const getRevenueTracking = async () => {
    const {data} = await axios.get(`${apiDataBaseUrl}/revenues/tracking`)

    return data;
}

export const deleteRevenueTracking = async (id) => {
    const {data} = await axios.delete(`${apiDataBaseUrl}/revenues/tracking/${id}`)

    return data;
}

export const storeRevenueTracking = async (name) => {
    const {data} = await axios.post(`${apiDataBaseUrl}/revenues/tracking`, {name: name})

    return data
}

export const updateRevenueTracking = async (id, name) => {
    const {data} = await axios.put(`${apiDataBaseUrl}/revenues/tracking/${id}`, {name: name})

    return data
}

export const getExportFiles = async () => {
    const {data} = await fetchData('/segments/exports')
    return data
}

export const getSpamEmailGroups = async () => {
    const {data} = await axios.get(`${apiDataBaseUrl}/spam-email-groups`)

    return data;
}

export const deleteSpamEmailGroup = async (id) => {
    await axios.delete(`${apiDataBaseUrl}/spam-email-groups/${id}`)
}

export const getSenderEmailGroups = async () => {
    const {data} = await axios.get(`${apiDataBaseUrl}/sender-email-groups`)

    return data;
}

export const getSenderEmailGroup = async (id) => {
    const {data} = await axios.get(`${apiDataBaseUrl}/sender-email-groups/${id}`)

    return data;
}

export const deleteSenderEmailGroup = async (id) => {
    await axios.delete(`${apiDataBaseUrl}/sender-email-groups/${id}`)
}

export const refreshSegments = async (ids) => {
    const {data} = await axios.post(`${apiDataBaseUrl}/segments/refresh`, {ids: ids})

    return data;
}
