import React, {Fragment, useEffect, useState, useMemo} from "react"
import SegmentLeads from "../../components/SegmentLeads"
import {useAppDispatch} from "../../Contexts/AppContext"
import SegmentLeadsPageActions from "./SegmentLeadsPageActions"
import {useSegmentState} from "../../Contexts/SegmentContext"
import {useQueryClient} from "react-query"
import {useParams} from "react-router-dom"
import AudienceHeader from "../../components/AudienceHeader"
import SegmentFilter from "../../components/SegmentFilter/SegmentFilter"
import SegmentFilterWrapper from "../../components/segmentFilterNew/SegmentFilterWrapper";

const SegmentLeadsPage = () => {
    const queryClient = useQueryClient()
    const {segmentId} = useParams()
    const {segment, segmentRequest} = useSegmentState()
    const {setBreadcrumbs, setSection} = useAppDispatch()
    const [search, setSearch] = useState("")
    const [sorting, setSorting] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [visibleColumns, setVisibleColumns] = useState([])

    useEffect(() => {
        setBreadcrumbs([
            {label: "Audiences", link: "./"},
            {label: segment && segment.name, loading: segmentRequest.isLoading}
        ])
        setSection("segments")
    }, [setBreadcrumbs, setSection, segment, segmentRequest.isLoading]);

    const onSegmentUpdated = async () => {
        await queryClient.invalidateQueries(["segmentLeads", segmentId])
    };

    return (
        <Fragment>
            <AudienceHeader/>
            {/*{segment && (*/}
            {/*    <SegmentFilter*/}
            {/*        segment={segment}*/}
            {/*        loading={segmentRequest.isLoading}*/}
            {/*        onSegmentUpdated={onSegmentUpdated}*/}
            {/*    />*/}
            {/*)}*/}
            {
                segment &&
                <SegmentFilterWrapper
                    segment={segment}
                    onSegmentUpdated={onSegmentUpdated}
                />
            }
            <SegmentLeads
                segmentId={segmentId}
                setSelectedRows={setSelectedRows}
                onSearch={setSearch}
                onSorting={setSorting}
                setVisibleColumns={setVisibleColumns}
            />
            <SegmentLeadsPageActions
                segment={segment}
                loading={segmentRequest.isLoading}
                search={search}
                sorting={sorting}
                selectedRows={selectedRows}
                visibleColumns={visibleColumns}
            />
        </Fragment>
    )
}

export default SegmentLeadsPage
