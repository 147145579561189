import React, {useState, useEffect} from 'react'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import DataService from '../../../Services/DataService'
import AlertToastr from '../../../Utils/alert'
import {useQueryClient} from "react-query";
import {AddCircleRounded, Close} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";

const CreateSpamEmailGroupModal = ({open, handleClose, spamEmailGroup}) => {

    const queryClient = useQueryClient()
    const [name, setName] = useState('')
    const [emails, setEmails] = useState([{id: null}])
    const [disableButtons, setDisabledButtons] = useState(false)

    useEffect(() => {
        if (spamEmailGroup) {
            setName(spamEmailGroup.name)
            let tempEmails = [];
            spamEmailGroup.emails.map(item => {
                tempEmails.push({id: item.id, email: item.email})
            })
            setEmails(tempEmails)
        } else {
            setName('');
        }
    }, [spamEmailGroup])


    const handleCreate = () => {
        setDisabledButtons(true)
        DataService.createSpamEmailGroup(name, emails)
            .then(response => {
                setName('')
                setEmails([{id: null}])
                setDisabledButtons(false)
                handleClose(response.data.data)
                queryClient.invalidateQueries('spamEmailGroupList')
            })
            .catch((error) => {
                setDisabledButtons(false)
                if (error && error.response && error.response.data && error.response.data.errors) {
                    AlertToastr.showErrorAlert(error.response.data.errors)
                }
            })
    }

    const handleSave = () => {
        setDisabledButtons(true)
        DataService.updateSpamEmailGroup(spamEmailGroup.id, name, emails)
            .then(response => {
                setDisabledButtons(false)
                setName('')
                setEmails([{id: null}])
                handleClose()
                queryClient.invalidateQueries('spamEmailGroupList')
            })
            .catch((error) => {
                setDisabledButtons(false)
                if (error && error.response && error.response.data && error.response.data.errors) AlertToastr.showErrorAlert(error.response.data.errors)
            })
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className='modal'
        >
            <div className="modalBody">
                <div className="modalTitleHolder mb30">
                    <h2 className='mb15'>{spamEmailGroup ? 'Edit' : 'Create'} Spam Email Group</h2>
                </div>
                <TextField
                    value={name}
                    onChange={e => {
                        setName(e.target.value)
                    }}
                    id="source"
                    label="Name"
                    variant="outlined"
                    margin='normal'
                />
                {emails.map((item, key) => (item.id !== undefined &&
                    <div className="wrapper content-around align-center" key={key}>
                        <TextField
                            value={item.email ?? ''}
                            id={'emails_' + key}
                            onChange={e => {
                                emails[key].email = e.target.value
                                setEmails([...emails]);
                            }}
                            label="Email"
                            variant="outlined"
                            margin='normal'
                            name={"emails[" + key + "]"}
                        />
                        <IconButton style={{marginLeft: 5}} size="small" onClick={() => {
                            emails.splice(key, 1);
                            setEmails([...emails]);
                        }}>
                            <Close fontSize="inherit"/>
                        </IconButton>
                    </div>
                ))}

                <div className="align-center wrapper content-justified">
                    <Button
                        variant="outlined"
                        name="add_email"
                        startIcon={<AddCircleRounded/>}
                        onClick={(e) => {
                            setEmails([...emails, {id: null}]);
                        }}
                    >Add Email</Button>
                </div>
                <div className="btnHolder hasPadTop twoBtn">
                    <div className="part">
                        <Button
                            disabled={!!disableButtons}
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={(e) => {
                                spamEmailGroup ? handleSave() : handleCreate()
                            }}
                        >
                            {spamEmailGroup ? 'Save' : 'Create'}
                        </Button>
                    </div>
                    <div className="part">
                        <Button
                            disabled={disableButtons}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default CreateSpamEmailGroupModal
