import React, {useEffect, useMemo, useState} from "react";
import {useAppDispatch} from "../Contexts/AppContext";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getKeywords, removeKeyword} from "../api";
import ListTable from "../components/ListTable/ListTable";
import DeleteModal from "../components/modals/DeleteModal";
import PlusCircle from "../assets/img/plus_circle.svg";
import EventEmitter from "../Utils/EventEmitter";
import SaveKeywordModal from "../components/modals/SaveKeywordModal";
import ListItemContextMenu from "../components/ListItemContextMenu";
import LeadStatus from "../components/LeadStatus";

const Keywords = () => {
    const {setBreadcrumbs, setSection} = useAppDispatch();
    const [list, setList] = useState([]);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentModel, setCurrentModel] = useState(false);
    const tableColumns = useMemo(() => {
        return [
            {
                Header: "Keywords",
                accessor: "values",
                Cell: ({row}) => {
                    return row?.values?.values?.map((e, i) =>
                        i === 0 ? `${e}` : `, ${e}`
                    );
                },
            },
            {
                Header: "System",
                accessor: "is_system",
                Cell: ({value}) => {
                    if (value) {
                        return "Yes"
                    }
                    return "No"
                },
            },
            {
                Header: "Gateway",
                accessor: "gateway.name",
            },
            {
                Header: "Numbers",
                accessor: "phones",
                Cell: ({row}) => {
                    return row?.values?.phones?.map((e, i) => {
                        if (e === "*") {
                            return "All Phones"
                        } else {
                            return i === 0 ? e : <span key={i}>,<br/>{e}</span>;
                        }
                    });
                },
            },
            {
                Header: "Response Message",
                accessor: "response_message",
            },
            {
                Header: "Action",
                accessor: "action",
                Cell: ({value}) => <LeadStatus status={value}/>,
            },
            {
                Header: "",
                id: "actions",
                className: "text-right",
                Cell: ({row}) => {
                    if (row.original.is_system) {
                        return null;
                    }
                    return (
                        <ListItemContextMenu
                            item={row.original}
                            onEdit={() => handleEditItem(row.original)}
                            onDelete={() => showDeleteConfirmation(row.original)}
                        />
                    );
                },
            },
        ];
    }, []);
    const queryClient = useQueryClient();
    const {setContextMenu} = useAppDispatch();

    useEffect(() => {
        setContextMenu([
            {
                label: "Add New Keyword",
                icon: PlusCircle,
                action: () => EventEmitter.emit("KeywordCreate"),
            },
        ]);
    }, [setContextMenu]);

    const listRequest = useQuery(["get-keywords"], () => getKeywords(), {
        keepPreviousData: false,
    });

    const deleteRequest = useMutation(removeKeyword);

    useEffect(() => {
        if (listRequest.isSuccess) {
            setList(listRequest.data.data);
        }
    }, [listRequest.isSuccess, listRequest.data]);

    useEffect(() => {
        const onEvent = () => setShowCreateModal(true);

        const listener = EventEmitter.addListener("KeywordCreate", onEvent);
        return () => {
            listener.remove();
        };
    }, []);

    useEffect(() => {
        setBreadcrumbs([
            {label: "Settings", link: "./settings"},
            {label: "Keywords"},
        ]);
        setSection("settings");
    }, [setBreadcrumbs, setSection]);

    const handleEditItem = (item) => {
        if (item.is_system) {
            return;
        }

        setCurrentModel({
            ...item,
            id: item?.id,
        });
        setShowUpdateModal(true);
    };
    const showDeleteConfirmation = (model) => {
        setCurrentModel(model);
        setShowDeleteModal(true);
    };

    const deleteItem = async () => {
        if (!currentModel) {
            return;
        }

        await deleteRequest.mutateAsync(currentModel.id);
        setShowDeleteModal(false);
        await queryClient.invalidateQueries("get-keywords");
        setCurrentModel(null);
    };

    return (
        <div className="pageHolder">
            <ListTable
                columns={tableColumns}
                data={list}
                loading={listRequest.isLoading}
                fetching={listRequest.isFetching}
                onSort={() => {
                }}
                emptyDataText="No Keywords Found"
                pagination={null}
                pageLimit={0}
                onPageChange={() => {
                }}
                onLimitChange={() => {
                }}
                disableSearchBlock={true}
                onRowClick={(item) => handleEditItem(item)}
            />
            <DeleteModal
                loading={deleteRequest.isLoading}
                open={showDeleteModal}
                handleDelete={deleteItem}
                handleClose={() => setShowDeleteModal(false)}
                instance={"this keywords"}
            />

            <SaveKeywordModal
                open={showCreateModal}
                handleClose={() => {
                    setShowCreateModal(false);
                }}
            />

            {currentModel && (
                <SaveKeywordModal
                    defaultValues={currentModel}
                    open={showUpdateModal}
                    handleClose={() => {
                        setCurrentModel(null);
                        setShowUpdateModal(false);
                    }}
                />
            )}
        </div>
    );
};

export default Keywords;
