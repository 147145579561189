import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import useApiCall from '../../Hooks/useApiCall';
import authEndpoints from '../../Endpoints/auth.endpoints';
import { useAuth } from '../../Contexts/AuthProvider';
import AlertToastr from '../../Utils/alert';
import { Alert } from '@material-ui/lab';

const LoginPage = () => {
    const { search, pathname } = useLocation();
    const params = new URLSearchParams(search);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isAddAccountAction, setIsAddAccountAction] = useState(false);
    const [showNotVerificateEmailMessage, setShowNotVerificateEmailMessage] = useState(false);
    const [request, signin] = useApiCall();
    const {login, isLoggedIn, loggedUser} = useAuth();


    const onLoginClicked = (e) => {
        e.preventDefault();
        signin({
            ...authEndpoints.login(),
            data: {
                email: email,
                password: password,
            },
        });
    };

    useEffect(() => {
        if (pathname.includes('add-account')) {
            setIsAddAccountAction(true);
        }
    }, [])

    useEffect(() => {
        if (request.error) {
            AlertToastr.showErrorAlert(request.error.message);
        }
    }, [request.error]);

    useEffect(() => {
        if (!request.response)
            return;
        if(!request.response.data.user.email_verified_at){
           return setShowNotVerificateEmailMessage(true);
        }
        login(request.response.data.access_token, request.response.data?.user);

    }, [request.response, login]);

    if (showNotVerificateEmailMessage) {
        return (
            <div className="formHolder">
                <Alert>
                    Account has not yet been approved. If you have any questions you can contact {process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS}
                </Alert>
            </div>
        );
    }
    if (isLoggedIn && !params.has('preventRedirect')) {
        return <Navigate to={`/u/${loggedUser}/`} />;
    }

    return (
        <div className="formHolder">
            <p className="mb20">Login to your account using your email and your password.</p>
            <form noValidate autoComplete="off">
                <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    margin="normal"
                    autoComplete="username"
                />
                <TextField
                    id="pass"
                    label="Password"
                    variant="outlined"
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    margin="normal"
                    autoComplete="current-password"
                />
                <div className="mtm4">
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Keep me logged in"
                    />
                </div>
                <div className="btnHolder mt20 mb15">
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                        onClick={onLoginClicked}
                    >
                        Log in
                    </Button>
                </div>
                { !isAddAccountAction &&
                    <a href="/forgot-password" className="text-mute text-semi-bold linkColorNormal cursor-pointer">Forgot
                    your password?</a>
                }
            </form>
        </div>
    );
};

export default LoginPage;
