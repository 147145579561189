import React, { useEffect, useState } from 'react';
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import CampaignLogsBlock from '../../components/CampaignLogsBlock'
import { useQuery, useQueryClient } from 'react-query';
import { getCampaignLogs } from '../../api';
import { useParams } from 'react-router-dom';
import { useQueryState } from 'use-location-state';

const CampaignLogs = () => {

    const [logs, setLogs] = useState([])
    const [page, setPage] = useQueryState('page', 1)
    const [limit, setLimit] = useQueryState('limit', 20)
    const [pagination, setPagination] = useState(null)
    const {campaignId} = useParams()
    const queryClient = useQueryClient()

    const campaignLogsRequest = useQuery(
        ['campaignLogsView', campaignId, page, limit],
        () => getCampaignLogs(campaignId, page, limit),
        {keepPreviousData: true}
    )
    useEffect(() => {
        if (campaignLogsRequest.isSuccess) {
            setLogs(campaignLogsRequest.data.data)
            const paginationData = campaignLogsRequest.data.meta
            if (paginationData.last_page < page) {
                setPage(1)
            }
            setPagination(paginationData)
        }
    }, [campaignLogsRequest.isSuccess, campaignLogsRequest.data, page, setPage])

    return (
        <div className='campaignsPageHolder'>
            <div className="timeLine">
                <div className="timeLineHeading mb15">
                    <h2>Logs</h2>
                    <Button
                        disabled={campaignLogsRequest.isFetching}
                        variant="contained"
                        color="primary"
                        startIcon={<RefreshIcon/>}
                        size='small'
                        onClick={() => queryClient.invalidateQueries('campaignLogsView')}
                    >
                        Refresh
                    </Button>
                </div>
                <CampaignLogsBlock
                    logInfo={logs}
                    pagination={pagination}
                    pageLimit={limit}
                    onPageChange={setPage}
                    onLimitChange={setLimit}
                />
            </div>
        </div>
    )
}

export default CampaignLogs
