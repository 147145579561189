import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useAppDispatch} from '../Contexts/AppContext';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {getAttributes, removeAttribute} from '../api';
import ListTable from '../components/ListTable/ListTable';
import AttributeItemContextMenu from '../components/AttributeItemContextMenu';
import DeleteModal from '../components/modals/DeleteModal';
import SaveAttributeModal from '../components/modals/SaveAttributeModal';
import PlusCircle from '../assets/img/plus_circle.svg';
import EventEmitter from '../Utils/EventEmitter';

const SystemAttributes = () => {
    const {setBreadcrumbs, setSection} = useAppDispatch();
    const [pagination, setPagination] = useState(null);
    const [sorting, setSorting] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);
    const [availableAttributes, setAvailableAttributes] = useState([]);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentModel, setCurrentModel] = useState(false);
    const tableColumns = useMemo(() => {
        return [
            {
                Header: 'Attribute Name',
                accessor: 'name',
            },
            {
                Header: 'Slug',
                accessor: 'slug',
            },
            {
                Header: 'Value Type',
                accessor: 'type',
            },
            {
                Header: '',
                id: 'actions',
                className: 'text-right',
                Cell: ({value, row}) => {
                    return (
                        <AttributeItemContextMenu
                            item={row.original}
                            onEdit={() => handleEditAttribute(row.original)}
                            onDelete={() => showDeleteConfirmation(row.original)}
                        />
                    );
                },
            },
        ];
    }, []);
    const queryClient = useQueryClient();
    const {setContextMenu} = useAppDispatch();

    useEffect(() => {
        setContextMenu([
            {
                label: 'Add Attribute',
                icon: PlusCircle,
                action: () => EventEmitter.emit('AttributeCreate'),
            },
        ]);
    }, [setContextMenu]);

    const attributesRequest = useQuery(
        ['attributes', page, limit, sorting],
        () => getAttributes(page, limit, sorting),
        {keepPreviousData: true},
    );

    const deleteAttributeRequest = useMutation(removeAttribute);

    useEffect(() => {
        if (attributesRequest.isSuccess) {
            setAvailableAttributes(attributesRequest.data.data);
            setPagination(attributesRequest.data.meta);
        }
    }, [attributesRequest.isSuccess, attributesRequest.data]);

    useEffect(() => {
        const onEvent = () => setShowCreateModal(true);

        const listener = EventEmitter.addListener('AttributeCreate', onEvent);
        return () => {
            listener.remove();
        };
    }, []);

    useEffect(() => {
        setBreadcrumbs([
            {label: 'Settings'},
            {label: 'System Attributes'}
        ]);
        setSection('settings');
    }, [setBreadcrumbs, setSection]);

    const handleLimitChange = useCallback((l) => {
        setPage(1);
        setLimit(l);
    }, [setPage, setLimit]);

    const handleEditAttribute = (attr) => {
        setCurrentModel(attr);
        setShowUpdateModal(true);
    };

    const showDeleteConfirmation = (attr) => {
        setCurrentModel(attr);
        setShowDeleteModal(true);
    };

    const deleteAttribute = async () => {
        if (!currentModel) return;

        await deleteAttributeRequest.mutateAsync(currentModel.id);
        setShowDeleteModal(false);
        await queryClient.invalidateQueries('attributes');
        setCurrentModel(null);
    };


    const attrLabel = currentModel ? `"${currentModel.name}" attribute` : 'attribute';

    return (
        <div className="pageHolder">
            <ListTable
                columns={tableColumns}
                data={availableAttributes}
                loading={attributesRequest.isLoading}
                fetching={attributesRequest.isFetching}
                onSort={setSorting}
                emptyDataText="No Attributes Found"
                pagination={pagination}
                pageLimit={limit}
                onPageChange={setPage}
                onLimitChange={handleLimitChange}
                disableSearchBlock={true}
            />
            <DeleteModal
                loading={deleteAttributeRequest.isLoading}
                open={showDeleteModal}
                handleDelete={deleteAttribute}
                handleClose={() => setShowDeleteModal(false)}
                instance={attrLabel}
            />

            <SaveAttributeModal
                open={showCreateModal}
                handleClose={() => {
                    setShowCreateModal(false)
                }}
            />

            {
                currentModel && (
                    <SaveAttributeModal
                        defaultValues={currentModel}
                        open={showUpdateModal}
                        handleClose={() => {
                            setShowUpdateModal(false);
                        }}
                    />
                )
            }
        </div>
    );
};

export default SystemAttributes;
