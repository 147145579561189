import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";

const SegmentFilterSelectValue = ({ multiple = false, valueMap, filter, valueOptions, onValueMapChange }) => {
    console.log(valueOptions);
    React.useEffect(() => {
        if (!Array.isArray(valueMap?.value)) {
            return;
        }

        const optionsValues = valueOptions?.items?.reduce((result, item) => {
            if (item.id) {
                result.push(item.id);
            }

            return result;
        }, []);


        const normalizedValue = valueMap?.value.reduce((res, value) => {
            if (optionsValues.includes(value)) {
                res.push(value);
            }

            return res;
        }, []);

        if (normalizedValue.length !== valueMap?.value.length) {
            onValueMapChange({
                ...valueMap,
                value: normalizedValue,
            });
        }
    }, []);

    const formatValues = (values) => {
        if (!values?.length && valueMap.nullable) {
            return null;
        }

        return values;
    };

    const onValueMapChangeHandler = (values) => {
        onValueMapChange({
            ...valueMap,
            value: formatValues(values),
        });
    };

    return (
        <>
            <InputLabel shrink variant="outlined">
                { valueOptions.label }
            </InputLabel>
            <Select
                displayEmpty
                label={valueOptions.label}
                variant="outlined"
                disabled={!filter?.expression || !valueOptions?.items?.length}
                value={valueMap.value ?? []}
                multiple={multiple}
                onChange={(event) => onValueMapChangeHandler(event.target.value)}
            >
                {
                    valueOptions?.items?.map((option, index) => {
                        if (option.id === null) {
                            return (
                                <ListSubheader disableSticky key={index + "_subhead"}>{option.name}</ListSubheader>
                            )
                        }
                        return (
                            <MenuItem
                                key={index}
                                value={option.id}
                                dense
                            >
                                {option.name}
                            </MenuItem>
                        )
                    })
                }
            </Select>
        </>
    )
};

export default SegmentFilterSelectValue;
