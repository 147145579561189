import React, {Fragment, useEffect, useState} from "react"
import {useNavigate, useParams} from 'react-router-dom';
import {useAppDispatch, useAppState} from "../../Contexts/AppContext"
import EventEmitter from "../../Utils/EventEmitter"
import Export from "../../assets/img/export.svg"
import ExitIcon from "../../assets/img/exit_to_app.svg"
import EditIcon from "../../assets/img/settings.svg"
import CopyIcon from "../../assets/img/copy.svg"
import LeadsExportModal from "../../components/modals/LeadsExportModal"
import SaveSegmentModal from "../../components/modals/SaveSegmentModal"
import DeleteModal from "../../components/modals/DeleteModal"
import DataService from "../../Services/DataService"
import AlertToastr from "../../Utils/alert"

const SegmentLeadsPageActions = props => {
    const {
        segment,
        loading,
        visibleColumns,
        search,
        sorting,
        entityTitle = "Audience"
    } = props;
    const {exportFiles} = useAppState();
    const {setContextMenu, setContextLinks} = useAppDispatch();
    const history = useNavigate();
    const params = useParams();
    const [showExportModal, setShowExportModal] = useState(false);
    const [showSaveSegmentModal, setShowSaveSegmentModal] = useState(false);
    const [showUpdateSegmentModal, setShowUpdateSegmentModal] = useState(false);
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        if (!segment) return

        if (exportFiles && exportFiles[segment.id]) {
            const fileData = exportFiles[segment.id]
            const enabled = fileData.type === "export-completed"
            setContextLinks((prev) => [...prev, ...[
                {
                    label: enabled ? "Download" : "Generating file...",
                    url: fileData.url,
                    disabled: !enabled,
                    segmentName: fileData.segment_name,
                    type: fileData.type,
                }
            ]])
        }
    }, [segment, exportFiles, setContextLinks])

    useEffect(() => {
        const items = [
            {
                label: "Export as CSV",
                icon: Export,
                action: () => EventEmitter.emit("SegmentExport"),
                disabled: loading
            },
            {
                label: `Edit ${entityTitle}`,
                icon: EditIcon,
                action: () => EventEmitter.emit("SegmentEdit"),
                disabled: loading
            },
            {
                label: `Duplicate ${entityTitle}`,
                icon: CopyIcon,
                action: () => EventEmitter.emit("SegmentDuplicate"),
                disabled: loading
            },
            {
                label: `Delete ${entityTitle}`,
                icon: ExitIcon,
                action: () => EventEmitter.emit("SegmentDelete"),
                disabled: loading
            }
        ]
        setContextMenu(items)
    }, [setContextMenu, loading, entityTitle])


    useEffect(() => {
        const onEvent = () => setShowExportModal(true)

        const listener = EventEmitter.addListener("SegmentExport", onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowUpdateSegmentModal(true)

        const listener = EventEmitter.addListener("SegmentEdit", onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowDuplicateModal(true)

        const listener = EventEmitter.addListener("SegmentDuplicate", onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowDeleteModal(true)

        const listener = EventEmitter.addListener("SegmentDelete", onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowSaveSegmentModal(true)

        const listener = EventEmitter.addListener("SegmentCreate", onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    const deleteSegment = () => {
        setShowDeleteModal(false)
        DataService.deleteSegment(segment.id)
            .then(response => {
                history(`/u/${params.loggedUser}/`)
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors)
            })
    }


    return (
        <Fragment>
            <LeadsExportModal
                search={search}
                sorting={sorting}
                visibleColumns={visibleColumns}
                open={showExportModal}
                handleClose={() => setShowExportModal(false)}
            />
            <SaveSegmentModal
                open={showSaveSegmentModal}
                handleClose={() => setShowSaveSegmentModal(false)}
                parentSegment={segment}
                isDuplicateSegmentMode={false}
                entityTitle={entityTitle}
            />
            <SaveSegmentModal
                open={showUpdateSegmentModal}
                handleClose={() => setShowUpdateSegmentModal(false)}
                segment={segment}
                isDuplicateSegmentMode={false}
                instance={entityTitle}
                entityTitle={entityTitle}
            />
            <SaveSegmentModal
                open={showDuplicateModal}
                handleClose={() => setShowDuplicateModal(false)}
                segment={segment}
                isDuplicateSegmentMode={true}
                entityTitle={entityTitle}
                onDuplicated={newSegment => {
                    setShowDuplicateModal(false)
                    if (entityTitle === 'Audience') {
                        history(`/u/${params.loggedUser}/audiences/${newSegment.id}`)
                    } else {
                        history(`/u/${params.loggedUser}/audiences/${newSegment.parent.id}/segments/${newSegment.id}`)
                    }
                }}
            />
            <DeleteModal
                open={showDeleteModal}
                handleDelete={deleteSegment}
                handleClose={(e) => setShowDeleteModal(false)}
                instance={entityTitle}
                entityTitle={entityTitle}
            />
        </Fragment>
    )
}

export default SegmentLeadsPageActions
