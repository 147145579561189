import React, {useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {storeCampaignLists, storeCampaignSegments, storeCampaignSources} from '../api'
import AlertToastr from '../Utils/alert'
import {useCampaignState} from '../Contexts/CampaignContext'
import {useParams} from 'react-router-dom'
import FilterRow from './FilterRow'
import FilterSegmentRow from './FilterSegmentRow'
import FilterListsRow from "./FilterListsRow";


const SyncCampaignResourcesBlock = () => {

    const queryClient = useQueryClient()
    const {campaignId} = useParams()
    const {
        segments,
        sources,
        lists,
        campaignSegmentsRequest,
        campaignSourcesRequest,
        campaignListsRequest,
        availableSegments,
        availableSources,
        campaign
    } = useCampaignState()

    const storeCampaignSegmentsMutation = useMutation(storeCampaignSegments)
    const storeCampaignSourcesMutation = useMutation(storeCampaignSources)
    const storeCampaignListsMutation = useMutation(storeCampaignLists)

    useEffect(() => {
        if (storeCampaignSegmentsMutation.error) {
            AlertToastr.showErrorResponseAlert(storeCampaignSegmentsMutation.error)
        }
    }, [storeCampaignSegmentsMutation.error])

    useEffect(() => {
        if (storeCampaignSourcesMutation.error) {
            AlertToastr.showErrorResponseAlert(storeCampaignSourcesMutation.error)
        }
    }, [storeCampaignSourcesMutation.error])

    useEffect(() => {
        if (storeCampaignListsMutation.error) {
            AlertToastr.showErrorResponseAlert(storeCampaignListsMutation.error)
        }
    }, [storeCampaignListsMutation.error])

    const handleSegmentsSync = async (items) => {
        if (!campaignId) return

        await storeCampaignSegmentsMutation.mutateAsync({
            id: campaignId,
            ids: items.map(i => i.id)
        })
        queryClient.invalidateQueries('campaignSegmentsView').then()
        queryClient.invalidateQueries('campaignLeads').then()
    }

    const handleSourcesSync = async (items) => {
        if (!campaignId) return

        await storeCampaignSourcesMutation.mutateAsync({
            id: campaignId,
            ids: items.map(i => i.id)
        })
        queryClient.invalidateQueries('campaignSourcesView').then()
        queryClient.invalidateQueries('campaignLeads').then()
    }

    const handleListsSync = async (items) => {
        if (!campaignId) {
            return
        }

        await storeCampaignListsMutation.mutateAsync({
            id: campaignId,
            ids: items.map(i => i.id)
        })
        queryClient.invalidateQueries('campaignListsView').then()
        queryClient.invalidateQueries('campaignLeads').then()
    }

    const renderAudiences = () => {
        if (sources.length || lists.length) {
            return <></>
        }

        return <FilterSegmentRow
            loading={storeCampaignSegmentsMutation.isLoading || campaignSegmentsRequest.isLoading}
            label="Add Audience/Segment"
            items={availableSegments}
            selectedItems={segments}
            onChange={handleSegmentsSync}
        />
    }

    const renderSources = () => {
        if (campaign?.type === 'automation' || campaign?.type === 'email_automation') {
            return <></>
        }

        if (segments.length || lists.length) {
            return <></>
        }

        return <FilterRow
            loading={storeCampaignSourcesMutation.isLoading || campaignSourcesRequest.isLoading}
            label="Add Source"
            items={availableSources}
            selectedItems={sources}
            onChange={handleSourcesSync}
        />
    }

    const renderLists = () => {
        if (campaign?.type === 'automation' || campaign?.type === 'email_automation') {
            return <></>
        }

        if (segments.length || sources.length) {
            return <></>
        }

        return <FilterListsRow
            loading={storeCampaignListsMutation.isLoading || campaignListsRequest.isLoading}
            label="Add List"
            items={availableSources}
            selectedItems={lists}
            onChange={handleListsSync}
        />
    }

    return (
        <div className="segmentsFilterHolder">
            <div className="segmentsFilter">
                {renderAudiences()}
                {renderSources()}
                {renderLists()}
            </div>
        </div>
    )
}

export default SyncCampaignResourcesBlock
