import React, {useEffect, useState} from 'react';
import PlusCircle from "../../assets/img/plus_circle.svg";
import EventEmitter from "../../Utils/EventEmitter";
import {useAppDispatch} from "../../Contexts/AppContext";
import SaveRevenueModal from "./revenue-tracking/SaveRevenueModal";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {deleteRevenueTracking, getRevenueTracking} from "../../api";
import ListTable from "../../components/ListTable/ListTable";
import ShortUrlDomainItemContextMenu from "../../components/ShortUrlDomainItemContextMenu";
import DeleteModal from "../../components/modals/DeleteModal";

const RevenueTracking = () => {

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [revenues, setRevenues] = useState([]);
    const [currentModel, setCurrentModel] = useState(null);

    const {setContextMenu} = useAppDispatch()

    const queryClient = useQueryClient();
    const deleteRequest = useMutation(deleteRevenueTracking)

    useEffect(() => {
        setContextMenu([
            {
                label: 'Add Tracking',
                icon: PlusCircle,
                action: () => EventEmitter.emit('RevenueTrackingCreate'),
            },
        ])
    }, [setContextMenu])

    useEffect(() => {
        const onEvent = () => setShowCreateModal(true)
        const listener = EventEmitter.addListener('RevenueTrackingCreate', onEvent)
        return () => {
            listener.remove()
        }
    }, []);

    const listRequest = useQuery(
        ['revenueTrackingList'],
        () => getRevenueTracking(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (listRequest.isSuccess) {
            setRevenues(listRequest.data.data)
        }
    }, [listRequest.isSuccess, listRequest.data])

    const tableColumns = [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Postback',
            accessor: 'postback_url'
        },
        {
            Header: 'Revenue',
            accessor: 'revenue',
            Cell: ({value}) => {
                return '$' + value;
            }
        },
        {
            Header: 'Total conversations',
            accessor: 'total_conversations',
        },
        {
            Header: '',
            id: 'actions',
            className: 'text-right',
            Cell: ({value, row}) => {
                return (
                    <ShortUrlDomainItemContextMenu
                        item={row.original}
                        onEdit={() => handleEditItem(row.original)}
                        onDelete={() => showDeleteConfirmation(row.original)}
                    />
                )
            }
        }
    ]

    const handleEditItem = (revenue) => {
        setCurrentModel(revenue)
        setShowUpdateModal(true);
    };

    const showDeleteConfirmation = (revenue) => {
        setCurrentModel(revenue)
        setShowDeleteModal(true)
    }

    return (
        <div>
            {
                <ListTable
                    columns={tableColumns}
                    data={revenues}
                    loading={listRequest.isLoading}
                    fetching={listRequest.isFetching}
                    onSort={() => {
                    }}
                    disablePagination
                    disableSearchBlock={true}
                    onRowClick={() => {
                    }}
                />
            }
            <SaveRevenueModal
                open={showCreateModal}
                revenue={null}
                handleClose={() => setShowCreateModal(false)}
            />
            <SaveRevenueModal
                open={showUpdateModal}
                revenue={currentModel}
                handleClose={() => {
                    setShowUpdateModal(false)
                    setCurrentModel(null)
                }}
            />
            <DeleteModal
                loading={deleteRequest.isLoading}
                open={showDeleteModal}
                handleDelete={() => {
                    deleteRequest.mutateAsync(currentModel.id)
                        .then(() => {
                            setCurrentModel(null)
                            setShowDeleteModal(false)
                            queryClient.invalidateQueries('revenueTrackingList');
                        })
                }}
                handleClose={() => setShowDeleteModal(false)}
                instance={currentModel}
            />
        </div>
    )
}

export default RevenueTracking
