import React, {useMemo} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const HCBar = ({campaignStat, campaign}) => {

    const options = useMemo(() => {

        if (!campaignStat) {
            return {}
        }

        if (!campaign) {
            return {};
        }
        var seriesData = [];
        if (campaign.type === 'sms' || campaign.type === 'automation') {
            seriesData = [
                {
                    name: 'Sent',
                    data: [['Sent', campaignStat?.sent]],
                    color: '#A9CFFC'
                },
                {
                    name: 'Delivered',
                    data: [['Delivered', campaignStat?.delivered]],
                    color: '#4D94F4'
                }
            ];
        } else {
            seriesData = [
                {
                    name: 'Sent',
                    data: [['Sent', campaignStat?.sent]],
                    color: '#A9CFFC'
                },
                {
                    name: 'Delivered',
                    data: [['Delivered', campaignStat?.delivered]],
                    color: '#4D94F4'
                },
                {
                    name: 'Opened',
                    data: [['Opened', campaignStat?.opened]],
                    color: '#3a7ad0'
                },
                {
                    name: 'Clicked',
                    data: [['Clicked', campaignStat?.clicked]],
                    color: '#227bf1'
                },
                {
                    name: 'Bounced',
                    data: [['Bounced', campaignStat?.bounces]],
                    color: '#0c59be'
                },
                {
                    name: 'Complained',
                    data: [['Complained', campaignStat?.complaints]],
                    color: '#0769ec'
                }
            ];
        }

        return {
            credits: {enabled: false},
            chart: {
                type: 'bar',
                backgroundColor: '#fbfbfb',
                height: 225
            },

            title: {
                text: 'Delivered Statistic'
            },
            xAxis: {
                visible: false,
            },
            yAxis: {
                visible: false
            },
            legend: {
                enabled: false
            },
            tooltip: {
                valueSuffix: ' messages',
                pointFormat: '{point.y}'
            },

            series: seriesData.filter(e=>e.data[0][1]!==0),

            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}: {point.y}',
                        align: 'left',
                        inside: true
                    }
                }
            },
        }
    }, [campaignStat, campaign])

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}

export default HCBar
