import React, {useEffect, useMemo, useState} from "react"
import CampaignStatisticsHeader from "../../components/CampaignStatisticsHeader"
import {useQuery} from "react-query"
import {getCampaignStatistics, getCampaignStatisticsLeads} from "../../api"
import {useNavigate, useParams} from "react-router-dom"
import {formatBlackListRow} from "../../helpers"
import ListTable from "../../components/ListTable/ListTable"
import {toLocalFormat} from "../../Utils/dates"
import LeadStatus from "../../components/LeadStatus"
import HCPie from "../../components/charts/HCPie"
import HCBar from "../../components/charts/HCBar"
import PageLoader from "../../components/loaders/PageLoader"
import CampaignStatisticFilters from "../../components/CampaignStatisticFilters"
import {storageKeys} from "../../config"
import {useQueryState} from "use-location-state";
import CampaignTabsStatistics from "./CampaignTabsStatistics";
import {useCampaignState} from "../../Contexts/CampaignContext";

const CampaignStatistics = () => {
    const {campaign} = useCampaignState()
    const {campaignId, loggedUser} = useParams()
    const [campaignStat, setCampaignStat] = useState({})

    const leadValue = formatBlackListRow
    const [filters, setFilters] = useState({})
    const [leads, setLeads] = useState([])
    const [leadsAttributes, setLeadsAttributes] = useState([])
    const [, setSelectedRows] = useState([])
    const [pagination, setPagination] = useState(null)
    const [search, setSearch] = useState("")
    const [sorting, setSorting] = useState([])
    const [page, setPage] = useQueryState("page", 1)
    const [limit, setLimit] = useQueryState("limit", 50)
    const [filterType, setFilterType] = useState(null)
    const navigate = useNavigate();
    const tableColumns = useMemo(() => {
        const attributes = leadsAttributes.map(attr => {
            return {
                Header: attr.name,
                accessor: `${attr.id}`,
                Cell: leadValue
            }
        })
        return [
            {
                Header: "ID",
                accessor: "id",
                Cell: leadValue
            },
            {
                Header: "Updated Date",
                accessor: "updated_at",
                Cell: ({value, row}) => {
                    return leadValue({value: toLocalFormat(value), row})
                }
            },
            {
                Header: "Carrier Name",
                accessor: "carrier_name",
                Cell: leadValue
            },
            ...attributes,
            {
                Header: "Duplicates",
                accessor: "duplicates",
                Cell: leadValue
            },
            {
                Header: "Status",
                accessor: "status",
                className: "text-right",
                Cell: ({value}) => <LeadStatus status={value}/>
            }
        ]
    }, [leadsAttributes, leadValue])
    const tableValues = useMemo(() => {
        return leads.map(item => {
            const leadValues = item.lead_values
            const newItem = {...item}
            leadValues.forEach(value => {
                const attrId = value && value.attribute && value.attribute.id
                const attrName = value && value.attribute && value.attribute.name
                if (attrId) {
                    newItem[attrId] = newItem[attrId]
                        ? `${newItem[attrId]}; ${value.value}`
                        : value.value
                }
                if (attrName === "Phone") {
                    newItem["carrier_name"] = newItem["carrier_name"]
                        ? `${newItem["carrier_name"]}; ${value.carrier_name}`
                        : value.carrier_name
                }
            })
            delete newItem["lead_values"]
            return newItem
        })
    }, [leads])

    const tableRecordsInfo = useMemo(() => {
        const info = []
        if (pagination) {
            if (pagination.total)
                info.push({count: pagination.total, label: "lead"})
            if (pagination.total_blacklisted)
                info.push({count: pagination.total_blacklisted, label: "blacklisted lead"})
        }
        return info
    }, [pagination])

    const campaignStatisticsRequest = useQuery(
        ["campaignStatistics", campaignId],
        () => getCampaignStatistics(campaignId),
        {keepPreviousData: true}
    )
    useEffect(() => {
        if (campaignStatisticsRequest.isSuccess) {
            setCampaignStat(campaignStatisticsRequest.data.data)
        }
    }, [campaignStatisticsRequest.isSuccess, campaignStatisticsRequest.data])

    const campaignStatisticsLeadsRequest = useQuery(
        ["campaignStatisticsLeads", campaignId, page, limit, search, sorting, filterType, filters],
        () => getCampaignStatisticsLeads(campaignId, page, limit, search, sorting, filterType, filters),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (campaignStatisticsLeadsRequest.isSuccess) {
            setLeads(campaignStatisticsLeadsRequest.data.data)
            const paginationData = campaignStatisticsLeadsRequest.data.meta
            if (paginationData.last_page < page) {
                setPage(1)
            }
            setPagination(paginationData)

            if (campaignStatisticsLeadsRequest.data.attributes) {
                setLeadsAttributes(campaignStatisticsLeadsRequest.data.attributes)
            }
        }
    }, [campaignStatisticsLeadsRequest.isSuccess, campaignStatisticsLeadsRequest.data, page, setPage])

    const handleFilter = filter => {
        setFilterType(filter)
    }

    const handleItemClick = item => {
        navigate(`/u/${loggedUser}/leads/${item?.id}`)
    }

    const renderNoStatisticsData = () => {
        return (
            <div className="pageHolder" style={{paddingTop: "15px", color: "rgb(144 144 144)", fontSize: "14px"}}>
                <p className="text-center">Statistic is unavailable.</p>
                <p className="text-center">There is no data available to build a statistic, because campaign is not
                    started yet.</p>
            </div>
        )
    }

    if (campaignStatisticsRequest.isLoading) {
        return (
            <div className="pageHolder">
                <PageLoader/>
            </div>
        )
    }

    if (!campaign || campaign.status === "draft" || campaign.status === "scheduled") {
        return renderNoStatisticsData()
    }

    const renderHeader = () => {
        return (
            <div>
                <div className="mt15 mb15" style={{
                    display: "flex"
                }}>
                    <div className="pageHeadingBlock "
                         style={{display: "flex", flexDirection: "column", padding: "10px", marginRight: "15px"}}>
                        <div style={{width: "350px", height: "250px"}}>
                            <HCPie
                                campaignStat={campaignStat}
                                currentFilter={filterType}
                                campaign={campaign}
                            />
                        </div>
                    </div>
                    <div style={{flexGrow: 1}}>
                        <CampaignTabsStatistics>
                            <div className="pageHeadingBlock mt10" style={{paddingTop: "10px"}}>
                                <h2 style={{fontSize: "18px", marginBottom: "25px"}} className="text-center">
                                    Lead Engagement Statistic
                                </h2>
                                <CampaignStatisticsHeader
                                    loading={campaignStatisticsLeadsRequest.isLoading}
                                    campaignStat={campaignStat}
                                    onFilter={handleFilter}
                                    campaign={campaign}
                                />
                            </div>
                            <div className="pageHeadingBlock mt10" style={{padding: "10px"}}>
                                <HCBar campaignStat={campaignStat} campaign={campaign}/>
                            </div>
                        </CampaignTabsStatistics>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="pageHolder">
            {renderHeader()}
            <CampaignStatisticFilters onChange={setFilters}/>
            <ListTable
                columns={tableColumns}
                data={tableValues}
                loading={campaignStatisticsLeadsRequest.isLoading}
                fetching={campaignStatisticsLeadsRequest.isFetching}
                isSelectionColumn={true}
                onSelectedRows={setSelectedRows}
                onRowClick={handleItemClick}
                onSort={setSorting}
                emptyDataText="No Leads Found"
                pagination={pagination}
                pageLimit={limit}
                onPageChange={setPage}
                onLimitChange={setLimit}
                onSearch={setSearch}
                recordsInfo={tableRecordsInfo}
                hiddenColumnsStorageKey={storageKeys.leadsHiddenColumnsInCampaign}
            />
        </div>
    )
}

export default CampaignStatistics
