import React, {Fragment, useEffect, useState} from 'react'
import {useAppDispatch} from '../../Contexts/AppContext'
import CreateSegmentIco from '../../assets/img/layers_alt.svg'
import EventEmitter from '../../Utils/EventEmitter'
import Delete from '../../assets/img/delete.svg'
import DoughnutChart from '../../assets/img/doughnut_chart.svg'
import SaveSegmentModal from '../../components/modals/SaveSegmentModal'
import DeleteModal from '../../components/modals/DeleteModal'
import DataService from '../../Services/DataService'
import AlertToastr from '../../Utils/alert'
import {useMutation, useQueryClient} from 'react-query'
import ConfirmModal from "../../components/modals/ConfirmModal";
import {refreshSegments, segmentsBatchLookup, sourcesListLookup} from "../../api";
import Chevron from "../../assets/img/chevron_big_right.svg";
import SmsLookupConfirmModal from "../../components/modals/lookups/SmsLookupConfirmModal";
import EmailLookupConfirmModal from "../../components/modals/lookups/EmailLookupConfirmModal";
import SmsDNCConfirmModal from "../../components/modals/lookups/SmsDNCConfirmModal";

const SegmentsPageActions = ({selectedRows}) => {

    const {setContextMenu} = useAppDispatch();
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showRefreshConfirmModal, setShowRefreshConfirmModal] = useState(false);
    const queryClient = useQueryClient()
    const [showRunLookupSmsModal, setShowRunLookupSmsModal] = useState(false);
    const [showRunLookupSmsDNCModal, setShowRunLookupSmsDNCModal] = useState(false);
    const [showRunLookupEmailModal, setShowRunLookupEmailModal] = useState(false);
    const lookupMutation = useMutation(segmentsBatchLookup)

    useEffect(() => {
        const items = [
            {
                label: 'Create Audience',
                icon: CreateSegmentIco,
                action: () => EventEmitter.emit('SegmentCreate'),
            }
        ]
        if (selectedRows.length) {
            items.push({
                label: 'Refresh Selection',
                icon: DoughnutChart,
                action: () => EventEmitter.emit('SegmentBulkRefresh'),
                iconStyle: {transform: 'rotate(45deg)'}
            })

            items.push({
                label: 'Run Lookup',
                icon: Chevron,
                actionSMS: () => EventEmitter.emit("AudiencesRunLookupSMS"),
                actionSmsDNC: () => EventEmitter.emit("AudiencesRunSmsDNC"),
                actionEmail: () => EventEmitter.emit("AudiencesRunLookupEmail"),
            })
        }
        if (selectedRows.length) {
            items.push({
                label: 'Delete Selection',
                icon: Delete,
                action: () => EventEmitter.emit('SegmentBulkDeletion'),
                iconStyle: {transform: 'rotate(45deg)'}
            })
        }

        setContextMenu(items)
    }, [setContextMenu, selectedRows])

    useEffect(() => {
        const onEvent = () => setShowDeleteModal(true)

        const listener = EventEmitter.addListener('SegmentBulkDeletion', onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowRefreshConfirmModal(true);

        const listener = EventEmitter.addListener('SegmentBulkRefresh', onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowRunLookupSmsModal(true);

        const listener = EventEmitter.addListener('AudiencesRunLookupSMS', onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowRunLookupSmsDNCModal(true);

        const listener = EventEmitter.addListener('AudiencesRunSmsDNC', onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowRunLookupEmailModal(true);

        const listener = EventEmitter.addListener('AudiencesRunLookupEmail', onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowSaveModal(true)

        const listener = EventEmitter.addListener('SegmentCreate', onEvent)
        return () => {
            listener.remove()
        }
    }, [])


    const deleteItems = () => {
        setShowDeleteModal(false)
        DataService.deleteSegments(selectedRows)
            .then(() => {
                queryClient.invalidateQueries('segmentsList').then()
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors)
            })
    }

    const lookupItemsSMS = async (gatewayId) => {
        const data = {
            ids: [...selectedRows],
            type: 1,
            setting_gateway_id: gatewayId
        }
        try {
            await lookupMutation.mutateAsync(data)
            setShowRunLookupSmsModal(false)
            AlertToastr.showAlert("Lookup has been started")
        } catch (error) {
            AlertToastr.showErrorResponseAlert(error)
        }
    }

    const lookupItemsSmsDNC = async (gatewayId) => {
        const data = {
            ids: [...selectedRows],
            type: 3,
            setting_gateway_id: gatewayId
        }
        try {
            await lookupMutation.mutateAsync(data)
            setShowRunLookupSmsDNCModal(false)
            AlertToastr.showAlert("Lookup has been started")
        } catch (error) {
            AlertToastr.showErrorResponseAlert(error)
        }
    }
    const lookupItemsEmail = async (gatewayId) => {
        const data = {
            ids: [...selectedRows],
            type: 2,
            setting_gateway_id: gatewayId,
        }
        try {
            await lookupMutation.mutateAsync(data);
            setShowRunLookupEmailModal(false)
            AlertToastr.showAlert("Lookup has been started")
        } catch (error) {
            AlertToastr.showErrorResponseAlert(error)
        }
    }

    return (
        <Fragment>
            <ConfirmModal
                open={showRefreshConfirmModal}
                handleClose={() => setShowRefreshConfirmModal(false)}
                message="Do you want to refresh selected segments?"
                handleConfirm={() => {
                    refreshSegments(selectedRows)
                        .then(() => {
                            setShowRefreshConfirmModal(false);
                            AlertToastr.showAlert('Segments added to refresh queue');
                        })
                        .catch((error) => {
                            if (error && error.response && error.response.data && error.response.data.errors)
                                AlertToastr.showErrorAlert(error.response.data.errors)
                        })
                }}
            >
            </ConfirmModal>
            <SaveSegmentModal
                open={showSaveModal}
                handleClose={() => setShowSaveModal(false)}
                isDuplicateSegmentMode={false}/>
            <SmsLookupConfirmModal
                loading={lookupMutation.isLoading}
                message='Do you want to lookup sms leads in selected audiences?'
                open={showRunLookupSmsModal}
                handleClose={(e) => setShowRunLookupSmsModal(false)}
                handleConfirm={(gatewayId) => {
                    lookupItemsSMS(gatewayId)
                }}
            />
            <SmsDNCConfirmModal
                loading={lookupMutation.isLoading}
                message='Do you want to lookup sms DNC in selected audiences?'
                open={showRunLookupSmsDNCModal}
                handleClose={(e) => setShowRunLookupSmsDNCModal(false)}
                handleConfirm={(gatewayId) => {
                    lookupItemsSmsDNC(gatewayId)
                }}
            />
            <EmailLookupConfirmModal
                open={showRunLookupEmailModal}
                handleClose={(e) => setShowRunLookupEmailModal(false)}
                handleConfirm={(gatewayId) => {
                    lookupItemsEmail(gatewayId)
                }}
                loading={lookupMutation.isLoading}
                message='Do you want to lookup email leads in selected audiences?'
            />
            <DeleteModal
                open={showDeleteModal}
                handleDelete={deleteItems}
                handleClose={(e) => setShowDeleteModal(false)}
                instance='selected audiences'/>
        </Fragment>
    )
}

export default SegmentsPageActions
