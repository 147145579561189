import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Collapse from "@material-ui/core/Collapse";
import { useAppDispatch } from "../../Contexts/AppContext";
import AlertToastr from "../../Utils/alert";
import "./styles.css";
import {apiDataDomain} from "../../config";

const ApiDocumentation = () => {
  const { setBreadcrumbs, setSection, setContextMenu } = useAppDispatch();
  const [showDetails, setShowDetails] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(
        `{
          "leads": [
            {
              "attributes": [
                {
                  "type": "email",
                  "attribute": "Email",
                  "value": "mail@gmail.com"
                },
                {
                  "type": "phone",
                  "attribute": "Phone",
                  "value": "+380502227777"
                },
                {
                  "type": "string",
                  "attribute": "Name",
                  "value": "John"
                }
              ]
            },
            {
              "attributes": [
                {
                  "type": "email",
                  "attribute": "Email",
                  "value": "john@gmail.com"
                },
                {
                  "type": "phone",
                  "attribute": "Phone",
                  "value": "+38050333888"
                }
              ]
            }
          ]
        }
        `
      )
      .then(() => {
        AlertToastr.showAlert("Request Body copied to clipboard");
      });
  };
  useEffect(() => {
    setBreadcrumbs([{ label: "API Documentation" }]);
    setSection("api_documentation");
    setContextMenu([]);
  }, [setBreadcrumbs, setSection, setContextMenu]);

  return (
    <div className="pageHolder">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="wrapper-doc">
          <div className="wrapper-info">
            <p className="info-title">Campaigns</p>
            <p className="info-subtitle fs18">Automation Campaigns:</p>
            <ul className="info-ul">
              <li>
                <p className="info-text">
                  Lead can only participate once in an automation campaign
                  regardless if it’s a new or dupe lead.
                </p>
              </li>
              <li>
                <p className="info-text">
                  Leads are added to campaign only when campaign is in
                  Active/Paused status
                </p>
              </li>
              <li>
                <p className="info-text">
                  When campaign is in Paused, leads are added to campaign on
                  background without SMS/Email sending. Next round of sending
                  starts once campaign is in Active status, based on “Run daily
                  from - to” interval
                </p>
              </li>
            </ul>
            <p className="info-subtitle fs18" style={{ marginBottom: "20px" }}>
              Sending
            </p>
            <p className="info-subtitle fs18">Failed</p>
            <ul className="info-ul">
              <li>
                <p className="info-text">
                  Campaigns with over 50% failed rate will be put into “failed
                  status”
                </p>
              </li>
            </ul>
            <p className="info-title">Import</p>
            <p className="info-subtitle fs18">Import API Keys</p>
            <ul className="info-ul">
              <li>
                <p className="info-text">
                  Leads are being added to Segma every{" "}
                  <span className="text-bold">30 min</span> (for instance: at
                  6:30 am; at 7:00 am etc)
                </p>
              </li>
            </ul>
            <p className="info-subtitle fs18">Lead Duplicate Logic</p>
            <ul className="info-ul">
              <li>
                <p className="info-text">
                  Every new Lead, before adding to Segma, is checking for
                  duplicates: if the imported Lead has an email or phone that is
                  already existed in Segma, then the imported Lead is merged
                  with the already existing Lead. This means that the imported
                  Lead's email/phone is displayed on the Single Lead detail page
                  as well as the existed Lead’s data.
                </p>
              </li>
            </ul>
            <p className="info-subtitle fs18">Data</p>
            <ul className="info-ul">
              <li>
                <p className="info-text">
                  Leads without Source/List are stored in default “No Source”
                  folder
                </p>
              </li>
            </ul>
            <p className="info-title">API Documentation</p>
            <p className="info-subtitle fs18" style={{ marginTop: "30px" }}>
              API Reference
            </p>
            <p className="info-text">
              The Segma API is organized around REST. Our API has predictable
              resource-oriented URLs, accepts form-encoded request bodies,
              returns JSON-encoded responses, and uses standard HTTP response
              codes.
            </p>
            <p className="info-subtitle fs18">Authentication</p>
            <p className="info-text">
              The Segma API uses API keys to authenticate requests. You can view
              and manage your API keys in Segma {">>"} Settings - Import API
              Keys page
            </p>
            <p className="info-text">
              All API requests must be made over HTTPS. Calls made over plain
              HTTP will fail. API requests without authentication will also
              fail.
            </p>
            <p className="info-subtitle fs18">Errors</p>
            <p className="info-text">
              Segma uses conventional HTTP response codes to indicate the
              success or failure of an API request. In general: Codes in the 2xx
              range indicate success. Codes in the 4xx range indicate an error
              that failed given the information provided (e.g., a required
              parameter was omitted, a charge failed, etc.). Codes in the 5xx
              range indicate an error with Stripe's servers (these are rare).
              Some 4xx errors that could be handled programmatically (e.g., a
              card is declined) include an error code that briefly explains the
              error reported.
            </p>
          </div>
          <div className="WrapperFirst">
            <div className="wrapper-code">
              <div className="wrapper-code-title">
                <p className="code-title">HTTP STATUS CODE SUMMARY:</p>
              </div>
              <div className="wrapper-code-text">
                <div className="https-statuses">
                  <div className="https-status">
                    <div className="text-grey first-column">200 - OK</div>
                    <div className="second-column">
                      Everything worked as expected.
                    </div>
                  </div>
                  <div className="https-status">
                    <div className="text-grey first-column">
                      400 - Bad Request
                    </div>
                    <div className="second-column">
                      The request was unacceptable, often due to missing a
                      required parameter.
                    </div>
                  </div>
                  <div className="https-status">
                    <div className="text-grey first-column">
                      401 - Unauthorized
                    </div>
                    <div className="second-column">
                      No valid API key provided.
                    </div>
                  </div>
                  <div className="https-status">
                    <div className="text-grey first-column">
                      402 - Request Failed
                    </div>
                    <div className="second-column">
                      The parameters were valid but the request failed.
                    </div>
                  </div>
                  <div className="https-status">
                    <div className="text-grey first-column">03 - Forbidden</div>
                    <div className="second-column">
                      The API key doesn't have permissions to perform the
                      request.
                    </div>
                  </div>
                  <div className="https-status">
                    <div className="text-grey first-column">
                      404 - Not Found
                    </div>
                    <div className="second-column">
                      The requested resource doesn't exist.
                    </div>
                  </div>
                  <div className="https-status">
                    <div className="text-grey first-column">409 - Conflict</div>
                    <div className="second-column">
                      The request conflicts with another request (perhaps due to
                      using the same idempotent key).
                    </div>
                  </div>
                  <div className="https-status">
                    <div className="text-grey first-column">
                      429 - Too Many Requests
                    </div>
                    <div className="second-column">
                      Too many requests hit the API too quickly. We recommend an
                      exponential backoff of your requests.
                    </div>
                  </div>
                  <div className="https-status">
                    <div className="text-grey first-column">
                      <p>500, 502, 503, 504 - Server Errors</p>
                    </div>
                    <div className="second-column">
                      Something went wrong on Stripe's end. (These are rare.)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper-doc" >
            <div className="edpointWrapper">
              <p className="info-title">Endpoint</p>
              <p className="info-subtitle">Import Lead(s)</p>
              <p className="info-text">
               <span className="info-subtitle">POST https://{apiDataDomain}/api/import/lead?api_key=API_KEY </span> <br /> You
                can use endpoint to import an individual lead or a list of leads
                using the API endpoint.
              </p>
              <p className="info-text bb">Parameters</p>
              <span className="text-grey-light">
                leads <span className="text-orange">REQUIRED</span> array of
                entity
              </span>
              <p className="info-text">
                Individual lead or a list of leads that has to be imported
              </p>
              <p
                className="info-text"
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                {" "}
                Child parameters <span className="text-orange"> REQUIRED</span>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setShowDetails((s) => !s)}
                  sx={{ height: "5px" }}
                >
                  {showDetails ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                </IconButton>
              </p>
              <Collapse in={showDetails} timeout="auto" unmountOnExit>
                <div className="endpoint">
                  <span className="text-grey-light">
                    attributes <span className="text-orange">REQUIRED</span>{" "}
                    array of entity
                  </span>
                  <p className="info-text">
                    List of data for single lead (lead name, phone, email etc)
                  </p>
                  <p className="info-text">
                    {" "}
                    Child parameters{" "}
                    <span className="text-orange">REQUIRED</span>
                  </p>

                  <div className="endpoint-child">
                    <span className="text-grey-light">
                      attributes.type{" "}
                      <span className="text-orange">REQUIRED</span> value is
                      “email”, “phone”, “string”
                    </span>
                    <p className="info-text bb">
                      Type of the attribute that is imported. This may be
                      “email”, “phone”, “string” type.{" "}
                    </p>
                    <span className="text-grey-light">
                      attributes.attribute{" "}
                      <span className="text-orange">REQUIRED</span>{" "}
                    </span>
                    <p className="info-text bb">
                      Lead’s attribute that is added to Lead object. As an
                      example, this maybe “email”, “phone”, “first name”, “car”,
                      etc. Each Lead should have at least one attribute with
                      value “email” or “phone”. All other attribute values are
                      optional. If the attribute value does not exist in Segma
                      account yet, it will be created automatically on
                      successful Lead Import.
                    </p>
                    <span className="text-grey-light">
                      attributes.value{" "}
                      <span className="text-orange">REQUIRED</span>{" "}
                    </span>
                    <p className="info-text">
                      Value of the attribute. If “attributes.attribute”:
                      “email”, then “attributes.value”: “mail@mail.com”
                    </p>
                  </div>
                </div>
              </Collapse>
            </div>
            <div className="requestWrapper">
              {" "}
              <div className="wrapper-code" style={{height: '100%'}}>
                <div className="wrapper-code-title">
                  <p className="code-title">REQUEST BODY EXAMPLE:</p>
                  <p
                    className="copy-btn"
                    onClick={() => {
                      copyToClipboard();
                    }}
                  >
                    Copy
                  </p>
                </div>
                <div className="wrapper-code-text">
                  <span className="d bl ">{`{`}</span>
                  <span className="w bl pl10">
                    "leads": <span className="d">{`[ `}</span>
                  </span>
                  <span className="d bl pl20">{`{`}</span>
                  <span className="w bl pl30">
                    "attributes": <span className="d">{`[ `}</span>
                  </span>
                  <span className="d bl pl40">{`{`}</span>
                  <span className="bl w pl50">
                    "type": <span className="g"> email,</span>
                  </span>
                  <span className="bl w pl50">
                    "attribute": <span className="g"> Email,</span>
                  </span>
                  <span className="bl w pl50">
                    "value": <span className="g"> mail@gmail.com,</span>
                  </span>
                  <span className="d bl pl40">{`}`},</span>

                  <span className="d bl pl40">{`{`}</span>
                  <span className="bl w pl50">
                    "type": <span className="g"> phone,</span>
                  </span>
                  <span className="bl w pl50">
                    "attribute": <span className="g"> Phone,</span>
                  </span>
                  <span className="bl w pl50">
                    "value": <span className="g"> +19532132132,</span>
                  </span>
                  <span className="d bl pl40">{`}`},</span>

                  <span className="d bl pl40">{`{`}</span>
                  <span className="bl w pl50">
                    "type": <span className="g"> string,</span>
                  </span>
                  <span className="bl w pl50">
                    "attribute": <span className="g"> Name,</span>
                  </span>
                  <span className="bl w pl50">
                    "value": <span className="g"> John,</span>
                  </span>
                  <span className="d bl pl40">{`}`}</span>
                  <span className="bl d pl30">{`]`}</span>
                  <span className="bl d pl20">{`},`}</span>

                  <span className="d bl pl20">{`{`}</span>
                  <span className="w bl pl30">
                    "attributes": <span className="d">{`[ `}</span>
                  </span>
                  <span className="d bl pl40">{`{`}</span>
                  <span className="bl w pl50">
                    "type": <span className="g"> email,</span>
                  </span>
                  <span className="bl w pl50">
                    "attribute": <span className="g"> Email,</span>
                  </span>
                  <span className="bl w pl50">
                    "value": <span className="g"> john@gmail.com,</span>
                  </span>
                  <span className="d bl pl40">{`}`},</span>

                  <span className="d bl pl40">{`{`}</span>
                  <span className="bl w pl50">
                    "type": <span className="g"> phone,</span>
                  </span>
                  <span className="bl w pl50">
                    "attribute": <span className="g"> Phone,</span>
                  </span>
                  <span className="bl w pl50">
                    "value": <span className="g">  +19513212312,</span>
                  </span>
                  <span className="d bl pl40">{`}`},</span>
                  <span className="bl d pl30">{`]`}</span>
                  <span className="bl d pl20">{`}`}</span>
                  <span className="bl d pl10">{`]`}</span>
                  <span className="bl d">{`}`}</span>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiDocumentation;
