import React, {Fragment} from 'react';
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

const EmailContentModal = ({onClose, content, isOpen}) => {

    return (
        <Fragment>
            <Dialog
                open={isOpen}
                onClose={onClose}
                className='modal'
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <div className="content" dangerouslySetInnerHTML={{__html: content}}></div>
                    </div>
                    <div className="btnHolder hasPadTop">
                        <div className="part">
                            <Button
                                disabled={false}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    )
}


export default EmailContentModal;