import React, {useEffect, useMemo, useState} from "react"
import {useQuery} from "react-query"
import {getImports} from "../api"
import {useAppDispatch} from "../Contexts/AppContext"
import {toLocalFormat} from "../Utils/dates"
import {useNavigate, useParams} from 'react-router-dom';
import ListTable from "../components/ListTable/ListTable"
import {useQueryState} from "use-location-state";

const ImportHistory = () => {
    const [list, setList] = useState([])
    const [pagination, setPagination] = useState(null)
    const [search, setSearch] = useState("")
    const [sorting, setSorting] = useState([{key: "updated_at", direction: "desc"}])
    const [page, setPage] = useQueryState("page", 1)
    const [limit, setLimit] = useQueryState("limit", 15)
    const {setBreadcrumbs, setSection} = useAppDispatch()
    const history = useNavigate()
    const params = useParams();
    const {setContextMenu} = useAppDispatch()
    const tableColumns = useMemo(() => {
        return [
            {
                Header: "Import Date",
                accessor: "updated_at",
                Cell: ({value}) => toLocalFormat(value)
            },
            {
                Header: "Filename",
                accessor: "name",
            },
            {
                Header: "Total Rows",
                accessor: "total_rows",
            },
            {
                Header: "Total Processed",
                accessor: "processed_count",
            },
            {
                Header: "Unique",
                accessor: "unique_count",
            },
            {
                Header: "Failed",
                accessor: "failed_count",
            },
            {
                Header: "Ignored Phones",
                accessor: "ignored_phones_count",
            },
            {
                Header: "Invalid Phones",
                accessor: "invalid_phones_count",
            },
            {
                Header: "Skipped Rows",
                accessor: "skipped_count",
            },
        ]

    }, [])

    const tableValues = useMemo(() => {
        return list
    }, [list])

    const tableSorting = useMemo(() => {
        if (!sorting) return null;

        return sorting.map(item => ({
            id: item.key, desc: item.direction === "desc"
        }))
    }, [sorting])

    useEffect(() => {
        setContextMenu([])
    }, [setContextMenu])

    useEffect(() => {
        setBreadcrumbs([
            {label: "Settings", link: "./settings"},
            {label: "Import History"}
        ])
        setSection("settings")
    }, [setBreadcrumbs, setSection])

    const {isLoading, isFetching, isSuccess, data} = useQuery(
        ["importsList", page, limit, sorting, search],
        () => getImports(page, limit, sorting, search),
        {
            keepPreviousData: true,
        }
    )

    useEffect(() => {
        if (isSuccess) {
            setList(data.data)
            const paginationData = data.meta
            if (paginationData.last_page < page) {
                setPage(1)
            }
            setPagination(paginationData)
        }
    }, [
        isSuccess, data, page, setPage
    ])

    const handleItemClick = item => {
        history(`/u/${params.loggedUser}/settings/import-history/${item.id}`)
    }

    return (
        <div className="pageHolder">
            <ListTable
                columns={tableColumns}
                data={tableValues}
                loading={isLoading}
                fetching={isFetching}
                isSelectionColumn={false}
                onRowClick={handleItemClick}
                onSort={setSorting}
                emptyDataText="No Files Found"
                pagination={pagination}
                pageLimit={limit}
                onPageChange={setPage}
                onLimitChange={setLimit}
                onSearch={setSearch}
                initSort={tableSorting}
            />
        </div>
    )
}

export default ImportHistory
