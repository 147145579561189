import React, {Fragment, useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {useQuery, useQueryClient} from "react-query";
import {getSMSGateways} from "../../../api";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import DataService from "../../../Services/DataService";
import AlertToastr from "../../../Utils/alert";
import RouteGroups from "./RouteGroups";

const CreateRouteModal = ({open, close, route, setRoute}) => {

    const queryClient = useQueryClient();
    const [disableButtons, setDisabledButtons] = useState(false)
    const [gateways, setGateways] = useState([])

    const gatewaysRequest = useQuery(['gateways'], () => getSMSGateways(), {keepPreviousData: true})

    useEffect(() => {
        if (gatewaysRequest.isSuccess) {
            setGateways(gatewaysRequest.data.data)
        }
    }, [gatewaysRequest.isSuccess, gatewaysRequest.data, setGateways])

    const handleSave = () => {
        setDisabledButtons(true);
        DataService.updateCampaignRoute(route.id, route)
            .then(response => {
                setDisabledButtons(false)
                queryClient.invalidateQueries('campaignRouteList')
                close()
            })
            .catch((error) => {
                setDisabledButtons(false)
                if (error && error.response && error.response.data && error.response.data.errors) {
                    AlertToastr.showErrorAlert(error.response.data.errors)
                }
            })
    }

    const setRouteGroups = (groups) => {
        setRoute({...route, groups: [...groups]})
    }


    const handleCreate = () => {
        setDisabledButtons(true);
        DataService.createCampaignRoute(route)
            .then(response => {
                setDisabledButtons(false)
                queryClient.invalidateQueries('campaignRouteList')
                close()
            })
            .catch((error) => {
                setDisabledButtons(false)
                if (error && error.response && error.response.data && error.response.data.errors) {
                    AlertToastr.showErrorAlert(error.response.data.errors)
                }
            })
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={close}
                className='modal'
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>{route.id ? 'Edit' : 'Create'} Route</h2>
                    </div>
                    <TextField
                        value={route.name}
                        onChange={e => {
                            setRoute({...route, name: e.target.value})
                        }}
                        id="name"
                        label="Name"
                        variant="outlined"
                        margin='normal'
                    />
                    <h3>Default way</h3>
                    <FormControl
                        disabled={gatewaysRequest.isLoading}
                        variant="outlined"
                        margin="normal"
                    >
                        <InputLabel id={'Default-gateway-label'}>{'Default Gateway'}</InputLabel>
                        <Select
                            value={route.default.gateway_id ?? ''}
                            onChange={e => {
                                setRoute({...route, default: {...route.default, gateway_id: e.target.value}})
                            }}
                        >
                            {gateways.map((gateway) => (
                                <MenuItem
                                    key={gateway.id}
                                    value={gateway.id}
                                >
                                    {gateway.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {route.default.gateway_id && <FormControl
                        variant="outlined"
                        margin="normal"
                    >
                        <InputLabel id={'Default-sender-label'}>{'Default sender'}</InputLabel>
                        <Select
                            value={route.default.sender_type ? route.default.sender_type + '|||' + route.default.sender : ''}
                            onChange={e => {
                                let value = e.target.value.split('|||')
                                let senderName = null
                                if (value[0] === 'phone') {
                                    gateways.map(gateway => {
                                        if (gateway.id === route.default.gateway_id) {
                                            gateway.from.map(phone => {
                                                if (phone.key === value[1]) {
                                                    senderName = phone.label
                                                }
                                            })
                                        }
                                    })

                                } else {
                                    gateways.map(gateway => {
                                        if (gateway.id === route.default.gateway_id) {
                                            gateway.pools.map(pool => {
                                                if (pool.key === value[1]) {
                                                    senderName = pool.label
                                                }
                                            })
                                        }
                                    })

                                }
                                setRoute({
                                    ...route,
                                    default: {
                                        ...route.default,
                                        sender: value[1],
                                        sender_type: value[0],
                                        sender_name: senderName,
                                    }
                                })
                            }}
                        >
                            <MenuItem
                                disabled
                                key={'Phones'}
                                value={'Phones'}
                            >
                                Phones
                            </MenuItem>
                            {gateways.map(gateway => {
                                if (gateway.id === route.default.gateway_id) {
                                    return gateway.from.map((phone) => (
                                            <MenuItem
                                                key={phone.key}
                                                value={'phone|||' + phone.key}
                                            >
                                                {phone.label}
                                            </MenuItem>
                                        )
                                    )
                                }
                            })

                            }
                            <MenuItem
                                disabled
                                key={'Pools'}
                                value={'Pools'}
                            >
                                Pools
                            </MenuItem>
                            {gateways && gateways.map(gateway => {
                                if (gateway.id === route.default.gateway_id) {
                                    return gateway.pools.map((pool) => {
                                        return (
                                            <MenuItem
                                                key={pool.key}
                                                value={'pool|||' + pool.key}
                                            >
                                                {pool.label}
                                            </MenuItem>
                                        )
                                    })
                                }
                            })
                            }
                        </Select>
                    </FormControl>}
                    <TextField
                        type="number"
                        value={route.default.frequency}
                        onChange={e => {
                            setRoute({...route, default: {...route.default, frequency: e.target.value}})
                        }}
                        id="default_frequency"
                        label="Default Frequency"
                        variant="outlined"
                        margin='normal'
                    />

                    <h3 style={{marginBottom: '10px'}}>Carrier Groups: </h3>

                    <RouteGroups
                        routeGroups={route.groups}
                        gateways={gateways}
                        setRouteGroups={setRouteGroups}
                    >
                    </RouteGroups>

                    <div className="btnHolder hasPadTop twoBtn">
                        <div className="part">
                            <Button
                                disabled={disableButtons}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={(e) => {
                                    route.id ? handleSave() : handleCreate()
                                }}
                            >
                                {route.id ? 'Save' : 'Create'}
                            </Button>
                        </div>
                        <div className="part">
                            <Button
                                disabled={disableButtons}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={close}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>

            </Dialog>
        </Fragment>
    );
};

export default CreateRouteModal;