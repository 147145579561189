import React, { Fragment, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { useMutation, useQueryClient } from 'react-query'
import { storeAttribute, updateAttribute } from '../../api'
import AlertToastr from '../../Utils/alert'

const SaveAttributeModal = props => {

    const {
        open,
        handleClose,
        defaultValues,
    } = props

    const queryClient = useQueryClient()

    const {
        register,
        handleSubmit,
        reset,
    } = useForm({
        mode: 'onTouched',
        defaultValues: defaultValues
    })


    const nameRef = useRef()

    const storeAttributeMutation = useMutation(storeAttribute)
    const updateAttributeMutation = useMutation(updateAttribute)

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues)
        }
    }, [defaultValues, reset])

    useEffect(() => {
        if (storeAttributeMutation.error) {
            AlertToastr.showErrorResponseAlert(storeAttributeMutation.error)
        }
    }, [storeAttributeMutation.error])

    useEffect(() => {
        if (updateAttributeMutation.error) {
            AlertToastr.showErrorResponseAlert(updateAttributeMutation.error)
        }
    }, [updateAttributeMutation.error])

    const onSubmit = handleSubmit(async data => {
        if (defaultValues) {
            await updateAttributeMutation.mutateAsync({id: defaultValues.id, ...data})
            handleClose()
            queryClient.invalidateQueries('attributes').then()
        } else {
            try {
                await storeAttributeMutation.mutateAsync(data)
                handleClose()
                queryClient.invalidateQueries('attributes').then()
            } catch (e) {
                console.log(e)
            }
        }

    })

    const title = defaultValues ? 'Edit Attribute' : 'Add Attribute'

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className='modal'
                onEntered={() => {
                    nameRef.current.focus()
                }}
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>{title}</h2>
                    </div>
                    <form onSubmit={onSubmit}>
                        <TextField
                            inputRef={(e) => {
                                register(e)
                                nameRef.current = e
                            }}
                            name="name"
                            label="Name"
                            variant="outlined"
                            margin='normal'
                        />
                        <input type="hidden" ref={register} name='sort_order'/>
                        <div className="btnHolder hasPadTop twoBtn">
                            <div className="part">
                                <Button
                                    disabled={storeAttributeMutation.isLoading || updateAttributeMutation.isLoading}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    type="submit"
                                    onClick={(e) => {
                                    }}
                                >
                                    {title}
                                </Button>
                            </div>
                            <div className="part">
                                <Button
                                    disabled={storeAttributeMutation.isLoading || updateAttributeMutation.isLoading}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default SaveAttributeModal
