import React, {useEffect, useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import IconClose from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import ListSubheader from "@material-ui/core/ListSubheader";
import Box from "@material-ui/core/Box";
import FormHelperText from "@material-ui/core/FormHelperText";
import SegmentFilterValues from "./SegmentFilterValues";
import { useSegmentFilterDispatch } from "./SegmentFilterContext";
import { segmentFilterExpression, segmentFilterType, systemSegmentFilterField } from "./enums";
import { getCreatedAtCustomValueMap, getCreatedAtRangeValueMap, getEngagementDateValueMap } from "./helpers";

const SegmentFilterItem = ({ filter, error, fieldOptions, expressionOptions, isLast, onFilterRemove, onFilterChange }) => {
    const [hasError, setHasError] = useState(false);
    const {getFilterValuesMap} = useSegmentFilterDispatch();


    useEffect(() => {
        setHasError(() => {
            if (Array.isArray(error)) {
                return Boolean(error.length);
            }

            return Boolean(error);
        })
    }, [error]);

    const setFieldHandler = (field, type) => {
        const nextFilter = {
            ...filter,
            type,
            field,
            expression: null,
            valuesMap: getFilterValuesMap({ field, type }),
        };

        onFilterChange(nextFilter);
        setHasError(false);
    };

    const setExpressionHandler = (expression) => {
        const getNotEmptyValue = (valueMap) => {
            if (valueMap?.nullable && valueMap?.value === null) {
                return null;
            }

            if (valueMap?.valueType === "date") {
                return null;
            }

            return valueMap?.value ?? [];
        };

        const getNextValuesMap = (expression) => {
            if (expression === segmentFilterExpression.DATE_RANGE) {
                return getCreatedAtRangeValueMap();
            }

            if (expression === segmentFilterExpression.CUSTOM_DATES) {
                return getCreatedAtCustomValueMap();
            }

            const isExpressionEmpty = [segmentFilterExpression.EMPTY, segmentFilterExpression.NOT_EMPTY].includes(expression);
            const valuesMap = filter.valuesMap;

            if (isExpressionEmpty) {
                return valuesMap.map((item) => {
                    return { ...item, value: null };
                });
            }

            return valuesMap.map((item) => {
                const value = getNotEmptyValue(item);
                return { ...item, value };
            });
        };

        onFilterChange({
            ...filter,
            expression,
            valuesMap: getNextValuesMap(expression),
        });
        setHasError(false);
    }

    const setValuesMapHandler = (value, index) => {
        let valuesMap = filter.valuesMap.map((item, i) => {
            if (i === index) {
                return value;
            }

            return item;
        });

        if (filter.type === segmentFilterType.CAMPAIGN_ATTRIBUTES && value.fieldName === "period") {
            if (value.value === "custom" && valuesMap.length >= 2) {
                valuesMap.push(...getEngagementDateValueMap());
            } else {
                valuesMap = valuesMap.filter((item) => item.fieldName !== "date_to" && item.fieldName !== "date_from");
            }
        }

        if (filter.field === systemSegmentFilterField.NON_DELIVERED_SMS_LEADS && value.fieldName === "period") {
            if (value.value === "custom" && valuesMap.length >= 2) {
                valuesMap.push(...getEngagementDateValueMap());
            } else {
                valuesMap = valuesMap.filter((item) => item.fieldName !== "date_to" && item.fieldName !== "date_from");
            }
        }

        onFilterChange({
            ...filter,
            valuesMap,
        });
        setHasError(false);
    }

    return (
        <React.Fragment>
            <Box className="segmentationItemWrapper">
                <Box className="segmentationItem">
                    <FormControl style={{ width: "100%" }} error={hasError}>
                        <InputLabel variant="outlined">Select attribute</InputLabel>
                        <Select
                            label="Select attribute"
                            variant="outlined"
                            value={filter.field ?? ""}
                        >
                            {
                                fieldOptions.map((item, index) => [
                                    index !== 0 && <Divider key={item.type + "_divider"} />,
                                    <ListSubheader disableSticky key={item.type + "_subhead"}>{item.label}</ListSubheader>,
                                    item.options.map((option) =>
                                        <MenuItem
                                            key={option.id}
                                            value={option.id}
                                            disabled={option?.disabled}
                                            dense
                                            onClick={() => setFieldHandler(option.id, item.type)}
                                        >
                                            {option.name}
                                        </MenuItem>
                                    )
                                ])
                            }
                        </Select>
                    </FormControl>

                    <FormControl style={{ width: "100%" }} error={hasError}>
                        <InputLabel variant="outlined">Select operator</InputLabel>
                        <Select
                            label="Select operator"
                            disabled={!filter?.field}
                            variant="outlined"
                            value={filter.expression ?? ""}
                        >
                            {
                                expressionOptions.map((option, index) =>
                                    <MenuItem
                                        key={index}
                                        value={option.value}
                                        dense
                                        onClick={() => setExpressionHandler(option.value)}
                                    >
                                        {option.label}
                                    </MenuItem>)
                            }
                        </Select>
                    </FormControl>

                    <FormControl className="segmentationItemValues">
                        {
                            filter?.valuesMap.map((valueMap, index) => (
                                <FormControl key={index} error={hasError}>
                                    <SegmentFilterValues
                                        valueMap={valueMap}
                                        filter={filter}
                                        hasError={hasError}
                                        onValueMapChange={(value) => setValuesMapHandler(value, index)}
                                    />
                                </FormControl>
                            ))
                        }
                    </FormControl>

                    <IconButton className="segmentationItemDeleteBtn" onClick={() => onFilterRemove(filter)}>
                        <IconClose fontSize="small" />
                    </IconButton>

                </Box>
                { hasError && <FormHelperText error>{ error }</FormHelperText> }
            </Box>
            {
                !isLast && <Divider className="segmentationItemDivider"/>
            }
        </React.Fragment>
    )
}

export default SegmentFilterItem;
