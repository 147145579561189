import React from 'react'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import {snakeToTitle} from '../helpers'
import {toLocalFormat} from '../Utils/dates'
import SmallListPagination from './SmallListPagination';
import Scroll from 'react-scroll';

function Row({row}) {
    const [open, setOpen] = React.useState(false)

    const attributes = (row.data && row.data.attributes) || []

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    {toLocalFormat(row.created_at)}
                </TableCell>
                <TableCell><span className="text-semi-bold">{snakeToTitle(row.type)}</span></TableCell>
                <TableCell scope="row" style={{width: '60px'}}>
                    {attributes.length > 0 &&
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>}
                    </IconButton>}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{padding: 0}} colSpan={6} className='hasBorder'>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Table size="small">
                                {attributes.map((attr) => (
                                    <TableBody key={`${attr.name}-${attr.value_to}`}>
                                        <TableRow>
                                            <TableCell><span
                                                className="text-semi-bold">{snakeToTitle(attr.name)}</span></TableCell>
                                            <TableCell>
                                                {attr.value_to}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ))}
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

const CampaignLogsBlock = props => {

    const {
        pagination,
        pageLimit,
        onPageChange,
        onLimitChange,
        logInfo
    } = props

    const renderPagination = () => {
        if (!pagination) return null

        if (pagination && pagination['total'] > 0) {
            return (
                <SmallListPagination
                    limit={pageLimit}
                    pagination={pagination}
                    onChange={p => {
                        Scroll.animateScroll.scrollToTop()
                        onPageChange(p)
                    }}
                    onLimitChange={onLimitChange}
                />
            )
        }
        return null
    }

    return (
        <div className='timeLineTable'>
            <Table>
                <TableBody>
                    {logInfo.map((row, index) => (
                        <Row key={index} row={row}/>
                    ))}
                </TableBody>
            </Table>
            {renderPagination()}
        </div>
    )
}

export default CampaignLogsBlock

