/**
 *
 * @type {number}
 */
const SMS = 1;

/**
 *
 * @type {number}
 */
const EMAIL = 2;

let ALL = [];

ALL[SMS] = 'SMS';
ALL[EMAIL] = 'Email';

export default {
    ALL,
    SMS,
    EMAIL
}