import React, { Fragment, useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import DataService from '../../Services/DataService'
import AlertToastr from '../../Utils/alert'
import { useQueryClient } from 'react-query'

const SaveSourceModal = ({open, handleClose, source}) => {

    const [name, setName] = useState('')
    const [disableButtons, setDisabledButtons] = useState(false)
    const queryClient = useQueryClient()

    useEffect(() => {
        if (source)
            setName(source.name)
        else
            setName('')
    }, [source])

    const handleCreate = () => {
        setDisabledButtons(true)
        DataService.createSource(name).then(response => {
            setName('')
            setDisabledButtons(false)
            handleClose(response.data.data)
            queryClient.invalidateQueries('sourcesList').then()
            queryClient.invalidateQueries('availableSources').then()
        })
            .catch((error) => {
                setDisabledButtons(false)
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors)
            })
    }

    const handleSave = () => {
        setDisabledButtons(true)
        DataService.updateSource(source.id, name, source.status).then(response => {
            setDisabledButtons(false)
            setName('')
            handleClose()
            queryClient.invalidateQueries('sourcesList').then()
            queryClient.invalidateQueries('sourceView').then()
        })
            .catch((error) => {
                setDisabledButtons(false)
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors)
            })
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className='modal'
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>{source ? 'Edit' : 'Create'} Source</h2>
                    </div>
                    <TextField
                        value={name}
                        onChange={e => setName(e.target.value)}
                        id="source"
                        label="Name"
                        variant="outlined"
                        margin='normal'
                    />
                    <div className="btnHolder hasPadTop twoBtn">
                        <div className="part">
                            <Button
                                disabled={!!disableButtons}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={(e) => {
                                    source ? handleSave() : handleCreate()
                                }}
                            >
                                {source ? 'Save' : 'Create'}
                            </Button>
                        </div>
                        <div className="part">
                            <Button
                                disabled={!!disableButtons}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default SaveSourceModal
