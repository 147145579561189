import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../../Contexts/AuthProvider'

const GuestRoute = ({ children }) => {
    const { isLoggedIn, loggedUser } = useAuth()

    if (isLoggedIn) {
        return <Navigate to={`/u/${loggedUser}/`} />
    }

    return children;
}

export default GuestRoute
