import React from 'react'

const TableProgressCell = ({value, value2}) => {

    const renderProgressBar = (value, secondary = false) => {
        if (value === undefined) return null

        const percent = Math.floor(value)

        const barClass = secondary ? 'tableProgressBarItem secondary': 'tableProgressBarItem'

        return (
            <div className={barClass}>
                <div className="pbItemHolder">
                    <div className="pbItem" style={{width: `${percent}%`}}/>
                </div>
                <div className="qnt">{`${percent}%`}</div>
            </div>
        )
    }

    return (
        <>
            {renderProgressBar(value)}
            {renderProgressBar(value2, true)}
        </>
    )
}

export default TableProgressCell
