import React from 'react'
import {Controller, useForm} from "react-hook-form";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {useMutation, useQueryClient} from "react-query";
import {storeRevenueTracking, updateRevenueTracking} from "../../../api";
import TextField from "@material-ui/core/TextField";
import AlertToastr from "../../../Utils/alert";

const SaveRevenueModal = (props) => {
    const {
        open,
        handleClose,
        revenue,
    } = props;

    const {control, handleSubmit} = useForm({
        mode: "onTouched",
        revenue,
    });

    const queryClient = useQueryClient();
    const storeMutation = useMutation(storeRevenueTracking)
    const updateMutation = useMutation(updateRevenueTracking)

    const onSubmit = async (data) => {
        if (!revenue) {
            storeMutation.mutateAsync(data.name)
                .then(() => {
                    handleClose();
                    queryClient.invalidateQueries('revenueTrackingList');
                })
                .catch((error) => {
                    if (error && error.response && error.response.data && error.response.data.errors) {
                        AlertToastr.showErrorAlert(error.response.data.errors)
                    }
                })

        } else {
            updateRevenueTracking(revenue.id, data.name)
                .then(() => {
                    handleClose();
                    queryClient.invalidateQueries('revenueTrackingList');
                })
                .catch((error) => {
                    if (error && error.response && error.response.data && error.response.data.errors) {
                        AlertToastr.showErrorAlert(error.response.data.errors)
                    }
                })
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} className="modal">
            <div className="modalBody">
                <div className="modalTitleHolder mb30">
                    <h2 className="mb15">
                        {revenue ? "Edit Tracking" : "Add Tracking"}
                    </h2>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue={revenue ? revenue.name : ''}
                        render={({onChange, value}) => {
                            return (
                                <TextField
                                    onChange={onChange}
                                    value={value}
                                    variant="outlined"
                                    margin="normal"
                                    label="Name"
                                />
                            );
                        }}
                    />
                    <div className="btnHolder hasPadTop twoBtn">
                        <div className="part">
                            <Button
                                disabled={storeMutation.isLoading || updateMutation.isLoading}
                                variant="contained"
                                color="primary"
                                fullWidth
                                type="submit"
                                onClick={(e) => {
                                }}
                            >
                                Save
                            </Button>
                        </div>
                        <div className="part">
                            <Button
                                disabled={storeMutation.isLoading || updateMutation.isLoading}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export default SaveRevenueModal