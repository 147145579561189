import React, {useEffect, useMemo, useState} from "react"
import {useAppDispatch} from "../Contexts/AppContext"
import PlusCircle from "../assets/img/plus_circle.svg";
import EventEmitter from "../Utils/EventEmitter";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {deleteCampaignPool, getPools, getSMSGateways} from "../api";
import ShortUrlDomainItemContextMenu from "../components/ShortUrlDomainItemContextMenu";
import ListTable from "../components/ListTable/ListTable";
import DeleteModal from "../components/modals/DeleteModal";
import Chip from "@material-ui/core/Chip";
import {Tooltip} from "@material-ui/core";
import CreatePoolModal from "../components/modals/pools/CreatePoolModal";

const CampaignPools = () => {
    const queryClient = useQueryClient()
    const {setBreadcrumbs, setSection} = useAppDispatch();
    const {setContextMenu} = useAppDispatch();
    const [pools, setPools] = useState([]);
    const [currentModel, setCurrentModel] = useState({
        id: null,
        name: "",
        senders: []
    });
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [gateways, setGateways] = useState();

    const gatewaysRequest = useQuery(["gateways"], () => getSMSGateways(), {keepPreviousData: true})

    useEffect(() => {
        if (gatewaysRequest.isSuccess) {
            setGateways(gatewaysRequest.data.data)
        }
    }, [gatewaysRequest.isSuccess, gatewaysRequest.data, gateways])

    const defaultPoolModel = useMemo(() => {
        return {
            id: null,
            name: "",
            senders: []
        }
    }, [])

    useEffect(() => {
        setBreadcrumbs([
            {label: "Settings", link: "./settings"},
            {label: "Campaign Pools"}
        ])
        setSection("settings")
    }, [setBreadcrumbs, setSection])

    useEffect(() => {
        setContextMenu([
            {
                label: "Add New Pool",
                icon: PlusCircle,
                action: () => EventEmitter.emit("CampaignPoolCreate"),
            },
        ])
    }, [setContextMenu])

    useEffect(() => {
        const onEvent = () => {
            setShowCreateModal(true)
            setCurrentModel(defaultPoolModel)
        }

        const listener = EventEmitter.addListener("CampaignPoolCreate", onEvent)
        return () => {
            listener.remove()
        }
    }, [setShowCreateModal, setCurrentModel, defaultPoolModel])


    const showDeleteConfirmation = (attr) => {
        setCurrentModel(attr)
        setShowDeleteModal(true)
    }

    const handleEditPool = (attr) => {
        setCurrentModel(attr)
        setShowUpdateModal(true)
    }

    const deleteCampaignPoolRequest = useMutation(deleteCampaignPool)
    const modelLabel = currentModel ? `"${currentModel.name}" campaign pool` : "campaign pool"
    const handleDeleteCampaignPool = async () => {
        if (!currentModel) {
            return;
        }

        await deleteCampaignPoolRequest.mutateAsync(currentModel.id)
        setShowDeleteModal(false)
        await queryClient.invalidateQueries("campaignPoolList")
        setCurrentModel(defaultPoolModel)
    }

    const tableColumns = useMemo(() => {
        return [
            {
                Header: "ID",
                accessor: "id",
            },
            {
                Header: "Name",
                accessor: "name"
            },
            {
                Header: "Senders",
                accessor: "senders",
                Cell: ({value, row}) => {
                    if (!value.length) {
                        return <span>Empty</span>
                    }
                    return value.map(sender => {
                        return (
                            <Tooltip title={
                                <div key={sender.id}>
                                    <span><b>SMS Gateway Name: </b>
                                        {gateways && gateways.map(gateway => {
                                            if (gateway.id === sender.gateway_id) {
                                                return gateway.name;
                                            }
                                        })}
                                    </span><br/>
                                    <span><b>Sender: </b> {sender.sender_name}</span><br/>
                                    <span><b>Sender type: </b>{sender.sender_type}</span><br/>
                                </div>
                            } placement="top-start" arrow>
                                <Chip label={sender.sender_name} variant="outlined" style={{marginLeft: "5px"}}/>
                            </Tooltip>
                        )
                    })
                }
            },
            {
                Header: "",
                id: "actions",
                className: "text-right",
                Cell: ({value, row}) => {
                    return (
                        <ShortUrlDomainItemContextMenu
                            item={row.original}
                            onEdit={() => handleEditPool(row.original)}
                            onDelete={() => showDeleteConfirmation(row.original)}
                        />
                    )
                }
            }
        ]
    }, [gateways])

    const poolRequest = useQuery(
        ["campaignPoolList"],
        () => getPools(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (poolRequest.isSuccess) {
            setPools(poolRequest.data.data)
        }
    }, [poolRequest.isSuccess, poolRequest.data])

    return (
        <div className="pageHolder">
            <ListTable
                columns={tableColumns}
                data={pools}
                loading={poolRequest.isLoading}
                fetching={poolRequest.isFetching}
                onSort={(sort) => {
                }}
                emptyDataText="No Pools Found"
                pagination={null}
                pageLimit={100}
                onPageChange={1}
                onLimitChange={(l) => {
                }}
                disableSearchBlock={true}
            />

            <CreatePoolModal
                open={showCreateModal}
                close={() => {
                    setShowCreateModal(false)
                }}
                pool={currentModel}
                setPool={setCurrentModel}
            />

            <CreatePoolModal
                open={showUpdateModal}
                close={() => {
                    setShowUpdateModal(false)
                }}
                pool={currentModel}
                setPool={setCurrentModel}
            />
            <DeleteModal
                loading={deleteCampaignPoolRequest.isLoading}
                open={showDeleteModal}
                handleDelete={handleDeleteCampaignPool}
                handleClose={() => setShowDeleteModal(false)}
                instance={modelLabel}
            />
        </div>
    )
}

export default CampaignPools
