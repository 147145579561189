import { storageKeys } from '../config';

class StorageService {
    clearStorage() {
        Object.values(storageKeys).forEach(key => {
            localStorage.removeItem(key);
        });
    }
}

export default new StorageService()
