import React from 'react'
import TablePagination from '@material-ui/core/TablePagination'

const ListPagination = props => {

    const {
        pagination,
        limit,
        onChange,
        onLimitChange
    } = props

    const {current_page, total} = pagination

    return (
        <TablePagination
            rowsPerPageOptions={[15, 50, 100]}
            component="div"
            count={total}
            page={current_page-1}
            SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
            }}
            onChangePage={(event, page) => {
                onChange(page+1)
            }}
            rowsPerPage={limit}
            onChangeRowsPerPage={event => {
                onLimitChange(parseInt(event.target.value))
            }}
        />
    )
}

export default ListPagination
