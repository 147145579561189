import React, { Fragment, useState, useEffect, useCallback } from 'react'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Folder from '../../assets/img/folder.svg'
import File from '../../assets/img/file_blank.svg'
import Button from '@material-ui/core/Button'
import MatchFormatModal from './match-format.modal'
import ReactDropzone from 'react-dropzone'
import DataService from '../../Services/DataService'
import { connect } from 'react-redux'
import AlertToastr from '../../Utils/alert'
import CircularProgress from '@material-ui/core/CircularProgress'
import { setNewImport } from '../../Store/modules/sources/actions'

const ImportFileModal = ({open, handleClose, source, list, setNewImport}) => {
    const [openMatchModal, setOpenMatchModal] = useState(false)
    const [selectedSource, setSelectedSource] = useState('')
    const [availableLists, setAvailableLists] = useState([])
    const [availableSources, setAvailableSources] = useState([])
    const [selectedList, setSelectedList] = useState('')
    const [isDropdownListMode, setIsDropdownListMode] = useState(true)
    const [isDropdownSourceMode, setIsDropdownSourceMode] = useState(true)
    const [newListName, setNewListName] = useState('')
    const [newSourceName, setNewSourceName] = useState('')
    const [isUploading, setIsUploading] = useState(false)
    const [isFileUploaded, setIsFileUploaded] = useState(false)
    const [importInfo, setImportInfo] = useState(null)
    const [mappingInfo, setMappingInfo] = useState(null)
    const [filename, setFileName] = useState('')

    const onSourceChanged = useCallback((source) => {
        if (!source || !source.id) {
            setIsDropdownListMode(false)
            return
        }
        setSelectedSource(source)
        setIsDropdownListMode(true)
        setAvailableLists(source.lists)
        if (list) {
            setSelectedList(source.lists.find(l => l.id === list.id))
            return
        }
        if (source && source.lists[0]) {
            setSelectedList(source.lists[0])
        } else
            setIsDropdownListMode(false)
    }, [list])

    useEffect(() => {
        async function fetchSources() {
            let response = await DataService.getSourcesWithList()
            if (!response || !response.data)
                return
            if (response.data.data)
                setAvailableSources(response.data.data)
            if (source) {
                onSourceChanged(response.data.data.find(s => s.id === source.id))
                return
            }
            if (!response.data.data || !response.data.data[0]) {
                setIsDropdownListMode(false)
                setIsDropdownSourceMode(false)
                return
            } else {
                setIsDropdownSourceMode(true)
                onSourceChanged(response.data.data[0])
                return
            }
        }

        if (!open) {
            return
        }
        if (isUploading)
            return
        fetchSources().then()
    }, [source, open, isUploading, onSourceChanged])

    const handleClickOpenMatchModal = () => {
        setOpenMatchModal(true)
    }

    const isDropzoneDisabled = () => {
        if ((!selectedSource && isDropdownSourceMode) || (!newSourceName && !isDropdownSourceMode)) {
            return true
        }
        if ((!selectedList && isDropdownListMode) || (!newListName && !isDropdownListMode)) {
            return true
        }
        return false
    }

    const onDrop = async (files) => {
        setIsUploading(true)
        setFileName(files[0].name)
        let currentList = selectedList
        let currentSource = selectedSource
        if (!isDropdownSourceMode) {
            try {
                let response = await DataService.createSource(newSourceName)
                currentSource = response.data.data
                setSelectedSource(response.data.data)
            } catch (error) {
                AlertToastr.showErrorResponseAlert(error)
            }

        }
        if (!isDropdownListMode) {
            try {
                let response = await DataService.createList(currentSource.id, newListName, 'active')
                currentList = response.data.data
                setSelectedList(response.data.data)
            } catch (error) {
                AlertToastr.showErrorResponseAlert(error)
            }

        }
        DataService.uploadFile(currentList.id, files[0]).then(response => {
            setImportInfo(response.data.data)
            setMappingInfo(response.data.mapping)

            setIsFileUploaded(true)
        })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors)
            })
    }

    const onClose = () => {
        setIsUploading(false)
        setIsDropdownListMode(true)
        setIsDropdownSourceMode(true)
        setFileName('')
        setIsFileUploaded(false)
        setImportInfo(null)
        setMappingInfo(null)
        setNewListName('')
        setNewSourceName('')
        handleClose()
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={onClose}
                className='modal'
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>Import File</h2>
                    </div>
                    <form noValidate autoComplete="off">
                        {isDropdownSourceMode &&
                        <TextField
                            value={selectedSource}
                            onChange={e => onSourceChanged(e.target.value)}
                            disabled={isUploading}
                            id="source"
                            label="Source"
                            variant="outlined"
                            margin='normal'
                            select
                        >
                            {availableSources?.map((option) => (
                                <MenuItem key={option.id} value={option}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        }
                        {!isDropdownSourceMode &&
                        <TextField
                            value={newSourceName}
                            onChange={e => setNewSourceName(e.target.value)}
                            disabled={isUploading}
                            id="newSourceName"
                            label="Source"
                            variant="outlined"
                            margin='normal'
                        />
                        }
                        {availableSources && availableSources.length > 0 && !isUploading && <span onClick={(e) => {
                            setIsDropdownSourceMode(!isDropdownSourceMode)
                            setIsDropdownListMode(!isDropdownSourceMode)
                        }} className="linkPrimary text-mute text-semi-bold cursor-pointer">
                            {isDropdownSourceMode ? 'Create new source' : 'Select existing source'}
                        </span>}
                        {isDropdownListMode &&
                        <TextField
                            value={selectedList}
                            onChange={(e) => setSelectedList(e.target.value)}
                            disabled={isUploading}
                            id="list"
                            label="List"
                            variant="outlined"
                            margin='normal'
                            select
                        >
                            {availableLists.map((option) => (
                                <MenuItem key={option.id} value={option}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>}
                        {!isDropdownListMode &&
                        <TextField
                            value={newListName}
                            onChange={e => setNewListName(e.target.value)}
                            disabled={isUploading}
                            id="newListName"
                            label="List"
                            variant="outlined"
                            margin='normal'
                        />}
                        <div className="linkHolder mb30">
                            {availableLists.length > 0 && isDropdownSourceMode && !isUploading &&
                            <span onClick={(e) => setIsDropdownListMode(!isDropdownListMode)}
                                  className="linkPrimary text-mute text-semi-bold cursor-pointer">
                                {isDropdownListMode ? 'Create new list' : 'Select existing list'}
                            </span>}
                        </div>

                        {!isUploading &&
                        (
                            <Fragment>
                                <p className="mb20">Drag and drop, or click to use the browser.</p>
                                <ReactDropzone accept=".csv, .xml, .xlsx, .tsv" onDrop={onDrop}
                                               disabled={isDropzoneDisabled()}>
                                    {({
                                          getRootProps,
                                          getInputProps,
                                          isDragActive,
                                          isDragReject,
                                          rejectedFiles
                                      }) => {
                                        return (
                                            <div className="fileDropHolder" {...getRootProps()} >
                                                <input {...getInputProps()} />
                                                <div className="icoHolder mb15">
                                                    <img src={Folder} alt=""/>
                                                </div>
                                                <div className="captionHolder text-center text-light text-mute">
                                                    CSV supported
                                                </div>
                                            </div>
                                        )
                                    }}
                                </ReactDropzone>
                            </Fragment>
                        )

                        }
                        {isUploading && !isFileUploaded &&
                        <div className="loaderHolder text-center">
                            <CircularProgress/>
                        </div>
                        }
                        {isFileUploaded &&
                        <div className="fileProgressHolder">
                            <ul className="fileProgress">
                                <li className="fileProgressItem">
                                    <div className="icoHolder">
                                        <img src={File} alt=""/>
                                    </div>
                                    <div className="progress">
                                        <div className="progressStatus mb5">
                                            <div className="name text-mute text-semi-bold">{filename}</div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        }
                        <div className="btnHolder hasPadTop twoBtn">
                            <div className="part">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    disabled={!isFileUploaded}
                                    onClick={handleClickOpenMatchModal}
                                >
                                    Continue
                                </Button>
                            </div>
                            <div className="part">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
            <MatchFormatModal
                open={openMatchModal}
                importInfo={importInfo}
                mappingInfo={mappingInfo}
                handleClose={(e) => {
                    setOpenMatchModal(false)
                    onClose()
                }}
                handleBack={(e) => {
                    setOpenMatchModal(false)
                }}
                filename={filename}
                setNewImport={setNewImport}/>
        </Fragment>
    )
}

const mapDispatchToProps = {
    setNewImport
}

export default connect(null, mapDispatchToProps)(ImportFileModal)

