import React, {Fragment, useEffect, useMemo, useState, useCallback} from "react"
import {useQuery, useQueryClient} from "react-query"
import {useParams} from "react-router-dom"
import {getSubsegments, refreshSegments} from "../../api"
import {useAppDispatch} from "../../Contexts/AppContext"
import {toLocalFormat} from "../../Utils/dates"
import LoadingValue from "../../components/LoadingValue"
import SearchInput from "../../components/SearchInput"
import BasicTable from "../../components/BasicTable"
import {useSegmentState} from "../../Contexts/SegmentContext"
import SubSegmentsPageActions from "./SubSegmentsPageActions"
import AudienceHeader from "../../components/AudienceHeader"
import {useQueryState} from "use-location-state";
import {segmentLinkWrapper} from "../../helpers";
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import AlertToastr from "../../Utils/alert";

const SubSegmentsPage = () => {
    const params = useParams();
    const {segment, segmentRequest} = useSegmentState();
    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState([]);
    const [page, setPage] = useQueryState("page", 1);
    const [limit, setLimit] = useQueryState("limit", 15);
    const {setBreadcrumbs, setSection} = useAppDispatch();
    const [selectedRows, setSelectedRows] = useState([]);
    const queryClient = useQueryClient();

    const segmentLink = useCallback((value) => {
        return `/u/${params.loggedUser}/audiences/${params.segmentId}/segments/${value}`;
    }, [params]);

    const tableColumns = useMemo(() => {
        return [
            {
                Header: "Audience Name",
                accessor: "name",
                Cell: ({ value, row }) => segmentLinkWrapper(segmentLink(row.original.id), value),
            },
            {
                Header: "Created Date",
                accessor: "created_at",
                Cell: ({ value, row }) => segmentLinkWrapper(segmentLink(row.original.id), toLocalFormat(value)),
            },
            {
                Header: "Lifetime Revenue",
                accessor: "revenue",
                Cell: ({ value, row }) => segmentLinkWrapper(segmentLink(row.original.id), <LoadingValue value={value}/>),
            },
            {
                Header: "Revenue Per Record",
                accessor: "revenue_per_record",
                Cell: ({ value, row }) => segmentLinkWrapper(segmentLink(row.original.id), <LoadingValue value={value}/>),
            },
            {
                Header: "Leads",
                accessor: "total_leads",
                disableSortBy: true,
                Cell: ({ value, row }) => segmentLinkWrapper(segmentLink(row.original.id), <LoadingValue value={value}/>),
            },
        ]
    }, [params]);

    const tableData = useMemo(() => {
        return list.map(item => {
            return {...item, status: true}
        })
    }, [list]);

    const subsegmentsRequest = useQuery(
        ["subsegmentsList", params.segmentId, page, limit, search, sorting],
        () => getSubsegments(params.segmentId, page, limit, search, sorting),
        {keepPreviousData: true}
    );

    useEffect(() => {
        if (subsegmentsRequest.isSuccess) {
            setList(subsegmentsRequest.data.data)
            const paginationData = subsegmentsRequest.data.meta
            if (paginationData.last_page < page) {
                setPage(1)
            }
            setPagination(paginationData)
        }
    }, [
        subsegmentsRequest.isSuccess,
        subsegmentsRequest.data,
        page,
        setPage,
    ]);

    useEffect(() => {
        setBreadcrumbs([
            {label: "Audiences", link: "./"},
            {
                label: segment && segment.name,
                link: `./audiences/${segment?.id}/leads`,
                loading: subsegmentsRequest.isLoading
            },
            {
                label: "Segments"
            }
        ])
        setSection("segments")
    }, [setBreadcrumbs, setSection, segment, subsegmentsRequest.isLoading]);

    const renderSearchBlock = () => {
        return (
            <div className="searchHolder">
                <div className="inputHolder">
                    <SearchInput
                        search={search}
                        onChange={s => setSearch(s)}
                    />
                </div>
                <div className="refresh-sub-segments">
                    <Button
                        className="refresh-button"
                        disabled={false}
                        variant="contained"
                        color="primary"
                        startIcon={<RefreshIcon/>}
                        size="small"
                        onClick={refresh}
                    >
                        Refresh
                    </Button>
                </div>
            </div>
        )
    }

    const refresh = () => {
        const ids = segment.segments.map((segment) => segment.id);

        refreshSegments(ids)
            .then(() => {
                queryClient.invalidateQueries("subsegmentsList")
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors)
            })
    };

    return (
        <Fragment>
            <AudienceHeader/>
            <div className="pageTableBlock">
                {renderSearchBlock()}
                <div className="tableHolder hasCheckboxes rowClickable">
                    <BasicTable
                        columns={tableColumns}
                        data={tableData}
                        loading={subsegmentsRequest.isLoading}
                        fetching={subsegmentsRequest.isFetching}
                        isSelectionColumn={true}
                        onSelectedRows={setSelectedRows}
                        onSort={setSorting}
                        emptyDataText="No Segments Found"
                        pagination={pagination}
                        pageLimit={limit}
                        onPageChange={setPage}
                        onLimitChange={setLimit}
                    />
                    <SubSegmentsPageActions
                        segment={segment}
                        loading={segmentRequest.isLoading}
                        search={search}
                        sorting={sorting}
                        selectedRows={selectedRows}
                        // visibleColumns={visibleColumns}
                    />
                </div>
            </div>
        </Fragment>

    )
}

export default SubSegmentsPage
