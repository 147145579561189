import React, {useEffect, useMemo, useState, useCallback} from "react";
import {useParams } from "react-router-dom";
import {useQueries, useQuery} from "react-query";
import {useQueryState} from "use-location-state";
import SourceHeader from "../../components/SourceHeader";
import {getListAggregations, getSource, getSourceLists} from "../../api";
import SearchInput from "../../components/SearchInput";
import LoadingValue from "../../components/LoadingValue";
import {useAppDispatch} from "../../Contexts/AppContext";
import SourcePageActions from "./SourcePageActions";
import BasicTable from "../../components/BasicTable";
import TableProgressCell from "../../components/TableProgressCell";
import {listLinkWrapper} from "../../helpers";

const SourcePage = () => {
    const params = useParams();
    const [source, setSource] = useState(null);
    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState([]);
    const [page, setPage] = useQueryState("page", 1);
    const [limit, setLimit] = useQueryState("limit", 50);
    const {setBreadcrumbs, setSection} = useAppDispatch();
    const [selectedRows, setSelectedRows] = useState([]);

    const listLink = useCallback((value) => {
        return `/u/${params.loggedUser}/sources/${params.sourceId}/${value}`;
    }, [params]);

    const tableColumns = useMemo(() => {
        return [
            {
                Header: "List Name",
                accessor: "name",
                Cell: ({ value, row }) => listLinkWrapper(listLink(row.original.id), value),
            },
            {
                Header: "Total Leads",
                accessor: "total_leads",
                disableSortBy: true,
                Cell: ({ value, row }) => listLinkWrapper(listLink(row.original.id), <LoadingValue value={value} />),
            },
            {
                Header: "Unique Today",
                accessor: "unique_today",
                disableSortBy: true,
                Cell: ({ value, row }) => listLinkWrapper(listLink(row.original.id), <LoadingValue value={value} />),
            },
            {
                Header: "Total Today",
                accessor: "added_today",
                disableSortBy: true,
                Cell: ({ value, row }) => {
                    return listLinkWrapper(listLink(row.original.id), <LoadingValue value={value} />);
                },
            },
            {
                Header: "Blacklisted",
                accessor: "total_blacklisted_leads",
                disableSortBy: true,
                Cell: ({ value, row }) => listLinkWrapper(listLink(row.original.id), <LoadingValue value={value} />),
            },
            {
                Header: "Flagged",
                accessor: "total_flagged_leads",
                disableSortBy: true,
                Cell: ({ value, row }) => listLinkWrapper(listLink(row.original.id), <LoadingValue value={value} />),
            },
            {
                Header: "Active Phones/Emails",
                accessor: "percentage_verified",
                disableSortBy: true,
                Cell: ({ value, row }) => {
                    const { percentage_verified, percentage_lookup, id } = row.original;
                    const progress = <TableProgressCell value={percentage_verified} value2={percentage_lookup} />;
                    return listLinkWrapper(listLink(id), <LoadingValue value={value} renderedValue={progress} />)
                },
            },
        ];
    }, [params.sourceId]);

    const { isSuccess: isSourceSuccess, data: sourceData, isLoading: isSourceLoading } = useQuery(
        ["sourceView", params.sourceId],
        () => getSource(params.sourceId),
        { keepPreviousData: true },
    );

    const { isSuccess, data, isLoading, isFetching } = useQuery(
        ["sourceLists", params.sourceId, page, limit, search, sorting],
        () => getSourceLists(params.sourceId, page, limit, search, sorting),
        { keepPreviousData: true, refetchOnMount: false },
    );

    const listAggregationsRequests = useQueries(list.map(item => {
        return {
            queryKey: ["listAggregations", item.id],
            queryFn: () => getListAggregations(item.id),
            cacheTime: 1000 * 300,
            refetchOnMount: false,
        };
    }));

    useEffect(() => {
        listAggregationsRequests.forEach(req => {
            if (req.isSuccess) {
                setList(prev => {
                    const updatedItem = req.data.data.data;
                    const newList = [...prev];
                    const index = newList.findIndex(s => s.id === updatedItem.id);
                    if (index !== -1) {
                        newList[index] = {
                            ...newList[index],
                            total_leads: updatedItem.total_leads,
                            unique_today: updatedItem.unique_today,
                            added_today: updatedItem.added_today,
                            // duplicate: updatedItem.duplicate,
                            total_blacklisted_leads: updatedItem.total_blacklisted_leads,
                            total_flagged_leads: updatedItem.total_flagged_leads,
                            percentage_lookup: updatedItem.percentage_lookup,
                            percentage_verified: updatedItem.percentage_verified,
                            // leads_count: updatedSegment.leads_count,
                            // duplicates_count: updatedSegment.duplicates_count,
                        };
                    }
                    return newList;
                });
            }
        });
    }, [listAggregationsRequests]);

    useEffect(() => {
        setBreadcrumbs([
            { label: "Sources", link: `./sources` },
            { label: source && source.name, loading: isSourceLoading },
        ]);
        setSection("sources");
    }, [setBreadcrumbs, source, setSection, isSourceLoading]);

    useEffect(() => {
        if (isSourceSuccess) {
            setSource(sourceData.data);
        }
    }, [isSourceSuccess, sourceData, setSource]);

    useEffect(() => {
        if (isSuccess) {
            setList(data.data);
            const paginationData = data.meta;
            if (paginationData.last_page < page) {
                setPage(1);
            }
            setPagination(paginationData);
        }
    }, [isSuccess, data, page, setPage]);


    // const handleItemClick = item => {
    //     history(`/sources/${sourceId}/${item.id}`)
    // }

    const renderSearchBlock = () => {
        return (
            <div className="searchHolder">
                <div className="inputHolder">
                    <SearchInput
                        search={search}
                        onChange={setSearch}
                    />
                </div>
            </div>
        );
    };

    return (
        <div className="pageHolder">
            <div className="pageHeadingBlock mb15">
                <SourceHeader
                    source={source}
                    loading={isSourceLoading}
                />
            </div>
            <div className="pageTableBlock">
                {renderSearchBlock()}
                <div className="tableHolder hasCheckboxes rowClickable">
                    <BasicTable
                        columns={tableColumns}
                        data={list}
                        loading={isLoading}
                        fetching={isFetching}
                        isSelectionColumn={true}
                        onSelectedRows={setSelectedRows}
                        onSort={setSorting}
                        emptyDataText="No Lists Found"
                        pagination={pagination}
                        pageLimit={limit}
                        onPageChange={setPage}
                        onLimitChange={setLimit}
                    />
                </div>
            </div>
            <SourcePageActions
                source={source}
                loading={isSourceLoading}
                selectedRows={selectedRows}
                bulkDone={() => {
                    setList(prev => [...prev]);
                }}
            />
        </div>
    );
};

export default SourcePage;
