import React, { useState } from "react";
import MomentUtils from "@date-io/moment";
import moment from "moment/moment";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import useDebouncedCallback from "../../Hooks/useDebounceCallback";

const dateRangeOptions = [
    { id: "today", name: "Today" },
    { id: "yesterday", name: "Yesterday" },
    { id: "this_week", name: "This Week" },
    { id: "last_week", name: "Last Week" },
    { id: "this_month", name: "This Month" },
    { id: "last_month", name: "Last Month" },
    { id: "this_year", name: "This Year" },
    { id: "all_dates", name: "All time" }
];

const CampaignStatPeriod = ({ initialCampaignStatPeriod, disabled, onChangePeriod }) => {
    const [campaignStatType, setCampaignStatType] = useState("range");
    const [period, setPeriod] = useState(initialCampaignStatPeriod);

    const onChangePeriodDebounce = useDebouncedCallback(onChangePeriod, 500);

    const onSetCampaignType = ({ target }) => {
        const { value } = target;
        setCampaignStatType(value);

        if (value === "custom") {
            const nextPeriod = {
                stat_from: moment(new Date()),
                stat_to: moment(new Date()),
            };
            onChangePeriod({
                stat_from: nextPeriod.stat_from.format("YYYY-MM-DD"),
                stat_to: nextPeriod.stat_to.format("YYYY-MM-DD"),
            })

            setPeriod(nextPeriod);
            return;
        }

        const nextPeriod = {
            period: "today",
        };
        onChangePeriod(nextPeriod);
        setPeriod(nextPeriod);
    }

    const onCampaignPeriodFromChange = (date) => {
        const nextPeriod = {
            ...period,
            stat_from: date,
        };

        onChangePeriodDebounce({
            ...initialCampaignStatPeriod,
            stat_from: date.format("YYYY-MM-DD")
        });
        setPeriod(nextPeriod);
    }

    const onCampaignPeriodToChange = (date) => {
        const nextPeriod = {
            ...period,
            stat_to: date,
        };

        onChangePeriodDebounce({
            ...initialCampaignStatPeriod,
            stat_to: date.format("YYYY-MM-DD")
        });
        setPeriod(nextPeriod);
    }

    const onCampaignPeriodChange = ({ target }) => {
        const { value } = target;

        const nextPeriod = {
            period: value,
        }
        onChangePeriod(nextPeriod);
        setPeriod(nextPeriod)
    }

    return (
        <div className="input" style={{ display: "flex", columnGap: "5px" }}>

            <FormControl variant="outlined" margin="none">
                <InputLabel shrink>Stat Period Type</InputLabel>
                <Select
                    value={campaignStatType}
                    onChange={onSetCampaignType}
                    disabled={disabled}
                    style={{minWidth: 200}}
                    autoWidth
                >
                    <MenuItem value="range" dense>
                        Range
                    </MenuItem>
                    <MenuItem value="custom" dense>
                        Custom Date
                    </MenuItem>
                </Select>
            </FormControl>
            {
                campaignStatType === "range" && (
                    <FormControl variant="outlined" margin="none">
                        <InputLabel shrink>Period</InputLabel>
                        <Select
                            value={period.period}
                            onChange={onCampaignPeriodChange}
                            disabled={disabled}
                            style={{minWidth: 200}}
                            autoWidth
                        >
                            {
                                dateRangeOptions.map((item) => (
                                    <MenuItem
                                        key={item.id}
                                        value={item.id}
                                        dense
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                )
            }
            {
                campaignStatType === "custom" && (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            style={{ maxWidth: 200 }}
                            disableToolbar
                            disabled={disabled}
                            label="Date From"
                            placeholder="mm/dd/yyyy"
                            value={period.stat_from}
                            onChange={onCampaignPeriodFromChange}
                            format="MM/DD/yyyy"
                            variant="inline"
                            inputVariant="outlined"
                            maxDate={period?.stat_to || undefined}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                        <KeyboardDatePicker
                            style={{ maxWidth: 200 }}
                            disableToolbar
                            disabled={disabled}
                            label="Date To"
                            placeholder="mm/dd/yyyy"
                            value={period.stat_to}
                            onChange={onCampaignPeriodToChange}
                            format="MM/DD/yyyy"
                            variant="inline"
                            inputVariant="outlined"
                            minDate={period?.stat_from || undefined}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                )
            }
        </div>

    )
}

export default CampaignStatPeriod;
