import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { toLocalFormat } from '../../Utils/dates'
import Scroll from 'react-scroll'
import SmallListPagination from '../../components/SmallListPagination'

function Row(props) {
    const {row} = props
    const [open, setOpen] = React.useState(false)

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    {toLocalFormat(row.date)}
                </TableCell>
                <TableCell><span className="text-semi-bold">{row.message}</span></TableCell>
                <TableCell scope="row" style={{width: '60px'}}>
                    {row.details.length > 0 &&
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>}
                    </IconButton>}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{padding: 0}} colSpan={6} className='hasBorder'>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Table size="small">
                                {row.details.map((detailsRow, index) => {
                                    return (
                                        <TableBody key={index}>
                                            <TableRow>
                                                <TableCell><span
                                                    className="text-semi-bold">{detailsRow.attribute}</span></TableCell>
                                                <TableCell>
                                                    {detailsRow.detail}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )
                                })}
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

Row.propTypes = {
    row: PropTypes.shape({
        date: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        details: PropTypes.arrayOf(
            PropTypes.shape({
                attribute: PropTypes.string.isRequired,
                detail: PropTypes.string.isRequired
            })
        ).isRequired
    }).isRequired
}


const TimeLineComponent = props => {

    const {
        pagination,
        pageLimit,
        onPageChange,
        onLimitChange,
        logInfo
    } = props

    const renderPagination = () => {
        if (!pagination) return null

        if (pagination && pagination['total'] > 0) {
            return (
                <SmallListPagination
                    limit={pageLimit}
                    pagination={pagination}
                    onChange={p => {
                        Scroll.animateScroll.scrollToTop()
                        onPageChange(p)
                    }}
                    onLimitChange={onLimitChange}
                />
            )
        }
        return null
    }

    return (
        <div className='timeLineTable'>
            <Table>
                <TableBody>
                    {logInfo.map((row, index) => (
                        <Row key={index} row={row}/>
                    ))}
                </TableBody>
            </Table>
            {renderPagination()}
        </div>
    )
}

export default TimeLineComponent
