import React, {useEffect, useState, useMemo, useCallback} from "react";
import DataService from "../../Services/DataService";
import AlertToastr from "../../Utils/alert";
import ListTable from "../../components/ListTable/ListTable";
import {useParams} from "react-router-dom";
import {useQuery, useQueryClient} from "react-query";
import {getList, getListLeads, getSource} from "../../api";
import ListHeader from "../../components/ListHeader";
import {useAppDispatch} from "../../Contexts/AppContext";
import ListPageActions from "./ListPageActions";
import {leadLinkWrapper} from "../../helpers";
import {toLocalFormat} from "../../Utils/dates";
import LeadStatus from "../../components/LeadStatus";
import {storageKeys} from "../../config";
import {useQueryState} from "use-location-state";

const ListPage = () => {
    const {sourceId, listId, loggedUser} = useParams();
    const [list, setList] = useState(null);
    const [leadsAttributes, setLeadsAttributes] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [source, setSource] = useState(null);
    const [leads, setLeads] = useState([]);
    const [search, setSearch] = useState('');
    const [sorting, setSorting] = useState([]);
    const [page, setPage] = useQueryState("page", 1);
    const [limit, setLimit] = useQueryState("limit", 50);

    const [pagination, setPagination] = useState(null);
    const queryClient = useQueryClient();
    const [updatingStatus, setUpdatingStatus] = useState(false);
    const {setBreadcrumbs, setSection} = useAppDispatch();

    const leadLink = useCallback((value) => {
        return `/u/${loggedUser}/leads/${value}`
    }, [loggedUser]);

    const tableColumns = useMemo(() => {
        const attributes = leadsAttributes.map(attr => {
            return {
                Header: attr.name,
                accessor: `${attr.id}`,
                Cell: ({ value, row }) => leadLinkWrapper(leadLink(row.original.id), value, row),
            };
        });
        return [
            {
                Header: "ID",
                accessor: "id",
                Cell: ({ value, row }) => leadLinkWrapper(leadLink(row.original.id), value, row),
            },
            {
                Header: "Updated Date",
                accessor: "updated_at",
                Cell: ({ value, row }) => leadLinkWrapper(leadLink(row.original.id), toLocalFormat(value), row),
            },
            ...attributes,
            {
                Header: "Revenue",
                accessor: "revenue",
            },
            {
                Header: "Duplicates",
                accessor: "duplicates_count",
                Cell: ({ value, row }) => leadLinkWrapper(leadLink(row.original.id), value, row),
            },
            {
                Header: "Status",
                accessor: "status",
                className: "col-sticky text-right",
                Cell: ({ value }) => <LeadStatus status={value}/>,
            },
        ];
    }, [leadsAttributes]);

    const tableValues = useMemo(() => {
        return leads.map(item => {
            const leadValues = item.lead_values;
            const newItem = {...item};
            leadValues.forEach(value => {
                const attrId = value && value.attribute && value.attribute.id;
                if (attrId)
                    newItem[attrId] = newItem[attrId]
                        ? `${newItem[attrId]}; ${value.value}`
                        : value.value;
            });
            delete newItem["lead_values"];
            return newItem;
        });
    }, [leads]);

    const {isSuccess, data, isLoading, isFetching} = useQuery(
        ["listLeads", listId, page, limit, search, sorting],
        () => getListLeads(listId, page, limit, search, sorting),
        {keepPreviousData: true},
    );

    const tableRecordsInfo = useMemo(() => {
        const info = [];
        if (pagination) {
            if (pagination.total)
                info.push({count: pagination.total, label: "lead"});
            if (pagination.total_blacklisted)
                info.push({count: pagination.total_blacklisted, label: "blacklisted lead"});
        }
        return info;
    }, [pagination]);

    const {
        isSuccess: isListSuccess,
        data: listData,
        isLoading: isListLoading,
    } = useQuery(
        ["listView", listId],
        () => getList(listId),
        {keepPreviousData: true},
    );

    const {
        isSuccess: isSourceSuccess,
        data: sourceData,
        isLoading: isSourceLoading,
    } = useQuery(
        ["sourceView", sourceId],
        () => getSource(sourceId),
        {keepPreviousData: true},
    );

    useEffect(() => {
        setBreadcrumbs([
            {label: "Sources", link: `./sources`},
            {label: source && source.name, link: `./sources/${sourceId}`, loading: !source},
            {label: list && list.name, loading: !list},
        ]);
        setSection("sources");
    }, [setBreadcrumbs, source, list, sourceId, setSection]);

    useEffect(() => {
        if (isSuccess) {
            setLeads(data.data);
            const paginationData = data.meta;
            if (paginationData.last_page < page) {
                setPage(1);
            }
            setPagination(paginationData);
        }
    }, [isSuccess, data, page, setPage]);

    useEffect(() => {
        if (isListSuccess) {
            setList(listData.data);

            if (listData.attributes) {
                setLeadsAttributes(listData.attributes);
            }
        }
    }, [isListSuccess, listData, setList]);

    useEffect(() => {
        if (isSourceSuccess) setSource(sourceData.data);
    }, [isSourceSuccess, sourceData, setSource]);

    const updateListStatus = () => {
        setUpdatingStatus(true);
        DataService.updateList(list.id, list.name, !list.is_active ? "active" : "inactive")
            .then(() => {
                queryClient.invalidateQueries("listView").then(() => {
                    setUpdatingStatus(false);
                });
            })
            .catch((error) => {
                setUpdatingStatus(false);
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message);
            });
    };

    return (
        <div className="pageHolder">
            <div className="pageHeadingBlock mb15">
                <ListHeader
                    list={list}
                    isSuccess={isListSuccess}
                    isLoading={isListLoading}
                    isFetching={updatingStatus}
                    updateListStatus={updateListStatus}
                />
            </div>
            <ListTable
                columns={tableColumns}
                data={tableValues}
                loading={isLoading}
                fetching={isFetching}
                isSelectionColumn={true}
                onSelectedRows={setSelectedRows}
                onSort={setSorting}
                emptyDataText="No Leads Found"
                pagination={pagination}
                pageLimit={limit}
                onPageChange={setPage}
                onLimitChange={setLimit}
                onSearch={setSearch}
                recordsInfo={tableRecordsInfo}
                hiddenColumnsStorageKey={storageKeys.leadsHiddenColumnsInList}
            />
            <ListPageActions
                source={source}
                list={list}
                selectedRows={selectedRows}
                loading={isListLoading || isSourceLoading}
            />
        </div>
    );
};

export default ListPage;
