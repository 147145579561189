import React, {Fragment, useEffect, useState} from 'react'
import {useAppDispatch} from '../../Contexts/AppContext'
import EventEmitter from '../../Utils/EventEmitter'
import Exit from '../../assets/img/exit.svg'
import PlusCircle from '../../assets/img/plus_circle.svg'
import ImportFileModal from '../../components/modals/ImportFileModal'
import SaveSourceModal from '../../components/modals/SaveSourceModal'
import Delete from '../../assets/img/delete.svg'
import Chevron from '../../assets/img/chevron_big_right.svg'
import DeleteModal from '../../components/modals/DeleteModal'
import DataService from '../../Services/DataService'
import AlertToastr from '../../Utils/alert'
import {useMutation, useQueryClient} from 'react-query'
import {sourcesLookupSMS, sourcesLookupEmail} from '../../api'
import ConfirmModal from '../../components/modals/ConfirmModal'
import EmailLookupConfirmModal from "../../components/modals/lookups/EmailLookupConfirmModal";
import SmsLookupConfirmModal from "../../components/modals/lookups/SmsLookupConfirmModal";
import SmsDNCConfirmModal from "../../components/modals/lookups/SmsDNCConfirmModal";

const SourcesPageActions = ({selectedRows, bulkDone}) => {

    const {setContextMenu} = useAppDispatch()
    const [showImportModal, setShowImportModal] = useState(false)
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showLookupModalEmail, setShowLookupModalEmail] = useState(false)
    const [showLookupModalSMS, setShowLookupModalSMS] = useState(false)
    const [showLookupModalSMSDNC, setShowLookupModalSMSDNC] = useState(false)
    const queryClient = useQueryClient()
    const lookupSMSMutation = useMutation(sourcesLookupSMS)
    const lookupEmailMutation = useMutation(sourcesLookupEmail)

    useEffect(() => {
        const items = [
            {
                label: 'Import File',
                icon: Exit,
                action: () => EventEmitter.emit('ImportFile'),
            },
            {
                label: 'Add New Source',
                icon: PlusCircle,
                action: () => EventEmitter.emit('SourceCreate'),
            },
        ]
        if (selectedRows.length) {
            items.push({
                label: 'Run Lookup',
                icon: Chevron,
                actionSMS: () => EventEmitter.emit('SourcesRunLookupSMS'),
                actionSmsDNC: () => EventEmitter.emit('SourcesRunSmsDNC'),
                actionEmail: () => EventEmitter.emit('SourcesRunLookupEmail')
            })

            items.push({
                label: 'Delete Selection',
                icon: Delete,
                action: () => EventEmitter.emit('SourceBulkDeletion'),
                iconStyle: {transform: 'rotate(45deg)'}
            })
        }
        setContextMenu(items)
    }, [setContextMenu, selectedRows])

    useEffect(() => {
        const onEvent = () => {
            setShowImportModal(true)
        }

        const listener = EventEmitter.addListener('ImportFile', onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowSaveModal(true)

        const listener = EventEmitter.addListener('SourceCreate', onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowDeleteModal(true)

        const listener = EventEmitter.addListener('SourceBulkDeletion', onEvent)
        return () => {
            listener.remove()
        }
    }, [])
    useEffect(() => {
        const onEvent = () => setShowLookupModalEmail(true)

        const listener = EventEmitter.addListener('SourcesRunLookupEmail', onEvent)
        return () => {
            listener.remove()
        }
    }, [lookupEmailMutation, selectedRows])
    useEffect(() => {
        const onEvent = () => setShowLookupModalSMS(true)

        const listener = EventEmitter.addListener('SourcesRunLookupSMS', onEvent)
        return () => {
            listener.remove()
        }
    }, [lookupSMSMutation, selectedRows])

    useEffect(() => {
        const onEvent = () => setShowLookupModalSMSDNC(true)

        const listener = EventEmitter.addListener('SourcesRunSmsDNC', onEvent)
        return () => {
            listener.remove()
        }
    }, [lookupSMSMutation, selectedRows])

    const deleteItems = () => {
        setShowDeleteModal(false)
        DataService.deleteSources(selectedRows)
            .then(() => {
                queryClient.invalidateQueries('sourcesList').then()
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors)
            })
    }

    const lookupItemsSMS = async (gatewayId) => {
        const data = {
            ids: selectedRows,
            type: 1,
            setting_gateway_id: gatewayId
        }
        await lookupSMSMutation.mutateAsync(data)
        setShowLookupModalSMS(false)
        AlertToastr.showAlert('Lookup has been started')
        if (bulkDone) {
            bulkDone()
        }
    }

    const lookupItemsSmsDNC = async (gatewayId) => {
        const data = {
            ids: selectedRows,
            type: 3,
            setting_gateway_id: gatewayId
        }
        await lookupSMSMutation.mutateAsync(data)
        setShowLookupModalSMS(false)
        AlertToastr.showAlert('Lookup has been started')
        if (bulkDone) {
            bulkDone()
        }
    }
    const lookupItemsEmail = (gatewayId) => {
        const data = {
            ids: selectedRows,
            type: 2,
            setting_gateway_id: gatewayId,
        };
        lookupEmailMutation.mutate(data)
        setShowLookupModalEmail(false)
        AlertToastr.showAlert('Lookup has been started')
        if (bulkDone) {
            bulkDone()
        }
    }

    return (
        <Fragment>
            <ImportFileModal
                open={showImportModal}
                handleClose={() => setShowImportModal(false)}/>
            <SaveSourceModal
                open={showSaveModal}
                handleClose={(event) => {
                    setShowSaveModal(false)
                }}
            />
            <DeleteModal
                open={showDeleteModal}
                handleDelete={deleteItems}
                handleClose={(e) => setShowDeleteModal(false)}
                instance='selected sources'/>

            <SmsLookupConfirmModal
                loading={lookupSMSMutation.isLoading}
                message='Do you want to lookup sms leads in selected sources?'
                open={showLookupModalSMS}
                handleClose={(e) => setShowLookupModalSMS(false)}
                handleConfirm={(gatewayId) => {
                    lookupItemsSMS(gatewayId)
                }}
            />
            <SmsDNCConfirmModal
                loading={lookupSMSMutation.isLoading}
                message='Do you want to lookup sms DNC in selected sources?'
                open={showLookupModalSMSDNC}
                handleClose={(e) => setShowLookupModalSMSDNC(false)}
                handleConfirm={(gatewayId) => {
                    lookupItemsSmsDNC(gatewayId)
                }}
            />
            <EmailLookupConfirmModal
                open={showLookupModalEmail}
                handleClose={(e) => setShowLookupModalEmail(false)}
                handleConfirm={(gatewayId) => {
                    lookupItemsEmail(gatewayId)
                }}
                loading={lookupEmailMutation.isLoading}
                message='Do you want to lookup email leads in selected sources?'
            >
            </EmailLookupConfirmModal>
        </Fragment>
    )
}

export default SourcesPageActions
