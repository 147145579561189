import React, {useEffect, useMemo, useState} from "react";
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import {useQuery, useQueryClient} from "react-query";
import {getCampaignMessageLogs} from "../../api";
import {useParams} from "react-router-dom";
import {useQueryState} from "use-location-state";
import ListTable from "../../components/ListTable/ListTable";
import {toLocalFormat} from "../../Utils/dates";
import {useCampaignState} from "../../Contexts/CampaignContext";
import EmailContentModal from "../../components/modals/campaigns/emails/EmailContentModal";

const CampaignMessageLogs = () => {
    const {campaign} = useCampaignState()
    const [logs, setLogs] = useState([]);
    const [page, setPage] = useQueryState("page", 1);
    const [limit, setLimit] = useQueryState("limit", 100);
    const [pagination, setPagination] = useState(null);
    const [sorting, setSorting] = useState([{key: "sent_at", direction: "desc"}]);
    const {campaignId, loggedUser} = useParams();
    const queryClient = useQueryClient();

    const tableColumns = useMemo(() => {
        if (!campaign) {
            return [];
        }

        if (campaign.type === "email" || campaign.type === "email_automation") {
            return [
                {
                    Header: "Lead ID",
                    accessor: "lead_id",
                    Cell: ({value}) => {
                        return <div onClick={() => handleItemClick(value)}>{value}</div>
                    }
                },
                {
                    Header: "Gateway",
                    accessor: "gateway",
                },
                {
                    Header: "Step Status",
                    accessor: "step_status",
                },
                {
                    Header: 'SID',
                    accessor: 'gateway_message_id'
                },
                {
                    Header: 'From',
                    accessor: 'from',
                },
                {
                    Header: "From Name",
                    accessor: "from_name"
                },
                {
                    Header: "To",
                    accessor: "to",
                },
                {
                    Header: "Subject",
                    accessor: "subject",
                    Cell: ({value, row}) => {
                        return <div onClick={() => handleOpenEmailContent(row.original.body)}>{value}</div>
                    }
                },
                {
                    Header: "Status",
                    accessor: "status_label",
                },
                {
                    Header: "Status Type",
                    accessor: "status_type",
                },
                {
                    Header: "Status sub type",
                    accessor: "status_sub_type"
                },
                {
                    Header: "Updated At",
                    accessor: "updated_at",
                    Cell: ({value}) => {
                        return toLocalFormat(value)
                    }
                },
                {
                    Header: "Sent At",
                    accessor: "sent_at",
                    Cell: ({value, row}) => {
                        return toLocalFormat(value)
                    }
                },
                {
                    Header: "Delivered At",
                    accessor: "delivered_at",
                    Cell: ({value, row}) => {
                        return toLocalFormat(value)
                    }
                },
                {
                    Header: "Opened At",
                    accessor: "opened_at",
                    Cell: ({value, row}) => {
                        return toLocalFormat(value)
                    }
                },
                {
                    Header: "Clicked At",
                    accessor: "clicked_at",
                    Cell: ({value, row}) => {
                        return toLocalFormat(value)
                    }
                }
            ];
        }
        return [
            {
                Header: "Lead ID",
                accessor: "lead_id",
                Cell: ({value}) => {
                    return <div onClick={() => handleItemClick(value)}>{value}</div>
                }
            },
            {
                Header: "Sent At",
                accessor: "sent_at",
                Cell: ({value, row}) => {
                    return toLocalFormat(value)
                }
            },
            {
                Header: "Updated At",
                accessor: "updated_at",
                Cell: ({value, row}) => {
                    return toLocalFormat(value)
                }
            },
            {
                Header: "Gateway",
                accessor: "gateway",
            },
            {
                Header: "Step Status",
                accessor: "step_status",
            },
            {
                Header: "Sid",
                accessor: "sid",
            },
            {
                Header: "Sender Name",
                accessor: "sender_name"
            },
            {
                Header: "From",
                accessor: "from_number",
            },
            {
                Header: "To",
                accessor: "to_number",
            },
            {
                Header: "Message",
                accessor: "message",
            },
            {
                Header: "Status",
                accessor: "status",
            },
            {
                Header: "Carrier Name",
                accessor: "carrier_name"
            },
            {
                Header: "Cost",
                accessor: "cost",
            },
            {
                Header: "Error Code",
                accessor: "error_code",
            },
            {
                Header: "Error Sub Code",
                accessor: "error_sub_code",
            },
            {
                Header: "Error",
                accessor: "error_title",
            },
            {
                Header: "Error Details",
                accessor: "error_message",
            },
        ];

    }, [campaign]);

    const tableValues = useMemo(() => {
        return logs;
    }, [logs]);

    const tableSorting = useMemo(() => {
        if (!sorting) return null;

        return sorting.map(item => ({
            id: item.key, desc: item.direction === "desc"
        }))
    }, [sorting])

    const campaignLogsRequest = useQuery(
        ["campaignMessageLogsView", campaignId, page, limit, sorting],
        () => getCampaignMessageLogs(campaignId, page, limit, sorting),
        {keepPreviousData: true},
    );
    useEffect(() => {
        if (campaignLogsRequest.isSuccess) {
            setLogs(campaignLogsRequest.data.data);
            const paginationData = campaignLogsRequest.data.meta;
            if (paginationData.last_page < page) {
                setPage(1);
            }
            setPagination(paginationData);
        }
    }, [campaignLogsRequest.isSuccess, campaignLogsRequest.data, page, setPage]);

    const handleItemClick = lead_id => {
        window.open(`/u/${loggedUser}/leads/${lead_id}`)
    }

    const [emailContent, setEmailContent] = useState(null);
    const [showEmailContentModal, setShowEmailContentModal] = useState(false);

    const handleOpenEmailContent = (content) => {
        setEmailContent(content);
        setShowEmailContentModal(true);
    }

    const handleCloseEmailModal = () => {
        setEmailContent(null);
        setShowEmailContentModal(false);
    }

    return (
        <div className="campaignsPageHolder">
            <div className="timeLine">
                <div className="timeLineHeading mb15">
                    <h2>Message Logs</h2>
                    <Button
                        disabled={campaignLogsRequest.isFetching}
                        variant="contained"
                        color="primary"
                        startIcon={<RefreshIcon/>}
                        size="small"
                        onClick={() => queryClient.invalidateQueries("campaignMessageLogsView")}
                    >
                        Refresh
                    </Button>
                </div>
                <ListTable
                    columns={tableColumns}
                    data={tableValues}
                    loading={campaignLogsRequest.isLoading}
                    fetching={campaignLogsRequest.isFetching}
                    isSelectionColumn={false}
                    onSort={setSorting}
                    emptyDataText="No Message Logs Found"
                    pagination={pagination}
                    pageLimit={limit}
                    onPageChange={setPage}
                    onLimitChange={setLimit}
                    initSort={tableSorting}
                    disableSearchBlock
                    disa
                />
                <EmailContentModal
                    content={emailContent}
                    onClose={handleCloseEmailModal}
                    isOpen={showEmailContentModal}
                >
                </EmailContentModal>
            </div>
        </div>
    );
};

export default CampaignMessageLogs;
