import React, { createContext, useCallback, useState, useEffect, useContext } from 'react';
import { useQueryClient } from 'react-query';
import { useMatch, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ApiService from '../Services/Api.service';
import DataService from '../Services/DataService';
import LoggedUsersService from '../Services/LoggedUsersService';
import StorageService from '../Services/StorageService';
import AlertToastr from '../Utils/alert';

const AuthContext = createContext({})
AuthContext.displayName = 'AuthContext'

export const AuthProvider = ({ children }) => {
    const match = useMatch('/u/:loggedUser/*');
    const navigate = useNavigate();
    const [userLoading, setUserLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [accessToken, setAccessToken] = useState('');
    const [userName, setUserName] = useState('');
    const [userId, setUserId] = useState(0);
    const [loggedUser, setLoggedUser] = useState(0);

    const queryClient = useQueryClient();

    const setAuthData = useCallback(async (token, loggedUser = 0, isFirstLogin = false) => {
        ApiService.init(); // do we need this here?
        try {
            if (!isFirstLogin) {
                setAccessToken(token);
                setIsAuthenticated(true);
            }

            ApiService.setAuthHeader(token)
            const { data } = await DataService.getUserInfo();

            if (data.name) {
                document.title = `${data.name} | ${process.env.REACT_APP_NAME}`;
            }

            setUserName(data.name);
            setUserId(data.id);
            if (isFirstLogin) {
                setAccessToken(token);
                setIsAuthenticated(true);
            }

            const loggedUserIndex = LoggedUsersService.getLoggedUserTokenByUserId(data.id);
            if (loggedUserIndex !== loggedUser) {
                setLoggedUser(loggedUserIndex);
                navigate(match.pathname.replace(`/u/${match.params.loggedUser}`, `/u/${loggedUserIndex}`));
            }
        } catch (e) {
            AlertToastr.showErrorAlert('Error while fetching user name')
            setTimeout(() => {
                logoutHandler();
                window.location.href = '/signin';
            }, 2000);
        }
    }, []);

    const getLoggedUserParam = useCallback(() => {
        return match?.params?.loggedUser ? Number(match?.params?.loggedUser) : 0;
    }, [match]);

    const getLoggedUserToken = useCallback(() => {
        return LoggedUsersService.getLoggedUserTokenByIndex(getLoggedUserParam());
    }, []);

    const loginHandler = (token, user, isFirstLogin = false) => {
        if (isAuthenticated) {
            setUserName('');
            setUserId(0);
            setLoggedUser(0);
            queryClient.clear();
        }

        const addedLoggedUserIndex = LoggedUsersService.addLoggedUser(user, token);
        setLoggedUser(addedLoggedUserIndex);

        if (isAuthenticated) {
            navigate(`/u/${addedLoggedUserIndex}/`);
        }

        setAuthData(token, addedLoggedUserIndex, isFirstLogin);
    }

    const logoutHandler = useCallback(() => {
        LoggedUsersService.clearLoggedUsers();
        StorageService.clearStorage();
        setIsAuthenticated(false);
        setAccessToken('');
        setUserName('');
        setUserId(0);
        setLoggedUser(0);
        ApiService.removeAuthHeader();
        queryClient.clear();
        document.title = process.env.REACT_APP_NAME;

    }, [queryClient])

    // init auth status
    useEffect(() => {
        const token  = getLoggedUserToken();
        const loggedUserParam = getLoggedUserParam();

        if (loggedUserParam !== loggedUser) {
            setLoggedUser(loggedUserParam);
        }

        if (token) {
            setAuthData(token, loggedUserParam);
        }

        setUserLoading(false);
    }, [setAuthData]);

    // api errors handler
    useEffect(() => {
        const authInterceptor = axios.interceptors.response.use(
            response => response,
            async error => {
                if (error.response) {
                    const status = error.response.status || 500

                    if (status === 401) {

                    }

                    if (status === 403) {
                        logoutHandler(); // TODO: implement refresh token logic
                        return
                    }
                }
                throw error
            })
        return () => {
            axios.interceptors.request.eject(authInterceptor);
        }
    }, [logoutHandler]);

    useEffect(() => {
        if (!LoggedUsersService.isUserLogged(loggedUser)) {
            logoutHandler();
        }
    }, [match]);

    return (
        <AuthContext.Provider value={{
            isLoggedIn: isAuthenticated,
            userName: userName,
            userId: userId,
            loggedUser: loggedUser,
            token: accessToken,
            login: loginHandler,
            logout: logoutHandler
        }}>
            {!userLoading && children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext)
