import React, {useState, useEffect, useContext, useMemo} from "react";
import useSegmentFilterAvailableOptions from "./useSegmentFilterAvailableOptions";
import { segmentFilterExpression, segmentFilterType, segmentFilterValue, systemSegmentFilterField } from "./enums";
import {
    DEFAULT_EXPRESSION,
    expressionOptions,
    getCampaignFieldOptions,
    getDataFieldOptions,
    getSystemFieldOptions,
} from "./const";
import {
    getCampaignStatusValueMap,
    getCampaignValueMap,
    getEngagementValueMap,
    getCreatedAtRangeValueMap,
    getCreatedAtCustomValueMap,
    getEngagementDateValueMap,
    getNonDeliveredSmsLeadsValueMap,
} from "./helpers";
import { weekOfYearOptions } from "../../config";

const SegmentFilterState = React.createContext({});
SegmentFilterState.displayName = "SegmentFilterState";

const SegmentFilterDispatch = React.createContext({});
SegmentFilterDispatch.displayName = "SegmentFilterDispatch";

const DEFAULT_VALUE_MAP_FIELD = "_default";

const campaignEngagementFields = [
    systemSegmentFilterField.SMS_CLICKED,
    systemSegmentFilterField.SMS_UNSUBSCRIBED,
    systemSegmentFilterField.EMAIL_CLICKED,
    systemSegmentFilterField.EMAIL_UNSUBSCRIBED,
    systemSegmentFilterField.EMAIL_OPENED,
];

const campaignStatusesFields = [
    systemSegmentFilterField.CAMPAIGN_SENT_EMAIL_STATUS,
    systemSegmentFilterField.CAMPAIGN_SENT_SMS_STATUS,
];

const SegmentFilterProvider = ({ children, segment }) => {
    const [normalizedFilters, setNormalizedFilters] = useState([]);

    const {
        sourceOptions,
        campaignOptions,
        attributeOptions,
        campaignSmsStatusOptions,
        campaignEmailStatusOptions,
        leadStatusesOptions,
        leadTypeOptions,
        phoneStatusesOptions,
        phoneCarrierStatusesOptions,
        phoneCampaignStatusesOptions,
        emailStatusesOptions,
        engagementPeriodOptions,
        lastDayClickedOptions,
        weekOfYearOptions,
        carrierTypesOptions,
        dateRangeOptions,
        statesOptions,
    } = useSegmentFilterAvailableOptions();

    // list options calculated from previous added source filter
    const listOptions = useMemo(() => {
        return sourceOptions.reduce((result, source) => {
            if (source.lists) {
                result.push({ id: null, name: source.name, })
                result.push(...source.lists);
            }

            return result;
        }, []);
    }, [normalizedFilters, sourceOptions]);

    const valueOptions = useMemo(() => {
        return {
            [systemSegmentFilterField.SOURCE]: {label: "Add Source", items: sourceOptions},
            [systemSegmentFilterField.LIST]: {label: "Add List", items: listOptions},
            [systemSegmentFilterField.LEAD_STATUS]: {label: "Add Status", items: leadStatusesOptions},
            [systemSegmentFilterField.LEAD_PHONE_CARRIER_STATUS]: {label: "Add Status", items: phoneCarrierStatusesOptions},
            [systemSegmentFilterField.LEAD_PHONE_CAMPAIGN_STATUS]: {label: "Add Status", items: phoneCampaignStatusesOptions},
            [systemSegmentFilterField.LEAD_EMAIL_STATUS]: {label: "Add Status", items: emailStatusesOptions},
            [systemSegmentFilterField.LAST_DAY_SMS_CLICKED]: {label: "Select days", items: lastDayClickedOptions},
            [systemSegmentFilterField.LAST_DAY_EMAIL_CLICKED]: {label: "Select days", items: lastDayClickedOptions},
            [systemSegmentFilterField.WEEK_OF_YEAR_IMPORTED]: {label: "Select week", items: weekOfYearOptions},
            [systemSegmentFilterField.WEEK_OF_YEAR_SMS_CLICKED]: {label: "Select week", items: weekOfYearOptions},
            [systemSegmentFilterField.CARRIER_TYPE]: {label: "Add Carrier Type", items: carrierTypesOptions},
            [systemSegmentFilterField.DAY_IMPORT]: {label: "Select days", items: lastDayClickedOptions},
            [systemSegmentFilterField.CREATED_AT]: {label: "Select days", items: dateRangeOptions},
            [systemSegmentFilterField.STATE]: {label: "Select State", items: statesOptions},
            [`${systemSegmentFilterField.CREATED_AT}.range`]: {label: "Select days", items: dateRangeOptions},
            [`${systemSegmentFilterField.CREATED_AT}.lead_type`]: {label: "Add Lead Type", items: leadTypeOptions},
        }
    }, [
        sourceOptions,
        listOptions,
        leadStatusesOptions,
        phoneStatusesOptions,
        emailStatusesOptions,
        lastDayClickedOptions,
    ]);

    const campaignValueOptions = useMemo(() => {
        const emailCampaignsOptions = campaignOptions.filter((campaign) => campaign.type === "email");
        const smsCampaignsOptions = campaignOptions.filter((campaign) => campaign.type === "sms");
        return {
            period: {label: "Add Period", items: engagementPeriodOptions},
            campaigns: {label: "Add Campaign", items: campaignOptions},
            campaignsEmail: {label: "Add Campaign", items: emailCampaignsOptions},
            campaignsSms: {label: "Add Campaign", items: smsCampaignsOptions},
            statusesSms: {label: "Add Sms Status", items: campaignSmsStatusOptions},
            statusesEmail: {label: "Add Email Status", items: campaignEmailStatusOptions},
        }
    }, [
        engagementPeriodOptions,
        campaignSmsStatusOptions,
        campaignEmailStatusOptions,
        campaignOptions,
    ]);

    const fields = useMemo(() => {
        const usedFieldsSet = new Set(normalizedFilters.map(({ field }) => field));

        const hasSource = usedFieldsSet.has(systemSegmentFilterField.SOURCE);
        const hasList = usedFieldsSet.has(systemSegmentFilterField.LIST);

        return [
            {
                type: segmentFilterType.DATA,
                label: "Data",
                options: getDataFieldOptions({ disableSource: hasSource, disableList: hasList }),
            },
            {
                type: segmentFilterType.ATTRIBUTES,
                label: "Fields",
                options: attributeOptions.map(attr => ({
                    id: attr.id,
                    name: attr.name,
                })),
            },
            {
                type: segmentFilterType.SYSTEM_ATTRIBUTES,
                label: "System Attributes",
                options: getSystemFieldOptions(),
            },
            {
                type: segmentFilterType.CAMPAIGN_ATTRIBUTES,
                label: "Campaign Attributes",
                options: getCampaignFieldOptions(),
            },
        ]
    }, [attributeOptions, normalizedFilters]);

    const prepareValuesMapBeforeStore = (filterType, filterField, valuesMap) => {
        if (valuesMap.length === 1 && valuesMap[0].fieldName === DEFAULT_VALUE_MAP_FIELD) {
            return valuesMap[0].value;
        }

        if (filterField === systemSegmentFilterField.CREATED_AT) {
            const defaultCreatedAtValue = {
                range: null,
                date_to: null,
                date_from: null,
                lead_type: null,
            }

            const value = valuesMap.reduce((result, value) => {
                let valueToStore = value.value;

                if (!!valueToStore && value.castTo === "number") {
                    valueToStore = Number(value.value);
                }

                result[value.fieldName] = valueToStore;
                return result;
            }, {});

            return {
                ...defaultCreatedAtValue,
                ...value,
            }
        }

        if (filterType !== segmentFilterType.CAMPAIGN_ATTRIBUTES) {
            return valuesMap.reduce((result, value) => {
                result[value.fieldName] = value.value;
                return result;
            }, {})
        }

        const defaultEmptyCampaignValue = {
            period: null,
            campaigns: null,
            statuses: null,
        }
        const value = valuesMap.reduce((result, value) => {
            result[value.fieldName] = value.value;
            return result;
        }, {});

        return {
            ...defaultEmptyCampaignValue,
            ...value,
        };
    }

    const mapFilterValuesToValuesMap = (filterType, filterField, filter) => {
        if (Array.isArray(filter?.values) || filter?.values === null) {
            let valueType = segmentFilterValue.MULTI_STRING;

            if (getFilterValuesOptionsHandler(filter)) {
                valueType = segmentFilterValue.MULTI_SELECT;
            }

            return [{
                fieldName: DEFAULT_VALUE_MAP_FIELD,
                value: filter.values,
                valueType,
            }];
        }

        if (filterField === systemSegmentFilterField.NON_DELIVERED_SMS_LEADS) {
            const defaultValuesMap = getNonDeliveredSmsLeadsValueMap();
            if (filter.values?.period === "custom") {
                defaultValuesMap.push(...getEngagementDateValueMap())
            }

            return defaultValuesMap.map((value) => ({
                ...value,
                value: filter.values[value.fieldName],
            }));
        }

        if (filterType === segmentFilterType.CAMPAIGN_ATTRIBUTES) {
            let defaultValuesMap = getCampaignValueMap();
            if (campaignEngagementFields.includes(filter.field)) {
                defaultValuesMap = getEngagementValueMap();
            }

            if (campaignStatusesFields.includes(filter.field)) {
                defaultValuesMap = getCampaignStatusValueMap();
            }

            if (filter.values?.period === "custom") {
                defaultValuesMap.push(...getEngagementDateValueMap())
            }

            return defaultValuesMap.map((value) => ({
                ...value,
                value: filter.values[value.fieldName],
            }));
        }

        if (filterField === systemSegmentFilterField.CREATED_AT) {
            const defaultValuesMap = filter.expression === segmentFilterExpression.DATE_RANGE
                ? getCreatedAtRangeValueMap()
                : getCreatedAtCustomValueMap();

            return defaultValuesMap.map((value) => ({
                ...value,
                value: filter.values[value.fieldName],
            }));
        }
    }

    const getFields = () => {
        return fields;
    }

    const getExpressions = (filter) => {
        if (!filter.type) {
            return [];
        }

        if (expressionOptions[filter.type][filter.field]) {
            return expressionOptions[filter.type][filter.field];
        }

        return expressionOptions[filter.type][DEFAULT_EXPRESSION] ?? [];
    }

    const getFilterValuesOptionsHandler = (filter, valueMap) => {
        if (valueOptions[`${filter.field}.${valueMap?.fieldName}`]) {
            return valueOptions[`${filter.field}.${valueMap.fieldName}`];
        }

        if (filter.field === systemSegmentFilterField.NON_DELIVERED_SMS_LEADS) {
            return campaignValueOptions[valueMap.fieldName]
        }

        if (filter.type !== segmentFilterType.CAMPAIGN_ATTRIBUTES) {
            return valueOptions[filter.field] ?? null;
        }

        if (filter.field.includes("sms") && campaignValueOptions[valueMap?.fieldName + "Sms"]) {
            return campaignValueOptions[valueMap.fieldName + "Sms"];
        }

        if (filter.field.includes("email") && campaignValueOptions[valueMap?.fieldName + "Email"]) {
            return campaignValueOptions[valueMap.fieldName + "Email"];
        }

        if (campaignValueOptions[valueMap?.fieldName]) {
            return campaignValueOptions[valueMap.fieldName];
        }

        if (filter.field === systemSegmentFilterField.CAMPAIGNS) {
            return campaignValueOptions.campaigns;
        }

        return [];
    };

    const formatSegmentFilterError = (filters, errors) => {
        const filtersTypeCount = {
            [segmentFilterType.DATA]: 0,
            [segmentFilterType.ATTRIBUTES]: 0,
            [segmentFilterType.SYSTEM_ATTRIBUTES]: 0,
            [segmentFilterType.CAMPAIGN_ATTRIBUTES]: 0,
        };

        const normalizedError = Object.keys(errors).reduce((res, key) => {
            const splitKey = key.split(".");
            if (splitKey?.length === 3 || splitKey?.length === 4) {
                const [type, index] = splitKey;
                if (!res[type]) {
                    res[type] = [];
                }

                if (!res[type][Number(index)]) {
                    res[type][Number(index)] = errors[key];
                }
            } else {
                res[key] = errors[key];
            }

            return res
        }, {});

        return filters.reduce((acc, filter, index) => {
            if (!normalizedError[filter.type]?.[filtersTypeCount[filter.type]]) {
                acc[index] = null;
                filtersTypeCount[filter.type]++;
                return acc;
            }


            acc[index] = normalizedError[filter.type][filtersTypeCount[filter.type]];
            filtersTypeCount[filter.type]++;
            return acc;
        }, {})
    };

    const prepareFiltersBeforeStore = (filters) => {
        const dataToStore = {
            [segmentFilterType.DATA]: [],
            [segmentFilterType.ATTRIBUTES]: [],
            [segmentFilterType.SYSTEM_ATTRIBUTES]: [],
            [segmentFilterType.CAMPAIGN_ATTRIBUTES]: [],
        };

        const getFilterFieldByType = (type) => {
            if (type === segmentFilterType.ATTRIBUTES) {
                return "attribute_id";
            }

            return "field";
        }

        return filters.reduce((acc, filter) => {
            const { type, field, expression, valuesMap } = filter;
            const filterField = getFilterFieldByType(type);

            acc[type].push({
                [filterField]: field,
                expression,
                values: prepareValuesMapBeforeStore(type, field, valuesMap),
            })
            return acc;
        }, dataToStore);
    };

    const getFilterValuesMap = (filter = null) => {
        if (filter.field === systemSegmentFilterField.CREATED_AT) {
            return getCreatedAtRangeValueMap();
        }

        if (filter.field === systemSegmentFilterField.NON_DELIVERED_SMS_LEADS) {
            return getNonDeliveredSmsLeadsValueMap();
        }

        if (filter.type === segmentFilterType.CAMPAIGN_ATTRIBUTES) {
            if (campaignEngagementFields.includes(filter.field)) {
                return getEngagementValueMap();
            }

            if (campaignStatusesFields.includes(filter.field)) {
                return getCampaignStatusValueMap();
            }

            return getCampaignValueMap();
        }

        if (!filter?.values || Array.isArray(filter?.values)) {
            let valueType = segmentFilterValue.MULTI_STRING;

            if (getFilterValuesOptionsHandler(filter)) {
                valueType = segmentFilterValue.MULTI_SELECT;
            }

            return [{
                fieldName: DEFAULT_VALUE_MAP_FIELD,
                value: filter?.values ?? [],
                valueType,
            }];
        }
    }

    const normalizeSegmentFilters = (segment) => {
        const segmentFilters = segment?.filters;

        if (!segmentFilters || Array.isArray(segmentFilters)) {
            return [];
        }

        return Object.keys(segmentFilters).reduce((res, key) => {
            const normalizedFilters = segmentFilters[key].map((filter) => {
                return {
                    type: key,
                    field: filter.field || filter.attribute_id,
                    expression: filter.expression,
                    valuesMap: mapFilterValuesToValuesMap(key, filter.field, filter),
                }
            })

            if (Boolean(normalizedFilters.length)) {
                res.push(...normalizedFilters);
            }

            return res;
        }, []);
    };

    useEffect(() => {
        setNormalizedFilters(normalizeSegmentFilters(segment));
    }, [segment]);

    return (
        <SegmentFilterState.Provider value={{
            normalizedFilters,
        }}>
            <SegmentFilterDispatch.Provider value={{
                formatSegmentFilterError,
                prepareFiltersBeforeStore,
                getExpressions,
                getFields,
                getFilterValuesOptionsHandler,
                getFilterValuesMap,
                setNormalizedFilters,
            }}>
                { children }
            </SegmentFilterDispatch.Provider>
        </SegmentFilterState.Provider>
    )
}

const useSegmentFilterState = () => useContext(SegmentFilterState);
const useSegmentFilterDispatch = () => useContext(SegmentFilterDispatch);

export {
    SegmentFilterProvider,
    useSegmentFilterState,
    useSegmentFilterDispatch,
}
