import MomentUtils from '@date-io/moment';
import {
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';


const SegmentFilterDateValue = ({ valueMap, onValueMapChange }) => {
    const handleDateChange = (date) => {
        if (date && !date.isValid()) {
            return;
        }

        onValueMapChange({
            ...valueMap,
            value: formatDate(valueMap.fieldName, date),
        });
    };

    const formatDate = (fieldName, date) => {
        if (!date) {
            return null;
        }

        if (fieldName === "date_to") {
            date?.set({ hour: 23, minute: 59, second: 59 });
        } else {
            date?.set({ hour: 0, minute: 0, second: 0 });
        }

        return date.format('YYYY-MM-DD HH:mm:ss');
    }

    const getLabel = () => {
        if (valueMap.fieldName === "date_to") {
            return "Date To";
        }

        if (valueMap.fieldName === "date_from") {
            return "Date From";
        }

        return "Date";
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="YYYY-MM-DD"
                margin="none"
                label={getLabel()}
                value={valueMap?.value}
                onChange={handleDateChange}
                inputVariant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />
        </MuiPickersUtilsProvider>
    )
};

export default SegmentFilterDateValue;
