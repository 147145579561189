import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Row from "./Row";
import {toServerFormat} from "../../../Utils/dates";
import SmsStep from "./SmsStep";
import EmailStep from "./EmailStep";

const SplitStep = (
    {
        item,
        onEdit = () => {
        },
        onDelete = () => {
        },
        onCopy = () => {
        },
        disableEdit = false,
        disableDelete = false
    }) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleEdit = () => {
        setAnchorEl(null)
        onEdit(item)
    }

    const handleCopy = () => {
        setAnchorEl(null);
        onCopy(item);
    }

    const handleDelete = () => {
        setAnchorEl(null)
        onDelete(item)
    }

    return (
        <>
            <li className="stepsListItem">
                <div className="sliContent">
                    <div className="sliTitleHolder">
                        <div className="sliTitle">
                            {item?.name}
                            <span className={`status ${item?.status}`} style={{paddingLeft: '5px'}}>
                            ({item?.status_label})
                        </span>
                        </div>
                        <div className="sliSubMenu">
                            <IconButton onClick={handleClick}>
                                <MoreVertIcon/>
                            </IconButton>
                        </div>
                        {
                            item && (
                                <Menu
                                    id="fade-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={open}
                                    onClose={handleClose}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <MenuItem
                                        disabled={disableEdit}
                                        onClick={handleEdit}>
                                        Edit
                                    </MenuItem>
                                    <MenuItem
                                        disabled={disableEdit}
                                        onClick={handleCopy}
                                    >
                                        Copy
                                    </MenuItem>
                                    <MenuItem
                                        disabled={disableDelete}
                                        onClick={handleDelete}
                                    >
                                        Delete
                                    </MenuItem>
                                </Menu>
                            )
                        }
                    </div>
                    <Row
                        label="Start Time:"
                        value={toServerFormat(item.started_at)}
                    ></Row>
                    <Row
                        label="Delay:"
                        value={item.delay_label}
                    ></Row>
                    <Row
                        label="Limit Cap:"
                        value={item.limit_cap}
                    ></Row>
                </div>
            </li>

            {Array.isArray(item.steps) && item.steps.length > 0 &&
                <li className="stepsListItemSub">
                    <ul className="stepsListSub">
                        {item.steps.map(step => {
                            return (
                                <li key={`split_step_${step.id}`} className="stepsListItemSub">
                                    <ul className="stepsList">
                                        {
                                            step.type === 'sms' ? <SmsStep {...{
                                                    item: step,
                                                    parentItem: item,
                                                    onEdit,
                                                    onDelete,
                                                    onCopy,
                                                    disableEdit,
                                                    disableDelete
                                                }} /> :
                                                <EmailStep {...{
                                                    item: step,
                                                    parentItem: item,
                                                    onEdit,
                                                    onCopy,
                                                    onDelete,
                                                    disableEdit,
                                                    disableDelete
                                                }} />
                                        }
                                    </ul>
                                </li>
                            )
                        })}
                    </ul>
                </li>
            }
        </>
    )
}

export default SplitStep;