const SMS_COOL_OFF = 1;
const SMS_INACTIVE_DEVICE = 2;
const EMAIL_COOL_OFF = 3;
const RESET_FREQUENCY = 4;
const INCREASE_FREQUENCY = 5;

let ALL = [];
ALL[SMS_COOL_OFF] = 'Sms Cool Off'
ALL[SMS_INACTIVE_DEVICE] = 'SMS Inactive Device'
ALL[EMAIL_COOL_OFF] = 'Email Cool Off'
ALL[RESET_FREQUENCY] = 'Reset Frequency'
ALL[INCREASE_FREQUENCY] = 'Increase Frequency'

export default {
    SMS_COOL_OFF,
    SMS_INACTIVE_DEVICE,
    EMAIL_COOL_OFF,
    RESET_FREQUENCY,
    INCREASE_FREQUENCY,
    ALL
}