import React, {Fragment, useEffect, useState} from "react"
import {useAppDispatch} from "../../Contexts/AppContext"
import EventEmitter from "../../Utils/EventEmitter"
import Exit from "../../assets/img/exit.svg"
import PlusCircle from "../../assets/img/plus_circle.svg"
import ImportFileModal from "../../components/modals/ImportFileModal"
import SaveSourceModal from "../../components/modals/SaveSourceModal"
import EditIcon from "../../assets/img/settings.svg"
import DeleteModal from "../../components/modals/DeleteModal"
import ExitIcon from "../../assets/img/exit_to_app.svg"
import DataService from "../../Services/DataService"
import AlertToastr from "../../Utils/alert"
import {useNavigate, useParams} from "react-router-dom"
import SaveListModal from "../../components/modals/SaveListModal"
import Delete from "../../assets/img/delete.svg"
import {useMutation, useQueryClient} from "react-query"
import {sourcesListLookup} from "../../api"
import Chevron from "../../assets/img/chevron_big_right.svg"
import EmailLookupConfirmModal from "../../components/modals/lookups/EmailLookupConfirmModal";
import SmsLookupConfirmModal from "../../components/modals/lookups/SmsLookupConfirmModal";
import SmsDNCConfirmModal from "../../components/modals/lookups/SmsDNCConfirmModal";

const SourcePageActions = ({source, loading, selectedRows, bulkDone}) => {
    const {setContextMenu} = useAppDispatch()
    const history = useNavigate()
    const params = useParams()
    const [showImportModal, setShowImportModal] = useState(false)
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showSaveListModal, setShowSaveListModal] = useState(false)
    const [showDeleteListsModal, setShowDeleteListsModal] = useState(false)
    const [showLookupModalEmail, setShowLookupModalEmail] = useState(false)
    const [showLookupModalSMS, setShowLookupModalSMS] = useState(false)
    const [showLookupModalSmsDNC, setShowLookupModalSmsDNC] = useState(false)
    const queryClient = useQueryClient()
    const lookupMutation = useMutation(sourcesListLookup)

    useEffect(() => {
        const items = [
            {
                label: "Import File",
                icon: Exit,
                action: () => EventEmitter.emit("ImportFile"),
                disabled: loading
            },
            {
                label: "Edit Source",
                icon: EditIcon,
                action: () => EventEmitter.emit("SourceEdit"),
                disabled: loading
            },
            {
                label: "Remove source",
                icon: ExitIcon,
                action: () => EventEmitter.emit("SourceDelete"),
                disabled: loading
            },
            {
                label: "Add New List",
                icon: PlusCircle,
                action: () => EventEmitter.emit("SourceListCreate"),
                disabled: loading
            }
        ]

        if (selectedRows.length) {

            items.push({
                label: "Run Lookup",
                icon: Chevron,
                actionSMS: () => EventEmitter.emit("SourcesRunLookupSMS"),
                actionSmsDNC: () => EventEmitter.emit("SourcesRunSmsDNC"),
                actionEmail: () => EventEmitter.emit("SourcesRunLookupEmail"),
            })

            items.push({
                label: "Delete Selection",
                icon: Delete,
                action: () => EventEmitter.emit("SourceListsBulkDeletion"),
                iconStyle: {transform: "rotate(45deg)"}
            })
        }

        setContextMenu(items)
    }, [setContextMenu, loading, selectedRows])

    //listener: import file
    useEffect(() => {
        const onEvent = () => setShowImportModal(true)

        const listener = EventEmitter.addListener("ImportFile", onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    //listener: source edit
    useEffect(() => {
        const onEvent = () => setShowSaveModal(true)

        const listener = EventEmitter.addListener("SourceEdit", onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    //listener: source delete
    useEffect(() => {
        const onEvent = () => setShowDeleteModal(true)

        const listener = EventEmitter.addListener("SourceDelete", onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowSaveListModal(true)

        const listener = EventEmitter.addListener("SourceListCreate", onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowDeleteListsModal(true)

        const listener = EventEmitter.addListener("SourceListsBulkDeletion", onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowLookupModalEmail(true)

        const listener = EventEmitter.addListener("SourcesRunLookupEmail", onEvent)
        return () => {
            listener.remove()
        }
    }, [lookupMutation, selectedRows])
    useEffect(() => {
        const onEvent = () => setShowLookupModalSMS(true)

        const listener = EventEmitter.addListener("SourcesRunLookupSMS", onEvent)
        return () => {
            listener.remove()
        }
    }, [lookupMutation, selectedRows])
   useEffect(() => {
        const onEvent = () => setShowLookupModalSmsDNC(true)

        const listener = EventEmitter.addListener("SourcesRunSmsDNC", onEvent)
        return () => {
            listener.remove()
        }
    }, [lookupMutation, selectedRows])


    const deleteSource = () => {
        setShowDeleteModal(false)
        DataService.deleteSource(source.id)
            .then(response => {
                history(`/u/${params.loggedUser}/sources`)
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors)
            })
    }

    const deleteItems = () => {
        setShowDeleteListsModal(false)
        DataService.deleteLists(selectedRows)
            .then(() => {
                queryClient.invalidateQueries("sourceLists").then()
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors)
            })
    }

    const lookupItemsSMS = async (gatewayId) => {
        const data = {
            ids: [...selectedRows],
            type: 1,
            setting_gateway_id: gatewayId
        }
        await lookupMutation.mutateAsync(data)
        setShowLookupModalSMS(false)
        AlertToastr.showAlert("Lookup has been started")
        if (bulkDone) bulkDone()
    }

    const lookupItemsSmsDNC = async (gatewayId) => {
        const data = {
            ids: [...selectedRows],
            type: 3,
            setting_gateway_id: gatewayId
        }
        await lookupMutation.mutateAsync(data)
        setShowLookupModalSmsDNC(false)
        AlertToastr.showAlert("Lookup has been started")
        if (bulkDone) bulkDone()
    }

    const lookupItemsEmail = (gatewayId) => {
        const data = {
            ids: [...selectedRows],
            type: 2,
            setting_gateway_id: gatewayId,
        }
        lookupMutation.mutate(data)
        setShowLookupModalEmail(false)
        AlertToastr.showAlert("Lookup has been started")
        if (bulkDone) {
            bulkDone()
        }
    }

    return (
        <Fragment>
            <ImportFileModal
                source={source}
                open={showImportModal}
                handleClose={() => setShowImportModal(false)}
            />
            <SaveSourceModal
                source={source}
                open={showSaveModal}
                handleClose={(event) => {
                    setShowSaveModal(false)
                }}
            />
            <DeleteModal
                open={showDeleteModal}
                handleDelete={deleteSource}
                handleClose={() => setShowDeleteModal(false)}
                instance={"the source"}
            />
            <SaveListModal
                open={showSaveListModal}
                handleClose={(e) => setShowSaveListModal(false)}
                sourceId={source?.id}
            />
            <DeleteModal
                open={showDeleteListsModal}
                handleDelete={deleteItems}
                handleClose={() => setShowDeleteListsModal(false)}
                instance={"selected lists"}
            />
            <SmsLookupConfirmModal
                loading={lookupMutation.isLoading}
                message="Do you want to lookup sms leads in selected sources?"
                open={showLookupModalSMS}
                handleClose={(e) => setShowLookupModalSMS(false)}
                handleConfirm={(gatewayId) => {
                    lookupItemsSMS(gatewayId)
                }}
            />
            <SmsDNCConfirmModal
                loading={lookupMutation.isLoading}
                message="Do you want to lookup sms DNC in selected sources?"
                open={showLookupModalSmsDNC}
                handleClose={(e) => setShowLookupModalSmsDNC(false)}
                handleConfirm={(gatewayId) => {
                    lookupItemsSmsDNC(gatewayId)
                }}
            />
            <EmailLookupConfirmModal
                open={showLookupModalEmail}
                handleClose={(e) => setShowLookupModalEmail(false)}
                handleConfirm={(gatewayId) => {
                    lookupItemsEmail(gatewayId)
                }}
                loading={lookupMutation.isLoading}
                message="Do you want to lookup email leads in selected lists?"
            >
            </EmailLookupConfirmModal>
        </Fragment>
    )
}

export default SourcePageActions
