import React, { useEffect } from 'react'
import { useAppDispatch } from '../Contexts/AppContext'

const Analytics = () => {

    const {setBreadcrumbs, setSection, setContextMenu} = useAppDispatch()

    useEffect(() => {
        setBreadcrumbs([{label: 'Analytics'}])
        setSection('analytics')
        setContextMenu([])
    }, [setBreadcrumbs, setSection, setContextMenu])

    return (
        <div className="pageHolder">
            Analytics, coming soon...
        </div>
    )
}

export default Analytics
