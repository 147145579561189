import React from 'react'
// import Route from './route'
import { Navigate, Route } from 'react-router-dom'
import { useAuth } from '../../Contexts/AuthProvider'

const PrivateRoute = ({ children, ...rest }) => {
    const { isLoggedIn } = useAuth();

    if (!isLoggedIn) {
        return <Navigate to={{ pathname: '/signin', state: { referer: rest.location } }} replace />
    }

    return children;

    // return isLoggedIn
    //     ? <Route {...rest} />
    //     : <Route element={<Navigate to={{ pathname: '/signin', state: { referer: rest.location } }}/> } />
}

export default PrivateRoute
