import React from 'react'
import ReactDOM from 'react-dom'
import './styles.scss'
import { Provider } from 'react-redux'
import { store } from './Store'
import App from './components/app/app.component'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import themeStyles from './theme'
import { BrowserRouter as Router } from 'react-router-dom'
import { AuthProvider } from './Contexts/AuthProvider'
import { QueryClientProvider, QueryClient } from 'react-query'
import { AppProvider } from './Contexts/AppContext'
import './i18n'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
})

ReactDOM.render(
    <Router>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <AuthProvider>
                    <AppProvider>
                        <ThemeProvider theme={createMuiTheme(themeStyles)}>
                            <App/>
                        </ThemeProvider>
                    </AppProvider>
                </AuthProvider>
            </Provider>
        </QueryClientProvider>
    </Router>,
    document.getElementById('root')
)

