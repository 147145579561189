import React, {useEffect, useMemo, useState, useCallback} from "react";
import {useParams} from "react-router-dom";
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import {useAppDispatch} from "../../Contexts/AppContext";
import {useQuery, useQueryClient} from "react-query";
import {getCampaigns, getSMSGateways} from "../../api";
import ListTable from "../../components/ListTable/ListTable";
import CampaignsActions from "./CampaignsActions";
import {toServerFormat} from "../../Utils/dates";
import CampaignStatus from "../../components/CampaignStatus";
import TableProgressCell from "../../components/TableProgressCell";
import LoadingValue from "../../components/LoadingValue";
import {storageKeys} from "../../config";
import {useQueryState} from "use-location-state";
import {campaignLinkWrapper} from "../../helpers";
import CampaignsCounters from "../../components/CampaignsCounters";
import CampaignFilterType from "./CampaignFilterType";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import useDebounce from "../../Hooks/useDebounce";
import moment from "moment";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import CampaignStatPeriod from "./CampaignStatPeriod";

const Campaigns = () => {
    const tableWrapperRef = React.useRef(null);
    const tableWrapperScrollLeft = React.useRef(0);
    const params = useParams()
    const queryClient = useQueryClient();
    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [sorting, setSorting] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useQueryState('page', 1);
    const [limit, setLimit] = useQueryState('limit', 50);
    const {setBreadcrumbs, setSection} = useAppDispatch();
    const [selectedRows, setSelectedRows] = useState([]);
    const [filteredTypes, setFilteredTypes] = useState([]);
    const [isFilterTypeInit, setIsFilterTypeInit] = useState(false);
    const [campaignStatPeriod, setCampaignStatPeriod] = useState({
        period: "today",
    });

    const campaignRequest = useQuery(
        ['campaignsList', page, limit, search, filteredTypes, campaignStatPeriod],
        () => getCampaigns(page, limit, search, filteredTypes, campaignStatPeriod),
        {
            keepPreviousData: true,
            enabled: Boolean(isFilterTypeInit),
        }
    );

    useEffect(() => {
        if (campaignRequest.isSuccess) {
            setList(campaignRequest.data.data);
            const paginationData = campaignRequest.data.meta;
            if (paginationData.last_page < page) {
                setPage(1);
            }
            setPagination(paginationData);

            if (tableWrapperScrollLeft.current && tableWrapperRef.current) {
                tableWrapperRef.current.scrollLeft = tableWrapperScrollLeft.current;
                tableWrapperScrollLeft.current = 0;
            }
        }
    }, [campaignRequest.isSuccess, campaignRequest.data, page, setPage])

    useEffect(() => {
        setBreadcrumbs([
            {label: 'Campaigns'},
        ])
        setSection('campaigns')
    }, [setBreadcrumbs, setSection]);

    const campaignLink = useCallback((value) => {
        return `/u/${params.loggedUser}/campaigns/${value}`;
    }, [params]);

    const [smsGateways, setSmsGateways] = useState([]);

    const smsGatewaysRequest = useQuery(
        ['smsGateways', []],
        () => getSMSGateways([]),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (smsGatewaysRequest.isSuccess) {
            setSmsGateways(smsGatewaysRequest.data.data)
        }
    }, [smsGatewaysRequest.isSuccess, smsGatewaysRequest.data])

    useEffect(() => {
        if (!list) {
            return;
        }
        if (!smsGateways) {
            return;
        }

        list.map((campaign, index) => {
            smsGateways.map((gateway) => {
                if (campaign.setting_gateway_id === gateway.id) {
                    if (campaign.pool_id) {
                        gateway.pools.map((pool) => {
                            if (pool.key === campaign.pool_id) {
                                campaign.pool_id = pool.label + '(' + pool.key + ')';
                            }
                        })
                    }
                    if (campaign.ms_sid) {
                        gateway.from.map((fromPhone) => {
                            if (fromPhone.key === campaign.ms_sid) {
                                campaign.ms_name = fromPhone.label;
                            }
                        })
                    }
                }
            });
        })

    }, [list, smsGateways]);

    const tableColumns = useMemo(() => {
        return [
            {
                Header: "Started Date",
                accessor: "started_at",
                Cell: ({value, row}) => campaignLinkWrapper(campaignLink(row.original.id), toServerFormat(value)),
            },
            {
                Header: "Created Date",
                accessor: "created_at",
                Cell: ({value, row}) => campaignLinkWrapper(campaignLink(row.original.id), toServerFormat(value)),
            },
            {
                Header: "ID",
                accessor: "id",
                Cell: ({value, row}) => campaignLinkWrapper(campaignLink(row.original.id), value),
            },
            {
                Header: "Name",
                accessor: "name",
                Cell: ({value, row}) => campaignLinkWrapper(campaignLink(row.original.id), value),
            },
            {
                Header: "Type",
                accessor: "type",
                Cell: ({value, row}) => campaignLinkWrapper(campaignLink(row.original.id), row.original.type_label),
            },
            {
                Header: "Gateway",
                accessor: "gateway",
                Cell: ({value, row}) => campaignLinkWrapper(campaignLink(row.original.id), row.original.gateway_label),
            },
            {
                Header: "From",
                accessor: "ms_name",
                Cell: ({value, row}) => campaignLinkWrapper(campaignLink(row.original.id), row.original.ms_name || "-"),
            },
            {
                Header: "Pool",
                accessor: "pool_id",
                Cell: ({value, row}) => campaignLinkWrapper(campaignLink(row.original.id), row.original.pool_id || "-"),
            },
            {
                Header: "Records",
                accessor: "records",
                Cell: ({value, row}) => campaignLinkWrapper(campaignLink(row.original.id), row.original.records),
            },
            {
                Header: "Steps",
                accessor: "steps",
                Cell: ({value, row}) => campaignLinkWrapper(campaignLink(row.original.id), row.original.steps),
            },
            {
                Header: "Progress, %",
                accessor: "processed_count",
                Cell: ({row}) => {
                    var value = 0;

                    if (row.original.records && row.original.steps) {
                        value = parseInt(row.original.processed_count / (row.original.records * row.original.steps) * 100)
                    }

                    if (value > 100) {
                        value = 100;
                    }

                    const progress = <TableProgressCell value={value}/>
                    return <LoadingValue value={value} renderedValue={progress}/>
                }
            },
            {
                Header: "Cost",
                accessor: "cost",
                Cell: ({row}) => campaignLinkWrapper(campaignLink(row.original.id), row.original.statistics.cost_formatted),
            },
            {
                Header: "Revenue",
                accessor: "total_revenue",
                Cell: ({row}) => campaignLinkWrapper(campaignLink(row.original.id), row.original.statistics.total_revenue_formatted),
            },
            {
                Header: "Profit",
                accessor: "profit",
                Cell: ({row}) => campaignLinkWrapper(campaignLink(row.original.id), row.original.statistics.profit_formatted),
            },
            {
                Header: "Sent",
                accessor: "sent",
                Cell: ({
                           value,
                           row
                       }) => campaignLinkWrapper(campaignLink(row.original.id), row.original.statistics.sent),
            },
            {
                Header: "Delivered",
                accessor: "delivered",
                Cell: ({
                           value,
                           row
                       }) => campaignLinkWrapper(campaignLink(row.original.id), row.original.statistics.delivered),
            },
            {
                Header: "Delivered, %",
                accessor: "percentage_delivered",
                Cell: ({row}) => <LoadingValue value={row.original.statistics.delivered_percent} suffix="%"/>
            },
            {
                Header: "Opened",
                accessor: "opened",
                Cell: ({
                           value,
                           row
                       }) => campaignLinkWrapper(campaignLink(row.original.id), row.original.statistics.opened),
            },
            {
                Header: "Clicked",
                accessor: "clicked",
                Cell: ({
                           value,
                           row
                       }) => campaignLinkWrapper(campaignLink(row.original.id), row.original.statistics.clicked),
            },
            {
                Header: "Clicked, %",
                accessor: "percentage_clicked",
                Cell: ({row}) => <LoadingValue value={row.original.statistics.clicked_percent} suffix="%"/>
            },
            {
                Header: "Error",
                accessor: "errors",
                Cell: ({row}) => campaignLinkWrapper(campaignLink(row.original.id), row.original.statistics.total_errors),
            },
            {
                Header: "Spam",
                accessor: "spam",
                Cell: ({row}) => campaignLinkWrapper(campaignLink(row.original.id), row.original.statistics.spam_count),
            },
            {
                Header: "Unsubscribe",
                accessor: "unsubscribed",
                Cell: ({
                           value,
                           row
                       }) => campaignLinkWrapper(campaignLink(row.original.id), row.original.statistics.unsubscribed),
            },
            {
                Header: "Unsubscribe, %",
                accessor: "percentage_unsubscribed",
                Cell: ({row}) => <LoadingValue value={row.original.statistics.unsubscribed_percent} suffix="%"/>
            },
            {
                Header: "Cool Offs",
                accessor: "cool_offs",
                Cell: ({row}) => campaignLinkWrapper(campaignLink(row.original.id), row.original.statistics.cool_offs),
            },
            {
                Header: "Cool Offs Inactive Device",
                accessor: "cool_offs_inactive_device",
                Cell: ({row}) => campaignLinkWrapper(campaignLink(row.original.id), row.original.statistics.cool_offs_inactive_device),
            },
            {
                Header: "Daily Limit",
                accessor: "daily_limit",
                Cell: ({row}) => campaignLinkWrapper(campaignLink(row.original.id), row.original.statistics.daily_limit),
            },
            {
                Header: "Status",
                accessor: "status",
                className: "col-sticky text-right",
                Cell: ({value}) => <CampaignStatus status={value}/>
            }
        ]
    }, []);

    return (
        <div className="pageHolder">
            <div className="pageHeadingBlock mb15">
                <CampaignsCounters/>
            </div>
            <div className="pageTableBlock">
                <div className="campaign-today">
                    <CampaignStatPeriod
                        initialCampaignStatPeriod={campaignStatPeriod}
                        disabled={campaignRequest.isFetching}
                        onChangePeriod={setCampaignStatPeriod}
                    />

                    <Button
                        className="refresh-button"
                        disabled={campaignRequest.isFetching}
                        variant="contained"
                        color="primary"
                        startIcon={<RefreshIcon/>}
                        size="small"
                        onClick={() => {
                            tableWrapperScrollLeft.current = tableWrapperRef.current.scrollLeft;
                            queryClient.invalidateQueries('campaignStatisticCounters');
                            queryClient.invalidateQueries('campaignsList');
                        }}
                    >
                        Refresh
                    </Button>
                </div>
                <ListTable
                    columns={tableColumns}
                    data={list}
                    loading={campaignRequest.isLoading}
                    fetching={campaignRequest.isFetching}
                    isSelectionColumn={true}
                    onSelectedRows={setSelectedRows}
                    onSort={setSorting}
                    emptyDataText="No Campaigns Found"
                    pagination={pagination}
                    pageLimit={limit}
                    onPageChange={setPage}
                    onLimitChange={setLimit}
                    onSearch={setSearch}
                    search={search}
                    hiddenColumnsStorageKey={storageKeys.leadsHiddenColumnsInCampaign}
                    additionalFilterComponent={<CampaignFilterType
                        filterTypeParams={filteredTypes}
                        setFilterTypeParams={(types) => {
                            setFilteredTypes([...types]);
                            if (!isFilterTypeInit) {
                                setIsFilterTypeInit(true);
                            }
                        }}
                    />}
                    additionalSearchCssClass="campaignFilters"
                    tableWrapperRef={tableWrapperRef}
                />
            </div>
            <CampaignsActions
                selectedRows={selectedRows}
            />
        </div>
    )
}

export default Campaigns
