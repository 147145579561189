import React from "react";

import { SegmentFilterProvider } from "./SegmentFilterContext";
import SegmentFilter from "./SegmentFilter";

const SegmentFilterWrapper = (props) => {
    const { segment } = props;
    return (
        <SegmentFilterProvider segment={segment}>
            <SegmentFilter { ...props } />
        </SegmentFilterProvider>
    )
};

export default SegmentFilterWrapper;
