import React, { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { useQuery } from "react-query"
import { getCampaignLeads } from "../../api"
import ListTable from "../../components/ListTable/ListTable"
import SyncCampaignResourcesBlock from "../../components/SyncCampaignResourcesBlock"
import { formatBlackListRow } from "../../helpers"
import { toLocalFormat } from "../../Utils/dates"
import LeadStatus from "../../components/LeadStatus"
import { storageKeys } from "../../config"
import { useQueryState } from "use-location-state";

const CampaignLeads = () => {

    const leadValue = formatBlackListRow
    const {campaignId, loggedUser} = useParams()
    const [search, setSearch] = useState("")
    const [sorting, setSorting] = useState([])
    const [page, setPage] = useQueryState("page", 1)
    const [limit, setLimit] = useQueryState("limit", 50)

    const [leads, setLeads] = useState([])
    const [leadsAttributes, setLeadsAttributes] = useState([])
    const [pagination, setPagination] = useState(null)
    const tableColumns = useMemo(() => {
        const attributes = leadsAttributes.map(attr => {
            return {
                Header: attr.name,
                accessor: `${attr.id}`,
                Cell: leadValue
            }
        })
        return [
            {
                Header: "ID",
                accessor: "id",
                Cell: leadValue
            },
            {
                Header: "Updated Date",
                accessor: "updated_at",
                Cell: ({ value, row }) => leadValue({value: toLocalFormat(value), row}),
            },
            ...attributes,
            {
                Header: "Revenue",
                accessor: "revenue",
            },
            {
                Header: "Duplicates",
                accessor: "duplicates_count",
                Cell: leadValue
            },
            {
                Header: "Status",
                accessor: "status",
                className: "text-right",
                Cell: ({ value }) => <LeadStatus status={value} />
            }
        ]
    }, [leadsAttributes, leadValue])
    const tableValues = useMemo(() => {
        if (!leads) return []
        return leads.map(item => {
            const leadValues = item.lead_values
            const newItem = {...item}
            leadValues.forEach(value => {
                const attrId = value && value.attribute && value.attribute.id
                if (attrId)
                    newItem[attrId] = newItem[attrId]
                        ? `${newItem[attrId]}; ${value.value}`
                        : value.value
            })
            delete newItem["lead_values"]
            return newItem
        })
    }, [leads])
    const tableRecordsInfo = useMemo(() => {
        const info = []
        if (pagination) {
            if (pagination.total)
                info.push({count: pagination.total, label: "lead"})
            if (pagination.total_blacklisted)
                info.push({count: pagination.total_blacklisted, label: "blacklisted lead"})
        }
        return info
    }, [pagination])

    const {isSuccess, data, isLoading, isFetching} = useQuery(
        ["campaignLeads", campaignId, page, limit, search, sorting],
        () => getCampaignLeads(campaignId, page, limit, search, sorting),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (isSuccess) {
            setLeads(data.data)
            const paginationData = data.meta
            if (paginationData.last_page < page) {
                setPage(1)
            }
            setPagination(paginationData)
            if (data.attributes) {
                setLeadsAttributes(data.attributes)
            }
        }
    }, [isSuccess, data, page, setPage])

    const handleItemClick = item => {
        window.open(`/u/${loggedUser}/leads/${item?.id}`)
    }

    return (
        <div className="campaignsPageHolder">
            <SyncCampaignResourcesBlock />
            <div className="pageTableBlock">
                <ListTable
                    columns={tableColumns}
                    data={tableValues}
                    loading={isLoading}
                    fetching={isFetching}
                    isSelectionColumn={false}
                    // onSelectedRows={setSelectedRows}
                    onRowClick={handleItemClick}
                    onSort={setSorting}
                    emptyDataText="No Leads Found"
                    pagination={pagination}
                    pageLimit={limit}
                    onPageChange={setPage}
                    onLimitChange={setLimit}
                    onSearch={setSearch}
                    recordsInfo={tableRecordsInfo}
                    hiddenColumnsStorageKey={storageKeys.leadsHiddenColumnsInCampaignAudience}
                />
            </div>
        </div>
    )
}

export default CampaignLeads
