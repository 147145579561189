import React, {useEffect, useMemo, useState} from 'react';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import {useQuery, useQueryClient} from 'react-query';
import {getCampaignRedirects} from '../../api';
import {useParams} from 'react-router-dom';
import {useQueryState} from 'use-location-state';
import ListTable from '../../components/ListTable/ListTable';
import {toLocalFormat} from "../../Utils/dates";
import AlertToastr from "../../Utils/alert";

const CampaignRedirects = () => {
    const [redirects, setRedirects] = useState([]);
    const [page, setPage] = useQueryState('page', 1);
    const [limit, setLimit] = useQueryState('limit', 20);
    const [pagination, setPagination] = useState(null);
    const [sorting, setSorting] = useState([{key: 'id', direction: 'desc'}]);
    const {campaignId, loggedUser} = useParams();
    const queryClient = useQueryClient();

    const tableColumns = useMemo(() => {
        return [
            {
                Header: "ID",
                accessor: 'id',
            },
            {
                Header: 'Lead ID',
                accessor: 'lead_id',
                Cell: ({value, row}) => <a target="_blank" href={`/u/${loggedUser}/leads/${value}`}>{value}</a>,
            },
            {
                Header: "Redirect date",
                accessor: 'created_at',
                Cell: ({value, row}) => toLocalFormat(value),
            },
            {
                Header: 'Reason',
                accessor: 'reason',
                Cell: ({value, row}) => {
                    return row.original.reason_label
                }
            },
            {
                Header: "Click URL",
                accessor: 'click_url',
            },
            {
                Header: "Redirect URL",
                accessor: 'redirect_url',
            },
            {
                Header: 'IP',
                accessor: 'ip',
                Cell: ({value, row}) => {
                    return row.original.ip
                }
            },
            {
                Header: 'User agent',
                accessor: 'user_agent',
            },
        ];

    }, []);

    const tableValues = useMemo(() => {
        return redirects;
    }, [redirects]);

    const tableSorting = useMemo(() => {
        if (!sorting) return null;

        return sorting.map(item => ({
            id: item.key, desc: item.direction === 'desc'
        }))
    }, [sorting])

    const campaignRedirectRequest = useQuery(
        ['campaignRedirectsView', campaignId, page, limit, sorting],
        () => {
            return getCampaignRedirects(campaignId, page, limit, sorting)
                .catch(error => {
                        if (error && error.response && error.response.data && error.response.data.errors) {
                            AlertToastr.showErrorAlert(error.response.data.errors)
                        }

                        return null;
                    }
                )
        },
        {keepPreviousData: true},
    );
    useEffect(() => {
        if (campaignRedirectRequest.isSuccess) {
            if (campaignRedirectRequest.data === null) {
                return;
            }

            setRedirects(campaignRedirectRequest.data.data);
            const paginationData = campaignRedirectRequest.data.meta;
            if (paginationData.last_page < page) {
                setPage(1);
            }
            setPagination(paginationData);
        }
    }, [campaignRedirectRequest.isSuccess, campaignRedirectRequest.data, page, setPage]);

    return (
        <div className="campaignsPageHolder">
            <div className="timeLine">
                <div className="timeLineHeading mb15">
                    <h2>Redirects</h2>
                    <Button
                        disabled={campaignRedirectRequest.isFetching}
                        variant="contained"
                        color="primary"
                        startIcon={<RefreshIcon/>}
                        size="small"
                        onClick={() => queryClient.invalidateQueries('campaignRedirectsView')}
                    >
                        Refresh
                    </Button>
                </div>
                <ListTable
                    columns={tableColumns}
                    data={tableValues}
                    loading={campaignRedirectRequest.isLoading}
                    fetching={campaignRedirectRequest.isFetching}
                    isSelectionColumn={false}
                    onSort={setSorting}
                    emptyDataText="Redirects Not Found"
                    pagination={pagination}
                    pageLimit={limit}
                    onPageChange={setPage}
                    onLimitChange={setLimit}
                    initSort={tableSorting}
                    disableSearchBlock
                />
            </div>
        </div>
    );
};

export default CampaignRedirects;
