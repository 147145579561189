import React, {Fragment, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {Controller, useForm} from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {
    getActiveEmailLookupGateways,
    getActiveSmsDNCGateways,
    getActiveSmsLookupGateways,
    storeImportList,
    updateImportList
} from '../../api';
import AlertToastr from '../../Utils/alert';
import MenuItem from '@material-ui/core/MenuItem';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SaveSourceModal from './SaveSourceModal';
import SaveListModal from './SaveListModal';
import {useTranslation} from 'react-i18next';
import IOSSwitch from '../IOSSwitch';
import ReactHookFormSelect from "../form/ReactHookFormSelect";
import {emailStatusesOptions, leadStatusesOptions, phoneStatusesOptions} from "../../config";

const useStyles = makeStyles((theme) =>
    ({
        collapseWrapper: {
            display: 'block',
        },
        selectAdornment: {
            marginRight: theme.spacing(3),
        },
    }),
);

const SaveListImportKeyModal = props => {

    const classes = useStyles();
    const queryClient = useQueryClient();

    const {
        sources,
        open,
        handleClose,
        defaultValues,
    } = props;

    const {
        control,
        handleSubmit,
        reset,
        watch,
        setValue,
    } = useForm({
        mode: 'onTouched',
        defaultValues,
    });

    const [availableLists, setAvailableLists] = useState([]);
    const [showAddSourceModal, setShowAddSourceModal] = useState(false);
    const [showAddListModal, setShowAddListModal] = useState(false);
    const [createdListId, setCreatedListId] = useState(null);
    const sourceId = watch('source_id');
    const {t} = useTranslation();

    useEffect(() => {
        setValue('list_id', '');
        if (!sourceId) return setAvailableLists([]);
        const sourceModel = sources.find(item => item.id === sourceId);
        setAvailableLists(sourceModel ? sourceModel.lists : []);
    }, [sourceId, setValue, sources]);

    useEffect(() => {
        if (!createdListId) return;
        if (availableLists.find(item => item.id === createdListId)) {
            setValue('list_id', createdListId);
        }
    }, [
        createdListId, availableLists, setValue,
    ]);

    const storeMutation = useMutation(storeImportList);
    const updateMutation = useMutation(updateImportList);

    const onSubmit = handleSubmit(async data => {
        const {list_id, name, check_mobile_status, lead_status, phone_status, email_status} = data;

        const storeData = {
            name,
            list_id,
            check_mobile_status,
            check_sms_dnc: checkSmsDNC,
            check_email_status: checkEmailStatus,
            email_lookup_gateway_id: emailLookupGatewayId,
            sms_lookup_gateway_id: smsLookupGatewayId,
            sms_dnc_gateway_id: smsDNCGatewayId,
            lead_status: lead_status,
            email_status: email_status,
            phone_status: phone_status
        };

        try {
            if (defaultValues) {
                await updateMutation.mutateAsync({id: defaultValues.id, ...storeData});
            } else {
                await storeMutation.mutateAsync(storeData);
            }
            reset();
            handleClose();
            queryClient.invalidateQueries('import-list').then();
            // queryClient.invalidateQueries(['listLeads']).then()
            AlertToastr.showAlert('Lead has been successfully added');
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.errors)
                AlertToastr.showErrorAlert(error.response.data.errors);
        }
    });

    const renderField = (key, label, type = null, disabled = false) => {
        return (
            <Controller
                key={key}
                name={key}
                control={control}
                defaultValue=""
                render={({onChange, value}) => {
                    return (
                        <TextField
                            disabled={disabled}
                            type={type || 'text'}
                            onChange={onChange}
                            value={value}
                            variant="outlined"
                            margin="normal"
                            label={label}
                        />
                    );
                }}
            />
        );
    };

    const renderSourceField = () => {
        if (defaultValues && !sources.length) return null
        return (
            <Fragment>
                <Controller
                    name={'source_id'}
                    control={control}
                    defaultValue=""
                    render={({onChange, value}) => {
                        return (
                            <TextField
                                select
                                onChange={onChange}
                                value={value}
                                variant="outlined"
                                margin="normal"
                                label={t('Modals.Source')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" className={classes.selectAdornment}>
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowAddSourceModal(true)}
                                                // onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                <AddCircleOutlineIcon/>
                                            </IconButton>
                                        </InputAdornment>),
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select Source
                                </MenuItem>
                                {sources.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        );
                    }}
                />
                <SaveSourceModal
                    onSuccessCreate={(item) => {
                        setValue('source_id', item.id);
                    }}
                    open={showAddSourceModal}
                    handleClose={(event) => {
                        setShowAddSourceModal(false);
                    }}
                />
            </Fragment>
        );
    };

    const renderListField = () => {
        if (defaultValues && !availableLists.length) return null
        return (
            <Fragment>
                <Controller
                    name="list_id"
                    control={control}
                    defaultValue=""
                    render={({onChange, value}) => {
                        return (
                            <TextField
                                select
                                onChange={onChange}
                                value={value}
                                variant="outlined"
                                margin="normal"
                                label={t('Modals.List')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" className={classes.selectAdornment}>
                                            <IconButton
                                                disabled={!sourceId}
                                                onClick={() => setShowAddListModal(true)}
                                                edge="end"
                                            >
                                                <AddCircleOutlineIcon/>
                                            </IconButton>
                                        </InputAdornment>),
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select List
                                </MenuItem>
                                {availableLists.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        );
                    }}
                />
                <SaveListModal
                    sourceId={sourceId}
                    onSuccessCreate={(item) => {
                        setCreatedListId(item.id);
                    }}
                    open={showAddListModal}
                    handleClose={(event) => {
                        setShowAddListModal(false);
                    }}
                />
            </Fragment>
        );
    };

    const title = 'Import List API Key';
    const buttonTitle = t('Modals.Save');

    const checkEmailStatus = watch('check_email_status');
    const checkMobileStatus = watch('check_mobile_status');
    const checkSmsDNC = watch('check_sms_dnc');
    const emailLookupGatewayId = watch('email_lookup_gateway_id');
    const smsLookupGatewayId = watch('sms_lookup_gateway_id');
    const smsDNCGatewayId = watch('sms_dnc_gateway_id');

    const [emailLookupGateways, setEmailLookupGateways] = useState([])

    const emailLookupGatewaysRequest = useQuery(
        ['emailLookupGateways'],
        () => getActiveEmailLookupGateways(),
        {keepPreviousData: true}
    )


    useEffect(() => {
        if (emailLookupGatewaysRequest.isSuccess) {
            setEmailLookupGateways(emailLookupGatewaysRequest.data.data)
        }
    }, [emailLookupGatewaysRequest.isSuccess, emailLookupGatewaysRequest.data])

    const [smsLookupGateways, setSmsLookupGateways] = useState([])
    const [smsDNCGateways, setSmsDNCGateways] = useState([])

    const smsLookupGatewaysRequest = useQuery(
        ['smsLookupGateways'],
        () => getActiveSmsLookupGateways(),
        {keepPreviousData: true}
    )


    useEffect(() => {
        if (smsLookupGatewaysRequest.isSuccess) {
            setSmsLookupGateways(smsLookupGatewaysRequest.data.data)
        }
    }, [smsLookupGatewaysRequest.isSuccess, smsLookupGatewaysRequest.data])

    const smsDNCGatewaysRequest = useQuery(
        ['smsDNCGateways'],
        () => getActiveSmsDNCGateways(),
        {keepPreviousData: true}
    )


    useEffect(() => {
        if (smsDNCGatewaysRequest.isSuccess) {
            setSmsDNCGateways(smsDNCGatewaysRequest.data.data)
        }
    }, [smsDNCGatewaysRequest.isSuccess, smsDNCGatewaysRequest.data])



    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className="modal"
        >
            <div className="modalBody">
                <div className="modalTitleHolder mb30">
                    <h2 className="mb15">{title}</h2>
                </div>
                <form onSubmit={onSubmit}>
                    {renderField('name', 'Name')}
                    {renderSourceField()}
                    {renderListField()}
                    {defaultValues && renderField('api_key', 'API Key', 'text', true)}
                    <div className="align-center wrapper content-justified mt15">
                        <div style={{fontWeight: '600', fontSize: '14px'}}>Lookup phone numbers</div>

                        <Controller
                            name="check_mobile_status"
                            control={control}
                            defaultValue={false}
                            render={({onChange, value}) => (
                                <IOSSwitch
                                    color="primary"
                                    checked={value}
                                    onChange={(e) => onChange(e.target.checked)}
                                />
                            )}
                        />
                    </div>
                    {checkMobileStatus && <Controller
                        name={'sms_lookup_gateway_id'}
                        control={control}
                        defaultValue=""
                        render={({onChange, value}) => {
                            return (
                                <TextField
                                    select
                                    onChange={onChange}
                                    value={value}
                                    variant="outlined"
                                    margin="normal"
                                    label="Sms Lookup Gateway"
                                >
                                    <MenuItem value="" disabled>
                                        Select gateway
                                    </MenuItem>
                                    {smsLookupGateways.map((gateway) => {
                                        return <MenuItem key={gateway.id} value={gateway.id}>
                                            {gateway.name}
                                        </MenuItem>
                                    })}
                                </TextField>
                            );
                        }}
                    />}
                    <div className="align-center wrapper content-justified mt15">
                        <div style={{fontWeight: '600', fontSize: '14px'}}>SMS DNC</div>

                        <Controller
                            name="check_sms_dnc"
                            control={control}
                            defaultValue={false}
                            render={({onChange, value}) => (
                                <IOSSwitch
                                    color="primary"
                                    checked={value}
                                    onChange={(e) => onChange(e.target.checked)}
                                />
                            )}
                        />
                    </div>
                    {checkSmsDNC && <Controller
                        name={'sms_dnc_gateway_id'}
                        control={control}
                        defaultValue=""
                        render={({onChange, value}) => {
                            return (
                                <TextField
                                    select
                                    onChange={onChange}
                                    value={value}
                                    variant="outlined"
                                    margin="normal"
                                    label="Sms DNC Gateway"
                                >
                                    <MenuItem value="" disabled>
                                        Select gateway
                                    </MenuItem>
                                    {smsDNCGateways.map((gateway) => {
                                        return <MenuItem key={gateway.id} value={gateway.id}>
                                            {gateway.name}
                                        </MenuItem>
                                    })}
                                </TextField>
                            );
                        }}
                    />}

                    <div className="align-center wrapper content-justified mt15">
                        <div style={{fontWeight: '600', fontSize: '14px'}}>Lookup emails</div>

                        <Controller
                            name="check_email_status"
                            control={control}
                            defaultValue={false}
                            render={({onChange, value}) => (
                                <IOSSwitch
                                    color="primary"
                                    checked={value}
                                    onChange={(e) => onChange(e.target.checked)}
                                />
                            )}
                        />
                    </div>

                    {checkEmailStatus && <Controller
                        name={'email_lookup_gateway_id'}
                        control={control}
                        defaultValue=""
                        render={({onChange, value}) => {
                            return (
                                <TextField
                                    select
                                    onChange={onChange}
                                    value={value}
                                    variant="outlined"
                                    margin="normal"
                                    label="Email Lookup Gateway"
                                >
                                    <MenuItem value="" disabled>
                                        Select gateway
                                    </MenuItem>
                                    {emailLookupGateways.map((gateway) => {
                                        return <MenuItem key={gateway.id} value={gateway.id}>
                                            {gateway.name}
                                        </MenuItem>
                                    })}
                                </TextField>
                            );
                        }}
                    />
                    }

                    <ReactHookFormSelect
                        disabled={false}
                        variant="outlined"
                        margin="normal"
                        id="type-select"
                        name="lead_status"
                        label="Lead Status"
                        control={control}
                        defaultValue={''}
                    >
                        <MenuItem value={null}>None</MenuItem>
                        {
                            leadStatusesOptions.map((status) => {
                                return <MenuItem key={status.id} value={status.id}>
                                    {status.name}
                                </MenuItem>
                            })
                        }

                    </ReactHookFormSelect>

                    <ReactHookFormSelect
                        disabled={false}
                        variant="outlined"
                        margin="normal"
                        id="type-select"
                        name="email_status"
                        label="Email Status"
                        control={control}
                    >
                        <MenuItem value={null}>None</MenuItem>
                        {
                            emailStatusesOptions.map((status) => {
                                return <MenuItem key={status.id} value={status.id}>
                                    {status.name}
                                </MenuItem>
                            })
                        }
                    </ReactHookFormSelect>

                    <ReactHookFormSelect
                        disabled={false}
                        variant="outlined"
                        margin="normal"
                        id="type-select"
                        name="phone_status"
                        label="Phone Status"
                        control={control}
                    >
                        <MenuItem value={null}>None</MenuItem>
                        {
                            phoneStatusesOptions.map((status) => {
                                return <MenuItem key={status.id} value={status.id}>
                                    {status.name}
                                </MenuItem>
                            })
                        }
                    </ReactHookFormSelect>

                    <div className="btnHolder hasPadTop twoBtn">
                        <div className="part">
                            <Button
                                disabled={storeMutation.isLoading}
                                variant="contained"
                                color="primary"
                                fullWidth
                                type="submit"
                                onClick={(e) => {
                                }}
                            >
                                {buttonTitle}
                            </Button>
                        </div>
                        <div className="part">
                            <Button
                                disabled={storeMutation.isLoading}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={handleClose}
                            >
                                {t('Modals.Cancel')}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </Dialog>
    );
};

export default SaveListImportKeyModal;
