import React, {useState, useEffect, useMemo} from "react"
import LogoSegma from "../../assets/img/Segma.svg"
import LogoNctek from "../../assets/img/nctek.svg"
import Logo from "../../assets/img/Leadsta.svg"
import Button from "@material-ui/core/Button"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import Popover from "@material-ui/core/Popover"
import Avatar from "@material-ui/core/Avatar"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import SettingsIcon from "@material-ui/icons/Settings"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import AddIcon from "@material-ui/icons/Add"
import {
    setSource,
    setList,
    setLead,
    setSources,
    setNewImport,
    setRunDataUpdate,
} from "../../Store/modules/sources/actions"
import {
    setSegment,
    setSegmentLead,
} from "../../Store/modules/segments/actions"
import {useAuth} from "../../Contexts/AuthProvider"
import {connect} from "react-redux"
import MenuIcon from "@material-ui/icons/Menu"
import IconButton from "@material-ui/core/IconButton"
import Drawer from "@material-ui/core/Drawer"
import Badge from "@material-ui/core/Badge"
import File from "../../assets/img/file_blank.svg"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorIcon from "@material-ui/icons/Error"
import ImportSubscription from "../../Subscriptions/ImportSubscription"
import DataService from "../../Services/DataService"
import {Navigate, useNavigate} from "react-router-dom"
import {useQueryClient} from "react-query"
import {useAppState} from "../../Contexts/AppContext"
import MenuItem from "../menus/MenuItem"
import BreadcrumbItem from "../menus/BreadcrumbItem"
import LookupSwitcher from "../../LookupSwitcher"
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone"
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile"
import {Alert} from "@material-ui/lab"
import {Typography} from "@material-ui/core"
import {splitURL} from "../../Utils/splitURL"
import {stringAvatar} from "../../Utils/avatarName"
import LoggedUsersService from "../../Services/LoggedUsersService";

const HeaderComponent = ({setSources, newImport}) => {
    const [isTabActive, setIsTabActive] = useState(true);
    const {logout, userName, userId, loggedUser} = useAuth()
    const [imports, setImports] = useState([])
    const [currentImport, setCurrentImport] = useState(null)
    const [badgeState, setBadgeState] = useState(true)
    const [userLogin] = useState({isLogged: true})
    const [redirectToChangePassword, setRedirectToChangePassword] =
        useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const handleUserMenuClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const {warnings, contextLinks, breadcrumbs, mainMenu} = useAppState()
    const history = useNavigate()
    const open = Boolean(anchorEl)
    const handleUserMenuClose = () => {
        setAnchorEl(null)
    }
    const queryClient = useQueryClient()
    const [refreshCounter, setRefreshCounter] = useState(0);
    const [loggedUsersList, setLoggedUsersList] = useState([]);

    const warningsEnabled = useMemo(() => {
        return warnings.lookup
    }, [warnings])

    useEffect(() => {
        if (userId) {
            const loggedUsers = LoggedUsersService.getLoggedUsersList().filter((u) => u.id !== userId);
            setLoggedUsersList(loggedUsers);
        }
    }, [userId])

    useEffect(() => {
        if (contextLinks.length) {
            setBadgeState(false)
        }
    }, [contextLinks])

    useEffect(() => {
        async function fetchActiveImports() {
            let response = await DataService.getActiveImports()
            if (!response || !response.data) return
            if (response.data.data) {
                let newImports = response.data.data
                    .map((i) => {
                        return {
                            fileId: i.id,
                            status: "Running",
                            filename: i.name,
                            processed_count: i.processed_count,
                            total_rows: i.total_rows
                        }
                    })
                setImports(newImports)
            }
        }

        // fetchActiveImports().then()
    }, [refreshCounter]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (isTabActive) {
                setRefreshCounter(refreshCounter + 1);
            }
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, [refreshCounter]);



    const onFocus = () => {
        setIsTabActive(true)
    };

    const onBlur = () => {
        setIsTabActive(false)
    };

    useEffect(() => {
        window.addEventListener("focus", onFocus);
        window.addEventListener("blur", onBlur);
        onFocus();
        return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);
        };
    }, []);

    useEffect(() => {
        if (!currentImport) {
            return
        }
        if (
            currentImport.type === "end_resolve_duplicates" ||
            (currentImport.type === "parse_finished" &&
                currentImport.duplicates_resolved)
        ) {
            queryClient.invalidateQueries("segmentLeads").then()
            queryClient.invalidateQueries("segmentsList").then()
            queryClient.invalidateQueries("sourceLists").then()
            queryClient.invalidateQueries("sourcesList").then()
            queryClient.invalidateQueries("sourcesTotal").then()
            queryClient.invalidateQueries("sourceView").then()
            queryClient.invalidateQueries("listLeads").then()
        }
    }, [currentImport, queryClient])

    const [anchorImportEl, setAnchorImportEl] = React.useState(null)
    const handleImportingClick = (event) => {
        setAnchorImportEl(event.currentTarget)
    }
    const openImporting = Boolean(anchorImportEl)
    const handleImportingClose = () => {
        setAnchorImportEl(null)
    }
    const handleOnSubmit = () => {
        userLogin.isLogged = false
        setSources([])
        logout()
    }
    const [showDrawer, setShowDrawer] = useState(false)
    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return
        }
        setShowDrawer(open)
    }

    const handleDownloadFile = (url) => {
        window.open(url)
    }

    const openNewTab = (url) => {
        window.open(url, "_blank")
    }

    const redirectDownloadFile = () => {
        history("./settings/download-file")
        handleImportingClose()
    }

    const addAccount = () => {
        history({
            pathname: "/add-account",
            search: "?preventRedirect=true",
        })
    }

    const resetImports = () => {
        setImports((imports) => {
            return [
                ...imports.filter(
                    (importData) =>
                        importData.status === "Running" || importData.status === "Resolving"
                ),
            ]
        })
    }

    useEffect(() => {
        if (!newImport) return
        setImports((imports) => {
            imports.push(newImport)
            return [...imports]
        })
        setNewImport(null)
    }, [newImport])

    const renderMobileMenu = () => {
        return (
            <Drawer
                anchor="left"
                open={showDrawer}
                onClose={toggleDrawer(false)}
                className="mainMenuDrawer"
            >
                <div className="menuList mobile">
                    {mainMenu.map((item) => (
                        <MenuItem item={item} key={item.label}/>
                    ))}
                </div>
            </Drawer>
        )
    }

    const renderBreadcrumbs = () => {
        return (
            <ol className="breadcrumbs">
                {breadcrumbs.map((item, idx) => (
                    <BreadcrumbItem item={item} key={idx}/>
                ))}
            </ol>
        )
    }

    const renderProcessesButton = () => {
        return (
            <Button
                color={warningsEnabled ? "secondary" : "default"}
                endIcon={<ArrowDropDownIcon/>}
                startIcon={
                    warningsEnabled ? (
                        <ErrorIcon/>
                    ) : (
                        <Badge
                            color="secondary"
                            variant="dot"
                            invisible={badgeState}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                        >
                            {" "}
                            <NotificationsNoneIcon/>
                        </Badge>
                    )
                }
                onClick={handleImportingClick}
            >
                {/*{inProgressImportsCount > 0 ? `Importing ${inProgressImportsCount} files` : "File importer1"}*/}
                System Processes
            </Button>
        )
    }

    if (redirectToChangePassword) return <Navigate to="/change-password"/>
    return (
        <header className="mainHeader">
            <div className="mobileMainMenuHolder">
                <IconButton onClick={toggleDrawer(true)}>
                    <MenuIcon/>
                </IconButton>
                {renderMobileMenu()}
            </div>
            <div className="logoHolder">
                <img
                    height="50"
                    src={window.location.host.includes("nctek.segma.io") ? LogoNctek : window.location.host.includes("segma.io") ? LogoSegma : Logo}
                    alt=""
                />
            </div>
            <div className="breadCrumbsHolder">{renderBreadcrumbs()}</div>
            <div className="userMenuHolder">
                <>
                    {renderProcessesButton()}
                    <Popover
                        open={openImporting}
                        anchorEl={anchorImportEl}
                        onClose={handleImportingClose}
                        className="importPopover"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <div className="importPopoverContent">
                            <div className="ipTitleWrapper">
                                <div className="title">System Processes</div>
                                <div
                                    className="linkColorNormal text-semi-bold text-mute clickable"
                                    onClick={(e) => resetImports()}
                                >
                                    Clear
                                </div>
                            </div>
                            {!badgeState && (
                                <>
                                    {contextLinks.slice(0, 5).map(({url, disabled, label}, id) => (
                                            <div key={id + 1} className="downloadWrapper">
                                                <div className="fileWrapper">
                                                    <InsertDriveFileIcon/>
                                                    <Typography variant="p" component="span">
                                                        {splitURL(url)}
                                                    </Typography>
                                                </div>
                                                <Button
                                                    disabled={disabled}
                                                    variant="outlined"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => handleDownloadFile(url)}
                                                    className="btnDownload"
                                                >
                                                    {label}
                                                </Button>
                                            </div>
                                        )
                                    )}
                                    <div className="seeMoreWrapper">
                                        <Button onClick={redirectDownloadFile} variant="text" color="primary"
                                                size="small">
                                            See More
                                        </Button>
                                    </div>
                                </>
                            )}

                            <div className="fileProgressHolder">
                                <ul className="fileProgress">
                                    {imports.map((importData) => {
                                        return (
                                            <li key={importData.fileId} className="fileProgressItem">
                                                <div className="icoHolder">
                                                    <img src={File} alt=""/>
                                                </div>
                                                <div className="progress">
                                                    <div className="progressStatus">
                                                        <div className="name text-mute text-semi-bold">
                                                            {importData.filename}
                                                        </div>
                                                        <div className="qnt text-light text-mute">
                                                            <div>Processed {importData.processed_count} leads
                                                                from {importData.total_rows}</div>
                                                        </div>
                                                    </div>
                                                    <LinearProgress
                                                        className="mt5"
                                                        variant="determinate"
                                                        value={parseInt(importData.processed_count / importData.total_rows * 100)}
                                                    />
                                                </div>
                                            </li>
                                        )
                                    })}
                                    <LookupSwitcher/>
                                </ul>
                                {warningsEnabled && (
                                    <Alert
                                        severity="error"
                                        style={{width: "100%", marginTop: "15px"}}
                                    >
                                        Insufficient funds to continue paid lookup!
                                    </Alert>
                                )}
                            </div>
                        </div>
                    </Popover>
                </>
                <>
                    <Button endIcon={<ArrowDropDownIcon/>} onClick={handleUserMenuClick}>
                        {userName}
                    </Button>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleUserMenuClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <List>
                            {
                                loggedUsersList.map((user) =>
                                    <ListItem
                                        key={user.id}
                                        button
                                        onClick={() => openNewTab(`/u/${user.index}/`)}
                                    >
                                        <ListItemAvatar>
                                            <Avatar
                                                {...stringAvatar(user.name[0])}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={user.name}
                                            secondary={user.isExpired ? "Not logged" : user.email}
                                        />
                                    </ListItem>
                                )
                            }
                        </List>

                        <Grid container justify="center">
                            <Button
                                size="small"
                                startIcon={<AddIcon/>}
                                onClick={addAccount}
                            >
                                Add Account
                            </Button>
                        </Grid>


                        <List
                            component="nav"
                            aria-label="main mailbox folders"
                            className="popoverMenu"
                        >
                            <ListItem
                                button
                                onClick={() => setRedirectToChangePassword(true)}
                            >
                                <ListItemIcon>
                                    <SettingsIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Change password"/>
                            </ListItem>
                            <ListItem button onClick={handleOnSubmit}>
                                <ListItemIcon>
                                    <ExitToAppIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Log out"/>
                            </ListItem>
                        </List>
                    </Popover>

                    <ImportSubscription/>
                </>
            </div>
        </header>
    )
}

const mapDispatchToProps = {
    setSources,
    setSource,
    setList,
    setLead,
    setNewImport,
    setRunDataUpdate,
    setSegment,
    setSegmentLead,
}

const mapStateToProps = ({sources, segments}) => ({
    newImport: sources.newImport,
    runDataUpdate: sources.runDataUpdate,
    segment: segments.segment,
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)
