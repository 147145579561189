import React, { useEffect, useMemo, useState } from "react";
import InlineLoader from "./loaders/InlineLoader"
import {useQuery} from "react-query"
import {getCampaignStatisticsCounters, getSourcesTotal} from "../api"

const CampaignsCounters = () => {
    const [aggregations, setAggregations] = useState({
        sent: {
            sms: "-",
            email: "-"
        },
        delivered: {
            sms: "-",
            email: "-"
        },
        clicks: {
            sms: "-",
            email: "-"
        },
        cost: {
            sms: "-",
            email: "-"
        }
    });

    const request = useQuery("campaignStatisticCounters", getCampaignStatisticsCounters, { keepPreviousData: false })

    useEffect(() => {
        if (!request.data) {
            return
        }

        setAggregations(request.data);
    }, [request]);

    const items = useMemo(() => [
        {
            label: "Sent Today",
            data: aggregations["sent"],
        },
        {
            label: "Delivered Today",
            data: aggregations["delivered"],
            percent_sms: aggregations["sent"]["sms"] > 0 ? parseInt(aggregations["delivered"]["sms"] / aggregations["sent"]["sms"] * 100) : 0,
            percent_email: aggregations["sent"]["email"] > 0 ? parseInt(aggregations["delivered"]["email"] / aggregations["sent"]["email"] * 100) : 0,
        },
        {
            label: "Clicks Today",
            data: aggregations["clicks"],
            percent_sms: aggregations["sent"]["sms"] > 0 ? parseInt(aggregations["clicks"]["sms"] / aggregations["sent"]["sms"] * 100) : 0,
            percent_email: aggregations["sent"]["email"] > 0 ? parseInt(aggregations["clicks"]["email"] / aggregations["sent"]["email"] * 100) : 0,
        },
        {
            label: "Cost Today",
            data: aggregations["cost"],
        },
    ], [request, aggregations]);

    return (
        <div className="pageHeadingBlockWrapper">
            {
                items.map((item) => (
                    <div className="part" key={item.label}>
                        <div className="partContent">
                            <h2 className="mb10">{item.label}</h2>
                            <div className="partContentItem">
                                SMS: {request.isFetching ? <InlineLoader style={{ display: "flex" }}/> : item.data["sms"]}
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default CampaignsCounters
