import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import LookupListActions from './LookupListActions'
import {useAppDispatch, useAppState} from '../../Contexts/AppContext'
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {getLookupList, getSMSGateways, updateLookupStatus} from '../../api';
import ListTable from '../../components/ListTable/ListTable'
import LookupStatus from '../../components/LookupStatus'
import TableProgressCell from '../../components/TableProgressCell'
import LoadingValue from '../../components/LoadingValue'
import LookupSaveModal from '../../components/modals/LookupSaveModal'
import EventEmitter from '../../Utils/EventEmitter'
import {Link} from 'react-router-dom';
import {StopRounded, PlayArrowRounded, Pause,} from '@material-ui/icons';
import {IconButton, Tooltip} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import {useAuth} from '../../Contexts/AuthProvider';

const LookupRunAction = ({id, status, loading = false}) => {
    const {t} = useTranslation();
    const actionButtons = {
        'start': {title: t('Lookup.Start'), action: 'start', icon: <PlayArrowRounded/>},
        'pause': {title: t('Lookup.Pause'), action: 'pause', icon: <Pause/>},
        'stop': {title: t('Lookup.Stop'), action: 'stop', icon: <StopRounded/>}
    }
    //{0: 'not started', 1: 'in progress', 2: 'stop', 3: 'finished'}
    const queryClient = useQueryClient()
    const updateMutation = useMutation(updateLookupStatus)
    const handleAction = (action) => async () => {
        let status;
        if (action === 'start') status = 1
        if (action === 'pause') status = 2
        if (action === 'stop') status = 2
        await updateMutation.mutateAsync({id, status})

        queryClient.invalidateQueries('lookupList').then()
    }

    const renderButton = (action) => {
        const disabled = updateMutation.isLoading || loading

        const button = (
            <IconButton
                disabled={disabled}
                size="small"
                onClick={handleAction(actionButtons[action].action)}
            >
                {actionButtons[action].icon}
            </IconButton>
        )
        return (disabled ? button : (
                <Tooltip title={actionButtons[action].title}>
                    {button}
                </Tooltip>
            )

        )
    }

    const renderButtons = () => {
        if ([0, 2].includes(status)) return renderButton('start')
        if (status === 1) return (
            <Fragment>
                {/*{renderButton('pause')}*/}
                {renderButton('stop')}
            </Fragment>
        )
        return null
    }

    return renderButtons()
}

const LookupList = () => {
    const {t} = useTranslation();

    const {setBreadcrumbs} = useAppDispatch()
    const {config, configIsLoading} = useAppState()
    const {availableSources} = useAppState()
    const {loggedUser} = useAuth()

    const FormatTypeLookup = (val) => {
        if (!configIsLoading) {
            const result = config?.lookup?.lead_type.find((el) => el.key === val)
            return result.value
        } else {
            return ' '
        }
    }
    const lookupStatusesOptions = useMemo(() => {
        if (!config['lookup']) return {}
        const output = {}
        Object.entries(config['lookup']['status']).forEach(([label, id]) => output[id] = label.toLocaleLowerCase())
        return output
    }, [config])

    const lookupStatusesSelectOptions = useMemo(() => {
        return Object.entries(lookupStatusesOptions).map(([id, name]) => ({id, name}))
    }, [lookupStatusesOptions])

    const [currentModel, setCurrentModel] = useState(false)
    const [showUpdateModal, setShowUpdateModal] = useState(false)
    const [lookups, setLookups] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [pagination, setPagination] = useState(null)
    const [search, setSearch] = useState('')
    const [sorting, setSorting] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const [userGateways, setUserGateways] = useState([])

    const lookupListRequest = useQuery(
        ['lookupList', page, limit, search, sorting],
        () => getLookupList(page, limit, search, sorting),
        {keepPreviousData: true}
    )

    const userGatewaysRequest = useQuery(
        ['smsGateways'],
        () => getSMSGateways(),
        {keepPreviousData: true}
    )

    const lookupGateways = useMemo(() => {
        return userGateways.filter(gateway => !!gateway.allow_lookup)
    }, [userGateways])

    const selectedModels = useMemo(() => {
        if (!lookups.length || !selectedRows.length) {
            return []
        }
        return lookups.filter(item => selectedRows.includes(item.id)).map(item => ({
            id: item.id,
            source_id: item.source?.id,
            list_id: item.list?.id,
            messaging_id: item.gateway?.id,
            type: item.type,
            status: item.status
        }))
    }, [lookups, selectedRows])

    const tableColumns = useMemo(() => {
        return [
            {
                Header: t('Lookup.Location'),
                Cell: ({row}) => {
                    const {source, list, segment} = row.original

                    if (segment) {
                        if (segment.parent) {
                            return <Link
                                to={`/u/${loggedUser}/audiences/${segment.parent.id}/segments/${segment.id}/leads`}>{segment.parent.name},{segment.name}</Link>
                        }

                        return <Link to={`/u/${loggedUser}/audiences/${segment.id}/leads`}>{segment.name}</Link>
                    }

                    return (
                        list ? (
                            <>
                                <Link style={{width: 'auto'}}
                                      to={`/u/${loggedUser}/sources/${source.id}`}>{source.name}</Link>
                                {', '}
                                <Link style={{width: 'auto'}}
                                      to={`/u/${loggedUser}/sources/${source.id}/${list.id}`}>{list.name}</Link>
                            </>
                        ) : (
                            <Link to={`/u/${loggedUser}/sources/${source.id}`}>{source.name}</Link>
                        )
                    )
                }
            },
            {
                Header: 'Gateway',
                accessor: '',
                Cell: ({row}) => {
                    if (row.original.gateway) {
                        return row.original.gateway.name;
                    }

                    return '-';
                }
            },
            {
                Header: t('Lookup.LookupType'),
                accessor: 'lead_type',
                Cell: ({value}) => FormatTypeLookup(value)
            },
            {
                Header: 'Total items',
                accessor: 'total_count',
                Cell: ({value}) => value
            },
            {
                Header: 'Items',
                accessor: 'count',
                Cell: ({value}) => value
            },
            {
                Header: 'Cost',
                accessor: 'cost',
                Cell: ({value}) => '$' + value
            },
            {
                Header: 'Service items',
                accessor: 'service_items_count',
            },
            {
                Header: 'Service cost',
                accessor: 'service_cost',
                Cell: ({value}) => '$' + value,
            },
            {
                Header: t('Lookup.Progress'),
                accessor: 'percent',
                Cell: ({value, row}) => {
                    const progress = <TableProgressCell value={value} tooltip={row.original.time_left}/>
                    return <LoadingValue value={value} renderedValue={progress}/>
                }
            },
            {
                Header: t('Lookup.Status'),
                className: 'col-sticky text-right',
                Cell: ({row}) => {
                    const {id, status} = row.original
                    return (
                        <>
                            <LookupRunAction id={id} status={status} loading={lookupListRequest.isFetching}/>
                            <LookupStatus id={status} label={lookupStatusesOptions[status] || '-'}/>
                        </>
                    )
                }
            },
        ]
    }, [lookupStatusesOptions, lookupListRequest.isFetching, t])


    const tableValues = useMemo(() => {
        return lookups
    }, [lookups])


    useEffect(() => {
        setBreadcrumbs([{label: t('Lookup.Lookup Management')}])
    }, [setBreadcrumbs, t])

    useEffect(() => {
        if (lookupListRequest.isSuccess) {
            setLookups(lookupListRequest.data.data)
            setPagination(lookupListRequest.data.meta)
        }
    }, [lookupListRequest.data, lookupListRequest.isSuccess])

    useEffect(() => {
        if (userGatewaysRequest.isSuccess) {
            const {data} = userGatewaysRequest.data
            setUserGateways(data.filter(item => item.active))
        }
    }, [userGatewaysRequest.isSuccess, userGatewaysRequest.data])

    useEffect(() => {
        const onEvent = (data) => {
            setLookups(prevData => {
                const newData = [...prevData]
                const idx = newData.findIndex(item => item.id === data.id)
                if (idx !== -1) {
                    newData[idx] = {
                        ...newData[idx],
                        status: data.status,
                        time_left: data.time_left,
                        percent: data.percent,
                        count: data.count
                    }
                }
                return newData
            })
        }

        const listener = EventEmitter.addListener('LookupData', onEvent)
        return () => {
            listener.remove()
        }
    }, [setLookups])

    const handleLimitChange = useCallback((l) => {
        setPage(1)
        setLimit(l)
    }, [setPage, setLimit])

    const handleSearchChange = useCallback((str) => {
        setPage(1)
        setSearch(str)
    }, [setPage, setSearch])

    const handleEditItem = (item) => {
        setCurrentModel({
            id: item.id,
            source_id: item.source?.id,
            list_id: item.list?.id,
            messaging_id: item.gateway?.id,
            type: item.type,
            status: item.status,
            lead_type: item.lead_type,
            setting_gateway_id: item.setting_gateway_id,
        })
        setShowUpdateModal(true)
    }

    return (
        <div className="pageHolder size">
            <ListTable
                columns={tableColumns}
                data={tableValues}
                loading={lookupListRequest.isLoading}
                fetching={lookupListRequest.isFetching}
                isSelectionColumn={true}
                onSelectedRows={setSelectedRows}
                onSort={setSorting}
                emptyDataText={t('Lookup.No Lookups Found')}
                pagination={pagination}
                pageLimit={limit}
                onPageChange={setPage}
                onLimitChange={handleLimitChange}
                onSearch={handleSearchChange}
                onRowClick={handleEditItem}
            />

            <LookupListActions
                statusOptions={lookupStatusesSelectOptions}
                selectedRows={selectedRows}
                selectedModels={selectedModels}
                userGateways={lookupGateways}
            />
            {
                currentModel && (
                    <LookupSaveModal
                        open={showUpdateModal}
                        handleClose={() => {
                            setCurrentModel(null)
                            setShowUpdateModal(false)
                        }}
                        sources={availableSources}
                        userGateways={lookupGateways}
                        statusOptions={lookupStatusesSelectOptions}
                        defaultValues={currentModel}
                    />
                )
            }

        </div>
    )
}

export default LookupList
