import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useQuery} from "react-query";
import {useParams} from "react-router-dom";
import {useQueryState} from "use-location-state";
import {getLeadsList} from "../api";
import {useAppDispatch} from "../Contexts/AppContext";
import {leadLinkWrapper} from "../helpers";
import {toLocalFormat} from "../Utils/dates";
import {storageKeys} from "../config";
import ListTable from "../components/ListTable/ListTable";
import LeadStatus from "../components/LeadStatus";
import LeadsListActions from "./LeadsListActions";


const LeadsList = () => {
    const params = useParams();
    const [leads, setLeads] = useState([]);
    const [leadsAttributes, setLeadsAttributes] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState([]);
    const [page, setPage] = useQueryState("page", 1);
    const [limit, setLimit] = useQueryState("limit", 50);
    const {setBreadcrumbs, setSection, setContextMenu} = useAppDispatch();

    const leadLink = useCallback((value) => {
        return `/u/${params.loggedUser}/leads/${value}`;
    }, [params]);

    const tableColumns = useMemo(() => {
        const attributes = leadsAttributes.map(attr => {
            return {
                Header: attr.name,
                accessor: `${attr.id}`,
                Cell: ({ value, row }) => leadLinkWrapper(leadLink(row.original.id), value, row),
            };
        });

        return [
            {
                Header: "ID",
                accessor: "id",
                Cell: ({ value, row }) => leadLinkWrapper(leadLink(row.original.id), value, row),
            },
            {
                Header: "Updated Date",
                accessor: "updated_at",
                Cell: ({ value, row }) => leadLinkWrapper(leadLink(row.original.id), toLocalFormat(value), row),
            },
            ...attributes,
            {
                Header: "Revenue",
                accessor: "revenue"
            },
            {
                Header: "Duplicates",
                accessor: "duplicates_count",
                Cell: ({ value, row} ) => leadLinkWrapper(leadLink(row.original.id), value, row),
            },
            {
                Header: "Lead Status",
                accessor: "status",
                className: "col-sticky text-right",
                Cell: ({ value }) => <LeadStatus status={value}/>,
            },
        ];
    }, [leadsAttributes]);

    const tableValues = useMemo(() => {
        return leads.map(item => {
            const leadValues = item.lead_values;
            const newItem = {...item};
            leadValues.forEach(value => {
                const attrId = value && value.attribute && value.attribute.id;
                if (attrId)
                    newItem[attrId] = newItem[attrId]
                        ? `${newItem[attrId]}; ${value.value}`
                        : value.value;
            });
            delete newItem["lead_values"];
            return newItem;
        });
    }, [leads]);

    const {isSuccess, data, isLoading, isFetching} = useQuery(
        ["leadsList", page, limit, search, sorting],
        () => getLeadsList(page, limit, search, sorting),
        {keepPreviousData: true},
    );

    useEffect(() => {
        setBreadcrumbs([{label: "Leads"}]);
        setSection("leads");
    }, [setBreadcrumbs, setSection, setContextMenu]);

    useEffect(() => {
        if (isSuccess) {
            setLeads(data.data.data);
            setPagination({
                current_page: page,
                from: page * limit + 1,
                last_page: page + 1,
                to: "xxx",
                total: page * limit + 1,
            })

            if (data.data.attributes) {
                setLeadsAttributes(data.data.attributes);
            }
        }
    }, [isSuccess, data, page, setPage]);

    return (
        <div className="pageHolder">
            <ListTable
                columns={tableColumns}
                data={tableValues}
                loading={isLoading}
                fetching={isFetching}
                isSelectionColumn={true}
                onSelectedRows={setSelectedRows}
                onSort={setSorting}
                emptyDataText="No Leads Found"
                pagination={pagination}
                pageLimit={limit}
                onPageChange={setPage}
                onLimitChange={setLimit}
                onSearch={setSearch}
                recordsInfo={null}
                hiddenColumnsStorageKey={storageKeys.leadsHiddenColumnsInLeads}
                selectedRowIds={{"d647aebc-963b-40e4-9fd2-fe986efe1e3e": true}}
            />
            <LeadsListActions
                selectedRows={selectedRows}
            />
        </div>
    );
};

export default LeadsList;
