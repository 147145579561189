import React, { useState } from "react";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core";

const ValueTextField = withStyles({
    root: {
        "& .MuiOutlinedInput-root": {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
        },
        "& .MuiOutlinedInput-input": {
            boxSizing: "border-box",
        }
    },
})(TextField);

const ValueChip = withStyles({
    root: {
        maxWidth: "100%",
        minHeight: 24,
        height: "auto",
        lineHeight: 1,
        "& .MuiChip-label": {
            whiteSpace: "normal",
            wordBreak: "break-word",
        }
    }
})(Chip);

const SegmentFilterInputValue = ({ valueMap, filter, hasError, onValueMapChange }) => {
    const [value, setValue] = useState("");

    const valueChangeHandler = (event) => {
        setValue(event.target.value);
    };

    const valueKeyDownHandler = (event) => {
        event.stopPropagation();
        if (event.key !== "Enter") {
            return
        }

        const trimmedValue = value.trim();

        if (!trimmedValue) {
            return;
        }

        if (valueMap.value.includes(trimmedValue)) {
            return;
        }

        onValueMapChange({
            ...valueMap,
            value: [...valueMap.value, trimmedValue],
        });
        setValue("");
    }

    const valueRemoveHandler = (value) => {
        onValueMapChange({
            ...valueMap,
            value: valueMap.value.filter((item) => item !== value),
        });
    }

    return (
        <ValueTextField
            id={`value-${valueMap.value}`}
            type="text"
            error={hasError}
            variant="outlined"
            InputProps={{
                startAdornment: valueMap?.value?.length ? (
                    <Box style={{ overflow: "scroll", paddingTop: 10 }}>{
                        valueMap.value.map((value, index) =>
                            <ValueChip
                                key={index}
                                label={value}
                                size="small"
                                variant="outlined"
                                onDelete={() => valueRemoveHandler(value)}
                            />
                        )
                    }
                    </Box>
                ) : null,
            }}
            onChange={valueChangeHandler}
            onKeyDown={valueKeyDownHandler}
            disabled={!filter?.expression}
            value={value}
            label="Add options"
        />
    )
};

export default SegmentFilterInputValue;
