import React, { Fragment, useState } from 'react'
import InlineLoader from './loaders/InlineLoader'

const ListHeader = props => {

    const {
        list,
        isLoading,
    } = props

    const [dummyAttributes] = useState([])

    const renderName = () => {
        return isLoading ? <InlineLoader size={30} /> : list?.name
    }

    const renderAttributes = () => {
        return (
            <Fragment>
                {
                    dummyAttributes.map((item, index) => {
                        return (
                            <p className='text-mute mb5' key={index}>
                                <span className="text-semi-bold">{item.label}:</span>&nbsp;<span className="text-light">{item.value}</span>
                            </p>
                        )
                    })
                }
            </Fragment>
        )
    }

    const items = [
        {
            label: 'Total Leads',
            count: list?.total_leads
        },
        {
            label: 'Unique Today',
            count: list?.unique_today
        },
        {
            label: 'Total today',
            count: list?.added_today
        }
    ]



    return (
        <div className="pageHeadingBlockWrapper">
            <div className="part main">
                <div className="partContent">
                    <div className="hasSwitch mb15">
                        <h2 className="textPrimary">{renderName()}</h2>
                    </div>
                    {renderAttributes()}
                </div>
            </div>
            {
                items.map(item => (
                    <div className="part" key={item.label}>
                        <div className="partContent">
                            <h2 className="mb10">{item.label}</h2>
                            <div>{isLoading ? <InlineLoader /> : item.count}</div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default ListHeader
