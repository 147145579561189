import React, {Fragment, useEffect, useMemo, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import {useAppState} from '../../Contexts/AppContext'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {
    getClickTriggerCampaigns,
    getEmailGatewayDomains, getSenderEmailGroups,
    getSendersWithAccess,
    getShortUrlDomainsWithAccess, getTmoLimitTriggerCampaigns, getTwoFATriggerCampaigns,
    storeCampaignStep,
    updateCampaignStep
} from '../../api'
import AlertToastr from '../../Utils/alert'
import MenuItem from '@material-ui/core/MenuItem'
import ReactHookFormSelect from '../form/ReactHookFormSelect'
import {useCampaignState} from '../../Contexts/CampaignContext'
import Chip from '@material-ui/core/Chip'
import SmsCounter from "./SmsCounter";
import {emailCampaignsSenderTypes, emailClicksTrackingVersions} from "../../config";
import IOSSwitch from "../IOSSwitch";
import InputAdornment from "@material-ui/core/InputAdornment";

const SaveCampaignStepModal = props => {
    const {
        campaign,
        open,
        handleClose,
        defaultValues,
    } = props

    const [campaignStepTypes, setCampaignStepTypes] = useState([])
    useEffect(() => {
        if (campaign.type === 'email' || campaign.type === 'email_automation') {
            setCampaignStepTypes(
                [
                    {key: 'email', label: "Email"},
                    {key: 'split', label: "Split"}
                ]
            );
        } else {
            setCampaignStepTypes(
                [
                    {key: 'sms', label: 'Sms'},
                    {key: 'split', label: 'Split'}
                ]
            );
        }
    }, [campaign])
    const {
        control,
        watch,
    } = useForm({
        mode: 'onTouched',
        defaultValues: defaultValues
    })

    const typeValue = watch('type', campaignStepTypes[0] && campaignStepTypes[0].key)
    const title = defaultValues ? 'Update Step' : 'Add Step'

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className='modal'
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>{title}</h2>
                    </div>

                    {!hasParentId(defaultValues) &&
                        <ReactHookFormSelect
                            disabled={Boolean(defaultValues)}
                            variant="outlined"
                            margin="normal"
                            id="type-select"
                            name="type"
                            label="Step Type"
                            control={control}
                            defaultValue={campaignStepTypes[0] && campaignStepTypes[0].key}
                        >
                            {campaignStepTypes.map(option => (
                                <MenuItem key={option.key} value={option.key}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>
                    }

                    {typeValue === 'sms' &&
                        <SmsTypeForm {...props} />
                    }

                    {typeValue === 'split' &&
                        <SplitTypeForm {...props} />
                    }

                    {typeValue === 'email' &&
                        <EmailTypeForm {...props} />
                    }

                </div>
            </Dialog>
        </Fragment>
    )
}

const SmsTypeForm = props => {
    const {
        handleClose,
        defaultValues,
        campaign,
        disableDelay
    } = props

    const {configIsLoading} = useAppState()
    const {availableAttributes} = useCampaignState()
    const [shortUrlsOptions, setShortUrlsOptions] = useState([])
    const [clickTriggerCampaigns, setClickTriggerCampaigns] = useState([])
    const [twoFaTriggerCampaignsCampaigns, setTwoFaTriggerCampaignsCampaigns] = useState([])
    const [tmoLimitTriggerCampaigns, setTmoLimitTriggerCampaigns] = useState([])
    const [showClickAutomationList, setShowClickAutomationList] = useState(Boolean(defaultValues?.click_automation_campaign_id));
    const [showTmoLimitAutomationList, setShowTmoLimitAutomationList] = useState(Boolean(defaultValues?.tmo_limit_automation_campaign_id));

    const queryClient = useQueryClient()
    const [messageCursorPosition, setMessageCursorPosition] = useState()
    const availableAttributesWithLink = useMemo(() => {
        let attrs = [
            {id: 'link', name: 'Link', slug: 'link'},
            {id: 'source', name: 'Source', slug: 'source'},
            {id: 'list', name: 'List', slug: 'list'},
            {id: "last4num", name: "Last 4 num", slug: "last4num"},
            {id: "from100-5000by500", name: "Random Number", slug: "from100-5000by500"},
        ]
        if (availableAttributes) {
            attrs = [...availableAttributes, ...attrs]
            return attrs
        }
    }, [availableAttributes])

    const {
        control,
        register,
        handleSubmit,
        watch,
        reset,
        setValue,
    } = useForm({
        mode: 'onTouched',
        defaultValues: defaultValues
    })

    const messageValue = watch('message')
    const expireLink = watch('expire_link', false)
    const expireLinkAfterFirstClick = watch('expire_link_after_first_click', false)
    const IsBlockDesktopTraffic = watch('is_block_desktop_traffic', false)
    const needSendWelcomeMessage = watch('need_send_welcome_message', false)
    const messageHasLink = useMemo(() => {
        if (!messageValue) {
            return false;
        }

        return messageValue.includes('{{link}}')
    }, [messageValue])

    const isTrackingRevenue = watch('is_tracking_revenue', false);
    const redirectTo = watch('redirect_to');
    const isFilteringStatePhoneNumber = watch('is_filtering_state_phone_number', false);
    const is2FaLinkView = watch('is_2fa_link_view', false);
    useEffect(() => {
        if (!isTrackingRevenue || !messageHasLink) {
            if (redirectTo) {
                setValue('redirect_to', redirectTo.replace('{{revenue-token}}', ''), {shouldDirty: false})
            }
        } else {
            if (redirectTo) {
                if (!redirectTo.includes('{{revenue-token}}')) {
                    setValue('redirect_to', redirectTo + '{{revenue-token}}', {shouldDirty: false})
                }
            } else {
                setValue('redirect_to', '{{revenue-token}}', {shouldDirty: false})
            }
        }

    }, [messageHasLink, isTrackingRevenue])

    const storeCampaignStepMutation = useMutation(storeCampaignStep)
    const updateCampaignStepMutation = useMutation(updateCampaignStep)

    const shortUrlDomainsRequestWithAccess = useQuery(
        ['shortUrlDomainsWithAccess'],
        () => getShortUrlDomainsWithAccess(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (shortUrlDomainsRequestWithAccess.isSuccess) {
            setShortUrlsOptions(shortUrlDomainsRequestWithAccess.data.data.map(item => ({
                key: item.id,
                label: item.url
            })))
        }
    }, [shortUrlDomainsRequestWithAccess.isSuccess, shortUrlDomainsRequestWithAccess.data])

    const clickTriggerCampaignsRequest = useQuery(
        ['clickTriggerCampaigns'],
        () => getClickTriggerCampaigns(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (clickTriggerCampaignsRequest.isSuccess) {
            setClickTriggerCampaigns(clickTriggerCampaignsRequest.data.data)
        }
    }, [clickTriggerCampaignsRequest.isSuccess, clickTriggerCampaignsRequest.data])

    const twoFaTriggerCampaignsRequest = useQuery(
        ['twoFaTriggerCampaigns'],
        () => getTwoFATriggerCampaigns(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (twoFaTriggerCampaignsRequest.isSuccess) {
            setTwoFaTriggerCampaignsCampaigns(twoFaTriggerCampaignsRequest.data.data)
        }
    }, [twoFaTriggerCampaignsRequest.isSuccess, twoFaTriggerCampaignsRequest.data])

    const tmoLimitTriggerCampaignsRequest = useQuery(
        ['tmoLimitTriggerCampaigns'],
        () => getTmoLimitTriggerCampaigns(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (tmoLimitTriggerCampaignsRequest.isSuccess) {
            setTmoLimitTriggerCampaigns(tmoLimitTriggerCampaignsRequest.data.data)
        }
    }, [tmoLimitTriggerCampaignsRequest.isSuccess, tmoLimitTriggerCampaignsRequest.data])

    useEffect(() => {
        if (messageValue)
            setMessageCursorPosition(messageValue.length)
    }, [messageValue, setMessageCursorPosition])

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues)
        }
    }, [defaultValues, reset])

    useEffect(() => {
        if (storeCampaignStepMutation.error) {
            AlertToastr.showErrorResponseAlert(storeCampaignStepMutation.error)
        }
    }, [storeCampaignStepMutation.error])

    useEffect(() => {
        if (updateCampaignStepMutation.error) {
            AlertToastr.showErrorResponseAlert(updateCampaignStepMutation.error)
        }
    }, [updateCampaignStepMutation.error])

    const onSubmitSMS = handleSubmit(async data => {
        if (defaultValues) {
            const req = {
                id: defaultValues.id,
                ...data,
                delay: data.delay || 0,
                expire_link: !!data.expire_link,
                expire_link_days: data.expire_link_days,
                expire_link_after_first_click: !!data.expire_link_after_first_click,
                delay_time_period: data.delay_time_period || 'min',
                is_filtering_state_phone_number: !!data.is_filtering_state_phone_number,
                is_2fa_link_view: !!data.is_2fa_link_view,
                need_send_welcome_message: !!data.need_send_welcome_message,
                is_block_desktop_traffic: !!data.is_block_desktop_traffic,
            }
            await updateCampaignStepMutation.mutateAsync(req)
            handleClose()
            queryClient.invalidateQueries('campaignStepsView').then()
        } else {
            try {
                const req = {
                    id: campaign.id,
                    ...data,
                    delay: data.delay || 0,
                    expire_link: !!data.expire_link,
                    expire_link_days: data.expire_link_days,
                    expire_link_after_first_click: !!data.expire_link_after_first_click,
                    delay_time_period: data.delay_time_period || 'min',
                    is_filtering_state_phone_number: !!data.is_filtering_state_phone_number,
                    is_2fa_link_view: !!data.is_2fa_link_view,
                    need_send_welcome_message: !!data.need_send_welcome_message,
                    is_block_desktop_traffic: !!data.is_block_desktop_traffic,
                }
                await storeCampaignStepMutation.mutateAsync(req)
                handleClose()
                queryClient.invalidateQueries('campaignStepsView').then()
            } catch (e) {
                console.log(e)
            }
        }
    })

    const setCursorPosition = e => {
        setMessageCursorPosition(e.target.selectionStart)
    }

    const insertToken = token => {
        let value = watch('message')
        let textToInsert = `{{${token}}}`
        let cursorPosition = messageCursorPosition === undefined ? value ? value.length : 0 : messageCursorPosition
        let textBeforeCursorPosition = value.substring(0, cursorPosition)
        let textAfterCursorPosition = value.substring(cursorPosition, value.length)
        let newValue = textBeforeCursorPosition + textToInsert + textAfterCursorPosition
        setValue('message', newValue, {shouldDirty: false})

        setMessageCursorPosition(undefined)
    }

    const title = defaultValues ? 'Update Step' : 'Add Step'

    let currentShortUrlDomainId = watch('short_url_domain_id');
    let currentShortUrlDomain = null;
    if (!currentShortUrlDomainId && shortUrlsOptions[0]) {
        currentShortUrlDomainId = shortUrlsOptions[0].id;
        currentShortUrlDomain = shortUrlsOptions.find(domain => domain.key === currentShortUrlDomainId)
    }

    if (shortUrlsOptions && currentShortUrlDomainId) {
        currentShortUrlDomain = shortUrlsOptions.find(domain => domain.key === currentShortUrlDomainId)
    }

    return (
        <form onSubmit={onSubmitSMS}>
            <input type="hidden" ref={register} name='type' value="sms"/>
            <Fragment>
                <ReactHookFormSelect
                    disabled={configIsLoading}
                    variant="outlined"
                    margin="normal"
                    id="type-select"
                    name="short_url_domain_id"
                    label="Domain"
                    control={control}
                    defaultValue={(shortUrlsOptions[0] && shortUrlsOptions[0].key) || ""}
                >
                    {shortUrlsOptions.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                            {option.label}
                        </MenuItem>
                    ))}
                </ReactHookFormSelect>
                <Controller
                    name="message"
                    control={control}
                    defaultValue=''
                    render={({onChange, onBlur, value}) => (
                        <>
                            <TextField
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value || ""}
                                label="Message"
                                multiline
                                rows={4}
                                variant="outlined"
                                margin='normal'
                                onClick={setCursorPosition}
                            />

                            <div style={{position: "relative"}}>
                                <SmsCounter
                                    text={value}
                                    shortUrlDomainName={currentShortUrlDomain?.label}
                                    styleParam={{position: "absolute", top: -28, right: 15, left: "initial"}}
                                />
                            </div>
                        </>
                    )}
                />
                {availableAttributesWithLink.map((attr) => {
                    return (
                        <Chip
                            key={attr.id}
                            label={attr.name}
                            onClick={() => insertToken(attr.slug || attr.name)}
                            className='tagLikeChip'
                        />
                    )
                })}
                {messageHasLink && (
                    <TextField
                        inputRef={register}
                        name="redirect_to"
                        label="Redirect Link"
                        variant="outlined"
                        margin='normal'
                    />
                )}
                <div className="align-center wrapper content-justified mt15">
                    <div style={{fontWeight: "600", fontSize: "14px"}}>
                        Need send welcome message
                    </div>
                    <Controller
                        name="need_send_welcome_message"
                        control={control}
                        defaultValue={false}
                        render={({onChange, value}) => (
                            <IOSSwitch
                                color="primary"
                                checked={value}
                                onChange={(e) => onChange(e.target.checked)}
                            />
                        )}
                    />
                </div>
                {needSendWelcomeMessage && <TextField
                    inputRef={register}
                    type='text'
                    name="welcome_message_text"
                    label="Welcome message text"
                    variant="outlined"
                    margin='normal'
                    className='freqAdornment'
                />}
                {messageHasLink && <div className="align-center wrapper content-justified mt15">
                    <div style={{fontWeight: "600", fontSize: "14px"}}>
                        Expire links(over time)
                    </div>
                    <Controller
                        name="expire_link"
                        control={control}
                        defaultValue={false}
                        render={({onChange, value}) => (
                            <IOSSwitch
                                color="primary"
                                checked={value}
                                onChange={(e) => onChange(e.target.checked)}
                            />
                        )}
                    />
                </div>}
                {expireLink && <>
                    <TextField
                        inputRef={register}
                        type='number'
                        name="expire_link_days"
                        label="Expire link days"
                        variant="outlined"
                        margin='normal'
                        className='freqAdornment'
                    />
                    <TextField
                        inputRef={register}
                        type='text'
                        name="redirect_link_for_expire"
                        label="Expire link redirect URL"
                        variant="outlined"
                        margin='normal'
                        className='freqAdornment'
                    />
                </>}

                {messageHasLink && <div className="align-center wrapper content-justified mt15">
                    <div style={{fontWeight: "600", fontSize: "14px"}}>
                        Expire links(after first click)
                    </div>
                    <Controller
                        name="expire_link_after_first_click"
                        control={control}
                        defaultValue={false}
                        render={({onChange, value}) => (
                            <IOSSwitch
                                color="primary"
                                checked={value}
                                onChange={(e) => onChange(e.target.checked)}
                            />
                        )}
                    />
                </div>}
                {expireLinkAfterFirstClick && <TextField
                    inputRef={register}
                    type='text'
                    name="redirect_link_after_first_click"
                    label="Expire link after first click redirect URL"
                    variant="outlined"
                    margin='normal'
                    className='freqAdornment'
                />}
                {messageHasLink && <div className="align-center wrapper content-justified mt15">
                    <div style={{fontWeight: "600", fontSize: "14px"}}>
                        State click filter
                    </div>
                    <Controller
                        name="is_filtering_state_phone_number"
                        control={control}
                        defaultValue={defaultValues ? defaultValues.is_filtering_state_phone_number : false}
                        render={({onChange, value}) => (
                            <IOSSwitch
                                color="primary"
                                checked={value}
                                onChange={(e) => onChange(e.target.checked)}
                            />
                        )}
                    />
                </div>}

                {isFilteringStatePhoneNumber && <>
                    <ReactHookFormSelect
                        disabled={configIsLoading}
                        variant="outlined"
                        margin="dense"
                        id="type-select"
                        name="state_attribute_id"
                        label="Select state attribute"
                        control={control}
                        defaultValue={defaultValues ? defaultValues.state_attribute_id : ''}
                    >
                        <MenuItem value=""></MenuItem>
                        {availableAttributes.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </ReactHookFormSelect>
                    <TextField
                        inputRef={register}
                        type='text'
                        name="redirect_link_for_state_filtered"
                        label="State Filtered redirect URL"
                        variant="outlined"
                        margin='normal'
                        className='freqAdornment'
                    />
                </>}

                {messageHasLink && <div className="align-center wrapper content-justified mt15">
                    <div style={{fontWeight: "600", fontSize: "14px"}}>
                        Block Desktop Traffic (Mobile Only)
                    </div>
                    <Controller
                        name="is_block_desktop_traffic"
                        control={control}
                        defaultValue={defaultValues ? defaultValues.is_block_desktop_traffic : false}
                        render={({onChange, value}) => (
                            <IOSSwitch
                                color="primary"
                                checked={value}
                                onChange={(e) => onChange(e.target.checked)}
                            />
                        )}
                    />
                </div>}
                {IsBlockDesktopTraffic && <TextField
                    inputRef={register}
                    type='text'
                    name="redirect_link_for_block_desktop_traffic"
                    label="Block Desktop Traffic redirect URL"
                    variant="outlined"
                    margin='normal'
                    className='freqAdornment'
                />}

                {messageHasLink && <div className="align-center wrapper content-justified mt15">
                    <div style={{fontWeight: "600", fontSize: "14px"}}>
                        Use 2FA to view link
                    </div>
                    <Controller
                        name="is_2fa_link_view"
                        control={control}
                        defaultValue={defaultValues ? defaultValues.is_2fa_link_view : false}
                        render={({onChange, value}) => (
                            <IOSSwitch
                                color="primary"
                                checked={value}
                                onChange={(e) => onChange(e.target.checked)}
                            />
                        )}
                    />
                </div>}

                {is2FaLinkView && <ReactHookFormSelect
                    disabled={configIsLoading}
                    variant="outlined"
                    margin="dense"
                    id="type-select"
                    name="two_fa_automation_campaign_id"
                    label="2FA Automation Campaign ID"
                    control={control}
                    defaultValue={defaultValues ? defaultValues.two_fa_automation_campaign_id : ''}
                >
                    <MenuItem value=""></MenuItem>
                    {twoFaTriggerCampaignsCampaigns.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))}
                </ReactHookFormSelect>}

                {
                    (campaign?.type === 'sms' || campaign?.type === 'automation') &&
                    <div className="align-center wrapper content-justified mt15">
                        <div style={{fontWeight: "600", fontSize: "14px"}}>
                            Click Automation Campaign ID
                        </div>
                        <IOSSwitch
                            color="primary"
                            checked={showClickAutomationList}
                            onChange={(e) => {
                                setShowClickAutomationList(e.target.checked);
                            }}
                        />
                    </div>
                }

                {
                    showClickAutomationList &&
                    <ReactHookFormSelect
                        disabled={configIsLoading}
                        variant="outlined"
                        margin="dense"
                        id="type-select"
                        name="click_automation_campaign_id"
                        label="Click Automation Campaign ID"
                        control={control}
                        defaultValue={defaultValues ? defaultValues.click_automation_campaign_id : ''}
                    >
                        <MenuItem value=""></MenuItem>
                        {clickTriggerCampaigns.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </ReactHookFormSelect>
                }
                {
                    (campaign?.type === 'sms' || campaign?.type === 'automation') &&
                    <div className="align-center wrapper content-justified mt15">
                        <div style={{fontWeight: "600", fontSize: "14px"}}>
                            TMO Limit Automation ID
                        </div>
                        <IOSSwitch
                            color="primary"
                            checked={showTmoLimitAutomationList}
                            onChange={(e) => {
                                setShowTmoLimitAutomationList(e.target.checked);
                            }}
                        />
                    </div>
                }
                {
                    showTmoLimitAutomationList &&
                    <ReactHookFormSelect
                        disabled={configIsLoading}
                        variant="outlined"
                        margin="dense"
                        id="type-select"
                        name="tmo_limit_automation_campaign_id"
                        label="Tmo Limit Automation Campaign ID"
                        control={control}
                        defaultValue={defaultValues ? defaultValues.tmo_limit_automation_campaign_id : ''}
                    >
                        <MenuItem value=""></MenuItem>
                        {tmoLimitTriggerCampaigns.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </ReactHookFormSelect>
                }

                <div className="align-center wrapper content-justified mt15">
                    <div style={{fontWeight: "600", fontSize: "14px"}}>
                        Track Revenue
                    </div>
                    <Controller
                        name="is_tracking_revenue"
                        control={control}
                        defaultValue={defaultValues && defaultValues.redirect_to && defaultValues.redirect_to.includes('{{revenue-token}}') || false}
                        render={({onChange, value}) => (
                            <IOSSwitch
                                color="primary"
                                checked={value}
                                onChange={(e) => onChange(e.target.checked)}
                            />
                        )}
                    />
                </div>

                {!hasParentId(defaultValues) &&
                    <TextField
                        inputRef={register}
                        name="limit_cap"
                        label="Limit Cap"
                        variant="outlined"
                        margin='normal'
                        // type='number'
                    />
                }
            </Fragment>

            {!disableDelay && !hasParentId(defaultValues) && (
                <TextField
                    inputRef={register}
                    name="delay"
                    label="Delay"
                    variant="outlined"
                    margin='normal'
                    defaultValue='0'
                    className='freqAdornment'
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <ReactHookFormSelect
                                    disabled={configIsLoading}
                                    variant="standard"
                                    margin="normal"
                                    id="delay_time_period"
                                    name="delay_time_period"
                                    control={control}
                                    defaultValue={defaultValues ? defaultValues.delay_time_period : 'min'}
                                >
                                    <MenuItem value={'min'}>minutes</MenuItem>
                                    <MenuItem value={'hours'}>hours</MenuItem>
                                    <MenuItem value={'days'}>days</MenuItem>
                                </ReactHookFormSelect>
                            </InputAdornment>
                        ),
                    }}
                />
            )}

            <ModalHolderButton
                title={title}
                approveDisabled={storeCampaignStepMutation.isLoading || updateCampaignStepMutation.isLoading}
                handleClose={handleClose}
            />

        </form>
    )
}
const EmailTypeForm = props => {
    const {
        handleClose,
        defaultValues,
        campaign,
        disableDelay
    } = props
    const {configIsLoading} = useAppState()
    const {availableAttributes} = useCampaignState()
    const [shortUrlsOptions, setShortUrlsOptions] = useState([])
    const queryClient = useQueryClient()
    const [messageCursorPosition, setMessageCursorPosition] = useState()
    const availableAttributesWithLink = useMemo(() => {
        let attrs = [
            {id: 'link', name: 'Link', slug: 'link'},
            {id: 'source', name: 'Source', slug: 'source'},
            {id: 'list', name: 'List', slug: 'list'},
            {id: 'unsubscribe_url', name: 'Unsubscribe URL', slug: 'unsubscribe_url'}
        ]
        if (availableAttributes) {
            attrs = [...availableAttributes, ...attrs]
            return attrs
        }
    }, [availableAttributes])


    const [sendersOptions, setSendersOptions] = useState([])
    const SendersRequestWithAccess = useQuery(
        ['SendersWithAccess', campaign.id],
        () => getSendersWithAccess(campaign.setting_gateway_id),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (SendersRequestWithAccess.isSuccess && campaign.setting_gateway_id) {
            setSendersOptions(SendersRequestWithAccess.data.data.map(item => ({
                key: item.id,
                label: item.sender
            })))
        }
    }, [SendersRequestWithAccess.isSuccess, SendersRequestWithAccess.data]);

    const [domainsOptions, setDomainsOptions] = useState([])
    const domainsRequest = useQuery(
        ['emailGatewayDomains', campaign.id],
        () => getEmailGatewayDomains(campaign.setting_gateway_id),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (domainsRequest.isSuccess && campaign.setting_gateway_id) {
            setDomainsOptions(domainsRequest.data.data.map(item => ({
                key: item.id,
                label: item.domain
            })))
        }
    }, [domainsRequest.isSuccess, domainsRequest.data]);

    const [senderEmailGroups, setSenderEmailGroups] = useState([])
    const sendEmailGroupsRequest = useQuery(
        ['senderEmailGroupList'],
        () => getSenderEmailGroups(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (sendEmailGroupsRequest.isSuccess) {
            setSenderEmailGroups(sendEmailGroupsRequest.data.data.map(item => ({
                id: item.id,
                name: item.name
            })))
        }
    }, [sendEmailGroupsRequest.isSuccess, sendEmailGroupsRequest.data]);

    const {
        control,
        register,
        handleSubmit,
        watch,
        reset,
        setValue
    } = useForm({
        mode: 'onTouched',
        defaultValues: defaultValues
    })

    const messageValue = watch('message')
    const expireLink = watch('expire_link')
    const senderType = watch('sender_type')
    const messageHasLink = useMemo(() => {
        if (!messageValue) return false;
        return messageValue.includes('{{link}}')
    }, [messageValue])

    const storeCampaignStepMutation = useMutation(storeCampaignStep)
    const updateCampaignStepMutation = useMutation(updateCampaignStep)

    const shortUrlDomainsRequestWithAccess = useQuery(
        ['shortUrlDomainsWithAccess'],
        () => getShortUrlDomainsWithAccess(),
        {keepPreviousData: true}
    )

    const isTrackingRevenue = watch('is_tracking_revenue', false);
    const redirectTo = watch('redirect_to');
    useEffect(() => {
        if (!isTrackingRevenue || !messageHasLink) {
            if (redirectTo) {
                setValue('redirect_to', redirectTo.replace('{{revenue-token}}', ''), {shouldDirty: false})
            }
        } else {
            if (redirectTo) {
                if (!redirectTo.includes('{{revenue-token}}')) {
                    setValue('redirect_to', redirectTo + '{{revenue-token}}', {shouldDirty: false})
                }
            } else {
                setValue('redirect_to', '{{revenue-token}}', {shouldDirty: false})
            }
        }

    }, [messageHasLink, isTrackingRevenue])

    useEffect(() => {
        if (shortUrlDomainsRequestWithAccess.isSuccess) {
            setShortUrlsOptions(shortUrlDomainsRequestWithAccess.data.data.map(item => ({
                key: item.id,
                label: item.url
            })))
        }
    }, [shortUrlDomainsRequestWithAccess.isSuccess, shortUrlDomainsRequestWithAccess.data])

    useEffect(() => {
        if (messageValue)
            setMessageCursorPosition(messageValue.length)
    }, [messageValue, setMessageCursorPosition])

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues)
        }
    }, [defaultValues, reset])

    useEffect(() => {
        if (storeCampaignStepMutation.error) {
            AlertToastr.showErrorResponseAlert(storeCampaignStepMutation.error)
        }
    }, [storeCampaignStepMutation.error])

    useEffect(() => {
        if (updateCampaignStepMutation.error) {
            AlertToastr.showErrorResponseAlert(updateCampaignStepMutation.error)
        }
    }, [updateCampaignStepMutation.error])

    const getSender = (data) => {
        if (senderType === 'email') {
            return data.sender_email;
        }
        if (senderType === 'domain') {
            return data.sender_username + '@' + data.sender_domain;
        }

        if (senderType === 'group') {
            return null
        }
    }

    const onSubmitEmail = handleSubmit(async data => {
        if (defaultValues) {
            await updateCampaignStepMutation.mutateAsync({
                id: defaultValues.id,
                pre_header: data.pre_header,
                from_name: data.from_name,
                redirect_to: data.redirect_to,
                limit_cap: data.limit_cap,
                message: data.message,
                subject: data.subject,
                type: "email",
                short_url_domain_id: data.short_url_domain_id,
                delay: data.delay ? data.delay : defaultValues.delay,
                delay_time_period: data.delay_time_period ? data.delay_time_period : 'min',
                sender: getSender(data),
                sender_type: data.sender_type,
                is_tracking_opens: data.is_tracking_opens,
                clicks_tracking_version: data.clicks_tracking_version,
                sender_email_group_id: data.sender_email_group_id,
                sender_domain: data.sender_domain,
                expire_link: !!data.expire_link,
                expire_link_days: data.expire_link_days,
                expire_link_after_first_click: !!data.expire_link_after_first_click
            })
            handleClose()
            queryClient.invalidateQueries('campaignStepsView').then()
        } else {
            try {
                console.log(11111);
                await storeCampaignStepMutation.mutateAsync({
                    id: campaign.id,
                    pre_header: data.pre_header,
                    from_name: data.from_name,
                    redirect_to: data.redirect_to,
                    limit_cap: data.limit_cap,
                    message: data.message,
                    subject: data.subject,
                    type: "email",
                    short_url_domain_id: data.short_url_domain_id,
                    delay: data.delay ? data.delay : "0",
                    delay_time_period: data.delay_time_period ? data.delay_time_period : 'min',
                    sender: getSender(data),
                    sender_type: data.sender_type,
                    is_tracking_opens: data.is_tracking_opens,
                    clicks_tracking_version: data.clicks_tracking_version,
                    sender_email_group_id: data.sender_email_group_id,
                    sender_domain: data.sender_domain,
                    expire_link: !!data.expire_link,
                    expire_link_days: data.expire_link_days,
                    expire_link_after_first_click: !!data.expire_link_after_first_click,
                })
                handleClose()
                queryClient.invalidateQueries('campaignStepsView').then()
            } catch (e) {
                console.log(e)
            }
        }

    })

    const setCursorPosition = e => {
        setMessageCursorPosition(e.target.selectionStart)
    }

    const insertToken = token => {
        let value = watch('message')
        let textToInsert = `{{${token}}}`
        let cursorPosition = messageCursorPosition === undefined ? value ? value.length : 0 : messageCursorPosition
        let textBeforeCursorPosition = value.substring(0, cursorPosition)
        let textAfterCursorPosition = value.substring(cursorPosition, value.length)
        let newValue = textBeforeCursorPosition + textToInsert + textAfterCursorPosition
        setValue('message', newValue, {shouldDirty: false})

        // messageRef.current.value = newValue
        // messageRef.current.focus()
        setMessageCursorPosition(undefined)
    }

    const title = defaultValues ? 'Update Step' : 'Add Step'

    let currentShortUrlDomainId = watch('short_url_domain_id');
    let currentShortUrlDomain = null;
    if (!currentShortUrlDomainId && shortUrlsOptions[0]) {
        currentShortUrlDomainId = shortUrlsOptions[0].id;
        currentShortUrlDomain = shortUrlsOptions.find(domain => domain.key === currentShortUrlDomainId)
    }

    if (shortUrlsOptions && currentShortUrlDomainId) {
        currentShortUrlDomain = shortUrlsOptions.find(domain => domain.key === currentShortUrlDomainId)
    }

    return (
        <form onSubmit={onSubmitEmail}>
            <input type="hidden" ref={register} name='type' value="sms"/>

            <Fragment>
                <ReactHookFormSelect
                    disabled={configIsLoading}
                    variant="outlined"
                    margin="normal"
                    id="sender-select"
                    name="sender_type"
                    label="Sender Type"
                    control={control}
                    defaultValue={''}
                >

                    {emailCampaignsSenderTypes.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.value}
                        </MenuItem>
                    ))}
                </ReactHookFormSelect>
                {senderType && senderType !== 'group' && <Controller
                    name="from_name"
                    control={control}
                    defaultValue=''
                    render={({onChange, onBlur, value}) => (
                        <TextField
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value || ""}
                            label="From Name"
                            variant="outlined"
                            margin='normal'
                        />
                    )}
                />
                }
                {senderType === 'email' && <ReactHookFormSelect
                    disabled={configIsLoading}
                    variant="outlined"
                    margin="normal"
                    id="sender-select"
                    name="sender_email"
                    label="Sender"
                    control={control}
                    defaultValue={''}
                >

                    {sendersOptions.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                            {option.label}
                        </MenuItem>
                    ))}
                </ReactHookFormSelect>}
                {senderType === 'group' && <ReactHookFormSelect
                    disabled={configIsLoading}
                    variant="outlined"
                    margin="normal"
                    id="sender-select"
                    name="sender_email_group_id"
                    label="Sender Group"
                    control={control}
                    defaultValue={''}
                >

                    {senderEmailGroups.map((group) => (
                        <MenuItem key={group.id} value={group.id}>
                            {group.name}
                        </MenuItem>
                    ))}
                </ReactHookFormSelect>}
                {senderType === 'group' && <ReactHookFormSelect
                    disabled={configIsLoading}
                    variant="outlined"
                    id="sender-select"
                    name="sender_domain"
                    label="Sender Domain"
                    control={control}
                    defaultValue={''}
                >

                    {domainsOptions.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                            @{option.label}
                        </MenuItem>
                    ))}
                </ReactHookFormSelect>
                }
                {senderType === 'domain' && <Box
                    display="flex"
                    margin='normal'
                >
                    <Controller
                        name="sender_username"
                        control={control}
                        defaultValue=''
                        render={({onChange, onBlur, value}) => (
                            <TextField
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value || ""}
                                label="Sender username"
                                variant="outlined"
                            />
                        )}
                    />
                    <ReactHookFormSelect
                        disabled={configIsLoading}
                        variant="outlined"
                        id="sender-select"
                        name="sender_domain"
                        label="Sender Domain"
                        control={control}
                        defaultValue={''}
                        style={{width: '100%', marginLeft: '10px'}}
                    >

                        {domainsOptions.map((option) => (
                            <MenuItem key={option.key} value={option.key}>
                                @{option.label}
                            </MenuItem>
                        ))}
                    </ReactHookFormSelect>
                </Box>
                }
                <TextField
                    inputRef={register}
                    name="subject"
                    label="Subject"
                    variant="outlined"
                    margin='normal'
                />

                <Controller
                    name="message"
                    control={control}
                    defaultValue=''
                    render={({onChange, onBlur, value}) => (
                        <>
                            <TextField
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value || ""}
                                label="Message"
                                multiline
                                rows={4}
                                variant="outlined"
                                margin='normal'
                                onClick={setCursorPosition}
                            />
                        </>
                    )}
                />
                {availableAttributesWithLink.map((attr) => {
                    return (
                        <Chip
                            key={attr.id}
                            label={attr.name}
                            onClick={() => insertToken(attr.slug || attr.name)}
                            className='tagLikeChip'
                        />
                    )
                })}
                {messageHasLink && (
                    <Controller
                        name="redirect_to"
                        control={control}
                        defaultValue=''
                        render={({onChange, onBlur, value}) => (
                            <TextField
                                inputRef={register}
                                name="redirect_to"
                                label="Redirect Link"
                                variant="outlined"
                                margin='normal'
                            />)}
                    />
                )}

                {messageHasLink && <div className="align-center wrapper content-justified mt15">
                    <div style={{fontWeight: "600", fontSize: "14px"}}>
                        Expire links(over time)
                    </div>
                    <Controller
                        name="expire_link"
                        control={control}
                        defaultValue={false}
                        render={({onChange, value}) => (
                            <IOSSwitch
                                color="primary"
                                checked={value}
                                onChange={(e) => onChange(e.target.checked)}
                            />
                        )}
                    />
                </div>}
                {expireLink && <TextField
                    inputRef={register}
                    type='number'
                    name="expire_link_days"
                    label="Expire link days"
                    variant="outlined"
                    margin='normal'
                    className='freqAdornment'
                />}

                {messageHasLink && <div className="align-center wrapper content-justified mt15">
                    <div style={{fontWeight: "600", fontSize: "14px"}}>
                        Expire links(after first click)
                    </div>
                    <Controller
                        name="expire_link_after_first_click"
                        control={control}
                        defaultValue={false}
                        render={({onChange, value}) => (
                            <IOSSwitch
                                color="primary"
                                checked={value}
                                onChange={(e) => onChange(e.target.checked)}
                            />
                        )}
                    />
                </div>}

                <ReactHookFormSelect
                    disabled={configIsLoading}
                    variant="outlined"
                    margin="normal"
                    id="type-select"
                    name="short_url_domain_id"
                    label="Click Domain"
                    control={control}
                    defaultValue={(shortUrlsOptions[0] && shortUrlsOptions[0].key) || ""}
                >
                    {shortUrlsOptions.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                            {option.label}
                        </MenuItem>
                    ))}
                </ReactHookFormSelect>

                {messageHasLink && <ReactHookFormSelect
                    disabled={configIsLoading}
                    variant="outlined"
                    margin="normal"
                    id="sender-select"
                    name="clicks_tracking_version"
                    label="Clicks tracking version"
                    control={control}
                    defaultValue={''}
                >

                    {emailClicksTrackingVersions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.value}
                        </MenuItem>
                    ))}
                </ReactHookFormSelect>}

                <div className="align-center wrapper content-justified mt15">
                    <div style={{fontWeight: "600", fontSize: "14px"}}>
                        Track open statistic
                    </div>
                    <Controller
                        name="is_tracking_opens"
                        control={control}
                        defaultValue={defaultValues ? defaultValues.is_tracking_opens : true}
                        render={({onChange, value}) => (
                            <IOSSwitch
                                color="primary"
                                checked={value}
                                onChange={(e) => onChange(e.target.checked)}
                            />
                        )}
                    />
                </div>

                <div className="align-center wrapper content-justified mt15">
                    <div style={{fontWeight: "600", fontSize: "14px"}}>
                        Track Revenue
                    </div>
                    <Controller
                        name="is_tracking_revenue"
                        control={control}
                        defaultValue={defaultValues && defaultValues.redirect_to && defaultValues.redirect_to.includes('{{revenue-token}}') || false}
                        render={({onChange, value}) => (
                            <IOSSwitch
                                color="primary"
                                checked={value}
                                onChange={(e) => onChange(e.target.checked)}
                            />
                        )}
                    />
                </div>

                {!hasParentId(defaultValues) &&
                    <TextField
                        inputRef={register}
                        name="limit_cap"
                        label="Limit Cap"
                        variant="outlined"
                        margin='normal'
                        // type='number'
                    />
                }
            </Fragment>


            {!disableDelay && !hasParentId(defaultValues) && (
                <TextField
                    inputRef={register}
                    name="delay"
                    label="Delay"
                    variant="outlined"
                    margin='normal'
                    defaultValue='0'
                    className='freqAdornment'
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <ReactHookFormSelect
                                    disabled={configIsLoading}
                                    variant="standard"
                                    margin="normal"
                                    id="delay_time_period"
                                    name="delay_time_period"
                                    control={control}
                                    defaultValue={defaultValues ? defaultValues.delay_time_period : 'min'}
                                >
                                    <MenuItem value={'min'}>minutes</MenuItem>
                                    <MenuItem value={'hours'}>hours</MenuItem>
                                    <MenuItem value={'days'}>days</MenuItem>
                                </ReactHookFormSelect>
                            </InputAdornment>
                        ),
                    }}
                />
            )}

            <ModalHolderButton
                title={title}
                approveDisabled={storeCampaignStepMutation.isLoading || updateCampaignStepMutation.isLoading}
                handleClose={handleClose}
            />

        </form>
    )
}

const SplitTypeForm = props => {
    const {
        handleClose,
        defaultValues,
        campaign,
        disableDelay
    } = props

    const {
        register,
        handleSubmit,
        control
    } = useForm({
        mode: 'onTouched',
        defaultValues: defaultValues
    })

    const queryClient = useQueryClient()
    const storeCampaignStepMutation = useMutation(storeCampaignStep)
    const updateCampaignStepMutation = useMutation(updateCampaignStep)

    const partsFill = Array(4).fill(1).map((v, i) => i + 2)
    const defaultParts = defaultValues && Array.isArray(defaultValues.steps) ? defaultValues.steps.length : partsFill[0]
    const title = defaultValues ? 'Update Sub Step' : 'Add Sub Step'

    useEffect(() => {
        if (storeCampaignStepMutation.error) {
            AlertToastr.showErrorResponseAlert(storeCampaignStepMutation.error)
        }
    }, [storeCampaignStepMutation.error])

    useEffect(() => {
        if (updateCampaignStepMutation.error) {
            AlertToastr.showErrorResponseAlert(updateCampaignStepMutation.error)
        }
    }, [updateCampaignStepMutation.error])

    const onSubmitSplit = handleSubmit(async data => {

        if (defaultValues) {
            await updateCampaignStepMutation.mutateAsync({
                id: defaultValues.id,
                ...data,
                delay: data.delay || 0,
                delay_time_period: data.delay_time_period || 'min',
            })
            handleClose()
            queryClient.invalidateQueries('campaignStepsView').then()
        } else {
            try {
                await storeCampaignStepMutation.mutateAsync({
                    id: campaign.id,
                    ...data,
                    delay: data.delay || 0,
                    delay_time_period: data.delay_time_period || 'min',
                })
                handleClose()
                queryClient.invalidateQueries('campaignStepsView').then()
            } catch (e) {
                console.log(e)
            }
        }
    })

    return (
        <form onSubmit={onSubmitSplit}>
            <input type="hidden" ref={register} name='type' value="split"/>

            <TextField
                inputRef={register}
                name="parts"
                label="Parts in Step (min:1, max:50)"
                variant="outlined"
                margin='normal'
                type='number'
                defaultValue={defaultParts}
            />

            {!hasParentId(defaultValues) &&
                <TextField
                    inputRef={register}
                    name="limit_cap"
                    label="Limit Cap"
                    variant="outlined"
                    margin='normal'
                    type='number'
                />
            }

            {!disableDelay && (
                <TextField
                    inputRef={register}
                    name="delay"
                    label="Delay"
                    variant="outlined"
                    margin='normal'
                    defaultValue='0'
                    className='freqAdornment'
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <ReactHookFormSelect
                                    variant="standard"
                                    margin="normal"
                                    id="delay_time_period"
                                    name="delay_time_period"
                                    control={control}
                                    defaultValue={defaultValues ? defaultValues.delay_time_period : 'min'}
                                >
                                    <MenuItem value={'min'}>minutes</MenuItem>
                                    <MenuItem value={'hours'}>hours</MenuItem>
                                    <MenuItem value={'days'}>days</MenuItem>
                                </ReactHookFormSelect>
                            </InputAdornment>
                        ),
                    }}
                />
            )}

            <ModalHolderButton
                title={title}
                approveDisabled={storeCampaignStepMutation.isLoading || updateCampaignStepMutation.isLoading}
                handleClose={handleClose}
            />

        </form>
    )
}

const ModalHolderButton = ({
                               title = "", approveDisabled = false, handleClose = () => {
    }
                           }) => {
    return (
        <div className="btnHolder hasPadTop twoBtn">
            <div className="part">
                <Button
                    disabled={approveDisabled}
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                    onClick={(e) => {
                    }}
                >
                    {title}
                </Button>
            </div>
            <div className="part">
                <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={handleClose}
                >
                    Cancel
                </Button>
            </div>
        </div>
    )
}

const hasParentId = item => {
    if (!Boolean(item) || !Boolean(item.parentItem)) return false

    return Boolean(item.parentItem)
}

export default SaveCampaignStepModal
