import React, {useState, Fragment, useEffect} from 'react'
import Button from '@material-ui/core/Button'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import {useCampaignState} from '../../Contexts/CampaignContext'
import SaveCampaignStepModal from '../../components/modals/SaveCampaignStepModal'
import CampaignStepItem from '../../components/CampaignStepItem'
import DeleteModal from '../../components/modals/DeleteModal'
import {useMutation, useQueryClient} from 'react-query'
import {copyCampaignStep, removeCampaignStep} from '../../api'
import {hoursMinutesUSAFormat, toServerFormat} from '../../Utils/dates'
import Alert from '@material-ui/lab/Alert'
import {campaignStepEditDisabled, campaignStepDeleteDisabled} from '../../helpers'
import AlertToastr from '../../Utils/alert'
import TestSmsModal from "../../components/modals/TestSmsModal";
import EventEmitter from "../../Utils/EventEmitter";

const CampaignStart = () => {

    const [showSaveCampaignStepModal, setShowCampaignStepModal] = useState(false)
    const [showUpdateCampaignStepModal, setShowUpdateCampaignStepModal] = useState(false)
    const [showDeleteCampaignStepModal, setShowDeleteCampaignStepModal] = useState(false)
    const [currentStep, setCurrentStep] = useState()

    const {
        campaign,
        steps
    } = useCampaignState()

    const queryClient = useQueryClient()
    const deleteStepRequest = useMutation(removeCampaignStep)
    const copyStepRequest = useMutation(copyCampaignStep)

    useEffect(() => {
        if (deleteStepRequest.error) {
            AlertToastr.showErrorResponseAlert(deleteStepRequest.error)
        }
    }, [deleteStepRequest.error])

    const renderAttribute = (label, value, show = true) => {
        if (!show) return null

        return (
            <div className="tr">
                <div className="td">{label}</div>
                <div className="td">{value || '-'}</div>
            </div>
        )
    }

    const handleEditStep = (item) => {
        setCurrentStep(item)
        setShowUpdateCampaignStepModal(true)
    }

    const handleCopyStep = async step => {
        setCurrentStep(null)
        await copyStepRequest.mutateAsync(step.id);
        await queryClient.invalidateQueries('campaignStepsView')
    }

    const handleDeleteStep = (item) => {
        setCurrentStep(item)
        setShowDeleteCampaignStepModal(true)
    }

    const deleteStep = async () => {
        if (!currentStep) return

        await deleteStepRequest.mutateAsync(currentStep.id)
        setCurrentStep(null)
        setShowDeleteCampaignStepModal(false)
        await queryClient.invalidateQueries('campaignStepsView')
    }

    const disableAddStep = !campaign || campaign.status !== 'draft'

    return (
        <div className='campaignPageHolder'>
            <div className="campStepsHolder">
                <ul className="stepsList">
                    {
                        steps.map((item, index) => {
                            const isLast = (steps.length === 1) || (index === steps.length - 1)
                            return (
                                <CampaignStepItem
                                    key={item.id}
                                    item={item}
                                    onEdit={handleEditStep}
                                    onDelete={handleDeleteStep}
                                    onCopy={handleCopyStep}
                                    disableEdit={campaignStepEditDisabled(campaign, item, isLast)}
                                    disableDelete={campaignStepDeleteDisabled(campaign, item, isLast)}
                                />
                            )
                        })
                    }
                    <li className="stepsListItem btnHolder">
                        <Button
                            variant="outlined"
                            startIcon={<AddCircleIcon/>}
                            onClick={() => setShowCampaignStepModal(true)}
                            disabled={disableAddStep}
                        >
                            Add Step
                        </Button>
                    </li>
                </ul>
            </div>
            <div className="campSettingsHolder">
                <div className="leadDetails">
                    <div className="leadDetailsHeading mb30">
                        <div className="heading">
                            Settings
                        </div>
                    </div>
                    <div className="leadDetailsTableLike campSettingsTable">
                        <div className="tableLike">
                            {renderAttribute('Campaign Name', campaign?.name)}
                            {renderAttribute('Start Time', toServerFormat(campaign?.started_at))}
                            {renderAttribute('Created Time', toServerFormat(campaign?.created_at))}
                            {campaign?.daytime_limitation && renderAttribute('Run daily from', hoursMinutesUSAFormat(campaign?.run_daily_from))}
                            {campaign?.daytime_limitation && renderAttribute('Run daily to', hoursMinutesUSAFormat(campaign?.run_daily_to))}
                            {renderAttribute('Timezone', campaign?.started_at_tz_label)}
                            {renderAttribute('ID', campaign?.id)}
                            {renderAttribute('Type', campaign?.type_label)}
                            {renderAttribute('Gateway', campaign?.gateway_label)}
                            {campaign && campaign.pool_id ? (
                                renderAttribute('Pool', campaign.pool_label)
                            ) : (
                                renderAttribute('From', campaign?.ms_name)
                            )}
                            {!campaign?.route_id && renderAttribute('Frequency', campaign?.frequency_count + ' per ' + campaign?.frequency_period_label, !!campaign)}
                            {(campaign?.type === 'sms' || campaign?.type === 'automation') && renderAttribute('Cool Off Rule', campaign?.cool_off_rule ? campaign.cool_off_rule.name : '-')}
                            {(campaign?.type === 'sms' || campaign?.type === 'automation') && renderAttribute('Inactive Device Rule', campaign?.inactive_device_rule ? campaign.inactive_device_rule.name : '-')}
                            {(campaign?.type === 'email' || campaign?.type === 'email_automation') && renderAttribute('Cool Off Rule', campaign?.cool_off_rule ? campaign.cool_off_rule.name : '-')}
                            {campaign?.type === 'email' && renderAttribute('Reply to', campaign?.reply_to)}
                            {(campaign?.type === 'automation' || campaign?.type === 'email_automation') &&
                                renderAttribute('Triggers: ', campaign?.triggers ? campaign.triggers.join(', ') : '-')
                            }
                            {(campaign?.type === 'automation' || campaign?.type === 'sms') && renderAttribute('Send By Timezones', campaign?.need_send_by_timezones ? 'Yes' : 'No')}
                        </div>
                    </div>
                </div>
            </div>

            {
                campaign && (
                    <>
                        <SaveCampaignStepModal
                            campaign={campaign}
                            open={showSaveCampaignStepModal}
                            disableDelay={(!steps || !steps.length) && (campaign && (campaign.type !== 'automation' && campaign.type !== 'email_automation'))}
                            handleClose={() => {
                                setShowCampaignStepModal(false)
                            }}
                        />

                        <DeleteModal
                            loading={deleteStepRequest.isLoading}
                            open={showDeleteCampaignStepModal}
                            handleDelete={deleteStep}
                            handleClose={() => {
                                setCurrentStep(null)
                                setShowDeleteCampaignStepModal(false)
                            }}
                            instance={'step'}
                        />
                    </>

                )
            }

            {
                currentStep && (
                    <>
                        <SaveCampaignStepModal
                            defaultValues={currentStep}
                            campaign={campaign}
                            open={showUpdateCampaignStepModal}
                            disableDelay={(!steps || !steps.length || steps[0]?.id === currentStep?.id) && (campaign && (campaign.type !== 'automation' && campaign.type !== 'email_automation'))}
                            handleClose={() => {
                                setCurrentStep(null)
                                setShowUpdateCampaignStepModal(false)
                            }}
                        />
                    </>
                )
            }
        </div>
    )
}

export default CampaignStart
