import React, {useEffect, useMemo, useState} from 'react';
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import {useQuery} from "react-query";
import {getCarrierGroups} from "../../../api";
import {AddCircleRounded, Close} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";

const RouteGroups = ({routeGroups, gateways, setRouteGroups}) => {

    const [userCarrierGroups, setUserCarrierGroups] = useState([]);

    const userCarrierGroupsRequest = useQuery(['userGroups'], () => getCarrierGroups(), {keepPreviousData: true})

    useEffect(() => {
        if (userCarrierGroupsRequest.isSuccess) {
            setUserCarrierGroups(userCarrierGroupsRequest.data.data.data)
        }
    }, [userCarrierGroupsRequest.isSuccess, userCarrierGroupsRequest.data, setUserCarrierGroups])

    const defaultGroupObject = useMemo(() => {
        return {
            group_id: null,
            gateway_id: null,
            sender_type: null,
            sender_name: null,
            frequency: 0
        }
    }, [])

    return (
        <div>
            {routeGroups.map((group, index) => {
                return <div key={index} style={{marginBottom: '10px'}}>
                    <div className="wrapper content-around align-top" style={{border: '1px solid #e5e5e5', borderRadius: '10px'}}>
                        <div className="f-grow-1" style={{paddingLeft: '10px'}}>
                            <FormControl
                                disabled={!userCarrierGroups}
                                variant="outlined"
                                margin="normal"
                                key={index}
                            >
                                <InputLabel id={'Carrier-Group-label'}>{'Carrier Group'}</InputLabel>
                                <Select
                                    value={group.group_id ?? ''}
                                    onChange={e => {
                                        routeGroups[index] = {...group, group_id: e.target.value}
                                        setRouteGroups(routeGroups)
                                    }}
                                >
                                    {userCarrierGroups.map((carrierGroup) => (
                                        <MenuItem
                                            key={carrierGroup.id}
                                            value={carrierGroup.id}
                                        >
                                            {carrierGroup.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl
                                disabled={!gateways}
                                variant="outlined"
                                margin="normal"
                            >
                                <InputLabel id={'Gateway-label'}>{'Gateway'}</InputLabel>
                                <Select
                                    value={group.gateway_id ?? ''}
                                    onChange={e => {
                                        routeGroups[index] = {...group, gateway_id: e.target.value}
                                        setRouteGroups(routeGroups)
                                    }}
                                >
                                    {gateways.map((gateway) => (
                                        <MenuItem
                                            key={gateway.id}
                                            value={gateway.id}
                                        >
                                            {gateway.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {group.gateway_id &&
                                <FormControl variant="outlined" margin="normal">
                                    <InputLabel id={'Sender-label'}>{'Sender'}</InputLabel>
                                    <Select
                                        value={group.sender_type ? group.sender_type + '|||' + group.sender : ''}
                                        onChange={e => {
                                            let value = e.target.value.split('|||')
                                            let senderName = null
                                            if (value[0] === 'phone') {
                                                gateways.map(gateway => {
                                                    if (gateway.id === group.gateway_id) {
                                                        gateway.from.map(phone => {
                                                            if (phone.key === value[1]) {
                                                                senderName = phone.label
                                                            }
                                                        })
                                                    }
                                                })

                                            } else {
                                                gateways.map(gateway => {
                                                    if (gateway.id === group.gateway_id) {
                                                        gateway.pools.map(pool => {
                                                            if (pool.key === value[1]) {
                                                                senderName = pool.label
                                                            }
                                                        })
                                                    }
                                                })

                                            }
                                            routeGroups[index] = {
                                                ...group,
                                                sender_name: senderName,
                                                sender_type: value[0],
                                                sender: value[1]
                                            }
                                            setRouteGroups(routeGroups)
                                        }}
                                    >
                                        <MenuItem
                                            disabled
                                            key={'Phones'}
                                            value={'Phones'}
                                        >
                                            Phones
                                        </MenuItem>
                                        {gateways.map(gateway => {
                                            if (gateway.id === group.gateway_id) {
                                                return gateway.from.map((phone) => (
                                                        <MenuItem
                                                            key={phone.key}
                                                            value={'phone|||' + phone.key}
                                                        >
                                                            {phone.label}
                                                        </MenuItem>
                                                    )
                                                )
                                            }
                                        })

                                        }
                                        <MenuItem
                                            disabled
                                            key={'Pools'}
                                            value={'Pools'}
                                        >
                                            Pools
                                        </MenuItem>
                                        {gateways && gateways.map(gateway => {
                                            if (gateway.id === group.gateway_id) {
                                                return gateway.pools.map((pool) => {
                                                    return (
                                                        <MenuItem
                                                            key={pool.key}
                                                            value={'pool|||' + pool.key}
                                                        >
                                                            {pool.label}
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        })
                                        }
                                    </Select>
                                </FormControl>
                            }
                            <TextField
                                type="number"
                                name={'frequency[' + index + ']'}
                                value={group.frequency}
                                onChange={e => {
                                    routeGroups[index] = {...group, frequency: parseInt(e.target.value ? e.target.value : 0)}
                                    setRouteGroups([...routeGroups])
                                }}
                                id="frequency"
                                label="Frequency"
                                variant="outlined"
                                margin='normal'
                            />
                        </div>
                        <IconButton style={{marginLeft: 5}} size="small" onClick={() => {
                            routeGroups.splice(index, 1);
                            setRouteGroups([...routeGroups]);
                        }}>
                            <Close fontSize="inherit" />
                        </IconButton>
                    </div>
                </div>
            })}

            <div className="align-center wrapper content-justified">
                <Button
                    color="info"
                    variant="outlined"
                    name="add_carrier"
                    startIcon={<AddCircleRounded />}
                    onClick={(e) => {
                        setRouteGroups([...routeGroups, defaultGroupObject]);
                    }}
                >Add Carrier Group</Button>
            </div>
        </div>
    );
};

export default RouteGroups;