import React, {useEffect, useMemo, useState} from "react"
import {useQuery} from "react-query"
import {getImportFileHistory} from "../api"
import {useQueryState} from "use-location-state";
import ListTable from "../components/ListTable/ListTable";
import { Link, useParams } from 'react-router-dom';

const ImportFileHistory = ({fileId}) => {
    const params = useParams();
    const [history, setHistory] = useState([])
    const [search, setSearch] = useState("")
    const [sorting, setSorting] = useState([])
    const [page, setPage] = useQueryState("page", 1)
    const [limit, setLimit] = useQueryState("limit", 20)
    const [pagination, setPagination] = useState(null);

    const {isSuccess, data, isLoading, isFetching} = useQuery(
        ["importFileFailedHistoryRequest", fileId, page, limit, search, sorting],
        () => getImportFileHistory(fileId, page, limit, search, sorting),
        {
            keepPreviousData: true,
        }
    )

    const tableColumns = useMemo(() => {
        return [
            {
                Header: "Status",
                accessor: "status",
                Cell: ({row}) => {
                    return row.original.status_label
                }
            },
            {
                Header: "Reason",
                accessor: "failed_reason",
                Cell: ({row}) => {
                    return row.original.failed_reason_label
                }
            },
            {
                Header: "Phone",
                accessor: "phone",
            },
            {
                Header: "Lead By Phone",
                accessor: "found_lead_by_phone",
                Cell: ({value}) => {
                    if (!value) {
                        return "";
                    }

                    return <Link target="_blank" to={`/u/${params.loggedUser}/leads/${value}`}>{value}</Link>
                }
            },
            {
                Header: "Email",
                accessor: "email",
            },
            {
                Header: "Lead By Email",
                accessor: "found_lead_by_email",
                Cell: ({value}) => {
                    if (!value) {
                        return "";
                    }
                    return <Link target="_blank" to={`/u/${params.loggedUser}/leads/${value}`}>{value}</Link>
                }
            },
            {
                Header: "Created At",
                accessor: "created_at",
            }
        ]
    }, [])

    useEffect(() => {
        if (isSuccess) {
            setHistory(data.data)
            const paginationData = data.meta
            if (paginationData.last_page < page) {
                setPage(1)
            }
            setPagination(paginationData)
        }
    }, [isSuccess, data, page, setPage])


    return (
        <div className="pageHolder">
            <ListTable
                columns={tableColumns}
                data={history}
                loading={isLoading}
                fetching={isFetching}
                onSort={setSorting}
                emptyDataText="No History Found"
                pagination={pagination}
                pageLimit={limit}
                onPageChange={setPage}
                onLimitChange={setLimit}
            />
        </div>
    )
}

export default ImportFileHistory
