import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useAppDispatch } from "../Contexts/AppContext"
import { useQuery } from "react-query"
import { getImportFile } from "../api"
import ImportFileHistory from "./ImportFileHistory"

const ImportFile = () => {
    const {fileId} = useParams()
    const {setBreadcrumbs, setSection} = useAppDispatch()
    const [filename, setFilename] = useState("")
    const {setContextMenu} = useAppDispatch()

    useEffect(() => {
        setContextMenu([])
    }, [setContextMenu])

    const {isFetching} = useQuery(
        ["importFile", fileId],
        () => getImportFile(fileId),
        {
            keepPreviousData: true,
            onSuccess: data => {
                setFilename(data.data.name)
            }
        }
    )

    useEffect(() => {
        setBreadcrumbs([
            {label: "Settings", link: "./settings"},
            {label: "Import History", link: "./settings/import-history"},
            {label: filename, loading: isFetching}
        ])
        setSection("settings")
    }, [setBreadcrumbs, setSection, filename, isFetching])

    return <ImportFileHistory fileId={fileId} />
}

export default ImportFile
