import React, {Fragment, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {Controller, useForm} from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {getActiveEmailLookupGateways, getActiveSmsLookupGateways, storeLookup, updateLookup} from '../../api';
import AlertToastr from '../../Utils/alert';
import MenuItem from '@material-ui/core/MenuItem';
import {useTranslation} from 'react-i18next';
import IOSSwitch from "../IOSSwitch";
import LookupLeadType from "../../Enums/Lookups/LookupLeadType";
import RuleCoverage from "../../Enums/Rules/RuleCoverage";

const LookupSaveModal = props => {
    const {t} = useTranslation();
    const queryClient = useQueryClient();

    const {
        sources,
        open,
        handleClose,
        defaultValues,
        statusOptions,
    } = props;

    const {
        control,
        handleSubmit,
        reset,
        watch,
        setValue,
    } = useForm({
        mode: 'onTouched',
        defaultValues: defaultValues,
    });

    const [availableLists, setAvailableLists] = useState([]);
    const sourceId = watch('source_id');

    const type = watch('lead_type');

    useEffect(() => {
        setValue('setting_gateway_id', null);
    }, [type])

    const [emailLookupGateways, setEmailLookupGateways] = useState([]);

    const emailLookupGatewaysRequest = useQuery(
        ['activeEmailLookupGateways'],
        () => getActiveEmailLookupGateways(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (emailLookupGatewaysRequest.isSuccess) {
            setEmailLookupGateways(emailLookupGatewaysRequest.data.data)
        }
    }, [emailLookupGatewaysRequest.isSuccess, emailLookupGatewaysRequest.data])

    const [smsLookupGateways, setSmsLookupGateways] = useState([]);

    const smsLookupGatewaysRequest = useQuery(
        ['activeSmsLookupGateways'],
        () => getActiveSmsLookupGateways(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (smsLookupGatewaysRequest.isSuccess) {
            setSmsLookupGateways(smsLookupGatewaysRequest.data.data)
        }
    }, [smsLookupGatewaysRequest.isSuccess, smsLookupGatewaysRequest.data])

    useEffect(() => {
        setValue('list_id', '');
        if (!sourceId) return setAvailableLists([]);
        const sourceModel = sources.find(item => item.id === sourceId);
        setAvailableLists(sourceModel ? sourceModel.lists : []);

    }, [sourceId, setValue, sources]);

    const storeMutation = useMutation(storeLookup);
    const updateMutation = useMutation(updateLookup);

    const onSubmit = handleSubmit(async data => {
        if (defaultValues) {
            try {
                await updateMutation.mutateAsync({
                    id: defaultValues.id, ...data,
                });
                reset();
                handleClose();
                queryClient.invalidateQueries('lookupList').then();
            } catch (error) {
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors);
            }
        } else {
            try {
                const defaultStatus = statusOptions[0];
                data.status = defaultStatus?.id;
                await storeMutation.mutateAsync(data);
                reset();
                handleClose();
                queryClient.invalidateQueries('lookupList').then();
            } catch (error) {
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors);
            }
        }
    });

    const renderSourceField = () => {
        return (
            <Fragment>
                <Controller
                    name={'source_id'}
                    control={control}
                    defaultValue=""
                    render={({onChange, value}) => {
                        return (
                            <TextField
                                select
                                onChange={onChange}
                                value={value}
                                variant="outlined"
                                margin="normal"
                                label="Source"
                            >
                                <MenuItem value="" disabled>
                                    {t('Modals.Select Source')}
                                </MenuItem>
                                {sources.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        );
                    }}
                />
            </Fragment>
        );
    };
    const renderTypeField = () => {
        return (
            <Fragment>
                <Controller
                    name={'lead_type'}
                    control={control}
                    defaultValue=""
                    render={({onChange, value}) => {
                        return (
                            <TextField
                                select
                                onChange={onChange}
                                value={value}
                                variant="outlined"
                                margin="normal"
                                label="Type"
                                disabled={!!defaultValues}
                            >
                                <MenuItem value="" disabled>
                                    {t('Modals.Select Type')}
                                </MenuItem>
                                {LookupLeadType.ALL.map((label, key) => (
                                    <MenuItem key={key} value={key}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        );
                    }}
                />
            </Fragment>
        );
    };

    const renderLookupGatewaysField = () => {
        if (type == LookupLeadType.SMS) {
            return <Fragment>
                <Controller
                    name={'setting_gateway_id'}
                    control={control}
                    defaultValue=""
                    render={({onChange, value}) => {
                        return (
                            <TextField
                                select
                                onChange={onChange}
                                value={value}
                                variant="outlined"
                                margin="normal"
                                label="Sms Lookup Gateway"
                            >
                                <MenuItem value="" disabled>
                                    Select gateway
                                </MenuItem>
                                {smsLookupGateways.map((gateway) => {
                                    return <MenuItem key={gateway.id} value={gateway.id}>
                                        {gateway.name}
                                    </MenuItem>
                                })}
                            </TextField>
                        );
                    }}
                />
            </Fragment>
        }

        if (type === LookupLeadType.EMAIL) {
            return <Fragment>
                <Controller
                    name={'setting_gateway_id'}
                    control={control}
                    defaultValue=""
                    render={({onChange, value}) => {
                        return (
                            <TextField
                                select
                                onChange={onChange}
                                value={value}
                                variant="outlined"
                                margin="normal"
                                label="Email Gateway"
                            >
                                <MenuItem value="" disabled>
                                    Select gateway
                                </MenuItem>
                                {emailLookupGateways.map((gateway) => {
                                    return <MenuItem key={gateway.id} value={gateway.id}>
                                        {gateway.name}
                                    </MenuItem>
                                })}
                            </TextField>
                        );
                    }}
                />
            </Fragment>
        }

        return <></>;
    }

    const renderListField = () => {
        return (
            <Fragment>
                <Controller
                    name="list_id"
                    control={control}
                    defaultValue=""
                    render={({onChange, value}) => {
                        return (
                            <TextField
                                select
                                onChange={onChange}
                                value={value}
                                variant="outlined"
                                margin="normal"
                                label={t('Modals.List')}
                            >
                                <MenuItem value="" disabled>
                                    {t('Modals.Select List')}
                                </MenuItem>
                                {availableLists.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        );
                    }}
                />
            </Fragment>
        );
    };

    const renderStatusField = () => {
        return (
            <Fragment>
                <Controller
                    name="status"
                    control={control}
                    defaultValue=""
                    render={({value, onChange}) => {
                        return (
                            <input type="hidden" value={value}/>
                        );
                    }}
                />
            </Fragment>
        );
    };

    const title = defaultValues ? t('Modals.Edit Lookup') : t('Modals.Add Lookup');
    const buttonTitle = defaultValues ? t('Modals.Save') : t('Modals.Add Lookup Btn');

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className="modal"
        >
            <div className="modalBody">
                <div className="modalTitleHolder mb30">
                    <h2 className="mb15">{title}</h2>
                </div>
                <form onSubmit={onSubmit}>
                    {renderTypeField()}
                    {renderLookupGatewaysField()}
                    {renderSourceField()}
                    {renderListField()}
                    {renderStatusField()}
                    <div className="btnHolder hasPadTop twoBtn">
                        <div className="part">
                            <Button
                                disabled={storeMutation.isLoading || updateMutation.isLoading}
                                variant="contained"
                                color="primary"
                                fullWidth
                                type="submit"
                                onClick={(e) => {
                                }}
                            >
                                {buttonTitle}
                            </Button>
                        </div>
                        <div className="part">
                            <Button
                                disabled={storeMutation.isLoading || updateMutation.isLoading}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={handleClose}
                            >
                                {t('Modals.Cancel')}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </Dialog>
    );
};

export default LookupSaveModal;
