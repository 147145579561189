import { apiAuthBaseUrl } from '../config'

const authRoutes = {
    register: () => ({ url: `${apiAuthBaseUrl}/signup`, method: 'post' }),
    login: () => ({ url: `${apiAuthBaseUrl}/signin`, method: 'post' }),
    resetPassword: () => ({ url: `${apiAuthBaseUrl}/password/email`, method: 'post' }),
    sendPassword: () => ({ url: `${apiAuthBaseUrl}/password/reset`, method: 'post' }),
    changePassword: (userId) => ({ url: `${apiAuthBaseUrl}/user/${userId}/password/change`, method: 'post', ignoreAutToken: true })

}
export default authRoutes
