import React, {useMemo} from 'react'
import InlineLoader from './loaders/InlineLoader'

const CampaignStatisticsHeader = ({loading, onFilter, campaignStat, campaign}) => {

    const items = useMemo(() => {
        if (!campaign) {
            return [];
        }
        if (campaign.type === 'sms' || campaign.type === 'automation') {
            return [
                {
                    label: 'Clicked',
                    count: campaign?.statistics?.clicked,
                    action: () => onFilter('amount_eng')
                },
                {
                    label: 'Clicked last 90 days',
                    count: 0,
                    action: () => onFilter('amount_eng_last')
                },
                {
                    label: 'Unsubscribe',
                    count: campaign?.statistics?.unsubscribed,
                    action: () => onFilter('amount_unsubscribe')
                },
                {
                    label: 'Non-clicked',
                    count: (campaign?.statistics?.sent - campaign?.statistics?.clicked),
                    action: () => onFilter('amount_not_eng')
                },
            ]
        }
        if (campaign.type === 'email' || campaign.type === 'email_automation') {
            return [
                {
                    label: 'Clicked',
                    count: campaign?.statistics?.clicked,
                    action: () => onFilter('amount_eng')
                },
                {
                    label: 'Clicked last 90 days',
                    count: 0,
                    action: () => onFilter('amount_eng_last')
                },
                {
                    label: 'Unsubscribe',
                    count: campaign?.statistics?.unsubscribed,
                    action: () => onFilter('amount_unsubscribe')
                },
                {
                    label: 'Non-clicked',
                    count: campaign?.statistics?.sent - campaign?.statistics?.clicked,
                    action: () => onFilter('amount_not_eng')
                },
                {
                    label: 'Opened',
                    count: campaign?.statistics?.opened,
                    action: () => onFilter('opened')
                },
                {
                    label: 'Bounced',
                    count: campaign?.statistics?.bounces,
                    action: () => onFilter('bounced')
                },
                {
                    label: 'Complained',
                    count: campaign?.statistics?.complaints,
                    action: () => onFilter('complained')
                },
            ]
        }

    }, [campaignStat, onFilter, campaign])

    return (
        <div className="pageHeadingBlockWrapper">
            {
                items.map(item => (
                    <div className="part" key={item.label}>
                        <div className="partContent cursor-pointer" onClick={item.action}>
                            <h2 className="mb10">{item.label}</h2>
                            <div>{loading ? <InlineLoader/> : item.count}</div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default CampaignStatisticsHeader
