export const segmentFilterExpression = {
    EQUALS: "equals",
    NOT_EQUALS: "not_equals",
    CONTAINS: "contains",
    NOT_CONTAIN: "not_contain",
    GREATER_THAN: "greater_than",
    LESS_THAN: "less_than",
    EMPTY: "empty",
    NOT_EMPTY: "not_empty",
    LIKE: "like",
    NOT_LIKE: "not_like",
    UNIQUE: "unique",
    FIRST_APPEARED: "first_appeared",
    EXISTS: "exists",
    NOT_EXISTS: "not_exists",
    DATE_RANGE: "date_range",
    CUSTOM_DATES: "custom_dates",
}
