import React, { useEffect } from 'react';
import { useRouter } from '../../Hooks/useRouter';
import { useMutation } from 'react-query';
import { resetPassword } from '../../api';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AlertToastr from '../../Utils/alert';

const PasswordResetPage = () => {

    const {query, replace} = useRouter();

    const {
        register,
        control,
        handleSubmit
    } = useForm({
        mode: 'onTouched',
        defaultValues: {
            token: query?.token
        }
    })

    const resetPasswordMutation = useMutation(resetPassword)

    useEffect(() => {
        if (resetPasswordMutation.error) {
            AlertToastr.showErrorResponseAlert(resetPasswordMutation.error)
        }
    }, [resetPasswordMutation.error])

    const onSubmit = handleSubmit(async data => {
        try {
            const response = await resetPasswordMutation.mutateAsync(data)
            if (response.message) {
                AlertToastr.showAlert(response.message)
                replace('/')
            }
        } catch (e) {
            console.log(e)
        }
    })

    return (
        <div className="formHolder">
            <form onSubmit={onSubmit}>
                <input type="hidden" name="token" ref={register} />
                <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({onChange, value}) => {
                        return (
                            <TextField
                                onChange={onChange}
                                value={value}
                                variant="outlined"
                                margin="normal"
                                label="Email"
                                autoComplete="username"
                            />
                        )
                    }}
                />
                <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({onChange, value}) => {
                        return (
                            <TextField
                                type="password"
                                onChange={onChange}
                                value={value}
                                variant="outlined"
                                margin="normal"
                                label="New password"
                                autoComplete="new-password"
                            />
                        )
                    }}
                />
                <Controller
                    name="password_confirmation"
                    control={control}
                    defaultValue=""
                    render={({onChange, value}) => {
                        return (
                            <TextField
                                type="password"
                                onChange={onChange}
                                value={value}
                                variant="outlined"
                                margin="normal"
                                label="Confirm password"
                                autoComplete="new-password"
                            />
                        )
                    }}
                />
                <div className="btnHolder mt20 mb15">
                    <Button
                        type="submit"
                        disabled={resetPasswordMutation.isLoading}
                        variant="contained"
                        color="primary"
                        fullWidth
                    >
                        Reset Password
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default PasswordResetPage;
