import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppState } from "../../Contexts/AppContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getImportLists, removeImportList } from "../../api";
import ListTable from "../../components/ListTable/ListTable";
import DeleteModal from "../../components/modals/DeleteModal";
import PlusCircle from "../../assets/img/plus_circle.svg";
import EventEmitter from "../../Utils/EventEmitter";
import SaveListImportKeyModal from "../../components/modals/SaveListImportKeyModal";
import ListItemContextMenu from "../../components/ListItemContextMenu";
import AlertToastr from "../../Utils/alert";

const ImportApiKeys = () => {
    const { setBreadcrumbs, setSection } = useAppDispatch();
    const { availableSources } = useAppState();
    const [pagination, setPagination] = useState(null);
    const [sorting, setSorting] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [list, setList] = useState([]);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentModel, setCurrentModel] = useState(false);
    const tableColumns = useMemo(() => {
        return [
            {
                Header: "Name",
                accessor: "name",
            },
            {
                Header: "Source",
                accessor: "source.name",
            },
            {
                Header: "List",
                accessor: "list.name",
            },
            {
                Header: "API Key",
                accessor: "api_key",
            },
            {
                Header: "Phones Lookup",
                accessor: "check_mobile_status",
                Cell: ({value}) => value ? "Yes" : "No"
            },
            {
                Header: "Emails Lookup",
                accessor: "check_email_status",
                Cell: ({value}) => value ? "Yes" : "No"
            },
            {
                Header: "",
                id: "actions",
                className: "text-right",
                Cell: ({ row }) => {
                    return (
                        <ListItemContextMenu
                            item={row.original}
                            onEdit={() => handleEditItem(row.original)}
                            onDelete={() => showDeleteConfirmation(row.original)}
                        />
                    );
                },
            },
        ];
    }, []);
    const queryClient = useQueryClient();
    const { setContextMenu } = useAppDispatch();

    useEffect(() => {
        setContextMenu([
            {
                label: "Add API Key",
                icon: PlusCircle,
                action: () => EventEmitter.emit("ImportListCreate"),
            },
        ]);
    }, [setContextMenu]);

    const listRequest = useQuery(
        ["import-list", page, limit, sorting],
        () => getImportLists(page, limit, sorting),
        { keepPreviousData: false },
    );

    const deleteRequest = useMutation(removeImportList);

    useEffect(() => {
        if (listRequest.isSuccess) {
            setList(listRequest.data.data);
            setPagination(listRequest.data.meta);
        }
    }, [listRequest.isSuccess, listRequest.data]);

    useEffect(() => {
        const onEvent = () => setShowCreateModal(true);

        const listener = EventEmitter.addListener("ImportListCreate", onEvent);
        return () => {
            listener.remove();
        };
    }, []);

    useEffect(() => {
        setBreadcrumbs([
            { label: "Settings", link: "./settings" },
            { label: "Import API Keys" },
        ]);
        setSection("settings");
    }, [setBreadcrumbs, setSection]);

    const handleLimitChange = useCallback((l) => {
        setPage(1);
        setLimit(l);
    }, [setPage, setLimit]);

    const handleEditItem = (item) => {
        setCurrentModel({
            ...item,
            source_id: item?.source?.id,
        });
        setShowUpdateModal(true);
    };

    const showDeleteConfirmation = (model) => {
        setCurrentModel(model);
        setShowDeleteModal(true);
    };

    const deleteItem = async () => {
        if (!currentModel) return;

        await deleteRequest.mutateAsync(currentModel.id);
        setShowDeleteModal(false);
        await queryClient.invalidateQueries("import-list");
        setCurrentModel(null);
    };

    const copyToClipboard = item => {
        navigator.clipboard.writeText(item.api_key).then(() => {
            AlertToastr.showAlert("Key copied to clipboard");
        });

    };

    const modelLabel = currentModel ? `"${currentModel.name}" API Key` : "API Key";

    return (
        <div className="pageHolder">
            <ListTable
                columns={tableColumns}
                data={list}
                loading={listRequest.isLoading}
                fetching={listRequest.isFetching}
                onSort={setSorting}
                emptyDataText="No API Keys Found"
                pagination={pagination}
                pageLimit={limit}
                onPageChange={setPage}
                onLimitChange={handleLimitChange}
                disableSearchBlock={true}
                onRowClick={copyToClipboard}
            />
            <DeleteModal
                loading={deleteRequest.isLoading}
                open={showDeleteModal}
                handleDelete={deleteItem}
                handleClose={() => setShowDeleteModal(false)}
                instance={modelLabel}
            />

            <SaveListImportKeyModal
                open={showCreateModal}
                handleClose={() => {
                    setShowCreateModal(false);
                }}
                sources={availableSources}
            />

            {
                currentModel && (
                    <SaveListImportKeyModal
                        defaultValues={currentModel}
                        open={showUpdateModal}
                        handleClose={() => {
                            setCurrentModel(null);
                            setShowUpdateModal(false);
                        }}
                        sources={availableSources}
                    />
                )
            }
        </div>
    );
};

export default ImportApiKeys;
