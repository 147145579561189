import { isTokenExpired } from '../Utils/jwt';

const LOGGED_USERS_LOCAL_STORAGE_KEY = '_u';

class LoggedUsersService {
    getLoggedUsers() {
        return JSON.parse(localStorage.getItem(LOGGED_USERS_LOCAL_STORAGE_KEY)) || [];
    }

    getLoggedUsersList() {
        const loggedUsers = this.getLoggedUsers();

        return loggedUsers.map((u, index) => {
            return {
                index,
                id: u.user.id,
                name: u.user.name,
                email: u.user.email,
                isExpired: isTokenExpired(u.token),
            };
        });
    }

    getLoggedUserTokenByIndex(index) {
        const loggedUsers = this.getLoggedUsers();

        if (loggedUsers[index]){
            return loggedUsers[index].token;
        }

        if (loggedUsers.length > 0) {
            return loggedUsers[0].token;
        }

        return null;
    }

    getLoggedUserTokenByUserId(userId) {
        const loggedUsers = this.getLoggedUsers();
        return  loggedUsers.findIndex((u) => u.user.id === userId);
    }

    saveLoggedUsers(loggedUsers) {
        localStorage.setItem(LOGGED_USERS_LOCAL_STORAGE_KEY, JSON.stringify(loggedUsers));
    }

    addLoggedUser(user, token) {
        const loggedUser = {
            token,
            user: {
                id: user.id,
                name: user.name,
                email: user.email,
            },
        };

        return this.updateLoggedUser(loggedUser);
    }

    removeLoggedUserByUserId(userId) {
        const loggedUsers = this.getLoggedUsers();
        const loggedUserIndex = loggedUsers.findIndex((u) => u.user.id === userId);
        if (loggedUserIndex !== -1) {
            loggedUsers.splice(loggedUserIndex, 1);
        }

        this.saveLoggedUsers(loggedUsers);
    }

    clearLoggedUsers() {
        localStorage.setItem(LOGGED_USERS_LOCAL_STORAGE_KEY, JSON.stringify([]));
    }

    updateLoggedUser(loggedUser) {
        const loggedUsers = this.getLoggedUsers();
        const loggedUserIndex = loggedUsers.findIndex((u) => u.user.id === loggedUser.user.id);
        if (loggedUserIndex !== -1) {
            loggedUsers[loggedUserIndex] = loggedUser;
        } else {
            loggedUsers.push(loggedUser);
        }

        this.saveLoggedUsers(loggedUsers);

        return loggedUsers.length - 1;
    }

    isUserLogged(loggedUser) {
        const loggedUsers = this.getLoggedUsers();
        return Boolean(loggedUsers[loggedUser]);
    }
}

export default new LoggedUsersService()
