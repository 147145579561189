import React from 'react';
import EventEmitter from './Utils/EventEmitter';
import {Link} from 'react-router-dom';

export const getHtmlSortClass = (direction, isFetching = false) => {
    const className = direction === 'asc' ? 'hasArrow' : 'hasArrow up';
    return isFetching ? `${className} loading` : className;
};

export const formatLeadLog = logData => {
    const logInfo = [];
    logData.forEach((log) => {

        if (log.type === 'auto') {
            const details = [];
            log.diff_log.forEach((diffLog) => {
                let detailInfo = '';
                if (diffLog.action === 'added_to_black_list') {
                    detailInfo = `${diffLog.value_to} added to black list`;
                } else if (diffLog.action === 'detected_ignored_phone_number_template') {
                    detailInfo = `${diffLog.value_to} (matched with ignored phone template)`;
                } else if (diffLog.action === 'detected_invalid_phone_number_format') {
                    detailInfo = `${diffLog.value_to} (detected invalid format)`;
                } else {
                    detailInfo = diffLog.action === 'updated'
                        ? `${diffLog.value_from ? diffLog.value_from : ''} -> ${diffLog.value_to ? diffLog.value_to : ''}`
                        : `${diffLog.value_to ? diffLog.value_to : ''}`;
                }
                let detail = {
                    attribute: diffLog.lead_attribute_name,
                    detail: detailInfo,
                };
                details.push(detail);
            });
            const logLine = {
                date: log.created_at,
                message: log.action === 'resolving_duplicates' ? `Lead duplicated from ${log.filename}` : `Lead imported from ${log.filename}`,
                details: details,
            };
            logInfo.push(logLine);
        } else if (log.type === 'phone_carrier') {

            const details = [];
            let phone = log.filename ?? 'xxx';

            log.diff_log.forEach((diffLog) => {
                let detail = {
                    attribute: diffLog.lead_attribute_value,
                    detail: diffLog.action === 'updated' ? `${diffLog.value_from ? diffLog.value_from : ''} -> ${diffLog.value_to ? diffLog.value_to : ''}` : `${diffLog.value_to ? diffLog.value_to : ''}`,
                };
                details.push(detail);
            });

            const logLine = {
                date: log.created_at,
                message: `Phone ${phone} carrier info has been looked up`,
                details: details,
            };
            logInfo.push(logLine);
        } else if (log.type === 'sms_dnc_lookup') {

            const details = [];
            let phone = log.filename ?? 'xxx';

            log.diff_log.forEach((diffLog) => {
                let detail = {
                    attribute: diffLog.lead_attribute_value,
                    detail: diffLog.action === 'updated' ? `${diffLog.value_from ? diffLog.value_from : ''} -> ${diffLog.value_to ? diffLog.value_to : ''}` : `${diffLog.value_to ? diffLog.value_to : ''}`,
                };
                details.push(detail);
            });

            const logLine = {
                date: log.created_at,
                message: `Phone ${phone} SMS DNC has been looked up`,
                details: details,
            };
            logInfo.push(logLine);
        } else if (log.type === 'email_carrier') {

            const details = [];
            let email = log.filename ?? 'xxx';

            log.diff_log.forEach((diffLog) => {
                let detail = {
                    attribute: diffLog.lead_attribute_value,
                    detail: diffLog.action === 'updated' ? `${diffLog.value_from ? diffLog.value_from : ''} -> ${diffLog.value_to ? diffLog.value_to : ''}` : `${diffLog.value_to ? diffLog.value_to : ''}`,
                };
                details.push(detail);
            });

            const logLine = {
                date: log.created_at,
                message: `Email ${email}  info has been looked up`,
                details: details,
            };
            logInfo.push(logLine);
        } else if (log.type === 'phone_status') {

            const details = [];
            let phone = log.filename ?? 'xxx';

            log.diff_log.forEach((diffLog) => {
                let detail = {
                    attribute: diffLog.lead_attribute_value,
                    detail: diffLog.action === 'updated' ? `${diffLog.value_from ? diffLog.value_from : ''} -> ${diffLog.value_to ? diffLog.value_to : ''}` : `${diffLog.value_to ? diffLog.value_to : ''}`,
                };
                details.push(detail);
            });

            const logLine = {
                date: log.created_at,
                message: `Phone ${phone} status was updated`,
                details: details,
            };
            logInfo.push(logLine);
        } else if (log.type === 'phone_campaign') {

            const details = [];
            let campaign = log.filename ?? 'xxx';
            const logLine = {
                date: log.created_at,
                message: `Lead has been added to ${campaign} Campaign.`,
                details: details,
            };
            logInfo.push(logLine);
        } else if (log.type === 'link_clicked') {

            const details = [];
            log.diff_log.forEach((diffLog) => {
                let detail = {
                    attribute: diffLog.lead_attribute_value,
                    detail: diffLog.action === 'updated' ? `${diffLog.value_from ? diffLog.value_from : ''} -> ${diffLog.value_to ? diffLog.value_to : ''}` : `${diffLog.value_to ? diffLog.value_to : ''}`,
                };
                details.push(detail);
            });
            let titleRow = log.filename ?? 'xxx';
            const logLine = {
                date: log.created_at,
                message: titleRow,
                details: details,
            };
            logInfo.push(logLine);
        } else if (log.type === 'phone_campaign_sent_message') {

            const details = [];
            let campaign = log.filename ?? 'xxx';

            log.diff_log.forEach((diffLog) => {
                let detail = {
                    attribute: diffLog.lead_attribute_value,
                    detail: diffLog.action === 'updated' ? `${diffLog.value_from ? diffLog.value_from : ''} -> ${diffLog.value_to ? diffLog.value_to : ''}` : `${diffLog.value_to ? diffLog.value_to : ''}`,
                };
                details.push(detail);
            });

            const logLine = {
                date: log.created_at,
                message: log.action === 'created' ? `Campaign ${campaign} has been sent message to this Lead.` : `The sent message to ${campaign} has been updated`,
                details: details,
            };
            logInfo.push(logLine);
        } else if (log.type === 'unsubscribed') {
            let detail = {
                attribute: log.diff_log[0].lead_attribute_name,
                detail: log.diff_log[0].value_to,
            };

            const logLine = {
                date: log.created_at,
                message: 'Unsubscribed',
                details: [
                    detail,
                ],
            };

            if (Array.isArray(log.diff_log) && log.diff_log.length > 1) {
                logLine.details.push({
                    attribute: log.diff_log[1].lead_attribute_name,
                    detail: log.diff_log[1].value_to,
                });
            }

            logInfo.push(logLine);
        } else if (log.type === 'subscribed') {
            const details = [];

            log.diff_log.forEach((diffLog) => {
                details.push({
                    attribute: diffLog.lead_attribute_name,
                    detail: diffLog.value_to,
                });
            });

            const logLine = {
                date: log.created_at,
                message: log.filename + ' (this feature is not implemented yet)',
                details: details,
            };

            logInfo.push(logLine);
        } else if (log.type === 'message') {
            const details = [];

            log.diff_log.forEach((diffLog) => {
                details.push({
                    attribute: diffLog.lead_attribute_name,
                    detail: diffLog.value_to,
                });
            });

            const logLine = {
                date: log.created_at,
                message: log.filename,
                details: details,
            };

            logInfo.push(logLine);
        } else if (log.type === 'conversation') {
            const details = [];

            log.diff_log.forEach((diffLog) => {
                details.push({
                    attribute: diffLog.lead_attribute_name,
                    detail: diffLog.value_to,
                });
            });

            const logLine = {
                date: log.created_at,
                message: log.filename,
                details: details,
            };

            logInfo.push(logLine);
        } else {
            if (Array.isArray(log.diff_log) && log.diff_log.length) {
                let detail = {
                    attribute: log.diff_log[0].lead_attribute_name,
                    detail: log.diff_log[0].action === 'updated' ? `${log.diff_log[0].value_from ? log.diff_log[0].value_from : ''} -> ${log.diff_log[0].value_to}` : log.diff_log.value_to,
                };
                const logLine = {
                    date: log.created_at,
                    message: `${log.diff_log[0].lead_attribute_name} was updated`,
                    details: [
                        detail,
                    ],
                };
                logInfo.push(logLine);
            }
        }
    });

    return logInfo;
};

export const formatLeadAttributes = (leadValues) => {
    const leadAttributes = leadValues.map(attr => {
        const attrObj = {
            id: attr.id,
            name: attr.attribute.name,
            value: attr.value,
            newValue: attr.value,
            isEditing: false,
            position: attr.attribute.sort_order,
            type: attr.attribute.type,
            status: attr.status,
            status_label: attr.status_label,
        };
        if (attr.attribute.type === 'phone') {
            attrObj.carrier_name = attr.carrier_name;
            attrObj.carrier_type = attr.carrier_type;
            attrObj.clicked = attr.clicked;
            attrObj.ban = attr.ban;
            attrObj.unsubscribed = attr.unsubscribed;
            attrObj.state = attr.state;
            attrObj.city = attr.city;
            attrObj.timezone = attr.timezone;
            attrObj.lookup_action = attr.lookup_action;
            attrObj.lookup_reason = attr.lookup_reason;
            attrObj.black_list_alliance_phone_code = attr.black_list_alliance_phone_code;
            attrObj.black_list_alliance_phone_message = attr.black_list_alliance_phone_message;
            attrObj.carrier_status = attr.carrier_status;
            attrObj.carrier_status_label = attr.carrier_status_label;
        }
        if (attr.attribute.type === 'email') {
            attrObj.result = attr.result;
        }
        return attrObj;
    });

    let sortedAttributes = [...leadAttributes];
    sortedAttributes.sort((a, b) => {
        if (a.position < b.position) {
            return -1;
        }
        if (a.position > b.position) {
            return 1;
        }
        return 0;
    });
    return sortedAttributes;
};

export const formatBlackListRow = ({value, row}) => {
    const v = value || value === 0 ? value : '-';
    return row.original.status === 'blacklisted'
        ? <span style={{opacity: .5}}>{v}</span>
        : v;
};

export const leadLinkWrapper = (to, value, row) => {
    const output = formatBlackListRow({value, row});
    return <Link to={to}>{output}</Link>;
};

export const segmentLeadLinkWrapper = (to, value, row) => {
    const output = formatBlackListRow({value, row});
    return <Link to={to}>{output}</Link>;
}

export const campaignLinkWrapper = (to, value) => {
    return <Link to={to}>{value}</Link>;
};

export const audienceLinkWrapper = (to, value) => {
    return <Link to={to}>{value}</Link>;
};

export const segmentLinkWrapper = (to, value) => {
    return <Link to={to}>{value}</Link>;
};

export const sourceLinkWrapper = (to, value) => {
    return <Link to={to} className={value === 'No source' || value === 'No list' ? 'no_source' : ''}>{value}</Link>;
};

export const listLinkWrapper = (to, value) => {
    return <Link to={to}>{value}</Link>;
};

export const snakeToTitle = (str) => {
    return str.toString().split('_').map(function (item) {
        return item.charAt(0).toUpperCase() + item.substring(1);
    }).join(' ');
};
export const replaceStatus = (num) => {
    switch (num) {
        case 1 :
            return "Imported"
        case 2 :
            return "Blacklisted"
        case 'blacklist' :
            return "Blacklisted"
        case 3 :
            return "Flagged"
        case 4 :
            return "DNC"
        case 'flagged' :
            return "Flagged"
        case 'unsubscribe':
            return "Unsubscribed"
        case 'subscribe':
            return "Subscribed"
        case 'imported':
            return 'Imported';
        default:
            return "Pending";
    }
}

export const replaceStatusPhone = (status) => {
    switch (status) {
        case 'Not Checked':
            return "Subscribed"
        default:
            return status;
    }
}
export const statusContextMenu = () => {
    return [
        {
            label: 'Imported',
            action: () => EventEmitter.emit('LeadsBulkChangeStatus', 1),
            status: 'imported',
        },
        {
            label: 'Blacklisted',
            action: () => EventEmitter.emit('LeadsBulkChangeStatus', 2),
            status: 'blacklisted',
        },
        {
            label: 'Flagged',
            action: () => EventEmitter.emit('LeadsBulkChangeStatus', 3),
            status: 'flagged',
        },
    ];
};

export const statusClassMap = () => ({
    active: 'success',
    pending: 'pending',
    blacklisted: 'blackList',
    flagged: 'flagged',
    inactive: 'inactive',
});

export const campaignStatuses = () => {
    return ['draft', 'scheduled', 'paused', 'active'].map(item => (
        {key: item, label: snakeToTitle(item)}
    ));
};

export const campaignStatusContextMenu = (statuses = ['draft', 'scheduled', 'paused', 'stop']) => {
    return statuses.map(item => ({
        label: snakeToTitle(item),
        action: () => EventEmitter.emit('CampaignsBulkChangeStatus', item),
        status: item,
    }));
};

export const campaignStatusClassMap = () => ({
    active: 'success',
    draft: 'pending',
    scheduled: 'scheduled',
    paused: 'paused',
    completed: 'completed',
    failed: 'failed',
    stop: 'stop',
});

export const campaignStepEditDisabled = (campaign, step, isLast = false) => {
    if (!campaign || !step) return true;

    if (['active', 'completed', 'failed'].includes(campaign.status)) return true;
    if (campaign.status === 'draft') return false;

    if (campaign.status === 'scheduled') {
        return step.status !== 'not_sent';
    }

    if (campaign.status === 'paused') {
        return !((step.status === 'not_sent') && !isLast);
    }

    return false;
};

export const campaignStepDeleteDisabled = (campaign, step, isLast = false) => {
    if (!campaign || !step) {
        return true;
    }

    if (['active', 'completed', 'failed'].includes(campaign.status)) {
        return true;
    }
    if (campaign.status === 'draft') {
        return false;
    }

    if (['scheduled', 'paused'].includes(campaign.step)) {
        return !((step.status === 'not_sent') && !isLast);
    }

    return false;
};

export const filterHasEmptyValuesItems = (filterArray) => {

    const hasFiltersWithEmptyOperators = filterArray.filter(item => !item.operator).length;

    if (hasFiltersWithEmptyOperators) return true;

    const hasFiltersWithEmptyValuesArray = filterArray.filter(item => {
        if (!item.operator) return true;

        if (['empty', 'not_empty'].includes(item.operator)) return false;

        if (!item.values) return true;

        if (Array.isArray(item.values)) {
            if (!item.values.length) return true;
        } else {
            const {from, to, range} = item.values;
            if (!range) return true;
            if (range === 'custom') {
                if (!from && !to) return true;
            }
        }

        return false;
    });

    return !!hasFiltersWithEmptyValuesArray.length;
};

export const availableSegmentFilters = {
    'contains': 'Contains',
    'not_contains': 'Not Contains',
    'eq': 'Equals',
    'not_eq': 'Not Equal',
    'eq_strict': 'Equals',
    'not_eq_strict': 'Not Equal',
    'empty': 'Empty',
    'not_empty': 'Not Empty',
    'unique': 'Unique',
    'first_appeared_in_list': 'First Appeared',
    'date_range': 'Date Range',
};

export const filterObjectArray = (filtersIds) => {
    const array = [];
    filtersIds.forEach(filterId => {
        if (availableSegmentFilters[filterId]) {
            array.push({id: filterId, name: availableSegmentFilters[filterId]});
        }
    });
    return array;
};

export const lookupStatusClassMap = () => ({
    0: 'pending',
    1: 'success',
    2: 'paused',
    3: 'completed',
    4: 'stop',
});

export const filterAttributeMap = (attr) => {
    const map = {
        'source': ['eq', 'not_eq', 'unique', 'empty'],
        'list': ['eq', 'not_eq', 'unique', 'first_appeared_in_list'],
        'first_appeared_in_list': ['eq'],
        'lead_status': ['eq', 'not_eq'],
        'phone_status': ['eq', 'not_eq'],
        'email_status': ['eq', 'not_eq'],
        'engagement': ['eq', 'not_eq'],
        'engagement_period': ['eq'],
        'sms_status': ['eq', 'not_eq'],
        'sms_status_period': ['eq'],
        'campaigns': ['eq_strict', 'not_eq_strict', 'empty', 'not_empty'],
        'duplicates': ['eq', 'not_eq'],
        'last_day_clicked': ['eq'],
        'created_at': ['date_range'],
        'day_import': ['eq'],
    }

    if (map[attr.id]) return filterObjectArray(map[attr.id]);

    return filterObjectArray([
        'contains', 'not_contains',
        'eq', 'not_eq',
        'empty', 'not_empty',
    ]);
};

export const defaultAttributeFilter = (attr) => {
    const filters = filterAttributeMap(attr);

    return filters[0] ? filters[0].id : null;
};

export const defaultFilterValues = (filter) => {
    const map = {
        date_range: {from: null, to: null},
    };
    return map[filter] || [];
};

export const notEmptyFilter = (filter) => {
    if (!filter) return false;
    if (Array.isArray(filter)) return !!filter.length;
    return !!(filter.from || filter.to);

}

// export const hideLongText = text => {
//     console.log(text, 'text');
//     if (text.length < 100) {
//         return text
//     }
//     else {
//         return `${text.slice(0,50)}...`
//     }
// }
