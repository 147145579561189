import React, {useCallback, useEffect, useMemo, useState} from "react"
import {useAppDispatch} from "../Contexts/AppContext"
import {useMutation, useQuery, useQueryClient} from "react-query"
import {enableHttpsShortUrlDomain, getPointIP, getShortUrlDomains, removeShortUrlDomain} from "../api"
import ListTable from "../components/ListTable/ListTable"
import DeleteModal from "../components/modals/DeleteModal"
import ShortUrlDomainItemContextMenu from "../components/ShortUrlDomainItemContextMenu"
import SaveShortUrlDomainModal from "../components/modals/SaveShortUrlDomainModal"
import PlusCircle from "../assets/img/plus_circle.svg"
import EventEmitter from "../Utils/EventEmitter"
import Alert from "@material-ui/lab/Alert"
import ConfirmModal from "../components/modals/ConfirmModal";
import AlertToastr from "../Utils/alert";

const ShortUrlDomains = () => {
    const {setBreadcrumbs, setSection} = useAppDispatch()
    const [pagination, setPagination] = useState(null)
    const [pointDomationIP, setPointDomationIP] = useState("")
    const [sorting, setSorting] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const [availableAttributes, setAvailableAttributes] = useState([])
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showUpdateModal, setShowUpdateModal] = useState(false)
    const [showEnableHttpsModal, setShowEnableHttpsModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [currentModel, setCurrentModel] = useState(false)
    const tableColumns = useMemo(() => {
        return [
            {
                Header: "Domain Name",
                accessor: "url"
            },
            {
                Header: "Access",
                accessor: "access",
                Cell: ({value}) => (
                    <div className={value ? "chipLike success" : "chipLike"}>
                        <span className="chipLikeLabel">{value ? "Enabled" : "Disabled"}</span>
                    </div>
                )
            },
            {
                Header: "",
                id: "actions",
                className: "text-right",
                Cell: ({value, row}) => {
                    return (
                        <ShortUrlDomainItemContextMenu
                            item={row.original}
                            onEdit={() => handleEditDomain(row.original)}
                            onDelete={() => showDeleteConfirmation(row.original)}
                            onEnableHttps={() => showEnableHttpsConfirmation(row.original)}
                        />
                    )
                }
            }
        ]
    }, [])
    const queryClient = useQueryClient()
    const {setContextMenu} = useAppDispatch()

    useEffect(() => {
        setContextMenu([
            {
                label: "Add New Domain",
                icon: PlusCircle,
                action: () => EventEmitter.emit("ShortUrlDomainCreate"),
            },
        ])
    }, [setContextMenu])

    useEffect(() => {
        const onEvent = () => setShowCreateModal(true)

        const listener = EventEmitter.addListener("ShortUrlDomainCreate", onEvent)
        return () => {
            listener.remove()
        }
    }, [])

    const shortUrlDomainsRequest = useQuery(
        ["shortUrlDomains", page, limit, sorting],
        () => getShortUrlDomains(page, limit, sorting),
        {keepPreviousData: true}
    )

    const pointIPRequest = useQuery(
        ["pointIP"],
        () => getPointIP(),
        {keepPreviousData: true}
    )

    const deleteShortUrlDomainRequest = useMutation(removeShortUrlDomain)
    const enableHttpsForDomainRequest = useMutation(enableHttpsShortUrlDomain)

    useEffect(() => {
        if (shortUrlDomainsRequest.isSuccess) {
            setAvailableAttributes(shortUrlDomainsRequest.data.data)
            setPagination(shortUrlDomainsRequest.data.meta)
        }
    }, [shortUrlDomainsRequest.isSuccess, shortUrlDomainsRequest.data])

    useEffect(() => {
        if (pointIPRequest.isSuccess) {
            setPointDomationIP(pointIPRequest.data.short_url_domain_public_ip)
        }
    }, [pointIPRequest.data, pointIPRequest.isSuccess])

    useEffect(() => {
        setBreadcrumbs([
            {label: "Settings", link: "./settings"},
            {label: "Short Url Domains"},
        ])
        setSection("settings")
    }, [setBreadcrumbs, setSection])

    const handleLimitChange = useCallback((l) => {
        setPage(1)
        setLimit(l)
    }, [setPage, setLimit])


    const handleEditDomain = (attr) => {
        setCurrentModel(attr)
        setShowUpdateModal(true)
    }

    const showDeleteConfirmation = (attr) => {
        setCurrentModel(attr)
        setShowDeleteModal(true)
    }

    const showEnableHttpsConfirmation = (attr) => {
        setCurrentModel(attr)
        setShowEnableHttpsModal(true)
    }

    const deleteDomain = async () => {
        if (!currentModel) return;

        await deleteShortUrlDomainRequest.mutateAsync(currentModel.id)
        setShowDeleteModal(false)
        await queryClient.invalidateQueries("shortUrlDomains")
        setCurrentModel(null)
    }

    const enableHttpsForDomain = async () => {
        if (!currentModel) {
            return;
        }

        try {
            await enableHttpsForDomainRequest.mutateAsync(currentModel.id)
            setShowEnableHttpsModal(false)
            await queryClient.invalidateQueries("shortUrlDomains")
            setCurrentModel(null)
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.errors)
                AlertToastr.showErrorAlert(error.response.data.errors);
        }
    }

    const modelLabel = currentModel ? `"${currentModel.url}" domain` : "domain"

    return (
        <div className="pageHolder">
            <Alert severity="info" className="mb20">
                Point a record of domain to IP: {pointDomationIP}
            </Alert>
            <ListTable
                columns={tableColumns}
                data={availableAttributes}
                loading={shortUrlDomainsRequest.isLoading}
                fetching={shortUrlDomainsRequest.isFetching}
                onSort={setSorting}
                emptyDataText="No Domains Found"
                pagination={pagination}
                pageLimit={limit}
                onPageChange={setPage}
                onLimitChange={handleLimitChange}
                disableSearchBlock={true}
            />
            <DeleteModal
                loading={deleteShortUrlDomainRequest.isLoading}
                open={showDeleteModal}
                handleDelete={deleteDomain}
                handleClose={() => setShowDeleteModal(false)}
                instance={modelLabel}
            />
            <ConfirmModal
                loading={enableHttpsForDomainRequest.isLoading}
                open={showEnableHttpsModal}
                handleClose={() => setShowEnableHttpsModal(false)}
                message="Do you want to enable HTTPS?"
                handleConfirm={enableHttpsForDomain}
            />

            <SaveShortUrlDomainModal
                open={showCreateModal}
                handleClose={() => {
                    setShowCreateModal(false)
                }}
            />

            {
                currentModel && (
                    <SaveShortUrlDomainModal
                        defaultValues={currentModel}
                        open={showUpdateModal}
                        handleClose={() => {
                            setCurrentModel(null)
                            setShowUpdateModal(false)
                        }}
                    />
                )
            }
        </div>
    )
}

export default ShortUrlDomains
