// import React from 'react'
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
const enTranslation = require("./locales/en/translation.json")

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation
      }
    },
    lng: localStorage.getItem('language') || 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

export default i18n;
