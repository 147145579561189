import React, {useEffect, useMemo, useState} from 'react';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import {useQuery, useQueryClient} from 'react-query';
import {getCampaignIncomingMessages} from '../../api';
import {useParams} from 'react-router-dom';
import {useQueryState} from 'use-location-state';
import ListTable from '../../components/ListTable/ListTable';
import {toLocalFormat} from "../../Utils/dates";

const CampaignIncomingMessages = () => {
    const [logs, setLogs] = useState([]);
    const [page, setPage] = useQueryState('page', 1);
    const [limit, setLimit] = useQueryState('limit', 15);
    const [pagination, setPagination] = useState(null);
    const [sorting, setSorting] = useState([{key: 'updated_at', direction: 'desc'}]);
    const {campaignId, loggedUser} = useParams();
    const queryClient = useQueryClient();

    const tableColumns = [
        {
            Header: 'Lead Id',
            accessor: 'lead_id',
        },
        {
            Header: 'Body',
            accessor: 'body',
        },
        {
            Header: 'From',
            accessor: 'from_number'
        },
        {
            Header: 'To',
            accessor: 'to_number',
        },
        {
            Header: 'Cost',
            accessor: 'cost'
        },
        {
            Header: 'Answer Message',
            accessor: 'answer_message',
        },
        {
            Header: 'Created at',
            accessor: 'created_at',
            Cell: ({value, row}) => {
                return toLocalFormat(value)
            }
        }
    ];

    const tableValues = useMemo(() => {
        return logs;
    }, [logs]);

    const tableSorting = useMemo(() => {
        if (!sorting) return null;

        return sorting.map(item => ({
            id: item.key, desc: item.direction === 'desc'
        }))
    }, [sorting])

    const campaignLogsRequest = useQuery(
        ['campaignIncomingMessageView', campaignId, page, limit, sorting],
        () => getCampaignIncomingMessages(campaignId, page, limit, sorting),
        {keepPreviousData: true},
    );
    useEffect(() => {
        if (campaignLogsRequest.isSuccess) {
            setLogs(campaignLogsRequest.data.data);
            const paginationData = campaignLogsRequest.data.meta;
            if (paginationData.last_page < page) {
                setPage(1);
            }
            setPagination(paginationData);
        }
    }, [campaignLogsRequest.isSuccess, campaignLogsRequest.data, page, setPage]);

    const handleItemClick = item => {
        window.open(`/u/${loggedUser}/leads/${item?.lead_id}`)
    }

    return (
        <div className="campaignsPageHolder">
            <div className="timeLine">
                <div className="timeLineHeading mb15">
                    <h2>Incoming Messages</h2>
                    <Button
                        disabled={campaignLogsRequest.isFetching}
                        variant="contained"
                        color="primary"
                        startIcon={<RefreshIcon/>}
                        size="small"
                        onClick={() => queryClient.invalidateQueries('campaignIncomingMessageView')}
                    >
                        Refresh
                    </Button>
                </div>
                <ListTable
                    columns={tableColumns}
                    data={tableValues}
                    loading={campaignLogsRequest.isLoading}
                    fetching={campaignLogsRequest.isFetching}
                    isSelectionColumn={false}
                    onRowClick={handleItemClick}
                    onSort={setSorting}
                    emptyDataText="No Incoming Messages Found"
                    pagination={pagination}
                    pageLimit={limit}
                    onPageChange={setPage}
                    onLimitChange={setLimit}
                    initSort={tableSorting}
                    disableSearchBlock
                    disa
                />
            </div>
        </div>
    );
};

export default CampaignIncomingMessages;
