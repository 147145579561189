import React, {useState} from 'react';
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Row from "./Row";
import {toServerFormat} from "../../../Utils/dates";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import TestEmailModal from "../../modals/campaigns/steps/TestEmailModal";
import {useCampaignState} from "../../../Contexts/CampaignContext";
import EmailContentModal from "../../modals/campaigns/emails/EmailContentModal";

const EmailStep = (
    {
        item,
        onEdit = () => {
        },
        onDelete = () => {
        },
        onCopy = () => {
        },
        disableEdit = false,
        disableDelete = false,
        parentItem = null
    }) => {

    const [anchorEl, setAnchorEl] = React.useState(null)
    const [showDetails, setShowDetails] = useState(false)
    const [showTestEmailModal, setShowTestEmailModal] = useState(false)
    const open = Boolean(anchorEl)
    const {campaign} = useCampaignState()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleTestEmail = () => {
        setAnchorEl(null)
        setShowTestEmailModal(true);
    }

    const handleEdit = () => {
        setAnchorEl(null)
        onEdit({...item, parentItem})
    }

    const handleCopy = () => {
        setAnchorEl(null);
        onCopy({...item, parentItem})
    }

    const handleDelete = () => {
        setAnchorEl(null)
        onDelete({...item, parentItem})
    }

    const [showEmailContentModal, setShowEmailContentModal] = useState(false);

    const handleOpenEmailContent = (content) => {
        setShowEmailContentModal(true);
    }

    const handleCloseEmailContent = (content) => {
        setShowEmailContentModal(false);
    }

    return (
        <>
            <li className="stepsListItem">
                <div className="sliContent">
                    <div className="sliTitleHolder">
                        <div className="sliTitle">
                            {item?.name}
                            <span className={`status ${item?.status}`} style={{paddingLeft: '5px'}}>
                            ({item?.status_label})
                        </span>
                        </div>
                        <div className="sliSubMenu">
                            <IconButton aria-label="expand row" size="small" onClick={() => setShowDetails(s => !s)}>
                                {showDetails ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>}
                            </IconButton>
                            <IconButton onClick={handleClick}>
                                <MoreVertIcon/>
                            </IconButton>
                        </div>
                        {
                            item && (
                                <Menu
                                    id="fade-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={open}
                                    onClose={handleClose}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <MenuItem
                                        disabled={disableEdit}
                                        onClick={handleEdit}>
                                        Edit
                                    </MenuItem>
                                    <MenuItem
                                        disabled={!campaign || !['draft'].includes(campaign.status) ||
                                            !item || !item.hasOwnProperty("message") || !item.message}
                                        onClick={handleTestEmail}>
                                        Test Email
                                    </MenuItem>
                                    <MenuItem
                                        disabled={disableEdit}
                                        onClick={handleCopy}
                                    >
                                        Copy
                                    </MenuItem>
                                    <MenuItem
                                        disabled={disableDelete}
                                        onClick={handleDelete}
                                    >
                                        Delete
                                    </MenuItem>

                                </Menu>
                            )
                        }
                    </div>
                    {<Row label='Start Time:' value={toServerFormat(item.started_at)}></Row>}
                    {!Boolean(parentItem) && <Row label="Delay:" value={item.delay_label}></Row>}
                    {<Row label="Sender:" value={item.sender}></Row>}
                    {
                        <div
                            onClick={() => {
                                handleOpenEmailContent(item.message)
                            }}
                        >
                            <Row label="Subject:" value={item.subject}>
                            </Row>
                        </div>
                    }
                    <Collapse in={showDetails} timeout="auto" unmountOnExit>
                        <Box>
                            <Row label="Cost:" value={item.statistics?.cost_formatted}></Row>
                            <Row label="Revenue:" value={item.statistics?.revenue_formatted}></Row>
                            <Row label="Total Conversations:" value={item.statistics?.total_conversations}></Row>
                            <Row label="Profit:" value={item.statistics?.profit_formatted}></Row>
                            <Row label="Sent:" value={item.statistics?.sent}></Row>
                            <Row label="Delivered:" value={item.statistics?.delivered}></Row>
                            <Row label="Delivered, %:" value={item.statistics?.delivered_percent + ' %'}></Row>
                            <Row label="Opened:" value={item.statistics?.opened}></Row>
                            <Row label="Opened, %:" value={item.statistics?.opened_percent + ' %'}></Row>
                            <Row label="Clicked:" value={item.statistics?.clicked}></Row>
                            <Row label="Clicked, %:" value={item.statistics?.clicked_percent + ' %'}></Row>
                            <Row label="Errors:" value={item.statistics?.errors}></Row>
                            <Row label="Unsubscribe:" value={item.statistics?.unsubscribed}></Row>
                            <Row label="Unsubscribe, %:" value={item.statistics?.unsubscribed_percent + ' %'}></Row>
                            <Row label="Bounces:" value={item.statistics?.bounces}></Row>
                            <Row label="Complaints:" value={item.statistics?.complaints}></Row>
                            <Row label="Cool offs:" value={item.statistics?.cool_offs}></Row>
                            <Row label="Limit Cap:" value={item.limit_cap}></Row>
                            <Row label="Redirect URL:" value={item.redirect_to}></Row>
                            <Row label="Domain:" value={item.short_url_domain_label}></Row>
                        </Box>
                    </Collapse>
                </div>
            </li>
            {showTestEmailModal && <TestEmailModal
                open={showTestEmailModal}
                step={item}
                handleClose={() => {
                    setShowTestEmailModal(false)
                }}
            />
            }
            <EmailContentModal
                content={item.message}
                onClose={handleCloseEmailContent}
                isOpen={showEmailContentModal}
            >
            </EmailContentModal>
        </>
    )
}

export default EmailStep
