import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {testSmsCampaign} from "../../api";
import AlertToastr from "../../Utils/alert";
import {useMutation} from "react-query";
import {Controller, useForm} from "react-hook-form";
import {TextField} from "@material-ui/core";
import SmsCounter from "./SmsCounter";
import {useCampaignState} from "../../Contexts/CampaignContext";

const TestSmsModal = ({ open, step_id, text = "", handleClose = () => { } }) => {
    const {campaign} = useCampaignState()
    const [phone, setPhone] = useState("")
    const { control, watch } = useForm({
        mode: 'onTouched',
        defaultValues: {message: text}
    })
    const message = watch('message')

    const testSmsRequest = useMutation(testSmsCampaign)

    const handleSentSms = async () => {
        try {
            let params = { id: campaign.id, step_id, phone, message }

            const data = await testSmsRequest.mutateAsync(params)
            AlertToastr.showAlert(data.data)
            handleClose()
        } catch (error) {
            AlertToastr.showErrorResponseAlert(error)
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} className='modal'>
            <div className="modalBody">
                <div className="modalTitleHolder mb30">
                    <h2 className='mb15'>Test Sms</h2>
                </div>

                <TextField
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    id="ssms_phone"
                    label="Phone"
                    variant="outlined"
                    margin='normal'
                    placeholder="12483217851"
                />

                <Controller
                    name="message"
                    control={control}
                    defaultValue=''
                    render={({ onChange, onBlur, value }) => (
                        <>
                            <TextField
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                label="Message"
                                multiline
                                rows={4}
                                variant="outlined"
                                margin='normal'
                            />

                            <div style={{position: "relative"}}>
                                <SmsCounter
                                    text={value}
                                    styleParam={{position: "absolute", top: -28, right: 15, left: "initial"}}
                                />
                            </div>
                        </>
                    )}
                />

                <div className="btnHolder hasPadTop twoBtn">
                    <div className="part">
                        <Button disabled={testSmsRequest.isLoading} variant="contained" color="primary" fullWidth onClick={handleSentSms}>
                            {testSmsRequest.isLoading ? <CircularProgress size={24} color="inherit" /> : 'Send'}
                        </Button>
                    </div>
                    <div className="part">
                        <Button disabled={testSmsRequest.isLoading} variant="outlined" color="primary" fullWidth onClick={handleClose}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default TestSmsModal
