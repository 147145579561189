import React, {useEffect, useMemo, useState} from "react"
import DisabledValue from "../../../components/DisabledValue";
import ShortUrlDomainItemContextMenu from "../../../components/ShortUrlDomainItemContextMenu";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getSMSGateways, removeSMSGateway} from "../../../api";
import ListTable from "../../../components/ListTable/ListTable";
import DeleteModal from "../../../components/modals/DeleteModal";
import AddIntegrationModal from "./AddIntegrationModal";


const SmsList = () => {
    const [showUpdateModal, setShowUpdateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [gatewaysOptions, setGatewaysOptions] = useState([])
    const [currentModel, setCurrentModel] = useState(null)
    const [list, setList] = useState([])
    const [sorting, setSorting] = useState([])
    const queryClient = useQueryClient()

    const listRequest = useQuery(
        ["smsGateways", sorting],
        () => getSMSGateways(sorting),
        {keepPreviousData: true}
    )

    const handleEditItem = item => {
        if (item.default) {
            return;
        }
        setCurrentModel({
            connection_type: "sms",
            ...item,
            ...item.params,
        })
        setShowUpdateModal(true)
    }

    const showDeleteConfirmation = (item) => {
        setCurrentModel(item)
        setShowDeleteModal(true)
    }

    useEffect(() => {
        if (listRequest.isSuccess) {
            setList(listRequest.data.data)
            setGatewaysOptions(listRequest.data.campaign_gateway_list)
        }
    }, [listRequest.isSuccess, listRequest.data])

    const tableColumns = useMemo(() => {
        const gatewaysLabelsMapping = {}
        gatewaysOptions.forEach(item => {
            gatewaysLabelsMapping[item.key] = item.label
        })

        return [
            {
                Header: "SMS Gateway Name",
                accessor: "name",
                Cell: ({value, row}) => row.original.default ? <DisabledValue value={value}/> : value,
                className: "integrationTableColumn"
            },
            {
                Header: "Integration",
                accessor: "type_alias",
                Cell: ({value, row}) => {
                    const label = gatewaysLabelsMapping[row.original.type] ? value : ""
                    return row.original.default ? <DisabledValue value={label}/> : label
                },
                className: "integrationTableColumn"


            },
            {
                Header: "Status",
                accessor: "active",
                Cell: ({value, row}) => {
                    const label = (
                        <div className={value ? "chipLike success" : "chipLike"}>
                            <span className="chipLikeLabel">{value ? "Active" : "Inactive"}</span>
                        </div>
                    )
                    return row.original.default ? <DisabledValue value={label}/> : label
                },
                className: "integrationTableColumn"
            },
            {
                Header: "",
                id: "actions",
                className: "text-right",
                Cell: ({value, row}) => {
                    if (row.original.default) return null;
                    return (
                        <ShortUrlDomainItemContextMenu
                            item={row.original}
                            onEdit={() => handleEditItem(row.original)}
                            onDelete={() => showDeleteConfirmation(row.original)}
                        />
                    )
                }
            }
        ]
    }, [gatewaysOptions])

    const deleteRequest = useMutation(removeSMSGateway)

    const deleteItem = async () => {
        if (!currentModel) {
            return;
        }

        await deleteRequest.mutateAsync(currentModel.id)
        setShowDeleteModal(false)
        await queryClient.invalidateQueries("smsGateways")
        setCurrentModel(null)
    }

    return <div>
        <ListTable
            columns={tableColumns}
            data={list}
            loading={listRequest.isLoading}
            fetching={listRequest.isFetching}
            onSort={setSorting}
            emptyDataText={"No SMS Gateway"}
            disablePagination
            disableSearchBlock={true}
            onRowClick={handleEditItem}
        />
        <AddIntegrationModal
            showModal={showUpdateModal}
            handleClose={() => {
                setShowUpdateModal(false)
            }}
            integrationModel={currentModel}
        >
        </AddIntegrationModal>
        <DeleteModal
            open={showDeleteModal}
            handleDelete={deleteItem}
            handleClose={() => setShowDeleteModal(false)}
            instance={currentModel ? `"${currentModel.name}" SMS gateway` : "SMS gateway"}
        />
    </div>
}

export default SmsList
