import React from "react"
import { NavLink } from "react-router-dom"

const MenuItem = ({ item }) => {

    const {label, link, icon, action} = item

    if (link) return (
        <NavLink to={link} className="menuListItem">
            <img
                src={icon}
                className="ico"
                alt={label}
            />
            <span className="caption">{label}</span>
        </NavLink>
    )

    if (action) {
        return (
            <div className="menuListItem" onClick={action}>
                <img src={icon} className="ico" alt={label} />
                <span className="caption">{label}</span>
            </div>
        )
    }

    return null
}

export default MenuItem
