import { segmentFilterValue, segmentFilterType, segmentFilterExpression, systemSegmentFilterField } from "../enums";

export const getDefaultValueMap = () => ([
    {
        fieldName: "DEFAULT",
        value: [],
        valueType: segmentFilterValue.MULTI_STRING,
    }
]);

export const getCreatedAtRangeValueMap = () => ([
    {
        fieldName: "lead_type",
        value: null,
        valueType: segmentFilterValue.SELECT,
        nullable: true,
        castTo: "number",
    },
    {
        fieldName: "range",
        value: [],
        valueType: segmentFilterValue.SELECT,
    },
]);

export const getNonDeliveredSmsLeadsValueMap = () => ([
    {
        fieldName: "campaigns",
        value: null,
        valueType: segmentFilterValue.MULTI_SELECT,
        nullable: true,
    },
    {
        fieldName: "period",
        value: "",
        valueType: segmentFilterValue.SELECT,
        nullable: true,
    },
])

export const getCreatedAtCustomValueMap = () => ([
    {
        fieldName: "lead_type",
        value: null,
        valueType: segmentFilterValue.SELECT,
        nullable: true,
        castTo: "number",
    },
    {
        fieldName: "date_from",
        value: null,
        valueType: segmentFilterValue.DATE,
    },
    {
        fieldName: "date_to",
        value: null,
        valueType: segmentFilterValue.DATE,
    },
]);

export const getEngagementValueMap = () => ([
    {
        fieldName: "campaigns",
        value: null,
        valueType: segmentFilterValue.MULTI_SELECT,
        nullable: true,
    },
    {
        fieldName: "period",
        value: "",
        valueType: segmentFilterValue.SELECT,
        nullable: true,
    },
]);

export const getEngagementDateValueMap = () => ([
    {
        fieldName: "date_from",
        value: null,
        valueType: segmentFilterValue.DATE,
        nullable: true,
    },
    {
        fieldName: "date_to",
        value: null,
        valueType: segmentFilterValue.DATE,
        nullable: true,
    },
]);

export const getCampaignValueMap = () => ([
    {
        fieldName: "campaigns",
        value: null,
        valueType: segmentFilterValue.MULTI_SELECT,
        // nullable: true,
    },
    {
        fieldName: "period",
        value: "",
        valueType: segmentFilterValue.SELECT,
        nullable: true,
    },
]);

export const getCampaignStatusValueMap = () => ([
    {
        fieldName: "statuses",
        value: null,
        valueType: segmentFilterValue.MULTI_SELECT,
        // nullable: true,
    },
    {
        fieldName: "campaigns",
        value: null,
        valueType: segmentFilterValue.MULTI_SELECT,
        nullable: true,
    },
    {
        fieldName: "period",
        value: "",
        valueType: segmentFilterValue.SELECT,
        nullable: true,
    },
]);

export const getEmptySegment = () => ({
    type: null,
    field: null,
    expression: null,
    valuesMap: getDefaultValueMap(),
});

const checkIfFilterHasValue = (filter) => {
    if (
        filter.expression === segmentFilterExpression.EMPTY ||
        filter.expression === segmentFilterExpression.NOT_EMPTY
    ) {
        return true;
    }

    if (filter.type === segmentFilterType.CAMPAIGN_ATTRIBUTES) {
        const periodValueMap = filter.valuesMap.find((valueMap) => valueMap.fieldName === "period");
        if (periodValueMap.value === "custom") {
            const customDateValuesMap = [];
            const nonDateValuesMap = [];

            filter.valuesMap.forEach((valueMap) => {
                if (valueMap.fieldName === "date_from" || valueMap.fieldName === "date_to") {
                    customDateValuesMap.push(valueMap);
                } else {
                    nonDateValuesMap.push(valueMap);
                }
            });

            const hasCustomDateValue = customDateValuesMap.some((valueMap) => {
                return Boolean(valueMap?.value)
            });

            if (!hasCustomDateValue) {
                return false;
            }

            return nonDateValuesMap.every((valueMap) => {
                if (valueMap.nullable) {
                    return true;
                }
                return Boolean(valueMap?.value?.length);
            });
        }
    }

    if (
        filter.field === systemSegmentFilterField.CREATED_AT &&
        filter.expression === segmentFilterExpression.CUSTOM_DATES
    ) {
        return filter.valuesMap.some((valueMap) => {
            return Boolean(valueMap?.value)
        });
    }

    return filter.valuesMap.every((valueMap) => {
        if (valueMap.nullable) {
            return true;
        }
        return Boolean(valueMap?.value?.length);
    })
}

export const isFilterEmpty = (filter) => {
    const isValueNotEmpty = checkIfFilterHasValue(filter);
    return !filter.type || !filter.field || !filter.expression || !isValueNotEmpty;
}
