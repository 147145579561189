import React from 'react'
import InlineLoader from './loaders/InlineLoader'

const LoadingValue = ({value, renderedValue, suffix}) => {
    let render = renderedValue || value

    if (suffix && typeof render === 'string') {
        render = `${render} ${suffix}`
    }

    if (render === undefined) {
        render = <></>
    }

    return (value === 'loading' ? <InlineLoader/> : render)
}

export default LoadingValue
